import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Input from '../../Forms/Input'
import InputDatePicker from '../../Forms/InputDatePicker'
import Alert from '../../inc/Alert'
import DropDownInput from '../../Forms/Dropdown'
import $ from 'jquery'
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import LinkBtn from '../../inc/LinkBtn'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Tabs from '../../Widgets/Tabs/Tabs'
import { useParams } from 'react-router-dom'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import AgGrid from '../../grid/ag/ag-grid'
import FormValidator from '../../../inc/FormValidator'
import SimpleLoading from '../../Loading/SimpleLoading'
import Entities from './Entities'
import {ValidateData} from '../../../inc/Validation'
import Attributes from './Attributes'
import PriceDiscount from './PriceDiscount'
import EditGeneral from './EditGeneral'

let itemDiscountObj = null
let itemPricingObj = null
const EditItem = (props) => {

  //Components Level Data get APIS

  const {id} = useParams()
  const [editItem, setEditItem] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingEntity, setLoadingEntity] = useState(false)
  const [loadingVendor, setLoadingVendor] = useState(false)
  const [editItemDiscount, setEditItemDiscount] = useState({})
  const [editItemPricing, setEditItemPricing] = useState({})
  const [itemVendorGrid, setItemVendorGrid] = useState([])
  const [itemEntityGrid, setItemEntityGrid] = useState([])
  const [itemBarcode, setItemBarcode] = useState([])
  const[openEntity, setOpenEntity] = useState(false)

  // -----

  //Dropdowns -- States

  const [entityReason, setEntityReason] = useState([])
  const [purchaseUnit, setPurchaseUnit] = useState([])
  const [entityCode, setEntityCode] = useState([])
  const [entityName, setEntityName] = useState([])
  const [vendorCode, setVendorCode] = useState([])
  const [vendorName, setVendorName] = useState([])

  let item_source_id = 24

  // -----

  //API Reduction Calls
 
  let entityCodeNameListCalled = false
  let purchaseUnitListCalled = false
  let vendorsCodeNameListCalled = false
  let itemEntityReasonListCalled = false
  let itemObjCalled = false
  let itemPricingObjCalled = false
  let itemDiscountObjCalled = false
  let itemVendorObjCalled = false

  //End
  

 //Component-Rendered Functions:

  useEffect(() => { 
    get_entity_code_name_list()
    get_vendors_code_name_list()
    getItemObject() 
    getItemPricingObject()
    getItemDiscountObject()
    getItemVendorObject()
    get_item_entity_reason_list()  
    get_purchase_unit_list()
  }, [])

  useEffect(() => {
    if(editItem.itemmaster_id !== undefined){
      getItemEntityObject()
    } 
  }, [editItem.itemmaster_id])
  
  // -----
 
  //Validations

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [
        {name:'item_name', max: 255, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
        {name:'display_name', max: 255, displayName:Helper.getLabel(props.language,'display_name','Display Name'),types:['Required']}, 
        {name:'purchaseUoM', displayName:Helper.getLabel(props.language,'purchaseUoM','Purchase UoM'),types:['Required']}, 
        {name:'item_group_id', displayName:Helper.getLabel(props.language,'item_group','Item Group'),types:['Required']}, 
        {name:'taxcodes_id', displayName:Helper.getLabel(props.language,'taxcode','Tax Code'),types:['Required']}, 
        {name:'itemmaster_type', displayName:Helper.getLabel(props.language,'item_master','Item Master Type'),types:['Required']}, 
      ] 
    if(!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }
  validationConfigure()

  let navs = [
    {title:Helper.getLabel(props.language,'general','General'), isActive: true, },
    {title:Helper.getLabel(props.language,'price_&_discount','Price & Discount')}, 
    {title:Helper.getLabel(props.language,'attributes','Attributes')},
    {title:Helper.getLabel(props.language,'entity','Entity')},
    {title:Helper.getLabel(props.language,'vendor','Vendor')}, 
  ];
    
  const addComponentTop = () => {
    let items = [
      {title:Helper.getLabel(props.language,'item_id',"Item Id"),subtitle: id},
      {title:Helper.getLabel(props.language,'display_name',"Display Name"),subtitle: editItem.display_name},
      {title:Helper.getLabel(props.language,'name',"Name"),subtitle: editItem.item_name},
      {title:Helper.getLabel(props.language,'purchase_unit_of_measurement',"Purchase Unit Of Measurement"),subtitle: editItem.purchaseUoM ? getPurchaseTitleLabel() : ''}
    ]
    let tags = [
        {title:Helper.getLabel(props.language,'confirm','Confirmed')},
    ];
    return(
        <BlueCallToAction items={items} tags={tags}/>
    )
  }

const addComponentRight = () => {
  return (
    <MasterSidebar>
    <NoteAttachmentHistory notesource_integrator= {id}  attachmentsource_integrator ={id} source_id ={item_source_id} /> 
    </MasterSidebar>
  )
}
 
  const getPurchaseTitleLabel = () => {
    if(!document.getElementById('purchase_unit_id')) {
      return ''
    }
    let Result = ''
    let innerid = document.getElementById('purchase_unit_id').value
    purchaseUnit.forEach(element => {
      if (element.value == innerid){
        Result = element.label
      }
    }) 
    return Result
  }

  //get objects data
 

  const getItemObject = () => {
    if(id){  
      if(itemObjCalled){
        return
      }
      setLoading(true)
      let api = Api
      itemObjCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/item/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){
          let UPDATE_OBJ = {}
          UPDATE_OBJ = { 
              "item_name": res.data.data.name,
              "display_name": res.data.data.display_name,
              "description":res.data.data.description,
              "lastpurchaseprice":res.data.data.lastpurchaseprice,
              "purchaseUoM": res.data.data.purchaseUOM,
              "translationsource_id":res.data.data.translationsource_id,
              "ItemStyle_id": res.data.data.style_id,
              "item_configuration_id": res.data.data.configuration_id,
              "item_color_id": res.data.data.color_id,
              "item_size_id":res.data.data.size_id,
              "item_dimension_id": res.data.data.dimension_id,
              "notesource_id": res.data.data.notesource_id,
              "attachmentsource_id": res.data.data.attachmentsource_id,
              "item_group_id": res.data.data.itemgroup_id,
              "segment_combination_id":null,
              "itemmaster_id": res.data.data.itemmaster_id,
              "taxcodes_id": res.data.data.taxcodes_id,
              "item_buyergroup_id":res.data.data.itembuyergroup_id,
              "item_brand_id": res.data.data.itembrand_id,
              "itemmaster_type": res.data.data.itemmaster_type  
          }
          setLoading(false)
          setEditItem(UPDATE_OBJ)
          Helper.alert(res.data.message)    
        }else {
          setLoading(false)
        }
      }).catch((res) => { 
        setLoading(true)
      })
    }
  }
 
  const getItemVendorObject = () => {
    if(id){  
      if(itemVendorObjCalled){
        return;
      }
      setLoadingVendor(true)
      let api = Api
      itemVendorObjCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/itemvendor/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){  
          let UPDATED_GRID = []
          res.data.data.forEach((element) => {
            UPDATED_GRID.push({
              "unique_id": Date.now(),
              "vendor_id": element.vendor_id,
              "ispreferred": element.is_preferred === true ? "yes" : "no",
              "item_id": element.item_id,
              "vendor_code": element.vendor_code
            })
          })
          setLoadingVendor(false)
          setItemVendorGrid(UPDATED_GRID)   
        }
      }).catch((res) => { 
        // Helper.alert(res.response.data.message, 'error') 
        setLoadingVendor(false)
      }) 
    }
  }

  const getItemEntityObject = () => {
    setLoadingEntity(true)
      let api = Api
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/itementity/${editItem.itemmaster_id}`,{}).then(function(res){
        if(res.data.status ==="success"){  
          let UPDATED_GRID = []
          res.data.data.forEach((element) => {
            UPDATED_GRID.push({
              "unique_id": Date.now(),
              "entity_id": element.entity_id,
              "is_active": element.is_active == true ? "yes" : "no", 
              "itemmaster": element.itemmaster,
              "entity_code": element.entity_code,
              "comments": element.comments,
              "reason_id": element.reason_id
            })
          }) 
          setItemEntityGrid(UPDATED_GRID)   
          setLoadingEntity(false)
        }
      }).catch((res) => {  
        setLoadingEntity(false)
      }) 
  } 

  const getItemPricingObject = () => {
    if(id){  
      if(itemPricingObjCalled){
        return;
      }
      itemPricingObjCalled = true
      let api = Api
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/itemprice/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){   
          if(!ValidateData(res.data.data)){ 
            res.data.data.forEach((element) => { 
              setEditItemPricing(element)
              itemPricingObj = element
            }) 
          }
          if(ValidateData(res.data.data)){
            itemPricingObj = null
          }
          Helper.alert(res.data.message)
        }
      }) 
    }
  }
  
  const getItemDiscountObject = () => {
    if(id){  
      if(itemDiscountObjCalled){
        return;
      }
      let api = Api
      itemDiscountObjCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+`/getitem_discount/${id}`,{}).then(function(res){
        if(res.data.status ==="success"){  
          if(!ValidateData(res.data.data)){
            res.data.data.forEach((element) => { 
              setEditItemDiscount(element)
              itemDiscountObj = element
            }) 
          }
          if(ValidateData(res.data.data)){
            setEditItemDiscount({})
            itemDiscountObj = null
          }  
        }
      }) 
    }
  }

  const get_item_entity_reason_list = () => {
    if(itemEntityReasonListCalled){
      return;
    }
    let api = Api
    itemEntityReasonListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/vendorreason`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.reason_id,
          label: element.description
        })
      })
      setEntityReason(UPDATED_STATE)
      }
    })
   }

   const get_purchase_unit_list = () => {
    if(purchaseUnitListCalled){
      return;
    }
    let api = Api
    purchaseUnitListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.unit_id,
          label: `${element.description} - ${element.code}`
        })
      })
      setPurchaseUnit(UPDATED_STATE)
      }
    })
   }

   const get_entity_code_name_list = () => {
    if(entityCodeNameListCalled){
      return
    }
    let api = Api
    entityCodeNameListCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+`/search_entity`,{
      "code":"",
      "name":""
    }
    ).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE_CODE = []
        let UPDATED_STATE_NAME = []
        res.data.data.forEach((element) => {
          UPDATED_STATE_CODE.push({
          value: element.entity_id,
          label: element.code
        })
      })
      res.data.data.forEach((element) => {
        UPDATED_STATE_NAME.push({
        value: element.entity_id,
        label: element.name
      })
    })
      setEntityCode(UPDATED_STATE_CODE)
      setEntityName(UPDATED_STATE_NAME)
      }
    })
   }

   const get_vendors_code_name_list = () => {
    if(vendorsCodeNameListCalled){
      return
    }
    let api = Api
    vendorsCodeNameListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/vendor`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE_CODE = []
        let UPDATED_STATE_NAME = []
        res.data.data.forEach((element) => {
          UPDATED_STATE_CODE.push({
          value: element.vendor_id,
          label: element.code
        })
      })
      res.data.data.forEach((element) => {
        UPDATED_STATE_NAME.push({
        value: element.vendor_id,
        label: element.name
      })
    })
      setVendorCode(UPDATED_STATE_CODE)
      setVendorName(UPDATED_STATE_NAME)
      }
    })
   }


  //end

  //Update Item & Item Modules

  const ItemUpdateAPI = () => {
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiItemUrl+`/item/${id}`, {
      "item_name": editItem.item_name !== "" ? editItem.item_name : null,
      "display_name": editItem.display_name !== "" ? editItem.display_name : null,
      "description": editItem.description !== "" ? editItem.description : null,
      "lastpurchaseprice": editItem.lastpurchaseprice !== "" ? editItem.lastpurchaseprice : null,
      "purchaseUoM": document.getElementById('purchase_unit_id') ? parseInt(document.getElementById('purchase_unit_id').value) : null,
      "translationsource_id": editItem.translationsource_id,
      "ItemStyle_id": document.getElementById('style_id') ? parseInt(document.getElementById('style_id').value) : null,
      "item_configuration_id": document.getElementById('configuration_id') ? parseInt(document.getElementById('configuration_id').value) : null,
      "item_color_id": document.getElementById('color_id') ? parseInt(document.getElementById('color_id').value) : null,
      "item_size_id": document.getElementById('size_id') ? parseInt(document.getElementById('size_id').value) : null,
      "item_dimension_id": document.getElementById('dimension_id') ? parseInt(document.getElementById('dimension_id').value) : null,
      "notesource_id": editItem.notesource_id,
      "attachmentsource_id": editItem.attachmentsource_id,
      "item_group_id": document.getElementById('item_group_id_dropdown') ? parseInt(document.getElementById('item_group_id_dropdown').value) : null,
      "segment_combination_id":null,
      "taxcodes_id": document.getElementById('tax_code_id') ? parseInt(document.getElementById('tax_code_id').value) : null,
      "item_buyergroup_id": document.getElementById('buyer_group_id') ? parseInt(document.getElementById('buyer_group_id').value) : null,
      "item_brand_id": document.getElementById('brand_id') ? parseInt(document.getElementById('brand_id').value) : null,
      "itemmaster_type": document.getElementById('item_master_type_id') ? parseInt(document.getElementById('item_master_type_id').value) : null
  }).then(function(res){
      if(res.data.status ==="success"){
        Helper.alert(res.data.message)
        getItemObject()
      }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error') 
      getItemObject()
    }) 
  }

  const ItemDiscountUpdateAPI = () => { 
    if(itemDiscountObj !== null){
      if(editItemDiscount.itemdiscounttype_id === null) {
        Helper.alert('Item Discount Type field missing!', 'error');
        return;
      }
      let api = Api
      api.setUserToken()
      api.axios().put(Settings.apiItemUrl+`/itemdiscount/${editItemDiscount.item_discount_id}`, {
        "itemdiscounttype_id": document.getElementById('discount_type_id') ? parseInt(document.getElementById('discount_type_id').value) : null,
        "name": editItemDiscount.name !== "" ? editItemDiscount.name : null,
        "discount_unit": editItemDiscount.discount_unit ? parseInt(editItemDiscount.discount_unit) : null,
        "discount": editItemDiscount.discount ? parseInt(editItemDiscount.discount) : null,
        "effective_date": Helper.getDateForDb($('.effective_date_id').val()) !== "NaN-NaN-NaN" ? Helper.getDateForDb($('.effective_date_id').val())  : null,
        "expire_date":  Helper.getDateForDb($('.expiry_date_id').val()) !== "NaN-NaN-NaN" ? Helper.getDateForDb($('.expiry_date_id').val()) : null 
      }).then(function(res){
        if(res.data.status ==="success"){
          Helper.alert(res.data.message)
          getItemDiscountObject()
          console.log(res.data.message)
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error') 
        getItemDiscountObject()
      })
      }
      if(itemDiscountObj === null){
        if(ValidateData(editItemDiscount)){
          return;  
        }
        if(editItemDiscount.itemdiscounttype_id === null) {
          Helper.alert('Item Discount Type field missing!', 'error');
          return;
        }
          let api = Api
          api.setUserToken()
          api.axios().post(Settings.apiItemUrl+'/item_discount', {
            "item_id": parseInt(id),
            "itemdiscounttype_id": editItemDiscount.itemdiscounttype_id ? parseInt(editItemDiscount.itemdiscounttype_id) : null,
            "name": editItemDiscount.name !== "" ? editItemDiscount.name: null,
            "discount_unit": editItemDiscount.discount_unit !== "" ? parseInt(editItemDiscount.discount_unit) : null,
            "discount": editItemDiscount.discount !== "" ? parseInt(editItemDiscount.discount) : null,
            "effective_date": Helper.getDateForDb($('.effective_date_id').val()) !== "NaN-NaN-NaN" ? Helper.getDateForDb($('.effective_date_id').val()) : null,
            "expire_date": Helper.getDateForDb($('.expiry_date_id').val()) !== "NaN-NaN-NaN" ? Helper.getDateForDb($('.expiry_date_id').val()) : null
          }).then(function(res){
            if(res.data.status ==="success"){
              Helper.alert(res.data.message)
              getItemDiscountObject() 
            }
          }).catch((res) => { 
            Helper.alert(res.response.data.message, 'error') 
            getItemDiscountObject()
          }) 
      }
    }

  const ItemPricingUpdateAPI = () => { 
    if(itemPricingObj !== null){ 
      if(editItemPricing.pricing_type === null) {
        Helper.alert('Item Pricing Type field missing!', 'error');
        return;
      }
      let api = Api
      api.setUserToken()
      api.axios().put(Settings.apiItemUrl+`/itemprice/${editItemPricing.itempricing_id}`, {
        "pricing_type": document.getElementById('price_type_id') ? parseInt(document.getElementById('price_type_id').value) : null,
        "price_unit": editItemPricing.pricing_unit !== null ? parseInt(editItemPricing.pricing_unit) : null,
        "price": editItemPricing.price !== null ? parseInt(editItemPricing.price) : null
      }).then(function(res){
        if(res.data.status ==="success"){
          // Helper.alert(res.data.message)
          console.log(res.data.message)
          getItemPricingObject()
        }
      }).catch((res) => { 
        getItemPricingObject()
        Helper.alert(res.response.data.message, 'error') 
      }) 
      }
      if(itemPricingObj === null) {
        if(ValidateData(editItemPricing)) { 
          return;
        }
        if(editItemPricing.pricing_type === null) {
          Helper.alert('Item Pricing Type field missing!', 'error');
          return;
        }
          let api = Api
          api.setUserToken()
          api.axios().post(Settings.apiItemUrl+'/item_pricing',{
            "item_id": parseInt(id),
            "pricing_type": document.getElementById('price_type_id') ? parseInt(document.getElementById('price_type_id').value) : null,
            "price_unit": editItemPricing.pricing_unit ? parseInt(editItemPricing.pricing_unit) : null,
            "price": editItemPricing.price ? parseInt(editItemPricing.price) : null
        }).then(function(res){
          if(res.data.status ==="success"){
            // Helper.alert(res.data.message)
            getItemPricingObject()
            console.log(res.data.message)
          }
        }).catch((res) => { 
          getItemPricingObject()
          Helper.alert(res.response.data.message, 'error') 
        })  
      }
    }
 
   //End
 
  //Gird CRUD Operation

  const OpenEntityHandler = () => {
    setOpenEntity(true)
  }

  const DeleteEntity = (allRows) => {
    let api = Api
    api.setUserToken()
    allRows.forEach(element => {
      api.axios().delete(Settings.apiItemUrl+`/itementity/${editItem.itemmaster_id}/${element.entity_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            getItemEntityObject()
          }else{
            getItemEntityObject()
          }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error') 
        getItemEntityObject()
      })
  })
  }
  
  const DeleteHandler = (allRows) => {
    let api = Api
    api.setUserToken()
    allRows.forEach(element => {
      api.axios().delete(Settings.apiItemUrl+`/itemvendor/${id}/${element.vendor_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            getItemVendorObject()
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        getItemVendorObject()
      })
  })
  }

  const SaveHandlerEntity = (rowItem) => { 
    let api = Api
    api.setUserToken()
    rowItem.forEach(element => {
      api.axios().put(Settings.apiItemUrl+`/itementity/${element.itemmaster}/${element.entity_id}`,{
        "IsActive": element.is_active == "yes" ? true : false,
        "Reason_Id":parseInt(element.reason_id),
        "comments": element.comments
    }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getItemEntityObject()
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      getItemEntityObject()
    })
  }) 
  }

  const GridSaveHandler = (rowItem) => { 
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.unique_id || item.unique_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){ 
        addNewData(newData);
    }
    if(updatedData.length>=1){ 
        updateData(updatedData);
    }
  }

  const addNewData = (data) => { 
    let api = Api
    api.setUserToken()
    data.forEach(element => {
      api.axios().post(Settings.apiItemUrl+`/itemvendor`,[{
        "vendor_id": parseInt(element.vendor_id),
        "ispreferred": element.ispreferred == "yes" ? true : false,
        "item_id": parseInt(id)
      }]).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getItemVendorObject()
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      getItemVendorObject()
    })
  }) 
  }

  const updateData = (data) => {
    let api = Api
    api.setUserToken()
    data.forEach(element => {
      api.axios().put(Settings.apiItemUrl+`/itemvendor/${id}/${element.vendor_id}`,{
        "vendor_id": parseInt(element.vendor_id),
        "ispreferred": element.ispreferred == "yes" ? true : false,
        "item_id": parseInt(id)
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          getItemVendorObject()
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      getItemVendorObject()
    })
  }) 
  }

//End

//Grid Initialization

  const Editable = (event) => {
    if(!event.data.vendor_id) {
      return true
    }
    return false
  }

  const Columns_Headings_ENTITY = [
    {field:'entity_id', headerName:'Name', inputType: "dropdown", options: entityName, editable:false},
    {field:'entity_code', headerName:'Code', editable: false},
    {field:'is_active', headerName:'Active', inputType: "checkbox"},
    {field:'reason_id', headerName: 'Reason', inputType: "dropdown", options: entityReason},
    {field:'comments', headerName: 'Comments' },
  ]

  const Columns_Headings_VENDOR = [
    {field:'vendor_id', headerName:'Name', inputType: "dropdown", options: vendorName, editable: Editable},
    {field:'vendor_code', headerName:'Code', editable:false},
    {field:'ispreferred', headerName:'Preferred', inputType: "checkbox"}, 
  ]

//End

//Modules Rendering

  const VendorsModule = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'vendor',"Vendor")}>
        {loadingVendor ? <SimpleLoading /> : (
          <div className='container-fluid vendor'>
            <AgGrid data={itemVendorGrid} onDelete={DeleteHandler} onSave={GridSaveHandler} id="vendors-grid" hideDelete={false} header={Columns_Headings_VENDOR} />
          </div>  
        )}
      </Collapse> 
    )
  }

  const EntityModule = () => {
    return(
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'entity',"Entity")}>
        {loadingEntity ? <SimpleLoading /> : (
          <Fragment>
            <div className='container-fluid entity'>
              <AgGrid data={itemEntityGrid} onSave={SaveHandlerEntity} onAddClick={OpenEntityHandler} onDelete={DeleteEntity} id="entity-grid" hideDelete={false} header={Columns_Headings_ENTITY} />
            </div>
            {openEntity ? <Entities reloadGrid={getItemEntityObject} setOpenEntites={setOpenEntity} dataExportable = {false} itemMaster_id={editItem.itemmaster_id} /> : ""}
          </Fragment>
        )} 
      </Collapse>
    )
  }

//End

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item.item_edit_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  const SaveHandler = () => { 
    let editItemValid = { 
      "item_name": editItem.item_name,
      "display_name": editItem.display_name,
      "purchaseUoM": editItem.purchaseUoM,
      "item_group_id":  document.getElementById('item_group_id_dropdown'),
      "taxcodes_id":  document.getElementById('tax_code_id'),
      "itemmaster_type":  document.getElementById('item_master_type_id') 
    }
    let validData = {
      ...editItemValid
    }
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    } 
    $('.vendor .rs_grid_btn_save').trigger('click'); 
    $('.entity .rs_grid_btn_save').trigger('click'); 
    ItemUpdateAPI()
    ItemDiscountUpdateAPI()
    ItemPricingUpdateAPI()
  }

  return (
    <div className='container-fluid'>
      <NavigationHeder title={ `<span style="color:#000;">${Helper.getLabel(props.language,'Edit Employee',"Edit Item")} #</span> ${editItem.item_name}` }>
        <LinkBtn isActive= {false} to="/items" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canEdit(frontendIds.masterData.item_master_modules.item.item_edit_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
      {loading ? <SimpleLoading /> : (
        <Tabs navs={navs} showAll={true} scrollOffset="300" addComponentTop = {addComponentTop} addComponentRight={addComponentRight}>
          <Fragment><EditGeneral purchaseUnit={purchaseUnit} id={id} itemBarcode={itemBarcode} setItemBarcode={setItemBarcode} editItem={editItem} setEditItem={setEditItem} /></Fragment>
          <Fragment>{<PriceDiscount editItemDiscount={editItemDiscount} setEditItemDiscount={setEditItemDiscount} setEditItemPricing={setEditItemPricing} editItemPricing={editItemPricing} />}</Fragment>
          <Fragment>{<Attributes editItem={editItem} setEditItem={setEditItem} />}</Fragment> 
          <Fragment>{EntityModule()}</Fragment> 
          <Fragment>{VendorsModule()}</Fragment>
        </Tabs>
      )} 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.item_master_modules.item.item_edit_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditItem, SecurityOptions))))