import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../inc/ActionsTypes';
import RsWithRouter from '../inc/RsWithRouter';
import SimpleLoading from '../Loading/SimpleLoading';

class LogoutComponent extends Component {
    componentDidMount(){
        this.doLogout();
    }
    doLogout(){
        this.props.logOut();
    }
    render() {
        if(!this.props.auth.isLogin){
            this.props.rs_router.navigate('/')
        }
        return (
            <SimpleLoading/>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logOut:() => {
            dispatch({type:ActionTypes.SET_LOGOUT}) 
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) ( RsWithRouter(LogoutComponent) );