import React, {useState, useEffect, Fragment} from 'react';
import MasterComonent from '../../Backend/MasterComonent';
import Settings from '../../../inc/Settings'; 
import { connect, useSelector } from 'react-redux'; 
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import '../User Container/UserContainer.css'
import Button from '../../inc/Button';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import LinkBtn from '../../inc/LinkBtn'; 
import $ from 'jquery'
import { useDispatch } from 'react-redux';
import Collapse from '../../inc/Collapse';
import DropDownInput from '../../Forms/Dropdown';
import Delete from '../../../assets/delete.svg'
import Plus from '../../../assets/add-new.svg'
import { gridIdActions } from '../../../store/grid-id';
import { useNavigate } from 'react-router-dom';
import Popup from '../../Popup/Popup';
import AgGrid from '../../grid/ag/ag-grid';
import Input from '../../Forms/Input'; 
import Treeview from '../../Treeview/Treeview';
import FormValidator from '../../../inc/FormValidator';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import Checkbox from '../../Forms/Checkbox';
import SimpleLoading from '../../Loading/SimpleLoading';
import { ValidateData } from '../../../inc/Validation';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';

const NewUser = ( props) => {

const navigate = useNavigate()
const dispatch = useDispatch()
const [Lang, setLang] = useState([]) 
const [userrolesGrid, setUserRolesGrid] = useState([])
const showRolesGrid = useSelector(state => state.gridIdFlow.rolesListShow)
const [languages, setLanguages] = useState([])
const [userType, setUserType] = useState([])
const [rolestructure, setRoleStructure] = useState([])
const [selectedRoles, setSelectedRoles] = useState([])
const [userId, setUserId] = useState(null)
const SelectedRole_Id = useSelector(state => state.gridIdFlow.selected_role_id) 

  const [user, setUser] = useState({
    first_name: null,
    last_name: null,
    middle_name: null,
    user_name: null,
    enable: null,
    is_deleted:false,
    SID: null,
    network_domain: null,
    network_alias: null,
    language_id: null,
    usertype: null
  }) 
  const [loading, setLoading] = useState(false)

  //API Reduction Called
  let userRolesCalled = false
  let languagesListCalled = false
  let userGridCalled = false
  let userTypeCalled = false
   //End
   
   useEffect(() => {
    user_roles()
    get_languages_list()
    user_roles_grid()
    get_user_type_list()
   }, [])
 
   const user_roles_grid = () => {
    if(userGridCalled){
      return
    }
    let api = Api
    userGridCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiBaseUrl+'/role',{}).then(function(res){
      if(res.data.status==='success'){  
        let UPDATED_STATE_GRID = []
        res.data.data.forEach(element => {
           UPDATED_STATE_GRID.push({ 
            "description":  element.description, 
            "name":  element.name, 
            "role_id" : element.role_id,
           })
        })
        setUserRolesGrid(UPDATED_STATE_GRID) 
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'user_name', displayName:Helper.getLabel(props.language,'user_name','User Name'),types:['Required']}, 
        {name:'first_name', displayName:Helper.getLabel(props.language,' first_name','First Name'),types:['Required']},  
        {name:'last_name', displayName:Helper.getLabel(props.language,'last_name','Last Name'),types:['Required']},
        {name:'enable', displayName:Helper.getLabel(props.language,'enable','Enable'),types:['Required']},
        {name:'language_id', displayName:Helper.getLabel(props.language,'language','Language'),types:['Required']},
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()

   const user_roles = () => {
    if(userRolesCalled){
      return;
    }
    let api = Api
    userRolesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiBaseUrl+'/role',{}).then(function(res){
      if(res.data.status==='success'){  
        setRoleStructure(res.data.data) 
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  } 

  const get_user_type_list = () => {
    if(userTypeCalled){
      return;
    }
    let api = Api
    userTypeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/usertype',{}).then(function(res){
      if(res.data.status==='success'){   
        let USERS_TYPE_API_LIST = []
        res.data.data.forEach(item => {  
          USERS_TYPE_API_LIST.push({
           value: item.id,
           label: item.name
         })
        })
        setUserType(USERS_TYPE_API_LIST)
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const get_languages_list = () => {
    if(languagesListCalled){
      return;
    }
    let api = Api
    languagesListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiBaseUrl+'/language',{}).then(function(res){
      if(res.data.status==='success'){   
        let Languages_API_LIST = []
        res.data.data.forEach(item => {  
          Languages_API_LIST.push({
           value: item.languange_Id,
           label: item.language
         })
        })
        setLanguages(Languages_API_LIST)
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const Columns_Headings_Roles = [
    {field:'name', headerName: 'Role Name'},
    {field:'description', headerName: 'Role Description'}, 
  ]

const onChangeUser = (event) => {
  let User = event.target.value
  setUser((prevState) => {
    return {...prevState, user_name: User}
  })
}

const LanguageHandler = (event) => {
  let lang = event.target.value
  setUser((prevState) => {
    return {...prevState, language_id: document.getElementById('language_id').value}
  })
}

const UserTypeHandler = (event) => {
  let user = event.target.value
  setUser((prevState) => {
    return {...prevState, usertype: document.getElementById('user_type_id').value}
  })
}
      
const onChangefName = (event) => {
  let FirstName = event.target.value
  setUser((prevState) => {
    return {...prevState, first_name: FirstName}
  })
}
 
let gridObject ;

const GridAPI = (gridObj) => {
  gridObject = gridObj
}
  
const Callbuttons = () => {
  return <Button title='Selected' onClick={SelectedRolesList} />;
}  

const CloseHandler = (event) => { 
  dispatch(gridIdActions.rolesGridShow({payload: false})) 
  $('html').removeClass('rs_popup_open');
}

const onChangeNetwork = (event) => {
  let NetworkDomain = event.target.value
  setUser((prevState) => {
    return {...prevState, network_domain: NetworkDomain}
  })
}

const onChangeMName = (event) => {
  let MiddleName = event.target.value
  setUser((prevState) => {
    return {...prevState, middle_name: MiddleName}
  })
}

const onChangelName = (event) => {
  let LastName = event.target.value
  setUser((prevState) => {
    return {...prevState, last_name: LastName}
  })
}
 
const onChangeEnable = (name, value) => { 
  if(value == "on"){
    setUser((prevState) => {
      return {...prevState, enable: true}
    })
  }else {
    setUser((prevState) => {
      return {...prevState, enable: false}
    })
  }
}
  
const AssignRoles = () => { 
    dispatch(gridIdActions.rolesGridShow({payload: true})) 
}

let item_id = null

const DeleteRole = () => {
  if(item_id){ 
  let newData=  [];
  selectedRoles.forEach(item => {
    if(item_id.id != item.id){
      newData.push(item)
  }
  })
  setSelectedRoles(newData)
} 
}

const onItemClicked = (itemId) => { 
 item_id = itemId
}
  
const checkRoles = (array, itemId) => {
  let found;
  array.forEach(element => {
    if(element.role_id !== itemId){
      found = true
    }else {
      found = false
    }
}); 
return found
}

const SelectedRolesList = () => { 
  let UPDATED_ROLES = [];
  let roleArray = [];
  let dataListFormPopup = []; 
  let HierarchyRoles = []
dataListFormPopup = gridObject.api.getSelectedRows()
dataListFormPopup.forEach((element) => {
  UPDATED_ROLES.push({ 
    "name": element.name,
    "role_id": element.role_id,
    "user_id": null
  })
})
for(let i=0; i < UPDATED_ROLES.length; i++){
    if(checkRoles(UPDATED_ROLES, i)){
        roleArray.push(UPDATED_ROLES[i])
    } 
} 
  roleArray.forEach(element => {
    HierarchyRoles.push({
        id: element.role_id,
        title:element.name,
        showChild:false,
        items:[]
    })
  })
  setSelectedRoles( [].concat(selectedRoles, HierarchyRoles) )
  CloseHandler()
}

const SaveHandler = () => { 
  let validData = {
    ...user
  }
  if(!validator.isValid(validData)){
    validator.displayMessage();
    validator.reset() 
    return;
}
if(ValidateData(selectedRoles)){
  Helper.alert('Please Assign Any Roles To User!', 'error')
  return;
}
setLoading(true)
  let api = Api
  api.setUserToken()   
  api.axios().post(Settings.apiUrl+'/user',{
    first_name: user.first_name,
    last_name: user.last_name,
    middle_name: user.middle_name,
    user_name: user.user_name,
    enable: user.enable,
    is_deleted:false,
    SID: user.SID,
    network_domain: user.network_domain,
    network_alias: user.network_alias,
    language_id: user.language_id,
    usertype: userType ? userType.find(val => val.label == "Internal").value : null
  }).then(function(res){
    if(res.data.status==='success'){ 
      let UPDATED_ROLES_IDS = []
      selectedRoles.forEach((element) => {
        element.user_id = res.data.id
        UPDATED_ROLES_IDS.push({
          "role_id": element.id,
          "user_id": res.data.User_id
        })
      }) 
      api.axios().post(Settings.apiBaseUrl+'/assign_role', UPDATED_ROLES_IDS).then(function(res){
      if(res.data.status==='success'){ 
        setLoading(false)
        navigate('/users')
        Helper.alert(res.data.message)
      }
    }).catch((res) => { 
      setLoading(false)
    Helper.alert(res.response.data.message, 'error') 
    }) 
    }
}).catch((res) => { 
  Helper.alert(res.response.data.message, 'error')
})  
}

let security = props.security;
let frontendIds = Gui_id_list;
if(!security.canView(Gui_id_list.administrationForms.users.users_create_screen)){
  return <Fragment>
  <Alert message='NO ACCESS' type='danger' />
  </Fragment>
}

  return (
    <React.Fragment> 
    <div dlassName='new-user-section'>
      <div className='container-fluid'>
        <NavigationHeder title={Helper.getLabel(props.language,'new_user',"New User")}>
            <LinkBtn isActive= {false} to="/users" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
            <Button isDisable={!security.canCreate(frontendIds.administrationForms.users.users_create_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder>
        {loading ? <SimpleLoading /> : ''}
        <Collapse title={Helper.getLabel(props.language,11217,'General')} open={true}>
    
    <section className='users-fields'>
    <div className='container-fluid'>
    <div className='row'>
 
     <div className='col-12'>
     <div className='row users-fields-form gy-0 mt-2 mb-2'>
 
     <div className='col-lg-3 col-md-3'> 
      <Input label={Helper.getLabel(props.language,'user_USER_SETUP',"User Email")} name='text' required={true} hasError={validator.hasError('user_name')} placeholder='Enter Your User ID' onChange={onChangeUser} value={user.user_name} />
     </div>
 
     <div className='col-lg-3 col-md-3'> 
      <Input label={Helper.getLabel(props.language,'network_domain_USER_SETUP',"Network Domain")} type='text' placeholder='Enter Your Network Domain' onChange={onChangeNetwork} value={user.network_domain} />
     </div>
 
     <div className='col-lg-3 col-md-3'> 
      <Input label={Helper.getLabel(props.language,'first_name_USER_SETUP',"First Name")} type='text' hasError={validator.hasError('first_name')} required={true} placeholder='Enter Your First Name' onChange={onChangefName} value={user.first_name} />
     </div>

     <div className='col-lg-3 col-md-3'> 
      <Input label={Helper.getLabel(props.language,'middle_name_USER_SETUP',"Middle Name")} type='text' placeholder='Please Enter Your Middle Name' onChange={onChangeMName} value={user.middle_name} />
      </div> 
  
     </div>
 
     <div className='row users-fields-form gy-0 mb-2'>
 
      <div className='col-lg-3 col-md-3'> 
      <Input label={Helper.getLabel(props.language,'last_name_USER_SETUP',"Last Name")} type='text' required={true} hasError={validator.hasError('last_name')} placeholder='Please Enter Your Last Name' onChange={onChangelName} value={user.last_name} />
      </div> 
 
      <div className='col-lg-3 col-md-3 mt-auto'> 
      <DropDownInput label={Helper.getLabel(props.language,'default_language_USER_SETUP',"Default Language")} required={true}  options={languages} hasError={validator.hasError('language')} onChange={LanguageHandler} value={user.language_id} id='language_id' />
      </div>

      <div className='col-lg-2 col-md-2 mt-4'>
        <Checkbox type='checkbox' hasError={validator.hasError('enable')}  className='me-4' onChange={onChangeEnable} isChecked={user.enable} required={true} label={Helper.getLabel(props.language,'enable_USER_SETUP',"Enable")} /> 
      </div>

      </div>
 
     </div>
     </div>
     </div>
  </section>
  </Collapse> 
      </div>

      <div className='container-fluid mt-4'> 
      <Collapse title='Users Roles' open={true}>
      <section className='user-roles-section'>
      <div className='container-fluid'>
      <div className='row'>
          <div className='col-lg-12 gy-2 border-bottom'>
              <div className='roles-buttons d-flex justify-content-start'>
                 <button type='button' className='add-user-role ms-2' onClick={AssignRoles}><img src={Plus} className='img-fluid mb-1 roles-icons' />{Helper.getLabel(props.language,'assign_role_USER_SETUP',"Assign Role")}</button>
                 <button type='button' className='delete-role ms-2' onClick={DeleteRole}><img src={Delete} className='img-fluid mb-1 roles-icons' />{Helper.getLabel(props.language,'delete_role_USER_SETUP',"Delete Role")}</button>
              </div>
          </div>
          <div className='col-lg-4'> 
            <div className='user-treeview-hierarchy ps-4 pt-2'>
              <Treeview items={selectedRoles} onClick={onItemClicked} /> 
            </div>
          </div>
      </div>
  </div>
      </section>
   </Collapse> 
      </div>

    </div> 
    {showRolesGrid && 
      <div className='search_grid_height'> 
      <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
      <div className='rs_create_users_grid'>
      <AgGrid id="rs_popup_open_users_roles" afterDeleteButtons={Callbuttons} allBtnHide={true} viewOnly={true} onGridReady={GridAPI} header={Columns_Headings_Roles} data={userrolesGrid} />
      </div>
      </Popup>
    </div>
  }
  </React.Fragment>
  )
}

  
  const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.administrationForms.users.users_create_screen
  }
  
  
  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(NewUser, SecurityOptions))))