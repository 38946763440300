import React, { Component } from "react";

import Helper from "../../../inc/Helper";
import ChevronLeft from "../../../assets/chevron-left-single.svg";
import ChevronLeftDouble from "../../../assets/chevron-left-double.svg";
import ChevronRight from "../../../assets/chevron-right-single.svg";
import ChevronRightDouble from "../../../assets/chevron-right-double.svg";

// PROPS
// totalPages: number
// onPageChange: callback fn

export default class ModernPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumbers: [],
    };
  }

  componentDidMount() {
    this.setPageNumbers();
  }

  setPageNumbers = () => {
    const { totalPages } = this.props;
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    this.setState({ pageNumbers });
  };

  handleMoveToFirst = () => {
    this.props.onPageChange(1);
  };

  handleMoveToLast = () => {
    const { totalPages } = this.props;
    this.props.onPageChange(totalPages);
  };

  handlePrevious = () => {
    const { currentPage } = this.props;
    if (currentPage > 1) {
      this.props.onPageChange(currentPage - 1);
    }
  };

  handleNext = () => {
    const { currentPage, pageNumbers } = this.state;
    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      this.props.onPageChange(currentPage + 1);
    }
  };

  getVisiblePageNumbers = () => {
    const { currentPage, pageNumbers } = this.state;
    const visiblePageNumbers = [];
    const startIndex = currentPage - 2;
    const endIndex = currentPage + 2;

    for (let i = startIndex; i <= endIndex; i++) {
      const pageNumber = pageNumbers[i];
      if (pageNumber >= 1 && pageNumber <= pageNumbers.length) {
        visiblePageNumbers.push(pageNumber);
      }
    }

    return visiblePageNumbers;
  };

  render() {
    const { currentPage } = this.state;
    const visiblePageNumbers = this.getVisiblePageNumbers();

    return (
      <div className="modern-pagination">
        <button
          className={`arrow-button arrow-button-leftmost ${
            currentPage === 1 ? "disabled" : ""
          }`}
          onClick={this.handleMoveToFirst}
        >
          <img
            src={Helper.isRtl() ? ChevronRightDouble : ChevronLeftDouble}
            alt="move to first"
          />
        </button>
        <button className={`arrow-button`} onClick={this.handlePrevious}>
          <img
            src={Helper.isRtl() ? ChevronRight : ChevronLeft}
            alt="previous"
          />
        </button>
        {visiblePageNumbers.map((pageNumber) => {
          let className = "page-number";
          if (pageNumber === currentPage) {
            className += " active";
          }
          return (
            <button
              key={pageNumber}
              className={className}
              onClick={() => this.props.onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
        <button className={`arrow-button`} onClick={this.handleNext}>
          <img src={Helper.isRtl() ? ChevronLeft : ChevronRight} alt="next" />
        </button>
        <button
          className={`arrow-button arrow-button-rightmost  ${
            currentPage === this.props.totalPages ? "disabled" : ""
          }`}
          onClick={this.handleMoveToLast}
        >
          <img
            src={Helper.isRtl() ? ChevronLeftDouble : ChevronRightDouble}
            alt="move to last"
          />
        </button>
      </div>
    );
  }
}
