import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
  cartCount: 0,
  wishlistCount: 0,
  wishlistIds: [],
  checkoutLineIds: [],
  searchString: "",
  showEditNewCartModal: false,
  showEditOldCartModal: false,
  activeCartItem: null,
  didCartUpdate: false,
};
const PRShopReducers = (state = DefaultState, actions) => {
  if (actions.type === ActionTypes.CHANGE_CART_COUNT) {
    console.log(actions.payload);
    state = {
      ...state,
      cartCount: actions.payload,
    };
  }
  if (actions.type === ActionTypes.CHANGE_WISHLIST_COUNT) {
    state = {
      ...state,
      wishlistCount: actions.payload,
    };
  }
  if (actions.type === ActionTypes.UPDATE_WISHLIST_IDS) {
    state = {
      ...state,
      wishlistIds: [...actions.payload],
    };
  }
  if (actions.type === ActionTypes.UPDATE_CHECKOUT_LINE_IDS) {
    state = {
      ...state,
      checkoutLineIds: [...actions.payload],
    };
  }
  if (actions.type === ActionTypes.UPDATE_SEARCH_STRING) {
    state = {
      ...state,
      searchString: actions.payload,
    };
  }
  if (actions.type === ActionTypes.SHOW_EDITNEWCART_MODAL) {
    state = {
      ...state,
      showEditNewCartModal: true,
    };
  }
  if (actions.type === ActionTypes.CLOSE_EDITNEWCART_MODAL) {
    state = {
      ...state,
      showEditNewCartModal: false,
    };
  }
  if (actions.type === ActionTypes.SHOW_EDITOLDCART_MODAL) {
    state = {
      ...state,
      showEditOldCartModal: true,
      activeCartItem: { ...actions.payload },
    };
  }
  if (actions.type === ActionTypes.CLOSE_EDITOLDCART_MODAL) {
    state = {
      ...state,
      showEditOldCartModal: false,
      activeLineId: null,
    };
  }
  if (actions.type === ActionTypes.UPDATE_ACTIVRCARTITEM) {
    state = {
      ...state,
      activeCartItem: { ...actions.payload },
    };
  }
  if (actions.type === ActionTypes.TOGGLE_DIDCARTUPDATE) {
    state = {
      ...state,
      didCartUpdate: !state.didCartUpdate,
    };
  }
  return state;
};
export default PRShopReducers;
