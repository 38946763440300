import React from 'react'
import './GeneralInfo.css'
import CollapsedAddress from '../Collapsed Address/CollapsedAddress'

const GeneralInfo = () => {
  return (
    <CollapsedAddress title='General Information' defaultExpanded='false'>

    <div className='general-details'>
    <div className='container'>
    <div className='row gy-0'>

    <div className='col-12'>
    <div className='row general-details-form gy-0 mt-2 mb-2'>

    <div className='col-lg-4 col-md-6'>
     <label>Latitude </label>
     <input type='number' placeholder='0012' />
    </div>

     <div className='col-lg-4 col-md-6'>
     <label>Longitude </label>
     <input type='number' placeholder='43332' />
     </div>

     <div className='col-lg-4 col-md-6'>
     <label>MAP Information</label>
     <input type='text' placeholder='Enter Something' />
     </div>

    </div>

    
    </div>
    </div>
    </div>
  </div>

    </CollapsedAddress>
  )
}

export default GeneralInfo;
