import { createSlice } from '@reduxjs/toolkit';

const userAuth = createSlice({
    name:'User Authentication Slice',
    initialState: {
        userCredsSubmitted: false,
        isLoggedIn: false,
        token: null
    },
    reducers: {
        Login (state, action) {
            // send the tuser creds to backend, then when the loggin state changes, use useEffect to make a get request and fill the token, with the tokenID returned in response, then save it in redux,
            // and when the user logs out nullify/expire that token
            state.token = action.payload
            state.isLoggedIn = true
            // when true log the user into the resgistration screen.
        },
        Logout (state) { 
            state.token = null
            state.isLoggedIn = false
            // on 'null', set the route dynamically to the Login Screen.
        },
        submitCreds (state) {
            state.userCredsSubmitted = true
        }
    }

});

export const authActions = userAuth.actions;
export default userAuth;
