import React, { Component } from 'react';
import Popup from '../../Popup/Popup';
import $, { ready } from 'jquery';
import Helper from '../../../inc/Helper';
import AgGrid from '../../grid/ag/ag-grid';
import Button from '../../inc/Button';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import Input from '../../Forms/Input';
class SegmentDistributionPopup extends Component {
    constructor(props){
        super(props);
        this.grid = null;
        this.distributionGrid = null;
        this.isSegmentsLoaded = false;
        this.totalAmount = this.props.totalAmount;
        this.source_id = this.props.source_id;
        this.distrbutionintegrator_id =  this.props.integrator;
        this.distribution_source_id = this.props.distribution_source_id;
        this.state = {
            isLoading:false,
            isLoadingDetails:false,
            selectedNode:null,
            allSegments:[],
            allSegmentsValues:[],
            data:[],
            allCombinationDetails:[],
            dataDistribution:[],
            segmentCombination:this.getBlankSegmentCombination()
        }
    }
    componentDidMount(){
        this.loadSegments();
        this.loadSegmentValues();
        this.loadSegmentCombinations();
    }
    getTodayDate(){
        return '2023/02/19';
    }
    loadSegmentCombinations(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        
        api.axios().get(Settings.apiUrls.segments+'/segmentcombinationvalue').then( res => {
            let responseData = res.data.data.reverse();
            let data = responseData.map( item=>{
                return {
                    ...item,
                    name: item.segmentcombinationvalue
                }
            })
            that.setState({
                isLoading:false,
                data:data
            })
            that.loadCombinationDetails();
        })
    }
    loadSegments(){
        if(this.isSegmentsLoaded){
            return;
        }
        this.isSegmentsLoaded = true;
        let api = Api;
        let that = this;
        that.setState({
            isLoading:true,
            gridData:[]
        })
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment').then(res => {
            let data = res.data.data.reverse();
            that.setState({
                isLoading:false,
                allSegments:data
            })
        }).catch( error => {
            that.setState({
                isLoading:false
            })
        })
    }
    loadSegmentValues(){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoading:true,
        })
        api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
            let responseData = res.data.data.reverse();
            that.setState({
                allSegmentsValues:responseData,
                isLoading:false,
            })
        }).catch(error =>{
            that.setState({
                isLoading:false,
            })
        })
    }
    loadCombinationDetails(){
        if(!this.distribution_source_id){
            return;
        }
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        
        api.axios().get(Settings.apiUrls.segments+'/distribution_detail_by_source/'+this.distribution_source_id).then( res => {
            that.setState({
                isLoading:false,
                allCombinationDetails:res.data.data
            })
        })
    }
    getSegmentValues(setmentId){
        return this.state.allSegmentsValues.filter(item => {
            return item.segment_id == setmentId ? item.segmentvalue_id : null
        })
    }
    getValueIdForGridRow(combinationNameArray,segmentId){
        let output = '';
        let valuseArray = this.getSegmentValues(segmentId);
        if(Array.isArray(combinationNameArray)){
            combinationNameArray.forEach(id => {
                valuseArray.forEach(segmentValue => {
                    if(segmentValue.segmentvalue_id == id){
                        output = id;
                        return output;
                    }
                });
            })
        }
        return output;
    }
    loadSegmentDistributionDetails(combinationId){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoadingDetails:true,
        })
        let segmentCombination = that.state.segmentCombination;
        let segmentCombinationNameArray= segmentCombination.name.split('|')
        let dataDistribution = [];
        
        this.state.allSegments.forEach(item => {
            let tempRow = {
                segments:item.segment_id,
                value: this.getValueIdForGridRow(segmentCombinationNameArray,item.segment_id),
                description:""
            };
            dataDistribution.push(tempRow)
        })
        api.axios().get(Settings.apiUrls.segments +'/distribution_detail_by_combination/'+combinationId).then(res => {
            
            let data = res.data.data;
            if(data.length >= 1){
                segmentCombination = {
                    ...segmentCombination,
                    ...data[0],
                    distribution_detail_id:data[0].distributiondetail_id,
                    distribution_source_id:data[0].distributionsource_id,
                }
            }
            that.setState({
                dataDistribution:dataDistribution,
                segmentCombination:segmentCombination,
                isLoadingDetails:false
               }) 
        }).catch( error => {
            that.setState({
                dataDistribution:dataDistribution,
                segmentCombination:{},
                isLoadingDetails:false
               }) 
        })
    }
    onPopupCloseHandler(e){
        if(this.props.onPopupClose && typeof this.props.onPopupClose ==='function'){
            this.props.onPopupClose();
        }
        $('html').removeClass('rs_popup_open');
    }
    onGridReady(grid){
        this.grid = grid;
    }
    onDistributionGridReady(grid){
        this.distributionGrid = grid;
    }
    onDistributionChange(event){
        let data = Helper.getDataFromGrid(event);
        let combinationName = "";

        data.forEach( item => {
            if( Helper.getNullableValue(item.value) ){
                if(Helper.getNullableValue(combinationName) ){
                    combinationName += '|'+item.value;
                }else{
                    combinationName = item.value;
                }
            }
        })
        let segmentCombination = this.state.segmentCombination;
        this.setState({
            segmentCombination:{
                ...segmentCombination,
                name:combinationName
            }
        })
    }
    
    segmentValueSetter(params){
        let gridData = Helper.getDataFromGrid(params);
        let isExitInGrid = false;
        gridData.forEach(data =>{
            if(params.newValue == data.segment){
                isExitInGrid = true;
            }
        })
        if(isExitInGrid){
            Helper.alert("Segment already in use",'error');
            return false;
        }
        params.data.segment = params.newValue;
        return true;
    }
    getBlankSegmentCombination(){
        return {name:'',percentage:'',amount:'',distribution_source_id:null,distribution_detail_id:null};
    }
    onSelectionChanged(event){
        if(event.api.getSelectedNodes().length <=0){
            this.setState({
                segmentCombination:this.getBlankSegmentCombination(),
                dataDistribution:[],
                selectedNode:null
            })
            return;
        }
        let selectedRow = event.api.getSelectedNodes()[0];
        if(this.state.selectedNode && this.state.selectedNode.rowIndex === selectedRow.rowIndex){
            return;
        }
        let that = this;
        this.setState({
            segmentCombination:{
                ...selectedRow.data
            },
            selectedNode:selectedRow
        }, function(){
            that.loadSegmentDistributionDetails(that.state.segmentCombination.segmentcombination_id)
        })
    }
    componentDidUpdate(){
        let selectedNode = this.state.selectedNode;
        if(selectedNode){
            this.grid.api.forEachNode((node) =>{
                    if(node.rowIndex === selectedNode.rowIndex){
                        node.setSelected(true)
                    }
                }
            );
        }
        
    }
    onSaveClickHandler(){
        let that = this;
        let segmentCombination = this.state.segmentCombination;
        let amount = parseFloat(segmentCombination.amount);
        amount = amount ? amount : 0;
        let percentage = parseFloat(segmentCombination.percentage);
        percentage = percentage ?  percentage : 0;
        let segmentComData = segmentCombination.name.replaceAll('-','|');
        if(segmentCombination.segmentcombination_id && segmentCombination.distribution_detail_id){
            this.updateSegmentDistributionDetails(segmentCombination.distribution_detail_id,{
                amount:amount,
                percentage:percentage
            })
        }else if(!segmentCombination.segmentcombination_id){
            this.createSegmentCombination(segmentComData,function(res){
                if(that.distribution_source_id){
                    that.createSegmentDistributionDetailsWithSourceId({
                        distribution_source_id: that.distribution_source_id,
                        segment_combination_id: res.data.segmentcombination_id,
                        accounting_date:  that.getTodayDate(),
                        percentage: percentage,
                        amount: amount
                    })
                }else{
                    that.createSegmentDistributionDetails([{
                        segment_combination_id: res.data.segmentcombination_id,
                        accounting_date: that.getTodayDate(),
                        percentage: percentage,
                        amount: amount
                    }])
                }
            })
        }else{
            console.log("Can't take action")
        }
        
    }
    createSegmentDistributionDetails(details){
        let that = this;
        let api = Api;
        api.setUserToken();
        let newData = {
            source_id: this.source_id,
            distributionintegrator_id: this.distrbutionintegrator_id,
            distribution_details:details
        }
        api.axios().post(Settings.apiUrls.segments + '/segmentdistribution',newData).then(res=>{
            that.distribution_source_id = res.data.distributionsource_id;
            that.loadSegmentCombinations();
        })
    }
    createSegmentDistributionDetailsWithSourceId(details){
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiUrls.segments + '/segment_distribution_detail',details).then(res=>{
            that.loadSegmentCombinations();
        })
    }

    createSegmentCombination(data,afterCreateCombination =null){
        let segmentCombination = this.state.segmentCombination;
        if(segmentCombination.segmentcombination_id){
            return;
        }
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiUrls.segments + '/segmentcombinationvalue',{segmentcombination_value:data}).then(res=>{
            

            that.setState({
                segmentCombination:{
                    ...segmentCombination,
                    segmentcombination_id:res.data.segmentcombination_id
                }
            })
            if(afterCreateCombination){
                afterCreateCombination(res)
            }
        })
    }
    updateSegmentCombination(){

    }
    updateSegmentDistributionDetails(detailsId, details){
        let api = Api;
        api.setUserToken();
        api.axios().put(Settings.apiUrls.segments+'/segment_distribution_detail/'+detailsId,details).then(res=>{

        })
    }
    onCloseBtnClick(e){
        $('.rs_popup_close').trigger('click');
    }
    onInputChnageHandler(e){
        let segmentCombination = this.state.segmentCombination;
        let name = e.target.name;
        let percentage = 0;
        let amount = 0;
        if( parseFloat(e.target.value) <=0 ){
            percentage =  0;
            amount = 0;
        }else{
            if(name =='amount'){
                percentage =  ( e.target.value * 100 ) / this.totalAmount;
                amount = e.target.value;
            }else if(name =='percentage'){
                amount = ( e.target.value / 100 ) * this.totalAmount;
                percentage = e.target.value;
            }
        }
        
        this.setState({
            segmentCombination:{
                ...segmentCombination,
                percentage:percentage,
                amount:amount
            }
        })
        
    }
    getValueCodesString(valueIds){
        if(!Helper.getNullableValue(valueIds)){
            return "";
        }
        let valueIdAr = valueIds.split("|");
        let allSegmentsValues = this.state.allSegmentsValues;
        let output = '';
        valueIdAr.forEach( valueId => {
            allSegmentsValues.forEach(value =>{
                if(value.segmentvalue_id == valueId){
                    if(output===''){
                        output = value.code;
                    }else{
                        output += '-'+value.code;
                    }
                }
                
            })
        })
        return output;
        
    }
    getCombinationNameForVisitor(params){
        return this.getValueCodesString(params.data.name)
    }
    formHederContents(){
        let segmentCombination = this.state.segmentCombination;
        return(
            <div className='d-flex segment_distributions_labels'>
                <div className='item'><span>{Helper.getLabel(null,'segments_combination','Combination')} : </span>{ this.getValueCodesString(segmentCombination.name) }</div>
                <div className='item'><span>{Helper.getLabel(null,'percentage','Percentage')} : </span> <Input name="percentage" value={segmentCombination.percentage} onChange={ (e) => this.onInputChnageHandler(e) }/></div>
                <div className='item'><span>{Helper.getLabel(null,'amount','Amount')}  : </span><Input name="amount" value={segmentCombination.amount} onChange={ (e) => this.onInputChnageHandler(e) }/></div>
            </div>
        )
    }
    
    onSegmentCombinationAdd(e){
        let dataDistribution = [];
        this.state.allSegments.forEach(item => {
            dataDistribution.push({
                segments:item.segment_id,
                value:"",
                description:""
            })
        })
        this.setState({
            dataDistribution:dataDistribution,
            segmentCombination:{name:'',percentage:'',amount:''},
            selectedNode:null
        })
    }
    onSegmentCombinationDelHandle(e){
        if(!this.grid){
            return;
        }
        //this.grid.componentObj.delteBtnHandler(e)
        let that = this;
        let segmentCombination = this.state.segmentCombination;
        if(!segmentCombination.segmentcombination_id){
            return '';
        }
        if(segmentCombination.distribution_detail_id){
            this.deleteSegCompDtlById(segmentCombination.distribution_detail_id,function(){
                that.deleteCombinationById(segmentCombination.segmentcombination_id,function(){
                    Helper.alert("Success");
                })
            })
        }else{
            this.deleteCombinationById(segmentCombination.segmentcombination_id,function(){
                Helper.alert("Success");
            })
        }
        this.setState({
            dataDistribution:[],
            segmentCombination:this.getBlankSegmentCombination(),
            selectedNode:null
        })
    }
    deleteCombinationById(id,afterCallBack = null){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().delete(Settings.apiUrls.segments + '/segmentcombination/'+id).then(res=>{
            if(afterCallBack){
                afterCallBack(res);
            }
            that.loadSegmentCombinations();
        })
    }
    deleteSegCompDtlById(id,afterCallBack = null){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().delete(Settings.apiUrls.segments + '/segment_distribution_detail/'+id).then(res=>{
            if(afterCallBack){
                afterCallBack(res);
            }
            that.loadSegmentCombinations();
        })
    }
    getSegmentsDropdownOptions(){
        let options = [];
        let allSegments = this.state.allSegments;
        allSegments.forEach( segment => {
            options.push({
                label: segment.name,
                value:segment.segment_id
            })
        })
        
        return options;
    }
    valueOptionsFilter(options,params){
        let output = options.filter( item => {
            return item.segment_id == params.data.segments ? item : null
        })
        return output;
    }
    getSegmentValueFromValueId(valueId){
        let output = {};
        this.state.allSegmentsValues.map(item => {
            if(item.segmentvalue_id ==valueId ){
                output = item;
            }
        })
        return output;
    }
    getSegmentValueDropdownList(arg){
        let options = this.state.allSegmentsValues.map((item)=> {
            return {
                label: item.code+ ' | '+item.name,
                segment_id:item.segment_id,
                value: item.segmentvalue_id
            }
        })
        return options;
    }
    formateSegmentValueDropdownValue(prarams){
        let segmentValue = this.getSegmentValueFromValueId(prarams.value);
        if(segmentValue.code)
            return segmentValue.code;
        return prarams.value;
    }
    getDesctionFromRow(params){
        let description = '';
        let allSegmentsValues = this.state.allSegmentsValues;
        allSegmentsValues.forEach( segmentValue => {
            if(segmentValue.segmentvalue_id == params.data.value){
                description = segmentValue.name;
            }
        })
        return description;
    }
    
    render() {
        let language = this.props.language;
        let segmentCombination = this.state.segmentCombination;
        let header = [
            {field:'name',headerName:Helper.getLabel(language,'combination','Combination'),valueFormatter: this.getCombinationNameForVisitor.bind(this)},
            {field:'segmentcombinationvalue',hide:true},
            {field:'segmentcombination_id',hide:true},
            {field:'percentage',headerName:Helper.getLabel(language,'percentage','Percentage')},
        ]
        let headerDistribution = [
            {field:'segments',headerName:Helper.getLabel(language,'segments','Segments'), editable:false, valueSetter : this.segmentValueSetter.bind(this) ,inputType:'dropdown',options:this.getSegmentsDropdownOptions() },
            {field:'value',headerName:Helper.getLabel(language,'value','Value'),inputType:'dropdown',valueFormatter: this.formateSegmentValueDropdownValue.bind(this),options:this.getSegmentValueDropdownList(),optionsFilter: this.valueOptionsFilter.bind(this), editable: segmentCombination.segmentcombination_id ? false: true},
            {field:'description',headerName:Helper.getLabel(language,'description','Description'),valueFormatter: this.getDesctionFromRow.bind(this) ,editable:false},
        ]
        let data = this.state.data;
        let dataDistribution = this.state.dataDistribution;
        let allCombinationDetails = this.state.allCombinationDetails;
        data = data.map( (item,key) => {
            let distributionDetails = {};
            allCombinationDetails.forEach( itemDistributionDetails => {
                if(itemDistributionDetails.segmentcombination_id === item.segmentcombination_id){
                    distributionDetails = itemDistributionDetails;
                }
            })
            return{
                ...item,
                amount:distributionDetails.amount,
                percentage:distributionDetails.percentage
            }
        })


        return (
            <Popup className="segment_distribution_popup" onClose={ this.onPopupCloseHandler.bind(this)} autoOpen={true} width="1200px">
                <div className='popup_header'>
                    <h2 className='seg_p_title'>{Helper.getLabel(language,'segment_distribution','Segment distribution')}</h2>
                    <div className='percentage_grand_total'><strong>Total amount :</strong> $ {this.totalAmount}</div>
                </div>
                <div className='rs_button_box'>
                    <Button className="btn_md" onClick={ this.onSegmentCombinationAdd.bind(this)} title={Helper.getLabel(language,'add','Add')} icon="/images/icons/add-new.svg" />
                    <Button className= {"btn_md "+ ( segmentCombination.segmentcombination_id ? '':'disable')} onClick={ this.onSegmentCombinationDelHandle.bind(this)} title={Helper.getLabel(language,'remove','Remove')} icon="/images/icons/delete.svg" />
                </div>
                <div className='row'>
                    <div className='col-sm-4 segdpopup_grid_wraper'>
                        <AgGrid ppp={data.length}  viewOnly={true}  onSelectionChanged={this.onSelectionChanged.bind(this)}  id="segdpopup_grid" hideFooter={true} singleRowSelect={true}  allBtnHide={true} onGridReady={ this.onGridReady.bind(this) } data={data} header={header}/>
                    </div>
                    <div className='col-sm-8 rs_right_grid'>
                        {this.state.isLoadingDetails ? <SimpleLoading /> : <AgGrid dissableCheckBox={true} addBtnHide={true} hideDelete={true}  id="seg_distribution_grid"  deleteConfig={{apiUrl:Settings.apiUrls.segments + '/segment_distribution_detail',idName:'distributiondetail_id',that:this}} onGridReady={ this.onDistributionGridReady.bind(this)} onRowValueChanged={this.onDistributionChange.bind(this)} afterDeleteButtons={this.formHederContents.bind(this)} ppp={10000} hideSave={true}  data={dataDistribution} hideFooter={true} hideHeaderRightContents={true} header={headerDistribution}/> }
                    </div>
                </div>
                <h2 className='d-flex justify-content-right mt-5' style={{gap:"10px",'justify-content':'right'}}>
                    <Button className="btn_md light_gray mr-2" onClick ={ this.onCloseBtnClick.bind(this)} title={Helper.getLabel(language,'cancel','Cancel')} />
                    <Button className="blue btn_md" title={Helper.getLabel(language,'save','Save')} onClick={ this.onSaveClickHandler.bind(this) } />
                </h2>

            </Popup>
        );
    }
}

export default SegmentDistributionPopup;