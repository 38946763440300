import React, { Component } from "react";
import MasterComonent from "../../Backend/MasterComonent";
import NavigationHeder from "../../Navigations/NavigationHeder";
import MasterSidebar from "../../Sidebars/MasterSidebar";
import NoteAttachmentHistory from "../../Widgets/NoteAttachmentHistory";
import CheckDeleteActionBar from "../common/CheckDeleteActionBar";
import NavCartWishlist from "../common/NavCartWishlist";
import ShoppingCart from "../../../assets/shopping-cart.svg";
import CartItem from "./CartItem";
import OrderSummary from "../common/OrderSummary";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import Helper from "../../../inc/Helper";
import { connect } from "react-redux";
import SimpleLoading from "../../Loading/SimpleLoading";
import ActionTypes from "../../../inc/ActionsTypes";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      cartItems: [],
      categories: [],
      advancedCartItems: [],
      activeDistribution: null,
      selectedCartItems: [],
      selectedCategories: [],
      selectAll: false,
      discount: 0,
      tax: 0,
    };

    this.toggleSelect = this.toggleSelect.bind(this);
    this.deleteCartItem = this.deleteCartItem.bind(this);
    this.toggleDistribution = this.toggleDistribution.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.deleteAllCartItems = this.deleteAllCartItems.bind(this);
    this.decrementCartCount = this.decrementCartCount.bind(this);
  }

  componentDidMount() {
    this.getCartItems();
    this.fetchCategories();
  }

  componentDidUpdate(prevProps) {
    // refetch cart data on cart item edit
    if (
      (prevProps.showEditOldCartModal !== this.props.showEditOldCartModal ||
        prevProps.didCartUpdate !== this.props.didCartUpdate) &&
      this.props.showEditOldCartModal === false &&
      this.props.didCartUpdate === true
    ) {
      console.log("refetching cart");
      this.getCartItems();
      this.props.toggleDidCartUpdate();
    }
  }

  getAvancedCartItems() {
    const possibleCategoryIds = this.state.cartItems.map((item) => {
      if (item.category_id) return item.category_id;
    });

    const possibleCategories = this.state.categories.filter((category) =>
      possibleCategoryIds.includes(category.category_id)
    );

    possibleCategories.push({
      category_id: -1,
      name: Helper.getLabel(
        this.props.language,
        "miscellaneous",
        "Miscellaneous"
      ),
    });

    let advCartItems = [];

    for (let categoryObj of possibleCategories) {
      let newCategoryObj = {
        ...categoryObj,

        cartItems: this.getCartItemsForCategory(
          categoryObj.category_id !== -1 ? categoryObj.category_id : null
        ),
      };
      if (newCategoryObj?.cartItems?.length > 0)
        advCartItems.push(newCategoryObj);
    }

    return advCartItems;
  }

  getSubtotal() {
    let subtotal = 0;
    this.state.cartItems.forEach((item) => {
      if (this.state.selectedCartItems.includes(item.line_id)) {
        if (item.unit_price && item.ordered_qty) {
          subtotal += item.unit_price * item.ordered_qty;
        }
      }
    });
    return subtotal;
  }

  getCartItemsForCategory(categoryId) {
    return this.state.cartItems.filter(
      (item) => item.category_id === categoryId
    );
  }

  getCartItems() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartlines")
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            isLoading: false,
            cartItems: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  deleteCartItem = (item) => {
    let api = Api;
    api.setUserToken();
    let that = this;

    api
      .axios()
      .delete(
        Settings.apiPurchaseRequisitionUrl + "/pr_cartline/" + item.line_id
      )
      .then(function (res) {
        if (res.status === 202) {
          that.setState((prevState) => {
            const newCartItems = prevState.cartItems.filter(
              (cartItem) => cartItem.line_id !== item.line_id
            );
            return { cartItems: newCartItems };
          });
          that.decrementCartCount();
          Helper.alert("Cart item removed", "success");
        }
      })
      .catch(function (err) {
        Helper.alert("Failed to remove cart item", "error");
      });
  };

  // deleteallcartitems
  deleteAllCartItems() {
    let api = Api;
    let that = this;
    api.setUserToken();

    this.state.selectedCartItems.forEach((selectedLineId) => {
      api
        .axios()
        .delete(
          Settings.apiPurchaseRequisitionUrl + "/pr_cartline/" + selectedLineId
        )
        .then(function (res) {
          if (res.status === 202) {
            that.setState((prevState) => {
              const newCartItems = prevState.cartItems.filter(
                (cartItem) => cartItem.line_id !== selectedLineId
              );
              return { cartItems: newCartItems };
            });
            that.decrementCartCount();
            Helper.alert("Cart item removed", "success");
          }
        })
        .catch(function (err) {
          Helper.alert("Failed to remove cart item", "error");
        });
    });
  }

  fetchCategories() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + "/categories")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            categories: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  toggleSelect(ids) {
    const selectedCartItems = [...this.state.selectedCartItems];
    ids.forEach((id) => {
      const existingIndex = selectedCartItems.indexOf(id);
      if (existingIndex === -1) {
        selectedCartItems.push(id);
      } else {
        selectedCartItems.splice(existingIndex, 1);
      }
    });
    this.setState({ selectedCartItems });
    this.props.updateCheckoutIds(selectedCartItems);
  }

  toggleSelectByCat(catId) {
    this.setState((prevState) => {
      const advCartItems = this.getAvancedCartItems();
      const selectedCategories = [...prevState.selectedCategories];
      const existingCatIndex = selectedCategories.indexOf(catId);
      if (existingCatIndex === -1) {
        selectedCategories.push(catId);
      } else {
        selectedCategories.splice(existingCatIndex, 1);
      }

      const catInAction = advCartItems.find(
        (cartItems) => cartItems.category_id === catId
      );
      const cartItemIds = catInAction.cartItems?.map(
        (cartItem) => cartItem.line_id
      );
      this.toggleSelect(cartItemIds);
      return { selectedCategories };
    });
  }

  toggleSelectAll() {
    this.setState((prevState) => {
      const advCartItems = this.getAvancedCartItems();
      const selectedCategories = [...prevState.selectedCategories];
      const selectAll = !prevState.selectAll;

      if (selectAll) {
        advCartItems.forEach((cat) => {
          if (!selectedCategories.includes(cat)) {
            selectedCategories.push(cat.category_id);
          }
        });
      } else {
        selectedCategories.length = 0;
      }
      return { selectedCategories };
    });
    this.setState((prevState) => {
      const advCartItems = this.getAvancedCartItems();
      const selectedCartItems = [...prevState.selectedCartItems];
      const selectAll = !prevState.selectAll;

      advCartItems.forEach((cat) => {
        if (selectAll) {
          cat.cartItems.forEach((item) => {
            if (!selectedCartItems.includes(item.line_id)) {
              selectedCartItems.push(item.line_id);
            }
          });
        } else {
          selectedCartItems.length = 0;
        }
      });
      this.props.updateCheckoutIds(selectedCartItems);
      return { selectedCartItems };
    });
    this.setState({ selectAll: !this.state.selectAll });
  }

  toggleDistribution(item) {
    if (this.state.activeDistribution === item.line_id) {
      this.setState({ activeDistribution: null });
    } else {
      this.setState({ activeDistribution: item?.line_id });
    }
  }

  decrementCartCount() {
    this.props.changeCartCount(
      this.props.cartCount >= 1
        ? this.props.cartCount - 1
        : this.props.cartCount
    );
  }

  render() {
    return (
      <>
        <div className="shoppingCart-container">
          <div className="shoppingCart-container-items">
            <CheckDeleteActionBar
              icon={ShoppingCart}
              title="Shopping Cart"
              itemCount={this.state.cartItems?.length ?? 0}
              selectAll={this.state.selectAll}
              toggleSelectAll={this.toggleSelectAll}
              deleteAll={this.deleteAllCartItems}
              disable={this.state.selectedCartItems.length === 0}
            />
            {this.state.isLoading ? (
              <SimpleLoading />
            ) : (
              <div className="cartItems-wrapper">
                {/* {this.state.advancedCartItems.map((itemsByCat) => ( */}
                {this.getAvancedCartItems()?.map((itemsByCat) => (
                  <div className="cartItem">
                    <div className="cartItem-category">
                      <input
                        type="checkbox"
                        onChange={() =>
                          this.toggleSelectByCat(itemsByCat.category_id)
                        }
                        checked={this.state.selectedCategories.includes(
                          itemsByCat.category_id
                        )}
                      />{" "}
                      {itemsByCat.name}
                    </div>
                    <div className="cartItem-items">
                      {itemsByCat.cartItems.map((item) => (
                        <CartItem
                          cartItem={item}
                          deleteCartItem={this.deleteCartItem}
                          selectedCartItems={this.state.selectedCartItems}
                          handleSelect={this.toggleSelect}
                          toggleDistribution={this.toggleDistribution}
                          activeDistribution={this.state.activeDistribution}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="cart-sidebar">
            <MasterSidebar sidebarStckyContainerId="abc_tabs_sections">
              <OrderSummary
                title={Helper.getLabel(
                  this.props.language,
                  "order_summary",
                  "Order summary"
                )}
                itemCount={this.state.selectedCartItems.length}
                subtotal={this.getSubtotal()}
                discount={this.state.discount}
                tax={this.state.tax}
              />
              <NoteAttachmentHistory
                source_id={9}
                notesource_integrator={59}
                attachmentsource_integrator={59}
                hideHistory={true}
              />
            </MasterSidebar>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
    showEditOldCartModal: state.prShop.showEditOldCartModal,
    didCartUpdate: state.prShop.didCartUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_CART_COUNT, payload }),
    updateCheckoutIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_CHECKOUT_LINE_IDS, payload }),
    toggleDidCartUpdate: () => {
      dispatch({ type: ActionTypes.TOGGLE_DIDCARTUPDATE });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
