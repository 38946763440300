import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    purpose:[]
}
const AddressPurposeReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_ALL_PURPOSE){
        state = {
            ...state,
            purpose:actions.payload
        }
    }
    return state;
}
export default AddressPurposeReducer;