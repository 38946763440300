import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FieldsRenderer from './FieldsRenderer'

const SetupTab = (props) => {

  
  //States For Dropdowns
  const [buyerGroup, setBuyerGroup] = useState([])
  const [requester, setRequester] = useState([])
  const [ordered, setOrdered] = useState([])
  const [currency, setCurrency] = useState([])
  const [entity, setEntity] = useState([])
  const [sites, setSites] = useState([])
  //End

  //API Calls Renderer
    useEffect(() => {
        RequesterAPI()
        OrderedAPI()
        CurrencyAPI() 
        BuyerGroupAPI()
    }, [])
  // End

  //API Reduction 
   let buyerGroupCalled = false
   let RequesterCalled = false
   let OrderedCalled = false
   let CurrencyCalled = false 
  //End


  // API Calls
  const BuyerGroupAPI = () => {
    if(buyerGroupCalled) {
      return;
    }
      let api = Api
      buyerGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+'/item_buyergroup',{}).then(function(res){
      if(res.data.status==="success"){
          let ITEMBUYERGROUP = []
          res.data.data.forEach((item) => {
             ITEMBUYERGROUP.push({
                value: item.itembuyergroup_id,
                label: item.name
             })
          })
          setBuyerGroup(ITEMBUYERGROUP)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const RequesterAPI = () => {
    if(RequesterCalled) {
      return;
    }
      let api = Api
      RequesterCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let Requester = []
        res.data.data.forEach(item => {  
        Requester.push({
          value: item.employee_id,
          label: `${item.code} - ${item.displayname}`
        })
      }) 
      setRequester(Requester)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const OrderedAPI = () => {
    if(OrderedCalled) {
      return;
    }
      let api = Api
      OrderedCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let OrderedARR = []
        res.data.data.forEach(item => {  
        OrderedARR.push({
          value: item.employee_id,
          label: `${item.code} - ${item.displayname}`
        })
      }) 
      setOrdered(OrderedARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const CurrencyAPI = () => {
    if(CurrencyCalled) {
      return;
    }
      let api = Api
      CurrencyCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
      if(res.data.status==="success"){
        let CurrencyARR = []
        res.data.data.forEach(item => {  
        CurrencyARR.push({
          value: item.currency_id,
          label: `${item.currency_code} - ${item.currency_name}`
        })
      }) 
      setCurrency(CurrencyARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }
  
  //End
  
    let textBoxInputSettings = [ 
        {label:Helper.getLabel(props.language,'buyer_group',"Buyer Group") ,options: buyerGroup, inputType: "dropdown", name:'buyergroup_id', id:'buyergroup_id'},
        {label:Helper.getLabel(props.language,'requester',"Requester"), options: props.requester, inputType: "dropdown",name:'requester_id', id: 'requester_id'},
        {label:Helper.getLabel(props.language,'ordered',"Ordered"), options: props.ordered, inputType: "dropdown",name:'orderer_id', id:'orderer_id'},
        {label:Helper.getLabel(props.language,'currency_id',"Currency"), required: true, options:currency, inputType: "dropdown", name:'currency_id', id:'currency_id'},
        {label:Helper.getLabel(props.language,'allow_access_only_to_buyer_group',"Allow Access Only To Buyer Group"), inputType: "checkbox", name:'allowaccesstobuyergroup'},
        {label:Helper.getLabel(props.language,'email',"Email"), inputType: "text", name:'emailaddress'},
        {label:Helper.getLabel(props.language,'entity',"Entity"), required: true, options: props.entities, inputType: "dropdown", name:'entity_id', id: 'entity_id'},
        {label:Helper.getLabel(props.language,'site',"Site"), required: true, options:props.sites, inputType: "dropdown", name:'site_id', id: 'site_id'}, 
        {label:Helper.getLabel(props.language,'blind_receiving_of_quantity',"Blind Receiving Of Quantity"), inputType: "checkbox", name:'blindreceivingofqty'},
        {label:Helper.getLabel(props.language,'external_reference',"External Reference"), inputType: "text", name:'externalreference'},
    ]

  return (
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
    </Fragment>
  )
}
 

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (SetupTab) 