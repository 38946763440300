import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Helper from '../../inc/Helper';
import $ from 'jquery';
import Form_gui_ids from '../../inc/Form_gui_ids';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import LabelsGUI_ID from '../../inc/LabelsGUI_ID';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: []
        }
    }
    componentDidMount() {
        //Helper.makeSticky('.sidebar_section_inner')
    }
    componentDidUpdate(prevProps) {
        $('.sidebar_menu').on('a', 'click', function (e) {


        })
    }
    menuItemClickHandler(e) {
        $('.sidebar_menu li').removeClass('active');
        $(e.target).parents('li').addClass('active')
    }
    render() {
        let security = this.props.security;
        let frontendIds = Form_gui_ids;
        if (!security.canView(Form_gui_ids.appMasterSidebar.main_component_id)) {
            return <></>
        }
        let sidebarStyle = {};
        if (this.props.appOptions.options.isSidebarOpen) {
            sidebarStyle.width = '250px';
        } else {
            sidebarStyle.width = '0';
            sidebarStyle.overflow = "hidden";
            sidebarStyle.visibility = "hidden";
        }
        return (
            <div className='sidebar_section' style={sidebarStyle}>
                <div className='sidebar_section_inner'>
                    <Link className='main_logo rs_sticky_tag' onClick={e => this.menuItemClickHandler(e)} to={'/template'}><img src="/images/logo_white.svg" alt="Logo" /> </Link>
                    <ul className='sidebar_menu'>
                        {security.canView(frontendIds.appMasterSidebar.reports) ? <li><Link to="/template" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/reports.svg" alt="templates" /><span>{Helper.getLabel(this.props.language, '20362', 'Templates')}</span></Link></li> : ""}
                        {security.canView(frontendIds.appMasterSidebar.reports) ? <li><Link to="/workflow" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/hierarchy-icon-1.svg" alt="workflow" /><span>{Helper.getLabel(this.props.language, '20362', 'Workflow')}</span></Link></li> : ""}
                        {security.canView(frontendIds.appMasterSidebar.reports) ? <li><Link to="/stages" onClick={e => this.menuItemClickHandler(e)}><img src="/images/icons/users-gray.svg" alt="stages" /><span>{Helper.getLabel(this.props.language, '20362', 'Stages')}</span></Link></li> : ""}
                    </ul>
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        appOptions: state.options,
        language: state.language
    }
}
const SecurityOptions = {
    gui_id: Form_gui_ids.appMasterSidebar.main_component_id
}
export default connect(mapStateToProps)(ApplySecurityRoles(Sidebar, SecurityOptions));
