import React , { Component }  from 'react'
import Helper from '../../../inc/Helper';
import {connect} from 'react-redux'; 
import './TenantFields.css'
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import FormValidator from '../../../inc/FormValidator';
import Collapse from '../../inc/Collapse';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';

class TenantFields extends Component {
  constructor(props){
    super(props);
    this.isUserTypesLoaded = false;
    this.state = {
      user_types:[],
      data:this.props.data ? this.props.data: {}
    }
  }
  componentDidMount(){
    this.loadUserTypes();
  }
  loadUserTypes (){
    if(this.isUserTypesLoaded){
      return;
    }
    this.isUserTypesLoaded = true;
    let api = Api;
    let that = this;
    api.setUserToken();
    api.axios().get(Settings.apiUrl+'/enum/usertype').then(function(res){
      that.setState({
        user_types: res.data.data
      })
    })
  }
  onChangeInput(e){
    this.changeInputValue(e.target.name,e.target.value)
    
  }
  onChangeInputDate(name,value){
    if(name == "start_date"){
      let that = this;
      let oldData = this.state.data;
      let newData = {
        ...oldData,
        'end_date':""
      }
      this.setState({
        data:newData
      },function(){
        that.changeInputValue(name,value)
      })
    }else{
      this.changeInputValue(name,value)
    }
  }
  changeInputValue(name,value){
    let oldData = this.state.data;
    let newData = {
      ...oldData,
      [name]:value
    }
    this.props.setFormData(newData)
    this.setState({
      data:newData
    })
  }
  componentWillReceiveProps(nextProps){
    if(this.props.isNotNewForm){
      if(nextProps.data && nextProps.data !== this.state.data){
        this.setState({
          data: nextProps.data
        })
      }
    }
    
  }
  displayTenantsAllFields(validator){
    let language = this.props.language;
    let isEditable  = this.props.isAdminEditor ? true : false;
    let userType = "";

    let user_types = this.state.user_types.map( item=> {
      if(this.state.data.user_type == item.id){
        userType = item.name;
      }
      return {
        label: item.name,
        value: item.id
      }
    });
    if(!this.state.data.tenant_Id){
      userType = this.state.data.user_type
    }
    return(
      <>
      
        <div className='col-4'>
          <Input  hasError={validator.hasError('first_name')}   disable={isEditable} required={true} label={Helper.getLabel(language,"first_name",'First Name')} name="first_name" value={this.state.data.first_name } onChange={ this.onChangeInput.bind(this)}  placeholder={Helper.getLabel(language,"type_here",'Type Here')} />
        </div>
        <div className='col-4'>
          <Input    disable={isEditable} label={Helper.getLabel(language,"middle_name",'Middle Name')} value={this.state.data.middle_name } onChange={ this.onChangeInput.bind(this)} placeholder={Helper.getLabel(language,"type_here",'Type Here')} name="middle_name" />
        </div>

        <div className='col-4'>
          <Input   disable={isEditable} label={Helper.getLabel(language,"last_name",'Last Name')} type='text' value={this.state.data.last_name } onChange={ this.onChangeInput.bind(this)} placeholder={Helper.getLabel(language,"type_here",'Type Here')} name="last_name" />
        </div>
        <div className='col-4'>
          <Input hasError={validator.hasError('user_email')}  required={true}  disable={isEditable} label = {Helper.getLabel(language,"email",'Email')}  value={this.state.data.user_email } onChange={ this.onChangeInput.bind(this)} placeholder={Helper.getLabel(language,"type_here",'Type Here')} name="user_email" />
        </div>
        <div className='col-4'>
          <DropDownInput id="user_type" disable={isEditable} hasError={validator.hasError('user_type')} onChange={ this.onChangeInput.bind(this) }   label={Helper.getLabel(language,"user_type",'User Type')}   name="user_type" value={ userType } options = { user_types } />
        </div>
        {
          /*
            <div className='col-4'>
              <Input hasError={validator.hasError('default_login_entity')}  disable={isEditable} label ={Helper.getLabel(language,"default_login_entity",'Default Entity')} type='text' value={this.state.data.default_login_entity } onChange={ this.onChangeInput.bind(this)} placeholder={Helper.getLabel(language,"type_here",'Type Here')} name="default_login_entity" />
            </div>
          */
        }
        <div className='col-4'>
          <Input    disable={isEditable} label = {Helper.getLabel(language,"SID",'SID')} type='text' value={this.state.data.SID } onChange={ this.onChangeInput.bind(this)} placeholder={Helper.getLabel(language,"type_here",'Type Here')} name="SID" />
        </div>
        
        <div className='col-4'>
          <InputDatePicker inputId={'start_date'}   hasError={validator.hasError('start_date')} required={true}  disable={this.state.data.tenant_Id ? true : false} inputClassName="start_date" label = {Helper.getLabel(language,"start_date",'Start date')} type='text' value={ this.state.data.start_date } onChange={ this.onChangeInputDate.bind(this) }  placeholder='Select Date' name="start_date" />
        </div>
        
        <div className='col-4'>
          <InputDatePicker minDate ={this.state.data.start_date}   hasError={validator.hasError('end_date')} required={true}  disable={this.state.data.tenant_Id ? true : false} inputClassName="end_date" label = {Helper.getLabel(language,"end_date",'End Date')} type='text' value={ this.state.data.end_date } onChange={ this.onChangeInputDate.bind(this) }  placeholder='Select Date' name="end_date" />
        </div>
        <div className='col-2'>
          <DropDownInput id="isactive_select" hasError={validator.hasError('isactive')} onChange={ this.onChangeInput.bind(this) }   label={Helper.getLabel(language,"active",'Active')}   name="isactive" value={this.state.data.isactive } options = { [{value:'true',label:"Yes"},{value:'false',label:"No"}] } />
        </div>
      </>
    )
  }
  render() {
    let language = this.props.language;
    let isEditable  = this.props.isAdminEditor ? true : false;
    let validator = this.props.validator ? this.props.validator : new FormValidator([]);
    return (
      <Collapse title={Helper.getLabel(language,"general",'General')} open={true}>
        <section  className= {this.props.isSearchMode ? 'tenant-fields-section mb-2 ' : 'tenant-fields-section mb-2' }>
            <div className='tf_inner  rs_form_container'>
              <div className='row gy-0 mt-2 mb-2'>
                  <div className='col-12'>
                    <div className='row tenant-fields-form gy-0 mb-1'>
                      <div className='col-4'>
                        <Input disable={isEditable}   hasError={validator.hasError('code')} label = {Helper.getLabel(language,"code",'Code')}  required={true}  type='text' value={this.state.data.code} onChange={ e => this.onChangeInput(e)}  name="code" placeholder='Enter Code' />
                      </div>
                    
                      <div className='col-4'>
                        <Input label = {Helper.getLabel(language,'name','Name')}   hasError={validator.hasError('name')} required={true}  type='text' value={this.state.data.name} onChange={ e => this.onChangeInput(e)} placeholder='Enter Name' name="name" />
                      </div>
                    </div>
                    <div className='row tenant-fields-form gy-0 mb-1'>
                      { this.displayTenantsAllFields(validator) }
                    </div>
                </div>
              </div>
          </div>
      </section>
    </Collapse>
    );
  }
}


const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      language:state.language
  }
}
 
export default connect (mapStateToProps) (TenantFields);

