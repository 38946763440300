import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';

class OrderStatus extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab:'status'
        }
    }
    setActiveTab(e,activeTab){
        this.setState({
            activeTab:activeTab
        })
    }
    displayOrderStatus(){
        return(
            <div className='order_staus_items'>
                <div className='order_staus_item'>
                    <h3>Status</h3>
                    <h2>Open Order</h2>
                </div>
                <div className='order_staus_item'>
                    <h3>Document Status</h3>
                    <h2>Purchase Order</h2>
                </div>
                <div className='order_staus_item'>
                    <h3>Header Budger Check result</h3>
                    <h2>Confirmed</h2>
                </div>
                <div className='order_staus_item'>
                    <h3>Aproval Status</h3>
                    <h2>Purchase Order</h2>
                </div>
                <div className='order_staus_item'>
                    <h3>Quality Order Status</h3>
                    <h2>Confirmed</h2>
                </div>
            </div>
        )
    }
    displayOrderTotal(){
        return(
            <div className='order_totals_tab'>
                Order Total Tab
            </div>
        )
    }
    render() {
        let language = this.props.language;
        return (
            <div className="widget_order_status">
                <ul className='menu_style_1'>
                    <li className={this.state.activeTab ==='status' ? 'active' : ''} onClick={ e => this.setActiveTab(e,'status') }>{Helper.getLabel(language,'status','Status')}</li>
                    <li  className={this.state.activeTab ==='order_total' ? 'active' : ''} onClick={ e => this.setActiveTab(e,'order_total') }>{Helper.getLabel(language,'order_total','Order Total')}</li>
                </ul>
                <div className='widget_contents'>
                    {
                        this.state.activeTab ==='status' ? this.displayOrderStatus() : this.displayOrderTotal()
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}

export default connect (mapStateToProps) ( OrderStatus );