import React, {useState, useEffect, Fragment} from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../inc/Button'
import Api from '../../../inc/Api'
import Treeview from '../../Treeview/Treeview'
import '../User Container/UserContainer.css'
import {ValidateArray, ValidateData} from '../../../inc/Validation'
import Collapse from '../../inc/Collapse'
import Settings from '../../../inc/Settings'
import Helper from '../../../inc/Helper'
import LinkBtn from '../../inc/LinkBtn'
import NavigationHeder from '../../Navigations/NavigationHeder'
import { connect, useSelector, useDispatch } from 'react-redux'
import Delete from '../../../assets/delete.svg'
import $ from 'jquery'
import Plus from '../../../assets/add-new.svg'
import MasterComonent from '../../Backend/MasterComonent'
import { gridIdActions } from '../../../store/grid-id';
import Popup from '../../Popup/Popup';
import AgGrid from '../../grid/ag/ag-grid';
import DropDownInput from '../../Forms/Dropdown';
import SimpleLoading from '../../Loading/SimpleLoading'
import Input from '../../Forms/Input'
import FormValidator from '../../../inc/FormValidator'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'
import Checkbox from '../../Forms/Checkbox'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'

let gridObject ;

const EditUsers = (props) => {
  const {id } = useParams()
  const [rolesStructure, setRolesStructure] = useState([])
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState({})
  const [enableSelect, setEnableSelect] = useState(false)
  const [userrolesGrid, setUserRolesGrid] = useState([])
  const [ID, setID] = useState(null)
  const [empty, setEmpty] = useState(null) 
  const [selectedRoles, setSelectedRoles] = useState([])
  const [languages, setLanguages] = useState([])
  const showRolesGrid = useSelector(state => state.gridIdFlow.rolesListShow)
  const dispatch = useDispatch()

  //API Reduction Calls
   let userRolesGridCalled = false
   let languagesCalled = false
   let usersCalled = false
  let rolesCalled = false
  //End

  useEffect(() => { 
    user_roles_grid()
    get_languages_list() 
    load_users() 
    load_roles()
  }, [id])

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'user_name', displayName:Helper.getLabel(props.language,'user_name','User Name'),types:['Required']}, 
        {name:'first_name', displayName:Helper.getLabel(props.language,' first_name','First Name'),types:['Required']},  
        {name:'last_name', displayName:Helper.getLabel(props.language,'last_name','Last Name'),types:['Required']},
        {name:'enable', displayName:Helper.getLabel(props.language,'enable','Enable'),types:['Required']},
        {name:'language_id', displayName:Helper.getLabel(props.language,'language','Language'),types:['Required']},
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()

  const user_roles_grid = () => {
    if(userRolesGridCalled){
      return
    }
    let api = Api
    userRolesGridCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiBaseUrl+'/role',{}).then(function(res){
      if(res.data.status==='success'){  
        let UPDATED_STATE_GRID = []
        res.data.data.forEach(element => {
           UPDATED_STATE_GRID.push({ 
            "description":  element.description, 
            "name":  element.name, 
            "role_id" : element.role_id,
           })
        })
        setUserRolesGrid(UPDATED_STATE_GRID) 
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const load_users = () => { 
    let UserID = id ? id : null
    if(usersCalled || !UserID){
      return;
    }
    let api = Api
    usersCalled = true
    api.setUserToken()  
    api.axios().get(Settings.apiUrl+`/get_user/${id}`,{}).then(function(res){ 
      if(res.data.status==='success'){   
       setUsers(res.data.data)
       setLoading(false)
    } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })  
  }

  const load_roles = () => {
    if(rolesCalled){
      return;
    }
    let api = Api
    rolesCalled = true
    api.setUserToken()  
    api.axios().get(Settings.apiBaseUrl+`/get_user_roles/${id}`,{}).then(function(res){ 
      if(res.data.status==='success'){
        let ROLES_STRUCTURE = []
        res.data.data.forEach(element => {
          ROLES_STRUCTURE.push({
              id: element.role_id, 
              title: element.name,
              showChild:false,
              items:[]
          })
        })
        setRolesStructure(ROLES_STRUCTURE)
        if(res.data.data.length === 0){
          setEmpty(true)
        }
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })  
  }
 
  const CloseHandler = (event) => { 
    dispatch(gridIdActions.rolesGridShow({payload: false})) 
    $('html').removeClass('rs_popup_open');
  }
 
  const get_languages_list = () => {
    if(languagesCalled){
      return;
    }
    let api = Api
    languagesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiBaseUrl+'/language',{}).then(function(res){
      if(res.data.status==='success'){   
        let Languages_API_LIST = []
        res.data.data.forEach(item => {  
          Languages_API_LIST.push({
           value: item.languange_Id,
           label: item.language
         })
        })
        setLanguages(Languages_API_LIST)
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link">${params.value}</div>`;
  }

  const Columns_Headings_Roles = [
    {field:'name', headerName: 'Role Name'},
    {field:'description', headerName: 'Role Description'}, 
  ]
 
  const AssignRoles = () => {  
    if(id){
      dispatch(gridIdActions.rolesGridShow({payload: true}))
    }else {
      Helper.alert('Please Select A User To Assign Roles', 'error')
    }
  }

  const rolesHandler = (itemId) => {
     setID(itemId.id)
  }

const DeleteAssignedRoles = () => {
  let api = Api
  api.setUserToken()
  if(ID){
    api.axios().delete(Settings.apiBaseUrl+`/delete_user_role/${id}/${ID}`,{}).then(function(res){
      if(res.data.status==='success'){  
        Helper.alert('User Role Deleted Successfully')
        load_roles()
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
    load_roles()
  }) 
  }else{ 
    Helper.alert('Please Select Any Role To Delete!', 'error')
    load_roles()
  }
}

const Callbuttons = () => {
  return <Button title='Select' isDisable={!enableSelect} onClick={SelectedRolesList} />;
}

const onChangeUser = (event) => {
  let User = event.target.value
  setUsers((prevState) => {
    return {...prevState, user_name: User}
  })
}


const GridAPI = (gridObj) => {
  gridObject = gridObj
}

const RowSelection = (event) => { 
  // if(event.api.getSelectedRows().length >= 1){ 
  //   setEnableSelect(true)
  // }else { 
  //   setEnableSelect(false)
  // }
  setEnableSelect(true)
}

const onChangefName = (event) => {
  let FirstName = event.target.value
  setUsers((prevState) => {
    return {...prevState, first_name: FirstName}
  })
}

const SelectedRolesList = () => {   
    let api = Api
    api.setUserToken()
    let UPDATED_ROLES = [] 
    gridObject.api.getSelectedRows().forEach((element) => {
      UPDATED_ROLES.push({  
        "role_id": element.role_id,
        "user_id": parseInt(id)
      })
    })  
    api.axios().post(Settings.apiBaseUrl+'/assign_role',UPDATED_ROLES).then(function(res){
      if(res.data.status==='success'){  
        load_roles() 
        CloseHandler()
        Helper.alert('User Roles Updated Successfully!')
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  }) 
}


const onChangeNetwork = (event) => {
  let NetworkDomain = event.target.value
  setUsers((prevState) => {
    return {...prevState, network_domain: NetworkDomain}
  })
}

const onChangeMName = (event) => {
  let MiddleName = event.target.value
  setUsers((prevState) => {
    return {...prevState, middle_name: MiddleName}
  })
}

const onChangelName = (event) => {
  let LastName = event.target.value
  setUsers((prevState) => {
    return {...prevState, last_name: LastName}
  })
}
 
const onChangeEnable = (name, value) => { 
  if(value == "on"){
    setUsers((prevState) => {
      return {...prevState, enable: true}
    })
  }else {
    setUsers((prevState) => {
      return {...prevState, enable: false}
    })
  } 
}
 
  const SaveHandler = (e) => {
    let newData = {
      user_name: users.user_name,
      first_name: users.first_name,
      last_name: users.last_name,
      enable: users.enable ,
      language_id: users.language_id
    }
    let validData = {
      ...newData
    }
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
  } 
    let api = Api
    api.setUserToken()   
    api.axios().put(Settings.apiUrl+`/user/${id}`, {
      "first_name": users.first_name !== "" ? users.first_name : null,
      "last_name": users.last_name !== "" ? users.last_name : null,
      "middle_name": users.middle_name !== "" ? users.middle_name : null,
      "user_name":users.user_name !== "" ? users.user_name : null,
      "enable": users.enable,
      "SID":null,
      "network_domain":users.network_domain !== "" ? users.network_domain : null,
      "network_alias":null,
      "language_id": document.getElementById('language_id').value ? parseInt(document.getElementById('language_id').value) : null
  }).then(function(res){
    if(res.data.status==='success'){  
      load_roles()
      setEmpty(false)
      setSelectedRoles([])
      Helper.alert('User Updated Successfully!')
    }
  }).catch((res) => { 
  Helper.alert(res.response.data.message, 'error')
  })
    // }
  } 

  let security = props.security;
let frontendIds = Gui_id_list;
if(!security.canView(Gui_id_list.administrationForms.users.users_edit_screen)){
  return <Fragment>
  <Alert message='NO ACCESS' type='danger' />
  </Fragment>
}
  
  return (
    <React.Fragment> 
      <div className='container-fluid'>
      {loading ? <SimpleLoading /> : 
        <React.Fragment><NavigationHeder title={`<span style="color:#000;">${Helper.getLabel(props.language, 'user', "User")} #</span>  ${users.first_name}`}>
              <LinkBtn isActive={false} to="/users" title={Helper.getLabel(props.language, 'cancel', "Cancel")} />
              <Button isDisable={!security.canEdit(frontendIds.administrationForms.users.users_edit_screen_save_button)}  isActive={true} onClick={SaveHandler} title={Helper.getLabel(props.language, 'save', "Save")} icon={'/images/icons/save-white.svg'} />
            </NavigationHeder><React.Fragment>
                <Collapse title={Helper.getLabel(props.language, 11217, 'General')} open={true}>

                  <section className='users-fields'>
                    <div className='container-fluid'>
                      <div className='row'>

                        <div className='col-12'>
                          <div className='row users-fields-form gy-0 mt-2 mb-2'>

                            <div className='col-lg-3 col-md-3'> 
                              <Input label={Helper.getLabel(props.language, 'user', "User")} type='text' disabled placeholder='Enter Your User ID' required={true}  onChange={onChangeUser} value={users.user_name} />
                            </div>

                            <div className='col-lg-3 col-md-3'> 
                              <Input label={Helper.getLabel(props.language, 'network_domain_USER_SETUP', "Network Domain")} type='text' placeholder='Enter Your Network Domain' onChange={onChangeNetwork} value={users.network_domain} />
                            </div>

                            <div className='col-lg-3 col-md-3'> 
                              <Input label={Helper.getLabel(props.language, 'first_name_USER_SETUP', "First Name")} type='text' placeholder='Enter Your First Name' required={true}  onChange={onChangefName} value={users.first_name} />
                            </div>

                            <div className='col-lg-3 col-md-3'> 
                              <Input label={Helper.getLabel(props.language, 'middle_name_USER_SETUP', "Middle Name")} type='text' placeholder='Please Enter Your Middle Name' onChange={onChangeMName} value={users.middle_name} />
                            </div>

                          </div>

                          <div className='row users-fields-form gy-0 mb-2'>

                            <div className='col-lg-3 col-md-3'> 
                              <Input label={Helper.getLabel(props.language, 'last_name_USER_SETUP', "Last Name")} type='text' placeholder='Please Enter Your Last Name' required={true}  onChange={onChangelName} value={users.last_name} />
                            </div>

                            <div className='col-lg-3 col-md-3 mt-auto'> 
                              <DropDownInput label={Helper.getLabel(props.language, 'default_language_USER_SETUP', "Default Language")} options={languages} required={true} value={users.language_id} id='language_id' />
                            </div>

                            <div className='col-lg-2 col-md-2 mt-4'>
                              <Checkbox type='checkbox' className='me-4' onChange={onChangeEnable} isChecked={users.enable} label={Helper.getLabel(props.language, 'enable_USER_SETUP', "Enable")} required={true} /> 
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </section>
                </Collapse>
                <Collapse title='Users Roles' open={true}>
                  <section className='user-roles-section'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-lg-12 gy-2 border-bottom'>
                          <div className='roles-buttons d-flex justify-content-start'>
                            <button type='button' className='add-user-role ms-2' onClick={AssignRoles}><img src={Plus} className='img-fluid mb-1 roles-icons' /> {Helper.getLabel(props.language, 'assign_role_USER_SETUP', "Assign Role")}</button>
                            <button type='button' className='delete-role ms-2' onClick={DeleteAssignedRoles}><img src={Delete} className='img-fluid mb-1 roles-icons' />{Helper.getLabel(props.language, 'delete_role_USER_SETUP', "Delete Role")}</button>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='user-treeview-hierarchy ps-4 pt-2'>
                            <Treeview items={rolesStructure} onClick={rolesHandler} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </Collapse>

              </React.Fragment></React.Fragment>

   } </div>
       
    {showRolesGrid && 
      <div className='search_grid_height'> 
      <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
      <div className='rs_users_grid'>
      <AgGrid id="rs_popup_open" singleRowSelect={false} onSelectionChanged={RowSelection} afterDeleteButtons={Callbuttons} allBtnHide={true} viewOnly={true} onGridReady={GridAPI} header={Columns_Headings_Roles} data={userrolesGrid} />
      </div>
      </Popup>
    </div>
  }
  </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.administrationForms.users.users_edit_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditUsers, SecurityOptions))))
