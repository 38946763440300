import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import ActionTypes from "../../../inc/ActionsTypes";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import Dropdown from "../../Forms/Dropdown";
import Input from "../../Forms/Input";
import InputDatePicker from "../../Forms/InputDatePicker";
import Collapse from "../../inc/Collapse";
import Popup from "../../Popup/Popup";
import DistributionTabs from "../common/DistributionTabs";
import OverlayLoader from "../common/OverlayLoader";

class EditOldCartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      requesterList: [{ ...this.props.userData }],
      employeeList: [],
      requester: null,
      submitterList: [{ ...this.props.userData }],
      submitter: null,
      onBehalfRequest: false,
      itemDescription: this.props.activeCartItem?.description ?? null,
      deliveryDate: null,
      quantity: parseInt(this.props.activeCartItem?.ordered_qty) ?? null,
      units: [],
      unit: this.props.activeCartItem?.unit ?? null,
      unitPrice: parseInt(this.props.activeCartItem?.unit_price) ?? null,
      lineAmount: this.props.activeCartItem?.line_amount ?? null,
      discountPercentage: null,
      discountAmount: null,
      taxGroups: [],
      taxGroup: this.props.activeCartItem?.taxgroup_id ?? null,
      textPercentage: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props.activeCartItem);
    this.getTaxGroups();
    this.getAllEmployees();
    this.getUnits();
  }

  componentDidUpdate() {
    console.log(this.state, this.props);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const formData = {
      description: this.state.itemDescription,
      ordered_qty: this.state.quantity,
      unit: this.state.unit,
      unit_price: this.state.unitPrice,
      line_amount: this.state.lineAmount,
      taxgroup_id: this.state.taxGroup,
      discount_amount: this.state.discountAmount,
      discount_percentage: this.state.discountPercentage,
      requester_id: this.state.requester,
      onbehalf_request: this.state.onBehalfRequest,
    };
    console.log(formData, this.props.activeCartItem);
    // if (
    //   !formData.description ||
    //   formData.description === "" ||
    //   !formData.ordered_qty ||
    //   !formData.unit ||
    //   !formData.unit_price ||
    //   !formData.line_amount ||
    //   !formData.taxgroup_id ||
    //   !formData.discount_amount ||
    //   !formData.discount_percentage ||
    //   !formData.requester_id
    // ) {
    //   Helper.alert("Fill all fields", "error");
    // } else {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          "/pr_cartline/" +
          this.props.activeCartItem.line_id,
        formData
      )
      .then(function (res) {
        console.log(res);
        if (res.data.status === "success") {
          that.setState({ isLoading: false });
          that.props.toggleDidCartUpdate();
          that.props.closeEditOldCartModal();
          Helper.alert(res.data?.message, "success");
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message ?? error?.message, "error");
      });
    // }
  }

  getTaxGroups() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/taxgroup")
      .then(function (res) {
        if (res.data.status === "success") {
          console.log("received tax groups");
          that.setState({ isLoading: false, taxGroups: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getAllEmployees() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + "/employee")
      .then(function (res) {
        console.log(res);
        if (res.data.status === "success") {
          that.setState({ isLoading: false, employeeList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getUnits() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isLoading: true });
    api
      .axios()
      .get(Settings.loginUrl + "/unit")
      .then(function (res) {
        that.setState({
          isLoading: false,
          units: [...res.data.data],
        });
        console.log("helloc ategories: ", res);
        if (res.data.status === "success") {
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  render() {
    return (
      <Popup
        autoOpen={true}
        width="750px"
        className="editCartModal"
        onClose={this.props.closeEditOldCartModal}
      >
        <Collapse
          title={Helper.getLabel(
            this.props.language,
            "general_distribution",
            "General Information"
          )}
          open={true}
        >
          <form
            onSubmit={(e) => e.preventDefault()}
            className="generalInformation"
          >
            <div className="generalInformation-oldCartItem">
              <div className="generalInformation-oldCartItem-grid1">
                <Dropdown
                  label="Requester"
                  options={
                    this.state.onBehalfRequest
                      ? this.state.employeeList.map((item) => {
                          return {
                            value: item.employee_id,
                            label: item.displayname,
                          };
                        })
                      : this.state.requesterList.map((item) => {
                          return {
                            value: item.employee_id,
                            label: item.first_name,
                          };
                        })
                  }
                  placeHolder="Select requester"
                  id="requester_id"
                  onChange={(e) => {
                    this.setState({ requester: e.target.value });
                  }}
                />
                <div className="generalInformation-oldCartItem-grid1-toggle">
                  <div className="generalInformation-oldCartItem-grid1-toggle-label">
                    On behalf request
                    <label class="toggle-switch">
                      <input
                        type="checkbox"
                        class="toggle-switch-checkbox"
                        value={this.state.onBehalfRequest}
                        onChange={() => {
                          this.setState({
                            onBehalfRequest: !this.state.onBehalfRequest,
                            requester_id: null,
                          });
                        }}
                      />
                      <span class="toggle-switch-slider"></span>
                    </label>
                  </div>
                  <Dropdown
                    label="Submitter"
                    options={this.state.submitterList.map((item) => {
                      return {
                        value: item.employee_id,
                        label: item.first_name,
                      };
                    })}
                    placeHolder="Select submitter"
                    id="submitter_id"
                    onChange={(e) => {
                      this.setState({ submitter: e.target.value });
                    }}
                  />
                </div>
                <Input
                  label="Item Description"
                  onChange={(e) => {
                    this.setState({ itemDescription: e.target.value });
                  }}
                  value={this.state.itemDescription}
                />
                <InputDatePicker
                  label="Delivery date"
                  inputClassName="delivery_date"
                  name="delivery_date"
                  onChange={() => {
                    this.setState({
                      deliveryDate: $(".delivery_date").val(),
                    });
                  }}
                  value={this.state.deliveryDate}
                />
              </div>
              <div className="generalInformation-oldCartItem-grid2">
                <Input
                  itemType="number"
                  label="Quantity"
                  onChange={(e) => {
                    if (e.target.value > 0)
                      this.setState({ quantity: e.target.value });
                  }}
                  value={this.state.quantity}
                />
                <Dropdown
                  label="Unit"
                  options={this.state.units.map((item) => {
                    return {
                      value: item.unit_id,
                      label: item.description,
                    };
                  })}
                  placeHolder="Select unit"
                  id="unit_of_measure_id"
                  onChange={(e) => {
                    this.setState({
                      unit: e.target.value,
                    });
                  }}
                  value={this.state.selectedUnitOfMeasure_comment}
                />
                <Input
                  itemType="number"
                  label="Unit price"
                  onChange={(e) => {
                    if (e.target.value > 0)
                      this.setState({ unitPrice: e.target.value });
                  }}
                  value={this.state.unitPrice}
                />
                <Input
                  itemType="number"
                  label="Line amount"
                  onChange={(e) => {
                    if (e.target.value > 0)
                      this.setState({ lineAmount: e.target.value });
                  }}
                  value={this.state.lineAmount}
                />
                <Input
                  itemType="number"
                  label="Discount percentage"
                  onChange={(e) => {
                    if (e.target.value > 0)
                      this.setState({ discountPercentage: e.target.value });
                  }}
                  value={this.state.discountPercentage}
                />
                <Input
                  itemType="Number"
                  label="Discount amount"
                  onChange={(e) => {
                    if (e.target.value > 0)
                      this.setState({ discountAmount: e.target.value });
                  }}
                  value={this.state.discountAmount}
                />
                <Dropdown
                  label="Tax group"
                  options={this.state.taxGroups.map((item) => {
                    return {
                      value: item.taxgroup_id,
                      label: item.name,
                    };
                  })}
                  placeHolder="Select item type"
                  id="tax_group_id"
                  onChange={(e) => {
                    this.setState({ taxGroup: e.target.value });
                  }}
                />
                <Input
                  itemType="number"
                  label="Tax percentage"
                  onChange={(e) => {
                    if (e.target.value > 0)
                      this.setState({ taxPercentage: e.target.value });
                  }}
                  value={this.state.taxPercentage}
                />
              </div>
            </div>
          </form>
        </Collapse>
        <Collapse open={true}>
          <DistributionTabs />
        </Collapse>
        <div className="generalInformation-action">
          <button
            className="generalInformation-action-outlined"
            onClick={this.props.closeEditOldCartModal}
          >
            {Helper.getLabel(this.props.language, "cancel", "Cancel")}
          </button>
          <button
            className="generalInformation-action-solid"
            onClick={this.handleFormSubmit}
          >
            {Helper.getLabel(this.props.language, "add", "Add")}
          </button>
        </div>
        {this.state.isLoading ? <OverlayLoader /> : null}
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
    activeCartItem: state.prShop.activeCartItem,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeEditOldCartModal: () =>
      dispatch({ type: ActionTypes.CLOSE_EDITOLDCART_MODAL }),
    toggleDidCartUpdate: () => {
      dispatch({ type: ActionTypes.TOGGLE_DIDCARTUPDATE });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOldCartModal);
