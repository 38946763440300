import React, {Fragment, useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../inc/Api';
import Gui_id_list from '../../inc/Gui_id_list';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import MasterComonent from '../Backend/MasterComonent';
import MasterComponentWraper from '../Backend/MasterComponentWraper';
import AgGrid from '../grid/ag/ag-grid';
import CustomHtmlCell from '../grid/ag/cellRender/CustomHtmlCell';
import Alert from '../inc/Alert';
import ButtonNew from '../inc/ButtonNew';
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';

const ParametersArchive = (props) => {
    const [loading, setLoading] = useState(true)
    const [parametersData, setParametersData] = useState([])
    const [entityList, setEntityList] = useState([])
    const navigate = useNavigate()

    let paramsDataCalled = false
    let entitiesCalled = false

    const getParametersData = () => {
       if(paramsDataCalled){
        return;
       }
       let api = Api
       paramsDataCalled = true
       api.setUserToken()
       api.axios().get(Settings.apiProcurementSettingsUrl+'/procurementsetting',{}).then(function(res){
        if(res.data.status==='success'){   
          setParametersData(res.data.data.reverse())
          setLoading(false)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setLoading(false)
    })
    }

    const getEntitiesList = () => {
        if(entitiesCalled){
         return;
        }
        let api = Api
        entitiesCalled = true
        api.setUserToken()
        api.axios().post(Settings.apiUrl+'/search_entity',{
            "code":"",
            "name":""
        }).then(function(res){
          if(res.data.status==='success'){   
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                value: element.entity_id,
                label: element.name
            })
            })
            setEntityList(UPDATED_STATE)
          } 
      })
    }

    useEffect(() => {
        getParametersData()
        getEntitiesList()
    }, [])

    const CellHandler = (event) => {
        if(event.node.data.procurement_setting_id !== ''){ 
            if(event.colDef.field === 'name') { 
              navigate(`/edit-procurement-settings/${event.data.procurement_setting_id}`) 
            }
          }else {
            Helper.alert('Setting Id Doesnt Exists!', 'error')
          }
    }

    const gridLinkValueFormat = (params) => { 
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }

    const gridValueFormatChip = (params) => {
        let name = '' 
        params.value.forEach((element) => {
            entityList.forEach((item) => {
                if(element == item.value){  
                    name = name + " " + `<span class="chips-list-page">${item.label}</span>` 
                }
            })
        })
        return `<div>${name}</div>`  
    }

    const DeleteParamters = (allRows) => {
        let api = Api
        api.setUserToken() 
        allRows.forEach(element => {
          api.axios().delete(Settings.apiProcurementSettingsUrl+`/procurementsetting/${element.procurement_setting_id}`,{}).then(function(res){
              if(res.data.status==="success"){
                Helper.alert(res.data.message)
                getParametersData()
              } 
            }).catch((res) => { 
              Helper.alert(res.response.data.message, 'error')
              getParametersData()
            })
        })
    }
  
    const Columns_Headings = [
        {field:'name', headerName: Helper.getLabel(props.language,'name','Name') , editable: false,  cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat },
        {field:'entities', headerName:Helper.getLabel(props.language,'entities','Entities'), editable: false, valueFormatter: gridValueFormatChip,  cellRenderer: CustomHtmlCell }, 
    ]

    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.procure.procurement_settings.procurement_settings)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
    }
 
  return ( 
    <div className='parameters_settings_procure_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'procurement_settings','Procurement Settings')}>
            <ButtonNew isDisable={!security.canCreate(frontendIds.procure.procurement_settings.procurement_settings_main_create_button)} to="/procurement-settings-create" title={Helper.getLabel(props.language,'new','New')} />
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : null}
        <div className='rs_parameters_edit'>
            <AgGrid id="rs_procurement_settings_list" hideDelete={!security.canDelete(frontendIds.procure.procurement_settings.procurement_settings_main_delete_button)} onCellClicked={CellHandler} onDelete={DeleteParamters} header={Columns_Headings} data={parametersData} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
        </div>
    </div> 
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }

  const SecurityOptions = {
    gui_id:Gui_id_list.procure.procurement_settings.procurement_settings
  } 

  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ParametersArchive, SecurityOptions))))