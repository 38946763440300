import React from 'react';
import Logo from './logo.png';
import thumb from './like.svg';
import love from './love.svg';
import support from './support.svg';
import celebrate from './celebrate.svg';
import insightful from './insightful.svg';
import curious from './curios.svg';
import Globe from './Globe.svg';
import userLike from './react_like.svg';
import comment from './comment.svg';
import action from './action.svg';

const UserFeed = () => {
    //Accept props here
  return (
    <div className='user_feeds d-flex justify-content-start mt-3'>
      <div className='container-fluid p-0'>
        <div className='row company_info pt-3'>
          <div className='col-12 d-flex flex-row'>
            <img src={Logo} className='img-fluid' /> 
            <div className='ms-3'>
            <h2>Company name</h2>
            <p className='text-muted'>20h • 
            <span className='ms-2'><img src={Globe} className='img-fluid' /></span>
            </p>
          </div>
          </div>
        </div>

        <div className='row mt-2 mb-3 post_msg'>
           <div className='col-12'>
             <p>Lacus amet, laoreet viverra id faucibus nisi cras est sit pellentesque amet in auctor ac sapien enim nulla tellus risus ornare lobortis commodo in proin in fermentum morbi at sem facilisi orci magna in sagittis, tortor ac maecenas eget etiam ullamcorper magna eu risus ipsum nec nibh lacus,sna</p>
           </div>
        </div>

        <div className='row post_image'></div>

        <div className='row post_image_short_desc'>
          <div className='col-12'>
            <h3>Invoice Number# 123451</h3>
          </div>
          <div className='col-12'>
            <p>Source • 10 min read</p>
          </div>
        </div>

        <div className='row reaction-elements border-bottom border-1'> 
          <div className='col-6'>
            <div className='flex-row d-flex justify-content-start align-items-center'>
                <img src={thumb} className='img-fluid' />
                <img src={celebrate} className='img-fluid ms-2' />
                <img src={support} className='img-fluid ms-2' />
                <img src={love} className='img-fluid ms-2' />
                <img src={insightful} className='img-fluid ms-2' />
                <img src={curious} className='img-fluid ms-2' />

                <h3 className='ms-3 mt-1'>786 • 4 comments</h3>
            </div>
          </div> 
        </div>

        <div className='row user_reaction_post'>
            <div className='col-2'> 
                <img src={userLike} className='img-fluid' />
                <span className='ms-2'>Like</span>
            </div> 
            <div className='col-2'>
                <img src={comment} className='img-fluid' />
                <span className='ms-2'>Comment</span>
            </div> 
            <div className='col-2'>
                <img src={action} className='img-fluid' />
                <span className='ms-2'>Action</span>
            </div> 
        </div>

      </div>
    </div>
  )
}

export default UserFeed