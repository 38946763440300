import React, { Component } from 'react';
import AuthFooter from '../../components/Auth/AuthFooter';
import AuthHeader from '../../components/Auth/AuthHeader';
import LoginRegisterTab from '../../components/Auth/LoginRegisterTab';

class Register extends Component {
    render() {
        return (
            <div className='login_register_page register_page'>
                <AuthHeader/>
                    <LoginRegisterTab tab="register"/>
                <AuthFooter/>
            </div>
        );
    }
}

export default Register;