import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
    options:{
        isSidebarOpen:false
    }
}
const OptionsReducer = (state = DefaultState, actions) =>{
    if(actions.type ===  ActionTypes.SET_OPTIONS ){
        state = {
            ...state,
            options:{
                ...state.options,
                [actions.key]:actions.value
            }
        }
    }
    return state;
}
export default OptionsReducer;