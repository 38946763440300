import React from 'react'
import Action from './action.svg'
import PDF from './PDF.svg'

const AttachmentsLines = () => {
  return (
    <div className='purchase_orders_attachments'>
        <div className='row line_head_attachments d-flex justify-content-center align-items-center'>
            <div className='col-4'>
                <h4>Name</h4>
            </div>
            <div className='col-4'>
                <h4>Type</h4>
            </div>
            <div className='col-4'> 
                <h4>Action</h4>
            </div> 
        </div> 

        <div className='row line_head_attachments_data d-flex justify-content-center align-items-center'>
            <div className='col-4'>
                <div className='row'>
                   <div className='col-3'> 
                        <img src={PDF} className='img-fluid pdf_icon' />
                   </div>
                   <div className='col-9'>
                        <h3>Details of Project</h3>
                        <h4>PDF File</h4>
                   </div>
                </div>
            </div>
            <div className='col-4'>
                <h4>11/24/2015</h4>
            </div>
            <div className='col-4'> 
                <img src={Action} className='img-fluid action' />
            </div> 
        </div>

        <div className='row line_head_attachments_data d-flex justify-content-center align-items-center'>
            <div className='col-4'>
                <div className='row'>
                   <div className='col-3'> 
                        <img src={PDF} className='img-fluid pdf_icon' />
                   </div>
                   <div className='col-9'>
                        <h3>Details of Project</h3>
                        <h4>PDF File</h4>
                   </div>
                </div>
            </div>
            <div className='col-4'>
                <h4>11/24/2015</h4>
            </div>
            <div className='col-4'> 
                <img src={Action} className='img-fluid action' />
            </div> 
        </div>

        <div className='row line_head_attachments_data d-flex justify-content-center align-items-center'>
            <div className='col-4'>
                <div className='row'>
                   <div className='col-3'> 
                        <img src={PDF} className='img-fluid pdf_icon' />
                   </div>
                   <div className='col-9'>
                        <h3>Details of Project</h3>
                        <h4>PDF File</h4>
                   </div>
                </div>
            </div>
            <div className='col-4'>
                <h4>11/24/2015</h4>
            </div>
            <div className='col-4'> 
                <img src={Action} className='img-fluid action' />
            </div> 
        </div>

        <div className='row line_head_attachments_data d-flex justify-content-center align-items-center'>
            <div className='col-4'>
                <div className='row'>
                   <div className='col-3'> 
                        <img src={PDF} className='img-fluid pdf_icon' />
                   </div>
                   <div className='col-9'>
                        <h3>Details of Project</h3>
                        <h4>PDF File</h4>
                   </div>
                </div>
            </div>
            <div className='col-4'>
                <h4>11/24/2015</h4>
            </div>
            <div className='col-4'> 
                <img src={Action} className='img-fluid action' />
            </div> 
        </div>
     
    </div>
  )
}

export default AttachmentsLines;