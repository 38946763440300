import React, { Component } from 'react';
import './dropdown.css';
/*
onChange : function
*/
class Dropdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen:false,
            selectedItem:null
        }
    }
    onItemClick(item){
        if(this.props.onChange && typeof this.props.onChange === 'function' ){
            this.props.onChange(item);
        }
        this.setState({
            isOpen:false,
            selectedItem:item
        })
    }
    displayDropdown(){
        if(!this.state.isOpen){
            return <></>
        }
        let items = this.props.options && Array.isArray(this.props.options) ? this.props.options : [];
        return(
            <ul className="dropdown_items">
                {
                    items.map( (item,key) => { return (<li onClick = { e => this.onItemClick(item) } dataValue={item.value} key={key}>{item.label}</li>) })
                }
            </ul>
        )
    }
    getSelectedTitle(){
        let outout = this.props.label;
        if(this.state.selectedItem && this.state.selectedItem.label){
            outout = this.state.selectedItem.label;
        }
        return outout;
    }
    render() {
        return (
            <div className={'rs_dropdown ' +  ( this.props.className ?  this.props.className : '' ) + ( this.state.isOpen ? ' open ' : '') }>
                <div className='dropdown_label' onClick={ e => {this.setState({isOpen: !this.state.isOpen})}}>{this.getSelectedTitle()} <img className='icon' alt={this.getSelectedTitle()} src="/images/icons/angle-down.svg" /></div>
                { this.displayDropdown() }
            </div>
        );
    }
}

export default Dropdown;