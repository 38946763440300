import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import MasterComonent from "../Backend/MasterComonent";
import Button from "../inc/Button";
import NavigationHeder from "../Navigations/NavigationHeder";
import NavCartWishlist from "./common/NavCartWishlist";
import EditNewCartModal from "./modals/EditNewCartModal";
import EditOldCartModal from "./modals/EditOldCartModal";

class ShopLayout extends Component {
  componentDidMount() {
    console.log(this.props);
    this.getCartCount();
    this.getWishlistCount();
  }

  getCartCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartlines")
      .then(function (res) {
        console.log(res.data.data?.length);
        that.props.changeCartCount(res.data.data?.length);
      });
  }

  getWishlistCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/wishlist")
      .then(function (res) {
        console.log("wishlist fetched", res);
        that.props.changeWishlistCount(res.data.data?.length);
        that.props.updateWishlistIds([...res.data.data]);
      });
  }

  render() {
    return (
      <MasterComonent>
        <div className="pr-wrapper">
          <NavigationHeder
            backUrl={this.props.backUrl}
            title={this.props.title}
            hideMoreBtn={this.props.hideMoreBtn}
          >
            {this.props.hideMoreBtn ? (
              <NavCartWishlist />
            ) : (
              <>
                <Button isActive={false} title="Back" />
                <Button isActive={true} title="Save" />
              </>
            )}
          </NavigationHeder>
          {this.props.children}
          {/* {this.props.showEditNewCartModal ? <EditNewCartModal /> : null} */}
          {this.props.showEditOldCartModal ? <EditOldCartModal /> : null}
        </div>
      </MasterComonent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showEditNewCartModal: state.prShop.showEditNewCartModal,
    showEditOldCartModal: state.prShop.showEditOldCartModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
    updateWishlistIds: (payload) =>
      dispatch({ type: "UPDATE_WISHLIST_IDS", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopLayout);
