import React, { Component } from 'react';

class Alert extends Component {
    render() {
        return (
            <div className={ this.props.type ? 'rs_alert alert fade show alert-' + this.props.type :  'rs_alert alert alert-primary fade show'} role="alert">
                {this.props.icon ? <img className='icon' src={this.props.icon} alt="icon" /> :''}
                <div>
                    {this.props.message}
                </div>
            </div>
        );
    }
}

export default Alert;