import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Api from '../../inc/Api';
import Form_gui_ids from '../../inc/Form_gui_ids';
import Helper from '../../inc/Helper';
import LabelsGUI_ID from '../../inc/LabelsGUI_ID';
import Settings from '../../inc/Settings';
import AgGrid from '../grid/ag/ag-grid';
import CustomHtmlCell from '../grid/ag/cellRender/CustomHtmlCell';
import Alert from '../inc/Alert';
import ButtonNew from '../inc/ButtonNew';
import Dropdown from '../inc/Dropdown/Dropdown';
import LinkBtn from '../inc/LinkBtn';
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';

class Tenants extends Component {
    constructor(props){
        super(props);
        this.gridObj = null;
        this.tenantsIsCalled = false;
        this.state = {
            redirectTo:null,
            isLoading:true,
            tenantsList:[]
        }
    }
    componentDidMount(){
        this.loadTenants();
    }
    loadTenants(){
        if(this.tenantsIsCalled){
           return; 
        }
        this.tenantsIsCalled = true;
        let api = Api;
        let that = this;
        let frmData = {
            code:null,
            name:null
        }
        api.setUserToken();
        //api.setHeader({ "Content-type": "application/json" })
        api.axios().post(Settings.apiUrl + '/tenant',frmData).then(function(res){
            if(res.data.status==='success'){
                let tenantList = res.data.data.reverse();
                that.setState({
                    tenantsList:tenantList,
                    isLoading:false
                })
            }
            
        })
    }
    onCellClicked(event){
        if(event.colDef.field === 'code'){
            this.setState({
                redirectTo: '/tenant/edit/'+event.data.tenant_Id
            })
        }
    }
    statusValueFormat(params){
        if(params.value === true){
            return `<span class="rs_active_label">Active</span>`;
        }
        return `<span class="rs_inactive_label">Inactive</span>`;
    }
    gridLinkValueFormat(params){
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }
    setGridObj(gridObj){
        this.gridObj = gridObj;
    }
    onFilterChange(item){
        if(!this.gridObj){
            return;
        }
        if(item.value === 'any'){
            this.gridObj.api.destroyFilter('isactive');
            return;
        }
        let filterModel = this.gridObj.api.getFilterModel();
        filterModel.isactive = {
            filterType: 'text',
            type: 'startsWith',
            filter: item.value
        };
        this.gridObj.api.setFilterModel(filterModel);
    }
    appNavLeftItems(){
        let filterOptions = [
            {label:'All Tenants' , value:"any"},
            {label:'Active' , value:"Active"},
            {label:'Inactive' , value:"Inactive"}
        ]
        return <Dropdown onChange={ this.onFilterChange.bind(this) } options={filterOptions} label={"All Tenants"} />
    }
    navigationMoreOptions(){
        return(
            <div>
                
            </div>
        )
    }
    render() {
        let language = this.props.language;
        let props =this.props;
        let security = this.props.security;
        if(!security.canRead(Form_gui_ids.tenant.main_id)){
            return <Alert type="danger" message={Helper.getLabel(language,'no_access','No Access')} />
        }
        if(this.state.redirectTo){
            return <Navigate to={ this.state.redirectTo } />
        }
        let headers = [
            {field:'tenant_Id',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.id,'Id'), width:150, editable:false , hide:true},
            {field:'code',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.code,'Code'), width:150 ,valueFormatter: this.gridLinkValueFormat.bind(this),  cellRenderer: CustomHtmlCell},
            {field:'name',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.name,'Name'), width:150},
            {field:'isactive',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.status,'Status'), width:150,inputType:"select",options:[true,false], valueFormatter: this.statusValueFormat.bind(this),  cellRenderer: CustomHtmlCell},
            {field:'createdby',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.created_by,'Created By'), width:"20px", editable:false},
            {field:'modifiedby',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.modifiedby,'Modified by'), width:150, editable:false},
            {field:'start_date',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.start_date,'Start date'), valueFormatter: Helper.gridDateView, inputType:"datepicker", width:150},
            {field:'end_date',headerName: Helper.getLabel(language,LabelsGUI_ID.tenant.end_date,'End Date'),inputType:"datepicker",  width:150,valueFormatter: Helper.gridDateView},
        ];
        
        return (
            <div className='tenents_archive'>
                <div className='container-fluid'>
                    <NavigationHeder backUrl="/setup" title={Helper.getLabel(language,'all_tenants','All Tenants')} moreOptions={ this.navigationMoreOptions.bind(this)}>
                       {security.canView(Form_gui_ids.tenant.create) ? <ButtonNew isDisable={!security.canCreate(Form_gui_ids.tenant.create)} to="/new-tenant"/> : ''}
                    </NavigationHeder>
                    { this.state.isLoading ? <SimpleLoading/> : ''}
                    <AgGrid height="700px" onCellClicked = { this.onCellClicked.bind(this) } getGrid = { this.setGridObj.bind(this) } id="tenants_lists" data={this.state.tenantsList} header={headers} addBtnHide ={true}  hideSave={true} hideDelete={true} viewOnly={true} dissableSelect = {true} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
const SecurityOptions = {
    gui_id:Form_gui_ids.tenant.main_id
}
export default   connect (mapStateToProps) ( ApplySecurityRoles(Tenants,SecurityOptions));
