import React from 'react'
import Select from '../../Custom Select/Select';
import './Address.css'

const Address = () => {

  // Get ENUM_DATA through API call
  const ENUM_DATA = [
    {id: '0', label: 'E-mail Address'},
    {id: '1', label: 'URL'},
    {id: '2', label: 'Telex'},
    {id: '3', label: 'Fax'},
    {id: '4', label: 'Phone'}
  ];
  
  return (

  <div className='edit-address mb-2'>
    <div className='container'>
    <div className='row gy-0'>

    <div className='col-12'>
      <div className='address border-bottom'>
        <h1>Edit address - Vendor account :1134</h1> 
      </div>
    </div>

    <div className='col-12'>
    <div className='row address-form gy-0 mb-1'>

     <div className='col-lg-3 col-md-6 address-id'>
     <label>Address ID </label>
     <input type='text' placeholder='4692' />
     </div>

     <div className='col-lg-6 col-md-6 address-name'>
     <label>Name Of The Address </label>
     <input type='text' placeholder='Enter Address' />
     </div>

    <div className='col-lg-3 col-md-6 address-purposes'>
    <label>Purpose</label>
    <Select
    header='Select'
    options={ENUM_DATA}
    />
    </div>

    </div>
    
    </div>
    </div>
    </div>
  </div>
  
  )
}

export default Address;


