import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoutBtn from '../Auth/LogoutBtn';
import TanentSwitcher from '../Auth/TanentSwitcher';
class UserWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            isDropdownOpen:false
        }
    }

    render() {
        let user = this.props.auth.user ? this.props.auth.user : {};
        let userTenant = user.tenant ? user.tenant  : null;
        return (
            <div className={ "user_widget_menu" + (this.state.isDropdownOpen ? ' open ': ' close') }>
                <div className='d-flex' onClick={ e => { this.setState({isDropdownOpen: !this.state.isDropdownOpen})}}>
                    <span className='u_name'>{user.first_name +' '+ user.last_name}</span>
                    <img className='u_p' src='https://placehold.jp/150x150.png' alt=""/>
                    <img className='arrow' src="/images/icons/angle-down.svg" alt=""/>
                </div>
                <div className='dropdownwidget'>
                    <ul>
                        <li>
                            <Link to={'/my-account'}>
                                My Account
                            </Link>
                        </li>
                        <li>
                            <a>Switch Tentant</a>
                            <TanentSwitcher/>
                        </li>
                        <li><Link to={'/settings'}>Settings</Link></li>
                        {userTenant ? <li><Link to={'/tenant/edit/' + userTenant.tenant_Id}>My Tenant</Link></li> : ''}
                    </ul>
                    <LogoutBtn/>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        appOptions:state.options
    }
  }
  export default connect(mapStateToProps) (UserWidget);