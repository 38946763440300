import React, { useState } from 'react'
import Input from '../../../../../components/Forms/Input';
import AgGrid from '../../../../../components/grid/ag/ag-grid';
import Button from '../../../../../components/inc/Button';
import SimpleLoading from '../../../../../components/Loading/SimpleLoading';
import Popup from '../../../../../components/Popup/Popup';
import DropDownInput from '../../../../../components/Forms/Dropdown';
import axios from 'axios';
import Helper from '../../../../../inc/Helper';
import Settings from '../../../../../inc/Settings';

const AdvancedSettings = (props) => {

  const query = new URLSearchParams(window.location.search);
  const workflowId = query.get('wid');
  const getNodeId = () => `${+new Date()}`;

  const [loading, setLoading] = useState(false);
  const [nodeLabel, setnodeLabel] = useState('');

  const actionName = [
    { value: 1, label: 'Approve' },
    { value: 2, label: 'Reject' },
    { value: 3, label: 'Request more information' },
    { value: 4, label: 'Delegate' },
    { value: 5, label: 'Send back' },
    { value: 6, label: 'Recall' },
    { value: 7, label: 'Escalation - Task' },
    { value: 8, label: 'Escalation - Email' },
    { value: 9, label: 'Reminder' }
  ]

  const actionType = [
    { value: 0, label: 'Auto' },
    { value: 1, label: 'Manual' }
  ]
  const modalClose = () => {
    props.hideActionModal(false);
  }

  const saveDataHandler = () => {
    const nodid = getNodeId();
    const formData = {
      "workflow_id": +workflowId,
      "node_id": nodid,
      "actions": [{
        "action": document.getElementById('action_id') ? parseInt(document.getElementById('action_id').value) : null,
        "action_type": document.getElementById('action_type_id') ? parseInt(document.getElementById('action_type_id').value) : null,
      }]
    }
    console.log(formData);
    axios.post(Settings.baseUrl + 'action/create', formData).then((res) => {
      if (res.data.success) {
        console.log(res);
        Helper.alert(res.data.message);
        props.hideActionModal(false);
        props.mainModal(false);
        props.addNode(props.currentNode, props.actionValue, nodid, nodeLabel);
        setTimeout(() => { props.save() }, 100);
      } else {
        console.log("First fill the conditional field data");
        Helper.alert("First fill the conditional field data");
      }
    }).catch((error) =>
      console.log(error)
    )
  }

  return (
    <>
      <Popup onClose={modalClose} autoOpen={true} >
        <h3>Action</h3>
        <p>You can need an action for conditional decision</p>
        <Input label="Label" onChange={(e) => { setnodeLabel(e.target.value) }} required value={nodeLabel} />
        <div className="card mb-3">
          <div className="card-body">
            <div className="p-2">
              <div className='row'>
                <div className='row label-form'>
                  <div className='col-lg-6 col-md-3'>
                    <DropDownInput label="Action" options={actionName} required={true} id='action_id' />
                  </div>
                  {/* <div className='col-lg-6 col-md-3'>
                    <DropDownInput label="Action Type" options={actionType} required={true} id='action_type_id' />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end mt-4 '>
          <Button className="mx-2" title="Cancel" onClick={modalClose} />
          {loading ? <SimpleLoading /> : <Button title='Save' icon={'/images/icons/save-white.svg'} isActive={true} onClick={saveDataHandler} />}
        </div>
      </Popup>
    </>
  )
}

export default AdvancedSettings;