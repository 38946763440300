import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import { connect } from "react-redux";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import DropDownInput from "../../Forms/Dropdown";
import Input from "../../Forms/Input";
import OverlayLoader from "../common/OverlayLoader";

class AddCategoryFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCategoriesLoading: false,
      isItemGroupsLoading: false,
      isUnitsLoading: false,
      isSubmitting: false,
      itemType: null,
      comment: null,
      itemGroups: [],
      selectedItemGroup: null,
      categories: [],
      selectedCategory: null,
      quantity_comment: 0,
      quantity_category: 0,
      unitOfMeasure: [],
      selectedUnitOfMeasure_comment: null,
      selectedUnitOfMeasure_category: null,
      unitPrice_category: null,
      unitPrice_comment: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
    this.fetchItemGroups();
    this.fetchUnits();
  }

  fetchCategories() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isCategoriesLoading: true });
    api
      .axios()
      .get(Settings.loginUrl + "/categories")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isCategoriesLoading: false,
            categories: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isCategoriesLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  fetchItemGroups() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isItemGroupsLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + "/item_group")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isItemGroupsLoading: false,
            itemGroups: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isItemGroupsLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  fetchUnits() {
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isUnitsLoading: true });
    api
      .axios()
      .get(Settings.loginUrl + "/unit")
      .then(function (res) {
        that.setState({
          isUnitsLoading: false,
          unitOfMeasure: [...res.data.data],
        });
        console.log("helloc ategories: ", res);
        if (res.data.status === "success") {
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isUnitsLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  checkAddToCartDisable() {
    if (this.state.itemType === "category") {
      if (
        this.state.selectedCategory === null ||
        this.state.quantity_category === null ||
        this.state.unitOfMeasure_category === null ||
        this.state.price_category === null
      ) {
        return true;
      } else return false;
    } else if (this.state.itemType === "comment") {
      if (
        this.state.comment === null ||
        this.state.comment === "" ||
        this.state.selectedItemGroup === null ||
        this.state.unitOfMeasure_comment === null ||
        this.state.unitPrice_comment === null
      ) {
        return true;
      } else return false;
    } else return true;
  }

  handleFormSubmit(event) {
    event.preventDefault();
    let data = null;
    if (this.state.itemType === "category") {
      data = {
        category_id: this.state.selectedCategory * 1,
        ordered_qty: this.state.quantity_category * 1,
        unit: this.state.selectedUnitOfMeasure_category * 1,
        unit_price: this.state.unitPrice_category * 1,
      };
    } else if (this.state.itemType === "comment") {
      data = {
        comments: this.state.comment,
        itemgroup_id: this.state.selectedItemGroup * 1,
        ordered_qty: this.state.quantity_comment * 1,
        unit: this.state.selectedUnitOfMeasure_comment * 1,
        unit_price: this.state.unitPrice_comment * 1,
      };
    }
    if (data) {
      console.log(data);
      let api = Api;
      api.setUserToken();
      let that = this;
      this.setState({ isSubmitting: true });
      api
        .axios()
        .post(Settings.apiPurchaseRequisitionUrl + "/pr_cartline", data)
        .then(function (res) {
          console.log(res);
          if (res.data.status === "success") {
            that.setState({ isSubmitting: false });
            that.props.hide();
            that.props.incrementCartCount();
            Helper.alert(res.data.status, 'success')
          }
        })
        .catch((error) => {
          console.log(error);
          that.setState({ isSubmitting: false });
          Helper.alert(error?.response?.data?.message, "error");
        });
    }
  }

  render() {
    const { visibility, hide } = this.props;
    return (
      <Modal
        show={visibility}
        onHide={hide}
        className="pr-addcategory pr-addcategory-modal"
      >
        <Modal.Body className="pr-addcategory-modal-body">
          <form
            className="pr-addcategory-form"
            onSubmit={(e) => this.handleFormSubmit(e)}
          >
            <div className="rs_collapse">
              <div className="rs_collaps_title" open={true}>
                <p>
                  {Helper.getLabel(
                    this.props.language,
                    "general_information",
                    "General Information"
                  )}
                </p>
              </div>
              <DropDownInput
                className="pr-addcategory-form-dropdowninput"
                label={Helper.getLabel(
                  this.props.language,
                  "item_type",
                  "Item type"
                )}
                options={[
                  {
                    value: "category",
                    label: "Category",
                  },
                  {
                    value: "comment",
                    label: "Comment",
                  },
                ]}
                placeHolder="Select item type"
                id="item_type_id"
                value={this.state.itemType}
                onChange={(e) => this.setState({ itemType: e.target.value })}
              />
              {this.state.itemType === "category" ? (
                <>
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={Helper.getLabel(
                      this.props.language,
                      "category",
                      "Category"
                    )}
                    options={this.state.categories.map((item) => {
                      return {
                        value: item.category_id,
                        label: item.name,
                      };
                    })}
                    placeHolder="Select item type"
                    id="item_type2_id"
                    onChange={(e) => {
                      this.setState({ selectedCategory: e.target.value });
                    }}
                    value={this.state.selectedCategory}
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={Helper.getLabel(
                      this.props.language,
                      "quantity",
                      "Quantity"
                    )}
                    placeHolder="Enter quantity"
                    value={this.state.quantity_category}
                    onChange={(e) => {
                      if (e.target.value > 0)
                        this.setState({ quantity_category: e.target.value });
                    }}
                  />
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={Helper.getLabel(
                      this.props.language,
                      "unit_of_measure",
                      "Unit of measure"
                    )}
                    options={this.state.unitOfMeasure.map((item) => {
                      return {
                        value: item.unit_id,
                        label: item.description,
                      };
                    })}
                    placeHolder="Select item type"
                    id="unit_of_measure_id"
                    onChange={(e) => {
                      this.setState({
                        selectedUnitOfMeasure_category: e.target.value,
                      });
                    }}
                    value={this.state.selectedUnitOfMeasure_category}
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={Helper.getLabel(
                      this.props.language,
                      "unit_price",
                      "Unit Price"
                    )}
                    placeHolder="Enter unit price"
                    value={this.state.unitPrice_category}
                    onChange={(e) => {
                      if (e.target.value >= 0)
                        this.setState({ unitPrice_category: e.target.value });
                    }}
                  />
                </>
              ) : null}
              {this.state.itemType === "comment" ? (
                <>
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="textarea"
                    label={Helper.getLabel(
                      this.props.language,
                      "comment",
                      "Comment"
                    )}
                    placeHolder="Enter your comment"
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    value={this.state.comment}
                  />
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={Helper.getLabel(
                      this.props.language,
                      "item_group",
                      "Item group"
                    )}
                    options={this.state.itemGroups.map((item) => {
                      return {
                        value: item.itemgroup_id,
                        label: item.name,
                      };
                    })}
                    placeHolder="Select item type"
                    id="item_group_id"
                    onChange={(e) => {
                      this.setState({
                        selectedItemGroup: e.target.value,
                      });
                    }}
                    value={this.state.selectedItemGroup}
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={Helper.getLabel(
                      this.props.language,
                      "quantity",
                      "Quantity"
                    )}
                    placeHolder="Enter quantity"
                    value={this.state.quantity_comment}
                    onChange={(e) => {
                      if (e.target.value > 0)
                        this.setState({ quantity_comment: e.target.value });
                    }}
                  />
                  <DropDownInput
                    className="pr-addcategory-form-dropdowninput"
                    label={Helper.getLabel(
                      this.props.language,
                      "unit_of_measure",
                      "Unit of measure"
                    )}
                    options={this.state.unitOfMeasure.map((item) => {
                      return {
                        value: item.unit_id,
                        label: item.description,
                      };
                    })}
                    placeHolder="Select item type"
                    id="unit_of_measure_id"
                    onChange={(e) => {
                      this.setState({
                        selectedUnitOfMeasure_comment: e.target.value,
                      });
                    }}
                    value={this.state.selectedUnitOfMeasure_comment}
                  />
                  <Input
                    className="pr-addcategory-form-textarea"
                    inputType="number"
                    label={Helper.getLabel(
                      this.props.language,
                      "unit_price",
                      "Unit Price"
                    )}
                    placeHolder="Enter unit price"
                    value={this.state.unitPrice_comment}
                    onChange={(e) => {
                      if (e.target.value >= 0)
                        this.setState({ unitPrice_comment: e.target.value });
                    }}
                  />
                </>
              ) : null}
            </div>
            <div className="pr-addcategory-form-action-btns">
              <button
                className="pr-primary-btn pr-primary-btn-outline"
                onClick={hide}
              >
                {Helper.getLabel(this.props.language, "cancel", "Cancel")}
              </button>
              <button
                className="pr-primary-btn pr-primary-btn-solid"
                type="submit"
                disabled={
                  this.checkAddToCartDisable() || this.state.isSubmitting
                }
              >
                {Helper.getLabel(
                  this.props.language,
                  "add_to_cart",
                  "Add to Cart"
                )}
              </button>
            </div>
            {this.state.isCategoriesLoading ||
            this.state.isItemGroupsLoading ||
            this.state.isUnitsLoading ||
            this.state.isSubmitting ? (
              <OverlayLoader />
            ) : null}
          </form>{" "}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(AddCategoryFormModal);
