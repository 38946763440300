import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Gui_id_list from '../../inc/Gui_id_list';
import Helper from '../../inc/Helper';
import DropDownInput from '../../components/Forms/Dropdown';
import MasterComponentWraper from '../Backend/MasterComponentWraper';
import $ from 'jquery';
import MasterComonent from '../Backend/MasterComonent';
import Alert from '../inc/Alert';
import Button from '../inc/Button';
import Collapse from '../inc/Collapse';
import LinkBtn from '../inc/LinkBtn';
import NavigationHeder from '../Navigations/NavigationHeder';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import Tabs from '../Widgets/Tabs/Tabs';
import './Parameters.css'
import Checkbox from '../Forms/Checkbox';
import AgGrid from '../grid/ag/ag-grid';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import SimpleLoading from '../Loading/SimpleLoading';
import { useNavigate } from 'react-router-dom';
import Input from '../Forms/Input';
import { ValidateData } from '../../inc/Validation';
 
let settings_id = null

const Parameters = (props) => {
    console.log('var declaring', settings_id)
    // let settings_id = null;
    const navigate = useNavigate()
    const [numberSequenceGrid, setNumberSequenceGrid] = useState([])
    const [teamHeaderGrid, setTeamHeaderGrid] = useState([])
    const [settings, setSettings] = useState({
    "name": null, 
    "mode_of_delivery_id":null,
    "delivery_term_id":null,
    "taxgroup_id":null,
    "taxcodes_id":null,
    "currency_id":null,
    "distributionsource_id":null,
    "blind_receiving_of_qty":false,
    "blind_receiving_of_item":false,
    "activate_change_management":false,
    "default_procurement_category":null,
    "accept_under_delivery":false,
    "accept_over_delivery":false,
    "check_product_receipt_number":null,
    "perform_QA_before_goods_receiving":null,
    "allow_distribute_header_discount_to_lines":null,
    "allow_price_override_from_rate_card":null,
    "allow_access_only_to_buyer_group":null,
    "over_receipt_action":null,
    "match_level":null
    })
    const [entityNS, setEntityNS] = useState('')
    const [loading, setLoading] = useState(false)
    const [entityLoading, setEntityLoading] = useState(false)
    const [entityArr, setEntityArr] = useState([]) 
    const [teamHeaderList, setTeamHeaderList] = useState([])
    
  
  
    //Dropdowns State

    const [modeOfDelivery, setModeOfDelivery] = useState([])
    const [deliveryItem, setDeliveryItem] = useState([])
    const [taxGroup, setTaxGroup] = useState([])
    const [itemTaxCode, setItemTaxCode] = useState([])
    const [defaultDistribution, setDefaultDistribution] = useState([])
    const [procurementCategory, setProcurementCategory] = useState([])
    const [document, setDocument] = useState([])
    const [numberSequence, setNumberSequence] = useState([])
    const [matchLevel, setMatchLevel] = useState([])
    const [receiptAction, setReceiptAction] = useState([])
    const [receiptNumber, setReceiptNumber] = useState([])
    const [entityList, setEntityList] = useState([])

    //End

    // API Calls Reduction
    // let settingsCalled= false
    let modeOfDeliveryCalled= false
    let deliveryItemCalled= false
    let taxGroupCalled= false
    let itemTaxCodeCalled= false
    let defaultDistributionCalled= false
    let procurementCategoryCalled= false
    let documentCalled= false
    let numberSequenceCalled= false 
    let teamHeaderCalled= false 
    let matchLevelCalled= false 
    let receiptActionCalled= false 
    let receiptNumberCalled= false 
    let entityListCalled= false
    //End

    // API Calls 
    useEffect(() => {
        ModeOfDeliveryHandler()
        DeliveryItemHandler()
        TaxGroupHandler()
        ItemTaxCodeHandler()
        NumberSequenceHandler()
        DocumentHandler()
        MatchLevelHandler()
        ReceiptActionHandler()
        CheckProductReceiptNumberHandler()
        ProcurementCategoryHandler()
        getEntitiesList()
        TeamHeaderAPI()
    }, [])
  
    //End

    // GET SETTINGS

    // const getProcureSettings = () => {
    //     if(settingsCalled){
    //         return;
    //     }
    //     let api = Api
    //     settingsCalled = true
    //     api.setUserToken()
    //     api.axios().get(Settings.apiProcurementSettingsUrl+'/',{}).then(function(res){
    //       if(res.data.status==="success"){ 
    //         setGetSettings([])
    //       } 
    //   }).catch((res) => { 
    //     Helper.alert(res.response.data.message, 'error')
    //   })
    // }

    //End

    //Dropdowns Functions

    const getEntitiesList = () => {
      if(entityListCalled){
       return;
      }
      let api = Api
      setEntityLoading(true)
      entityListCalled = true
      api.setUserToken()
      api.axios().post(Settings.apiUrl+'/search_entity',{
          "code":"",
          "name":""
      }).then(function(res){
        if(res.data.status==='success'){   
          let UPDATED_STATE = []
          res.data.data.forEach((element) => {
              UPDATED_STATE.push({
              value: element.entity_id,
              label: element.name
          })
          })
          setEntityLoading(false)
          setEntityList(UPDATED_STATE)
        } 
    }).catch((err) => {
      setEntityLoading(false)
    })
  }

    const ModeOfDeliveryHandler = () => {
        if(modeOfDeliveryCalled){
            return;
        }
        let api = Api
        modeOfDeliveryCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+'/mode_of_delivery',{}).then(function(res){
          if(res.data.status==="success"){
            let modeOfDeliveryARR = []
            res.data.data.forEach(item => {  
            modeOfDeliveryARR.push({
              value: item.mode_of_delivery_id,
              label: item.name
            })
           }) 
           setModeOfDelivery(modeOfDeliveryARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const DeliveryItemHandler = () => {
        if(deliveryItemCalled){
            return;
        }
        let api = Api
        deliveryItemCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+'/delivery_term',{}).then(function(res){
          if(res.data.status==="success"){
            let DeliveryItemARR = []
            res.data.data.forEach(item => {  
            DeliveryItemARR.push({
              value: item.delivery_term_id,
              label: item.name
            })
           }) 
           setDeliveryItem(DeliveryItemARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const TaxGroupHandler = () => {
        if(taxGroupCalled){
            return;
        }
        let api = Api
        taxGroupCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
          if(res.data.status==="success"){
            let taxGroupARR = []
            res.data.data.forEach(item => {  
            taxGroupARR.push({
              value: item.taxgroup_id,
              label: item.name
            })
           }) 
           setTaxGroup(taxGroupARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const ItemTaxCodeHandler = () => {
        if(itemTaxCodeCalled) {
            return;
        }
        let api = Api
        itemTaxCodeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/taxcodes',{}).then(function(res){
          if(res.data.status==="success"){
            let itemTaxCodes = []
            res.data.data.forEach(item => {  
            itemTaxCodes.push({
              value: item.taxcodes_id,
              label: item.name
            })
           }) 
           setItemTaxCode(itemTaxCodes)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const DefaultDistributionHandler = () => {
        if(defaultDistributionCalled){
          return;
        }
        let api = Api
        defaultDistributionCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrls.segments+'/segmentdistribution',{}).then(function(res){
          if(res.data.status==="success"){
            let distributionARR = []
            res.data.data.forEach(item => {  
            distributionARR.push({
              value: item.distributionsource_id,
              label: item.name
            })
           }) 
           setDefaultDistribution(distributionARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const ProcurementCategoryHandler = () => {
        if(procurementCategoryCalled){
            return;
        }
        let api = Api
        procurementCategoryCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/categories',{}).then(function(res){
          if(res.data.status==="success"){
            let procurementARR = []
            res.data.data.forEach(item => {  
            procurementARR.push({
              value: item.category_id,
              label: item.name
            })
           }) 
           setProcurementCategory(procurementARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const DocumentHandler = () => {
        if(documentCalled){
            return;
        }
        let api = Api
        documentCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/source',{}).then(function(res){
          if(res.data.status==="success"){
            let documentsARR = []
            res.data.data.forEach(item => {  
            documentsARR.push({
              value: item.source_id,
              label: item.source_name
            })
           }) 
           setDocument(documentsARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const TeamHeaderAPI = () => {
      if(teamHeaderCalled) {
        return;
      }
      let api = Api
      teamHeaderCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/teamheader',{}).then(function(res){
      if(res.data.status==="success"){
        let teamHeaderARR = []
        res.data.data.forEach(item => {  
        teamHeaderARR.push({
          value: item.teamheader_id,
          label: item.name
        })
       }) 
       setTeamHeaderList(teamHeaderARR)
      } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const NumberSequenceHandler = () => {
        if(numberSequenceCalled) {
            return;
        }
        let api = Api
        numberSequenceCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/numbersequence',{}).then(function(res){
          if(res.data.status==="success"){
            let numberSeqARR = []
            res.data.data.forEach(item => {  
            numberSeqARR.push({
              value: item.sequencenumber_id,
              label: item.name
            })
           }) 
           setNumberSequence(numberSeqARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const MatchLevelHandler = () => {
        if(matchLevelCalled) {
            return;
        }
        let api = Api
        matchLevelCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/matchlevel',{}).then(function(res){
          if(res.data.status==="success"){
            let matchLevelDATA = []
            res.data.data.forEach(item => {  
            matchLevelDATA.push({
              value: item.id,
              label: item.name
            })
           }) 
           setMatchLevel(matchLevelDATA)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const ReceiptActionHandler = () => {
        if(receiptActionCalled) {
            return;
        }
        let api = Api
        receiptActionCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/overreceiptaction',{}).then(function(res){
          if(res.data.status==="success"){
            let receiptActionARR = []
            res.data.data.forEach(item => {  
            receiptActionARR.push({
              value: item.id,
              label: item.name
            })
           }) 
           setReceiptAction(receiptActionARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const CheckProductReceiptNumberHandler = () => {
        if(receiptNumberCalled) {
            return;
        }
        let api = Api
        receiptNumberCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/checkproductreceiptnumber',{}).then(function(res){
          if(res.data.status==="success"){
            let receiptNumberARR = []
            res.data.data.forEach(item => {  
            receiptNumberARR.push({
              value: item.id,
              label: item.name
            })
           }) 
           setReceiptNumber(receiptNumberARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }
 
//End


//STATES Dropdowns
const NameHandler = (event) => {
  let name = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      name: name
    }
  })
}

const DeliveryModeHandler = (event) => {
  let mode_devl = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      mode_of_delivery_id:  mode_devl 
    }
  })
}

const DeliveryTermHandler = (event) => {
  let term_devr = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      delivery_term_id: term_devr
    }
  })
}

const TaxGroupChangeHandler = (event) => {
  let tax_id = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      taxgroup_id:  tax_id
    }
  })
}

const TaxCodeHandler = (event) => {
  let taxCode = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      taxcodes_id: taxCode
    }
  })
}

const DistributionHandler = (event) => {
  let distribution = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      distributionsource_id: distribution 
    }
  })
}

const ProcurementHandler = (event) => {
  let procure = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      default_procurement_category: procure 
    }
  })
}

const BlindQuantity = (name,value) => {
  if(value == 'on'){
    setSettings((prevState) => {
      return {
        ...prevState,
        blind_receiving_of_qty: true
      }
    })
  }else {
    setSettings((prevState) => {
      return {
        ...prevState,
        blind_receiving_of_qty: false
      }
    })
  }
}

const BlindItems = (name, value) => {
  if(value == 'on'){
    setSettings((prevState) => {
      return {
        ...prevState,
        blind_receiving_of_item:true
      }
    })
  }else {
    setSettings((prevState) => {
      return {
        ...prevState,
        blind_receiving_of_item: false
      }
    })
  }
}

const ActiveCharge = (name, value) => {
  if(value == 'on'){
    setSettings((prevState) => {
      return {
        ...prevState,
        activate_change_management:true
      }
    })
  }else {
    setSettings((prevState) => {
      return {
        ...prevState,
        activate_change_management: false
      }
    })
  }
}

const AcceptUnderHandler = (name,value) => {
  if(value == 'on'){
    setSettings((prevState) => {
      return {
        ...prevState,
        accept_under_delivery: true
      }
    })
  }else {
    setSettings((prevState) => {
      return {
        ...prevState,
        accept_under_delivery: false
      }
    })
  }
}

const AcceptOverHandler = (name,value) => {
  if(value == 'on'){
    setSettings((prevState) => {
      return {
        ...prevState,
        accept_over_delivery: true
      }
    })
  }else {
    setSettings((prevState) => {
      return {
        ...prevState,
        accept_over_delivery: false
      }
    })
  }
}

const OverReceiptAction = (event) => {
  let receiptAction = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      over_receipt_action:  receiptAction 
    }
  })
}

const CheckProductNumberHandler = (event) => {
  let productNum = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      check_product_receipt_number:  productNum 
    }
  })
}

const MatchLevel = (event) => {
  let matchLevel = event.target.value
  setSettings((prevState) => {
    return {
      ...prevState,
      match_level: matchLevel 
    }
  })
}
const EntityHandler = (data) => {
  entityArr.push(data)
}

//End

const removeDuplicates = (arr) => {
  let newArr = arr.filter((c, index) => {
    return arr.indexOf(c) === index;
});
return newArr;
}

const addEntitiesToSettings = (id) => {
  if(!ValidateData(entityArr)){
  let api = Api
  api.setUserToken()  
  api.axios().post(Settings.apiProcurementSettingsUrl+'/procurementsetting_entity',{
    "procurementsetting_id":id,
    "entity_ids":removeDuplicates(entityArr)
}).then(function(res){
    if(res.data.status==="success"){ 
      console.log(res.data.message)
    }
  }).catch((res) => {  
    Helper.alert(res.response.data.message, 'error')
  }) 
}
}

const entityDropdownHandler = (event) => {
  setEntityNS(event.target.value)
}

const SaveNS = (rowItem) => { 
 if(ValidateData(rowItem)){
  return;
 } 
 rowItem.forEach((element) => {
  let api = Api
  api.setUserToken()  
  api.axios().post(Settings.apiProcurementSettingsUrl+'/numberingsequence',{
    "source_id": parseInt( element.source_id),
    "sequence_id": parseInt(element.sequencenumber_id),
    "entity_id": parseInt(entityNS)
  }).then(function(res){
    if(res.data.status==="success"){ 
      console.log(res.data.message)
    }
  }).catch((res) => {  
    Helper.alert(res.response.data.message, 'error')
  }) 
 }) 
}

    const DefaultNavs = [
        {title:Helper.getLabel(props.language,'default','Default'), isActive: true, },
        {title:Helper.getLabel(props.language,'controls','Controls')},  
        {title:Helper.getLabel(props.language,'entity','Entity')},  
        {title:Helper.getLabel(props.language,'numbering_sequence','Numbering Sequence')},  
        {title:Helper.getLabel(props.language,'team_header','Team Header')}, 
    ]

    const Columns_Headings = [ 
        {field:'teamheader_id', headerName:Helper.getLabel(props.language,'teamheader','Team Header'), inputType: "dropdown", options: teamHeaderList} 
      ]

    const Columns_Headings_NS = [
      {field:'source_id', headerName:Helper.getLabel(props.language,'document','Document'), inputType: "dropdown", options: document}, 
      {field:'sequencenumber_id', headerName:Helper.getLabel(props.language,'number_sequence','Number Sequence'), inputType: "dropdown", options: numberSequence}, 
    ]  

    
    const SaveTeamHeader = (rowItem) => {  
      if(ValidateData(rowItem) || !settings_id){
        return;
       } 
       rowItem.forEach((element) => {
        let api = Api
        api.setUserToken()  
        api.axios().post(Settings.apiProcurementSettingsUrl+'/procurementteamheader',{
          "procurementsetting_id":parseInt(settings_id) ,
          "teamheader_id": parseInt(element.teamheader_id)
        }).then(function(res){
          if(res.data.status==="success"){ 
            console.log(res.data.message)
          }
        }).catch((res) => {  
          Helper.alert(res.response.data.message, 'error')
        }) 
       })  
    }
    
    const SaveHandler = () => {  
      if(!settings.name){
        Helper.alert('Name field missing', 'error')
        return;
      }
      setLoading(true)
      let api = Api
      api.setUserToken()  
      api.axios().post(Settings.apiProcurementSettingsUrl+'/procurementsetting',{
        "name": settings.name !== "" ? settings.name : null, 
        "mode_of_delivery_id": settings.mode_of_delivery_id == "0" ? 0 : parseInt(settings.mode_of_delivery_id),
        "delivery_term_id":settings.delivery_term_id == "0" ? 0 : parseInt(settings.delivery_term_id),
        "taxgroup_id":settings.taxgroup_id == "0" ? 0 : parseInt(settings.taxgroup_id),
        "taxcodes_id":settings.taxcodes_id == "0" ? 0 : parseInt(settings.taxcodes_id),
        "currency_id":null,
        "distributionsource_id":settings.distributionsource_id == "0" ? 0 : parseInt(settings.distributionsource_id),
        "blind_receiving_of_qty":settings.blind_receiving_of_qty,
        "blind_receiving_of_item":settings.blind_receiving_of_item,
        "activate_change_management":settings.activate_change_management,
        "default_procurement_category":settings.default_procurement_category == "0" ? 0 : parseInt(settings.default_procurement_category),
        "accept_under_delivery":settings.accept_under_delivery,
        "accept_over_delivery":settings.accept_over_delivery,
        "check_product_receipt_number":settings.check_product_receipt_number == "0" ? 0 : parseInt( settings.check_product_receipt_number),
        "perform_QA_before_goods_receiving":null,
        "allow_distribute_header_discount_to_lines":null,
        "allow_price_override_from_rate_card":null,
        "allow_access_only_to_buyer_group":null,
        "over_receipt_action":settings.over_receipt_action == "0" ? 0 : parseInt(settings.over_receipt_action),
        "match_level": settings.match_level == "0" ? 0 : parseInt(settings.match_level) 
        }).then(function(res){
        if(res.data.status==="success"){
          navigate('/procurement-settings')
          settings_id = res.data.procurementsetting_id 
          $('.team_header_grid .rs_grid_btn_save').trigger('click') 
          Helper.alert(res.data.message) 
          setLoading(false)
          addEntitiesToSettings(res.data.procurementsetting_id) 
          $('.ns_grid .rs_grid_btn_save').trigger('click') 
        }
      }).catch((res) => { 
        setLoading(false)
        Helper.alert(res.response.data.message, 'error')
      })  
    }

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.procure.procurement_settings.procurement_settings)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }

  return (
    <>
    <div className='procurement_settings'>
        <div className='container-fluid'>
            <NavigationHeder backUrl='/procurement-settings' title={Helper.getLabel(props.language,'parameters',"Parameters")}>
                <LinkBtn isActive= {false} to="/procurement-settings" title={Helper.getLabel(props.language,'close',"Close")} />
                <Button title={Helper.getLabel(props.language,'history',"History")} />
                <Button onClick={SaveHandler} isActive={true} icon='/images/icons/save-white.svg' title={Helper.getLabel(props.language,'save',"Save")} isDisable={!security.canCreate(frontendIds.procure.procurement_settings.procurement_settings_main_button)} /> 
            </NavigationHeder>  
            {loading ? <SimpleLoading /> : ''}
            <Collapse className='mb-4 default_params_tabs' open={true} title={Helper.getLabel(props.language,'settings',"Settings")}>
                <Tabs disableSticky={true} navs={DefaultNavs} showAll={false} scrollOffset= "500">

                   <div className='container-fluid Default'>
                      <div className='row'>
                          <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                          </div>
                          <div className='col-lg-4'>
                            <Input onChange={NameHandler} value={settings.name} label={Helper.getLabel(props.language,'name',"Name")} id='name_dropdown'/>
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={DeliveryModeHandler} value={settings.mode_of_delivery_id} options={modeOfDelivery} label={Helper.getLabel(props.language,'mode_of_delivery',"Mode Of Delivery")} id='mode_of_delivery_dropdown'/>
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={DeliveryTermHandler} value={settings.delivery_term_id} options={deliveryItem} label={Helper.getLabel(props.language,'delivery_item',"Delivery Item")} id='delivery_item_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={TaxGroupChangeHandler} value={settings.taxgroup_id} options={taxGroup} label={Helper.getLabel(props.language,'tax_group',"Tax Group")} id='tax_group_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={TaxCodeHandler} value={settings.taxcodes_id} options={itemTaxCode} label={Helper.getLabel(props.language,'item_tax_code',"Item Tax Code")} id='item_tax_code_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={DistributionHandler} value={settings.distributionsource_id} options={defaultDistribution} label={Helper.getLabel(props.language,'default_distribution',"Default Distribution")} id='default_distribution_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={ProcurementHandler} value={settings.default_procurement_category} options={procurementCategory} label={Helper.getLabel(props.language,'procurement_category',"Procurement Category")} id='procurement_category_dropdown' />
                          </div>
                      </div>
                   </div>

                   <div className='container-fluid Controls'>
                      <div className='row mb-5 gy-4'> 
                        <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                               <div className='col-1'>
                                  <Checkbox type='checkbox' onChange={BlindQuantity} isChecked={settings.blind_receiving_of_qty} /> 
                               </div>
                               <div className='col-10 border-controls'> 
                                  <h5>Blind receiving of quantity</h5>
                                  <p>Request managers to approve orders above a minimum amount</p>
                               </div>
                               <div className='col-1'>
                                    <img className='img-fluid' /> 
                               </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                    <Checkbox onChange={BlindItems} isChecked={settings.blind_receiving_of_item} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Blind receiving of items</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                  <Checkbox onChange={ActiveCharge} isChecked={settings.activate_change_management} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Active charge management</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                    <Checkbox onChange={AcceptUnderHandler} isChecked={settings.accept_under_delivery} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Accept under delivery</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                    <Checkbox onChange={AcceptOverHandler} isChecked={settings.accept_over_delivery} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Accept over delivery</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                      </div>
                        <div className='row mt-3'> 
                            <div className='col-lg-4'>
                                <DropDownInput onChange={OverReceiptAction} value={settings.over_receipt_action} options={receiptAction} label={Helper.getLabel(props.language,'over_receipt_action',"Over Receipt Action")} id='over_receipt_action_dropdown' />
                            </div>
                            <div className='col-lg-4'>
                                <DropDownInput options={receiptNumber} onChange={CheckProductNumberHandler} value={settings.check_product_receipt_number} label={Helper.getLabel(props.language,'check_product_receipt_number',"Check Product Receipt Number")} id='check_product_receipt_number_dropdown' />
                            </div>
                            <div className='col-lg-4'>
                                <DropDownInput options={matchLevel} onChange={MatchLevel} value={settings.match_level} label={Helper.getLabel(props.language,'match_level',"Match Level")} id='match_level_dropdown' />
                            </div>
                        </div>
                   </div>

                   <div className='container-fluid entities'>
                   {entityLoading ? <SimpleLoading /> : ""}
                      <div className='row'>
                        {entityList.map((item) => {
                          return (
                            <div className='columns' onClick={() => EntityHandler(item.value)}>
                              <Checkbox label={item.label} /> 
                            </div> 
                            )
                        })}
                      </div>
                   </div>

                   <div className='container-fluid Numbering_Sequence'>
                      <div className='row'> 
                        <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                        </div>
                        <Collapse className='mb-4 number_sequence_grid' open={true} title={Helper.getLabel(props.language,'number_sequence',"Number Sequence")}>
                          <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-3 mb-3'>
                                      <DropDownInput options={entityList} onChange={entityDropdownHandler} value={entityNS} label={Helper.getLabel(props.language,'entity',"Entity")} id='entity_dropdown' />
                                    </div>
                                    <div className='col-12 ns_grid'>
                                        <AgGrid onSave={SaveNS} header={Columns_Headings_NS} id='number_sequence_purchase_order' data={numberSequenceGrid} />
                                    </div>
                                </div>
                          </div>
                        </Collapse>
                      </div>
                   </div>

                   <div className='container-fluid team_header'>
                      <div className='row'> 
                        <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                        </div>
                        {console.log(settings_id, 'render method here')}
                        <Collapse className='mb-4 team_header_grid' open={true} title={Helper.getLabel(props.language,'team',"Team")}>
                          <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-12'>
                                      <AgGrid onSave={SaveTeamHeader} header={Columns_Headings} id='team_header_purchase_order' data={teamHeaderGrid} />
                                    </div>
                                </div>
                          </div>
                        </Collapse>
                      </div>
                   </div>

                </Tabs>
            </Collapse>
        </div>
    </div>
    </>
  )
}
 

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.procurement_settings.procurement_settings
  }
  
  
  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(Parameters, SecurityOptions))))