// const img = document.createElement('img');
// const img = '/images/blank-profile.jpg';
const img = '/images/Ellipse 2369.svg';
// const actionUserImg = document.body.appendChild(img);

const date = new Date().toUTCString();
console.log(date);
export const tasks = [
  {
    start: new Date(2023, 0, 1),
    end: new Date(2023, 0, 2),
    name: 'React',
    id: 'Task 0',
    type: 'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    image: img,
    comment: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione culpa reiciendis modi laudantium, aspernatur architecto?`,
  },
  {
    start: new Date(2023, 0, 2),
    end: new Date(2023, 0, 3),
    name: 'React',
    id: 'Task 1',
    type: 'task',
    progress: 85,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    // image: img,
    comment: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione culpa reiciendis modi laudantium, aspernatur architecto?`,
  },
  {
    start: new Date(2023, 0, 1),
    end: new Date(2023, 0, 3),
    name: 'Chart',
    id: 'Task 2',
    type: 'task',
    progress: 35,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    image: img,
    comment: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione culpa reiciendis modi laudantium, aspernatur architecto?`,
  },
];
console.log(tasks[0].start);

// localStorage.setItem('task', JSON.stringify(data));
