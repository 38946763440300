import MasterComonent from '../../components/Backend/MasterComonent';
import EditTenant from '../../components/Tenant/Tenant Form/EditTenant';
import { useParams } from "react-router-dom";
const EditTenantPage = (props) => {
    return (
        <MasterComonent>
            <EditTenant {...props} params = {useParams()}/>
        </MasterComonent>
    );
}

export default  EditTenantPage ;