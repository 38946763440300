import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import DropDownInput  from '../Forms/Dropdown';

class SearchBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            sKey:'',
            isSearchActive:false,
            searchResult:[]
        }
    }
    onSearchValueChange(event){
        let that = this;
        this.setState({
            sKey:event.target.value
        }, function(){
            that.searchByText(that.state.sKey)
        })
    }
    searchByText(searchText){
        if(searchText ===''){
            this.setState({
                searchResult:[]
            })
            return;
        }
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().post(Settings.apiOrgUrl+'/elastic',{query:searchText}).then(function(res){
            that.setState({
                searchResult:res.data
            })
        })
    }
    onMouseOverHander(e){
        if(this.state.isSearchActive){
            return;
        }
        this.setState({
            isSearchActive:true
        })
    }
    onMouseLeaveHander(e){
        if(!this.state.isSearchActive){
            return;
        }
        this.setState({
            isSearchActive:false
        })
    }
    getLinkUrl(type,id){
        let url = '';
        switch(type){
            case 'item':
                url += '/edit-item/'+id;
                break;
            case 'vendor':
                url += '/edit-vendor/'+id;
                break;
            case 'site':
                url += '/site/edit/'+id;
                break;
            case 'entity':
                url += '/entity/edit-entity/'+id;
                break;
            case 'warehouse':
                url += '/warehouse/edit/'+id;
                break;
            case 'employee':
                url += '/edit-employee/'+id;
                break;
            default:
                url += '/'
                break;
        }
        return url;
    }
    displaySerchResult(){
        if(this.state.searchResult.length <=0){
            return <></>
        }
        let sIconUrl ='/images/icons/search-light.svg';
        return (
            <div className="search_result_dropdown">
                <ul>
                    {
                        this.state.searchResult.map( (result,key) => {
                            let resultData = result._source;
                            return(<li key={key}><Link to={this.getLinkUrl(result._index,result._id)} style={{backgroundImage:'url('+sIconUrl+')'}}><span className='sr_text'>{resultData.name}</span><span className='sr_code'> - {resultData.code}</span><span className='sr_type'>{resultData.type}</span></Link></li>)
                        })
                    }
                </ul>
            </div>
        )
    }
    render() {
        let language = this.props.language;
        let options = [
            {label:'All', value:'0'},
            {label:'Tenant', value:'1'},
            {label:'Vendor', value:'2'},
            {label:'Entity', value:'3'},
            {label:'Site', value:'4'}
        ]
        let searchResult = this.state.searchResult;
        return (
            <div className='search_box_section' onMouseOver={ e => this.onMouseOverHander(e)} onMouseLeave={ e => this.onMouseLeaveHander(e)}>
                <div className={'search_box_section_inner ' + (this.state.isSearchActive ? 'active' : '') + ( searchResult.length >= 1 ? ' has_result' : '' ) }>
                    <form className='s_form'>
                        <div className='icon'><img src="/images/icons/search.svg" alt=""/></div>
                        <div className='input_box'>
                            <input type="text" value={this.state.sKey} onChange={ this.onSearchValueChange.bind(this)} placeholder={ Helper.getLabel(language,'search','Search')} />
                        </div>
                        <div className='options_box'>
                            <DropDownInput inputType="dropdown" options= {options} placeholder={ Helper.getLabel(language,'all','All')}/>
                        </div>
                    </form>
                    {
                        this.displaySerchResult()
                    }
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language,
    }
}
export default connect (mapStateToProps) ( SearchBox );