import React, { Fragment, useState, useEffect, } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import MasterComonent from '../../../Backend/MasterComonent'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import AgGrid from '../../../grid/ag/ag-grid'
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../../inc/Alert'
import ButtonNew from '../../../inc/ButtonNew' 
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

const BusinessArchive = (props) => {
  let vendorBusinessCalled = false
  const [vendors, setVendors] = useState([]) 
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    get_vendors_business_data() 
  }, [])

  
  const CellHandler = (event) => {   
      if(event.colDef.field === 'description') { 
        console.log(event.data.bussinessclassification_id)
        navigate(`/edit-vendor-business-classification/${event.data.bussinessclassification_id}`)
      }
  }

  const get_vendors_business_data = () => {
    if(vendorBusinessCalled) {
      return;
    }
    let api = Api
    vendorBusinessCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+'/businessclassification',{}).then(function(res){
      if(res.data.status==="success"){  
        setVendors(res.data.data.reverse())
        setLoading(false)
      }
    }).catch((res) => {
      Helper.alert(res.response.data.message, 'error') 
      setLoading(false)
    })
  }
 

  const DeleteHandler = (allRows) => {
    let api = Api
    api.setUserToken() 
    allRows.forEach(element => {
      api.axios().delete(Settings.apiVendorUrl+`/businessclassification/${element.bussinessclassification_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            get_vendors_business_data()
            Helper.alert(res.data.message)
          }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        get_vendors_business_data()
      })
  })
  }

  const gridLinkValueFormat = (params) => {
      return `<div class="rs_grid_simple_link">${params.value}</div>`;
  }

  const Columns_Headings = [
    {field:'description', headerName:Helper.getLabel(props.language,'description',"Name"), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat}, 
  ]

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_business_classification.vendors_business_classification)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
    <div className='vendors_business_classification_archive'>
      <div className='container-fluid'>
          <NavigationHeder backUrl='/master-data' title={Helper.getLabel(props.language,'vendors_business',"Vendors Business Classification")}>
          <ButtonNew to="/create-vendors-business-classification" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.vendor_business_classification.vendors_business_classification_main_create_button)} /> 
        </NavigationHeder> 
          {loading ? <SimpleLoading /> : null}
          <AgGrid id="rs_vendors_business_class" hideDelete={!security.canDelete(!security.canCreate(frontendIds.masterData.vendor_business_classification.vendors_business_classification_main_delete_button))} onCellClicked={CellHandler} onDelete={DeleteHandler} header={Columns_Headings} data={vendors} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div> 
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_business_classification.vendors_business_classification
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(BusinessArchive, SecurityOptions))))