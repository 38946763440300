import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper'; 
import Settings from '../../../inc/Settings';
import { ValidateData } from '../../../inc/Validation';
import SimpleLoading from '../../Loading/SimpleLoading';
import SegmentsDistribution from '../../Segments/SegmentsDistribution';
import Tabs from '../../Widgets/Tabs/Tabs';
import AdditionalInfoDistributionTab from './AdditionalInfoDistributionTab';
import BilingRuleTab from './BilingRuleTab'; 
import GeneralDistributionTab from './GeneralDistributionTab';
import GeneralLinesListView from './GeneralLinesListView';
import SetupLinesTab from './SetupLinesTab'; 

const ListViewTabs = (props) => {
    const [additionalInfo, setAdditionalInfo] = useState({})
    const [generalDistributionData, setGeneralDistributionData] = useState([])
    const [billingData, setBillingData] = useState([])
    const [gridAPIObj, setGridAPIObj] = useState([])
    const [loadingPOSites, setLoadingPOSites] = useState(false)
    const [loadingBillingRules, setLoadingBillingRules] = useState(false)
  
    //API renders

      useEffect(() => {
        getPurchaseOrdersSiteLines()
        getBillingRules()
      }, [props.lineSiteId])
 
    //End

    //Api reduction
      let POSiteLineCalled = false
      let POBillingLineCalled = false
    //end 

    // Grid Functionalities
      const updateGridLinesDataState = (name, value) => {   
        console.log(props.datafromInput)
        let newData = [] 
        // if(props.listTabsGridObject.api){ 
        // props.listTabsGridObject.api.forEachNode((element) => { 
          console.log(props.listTabsGridObject.data, 'data from grid')
          // let obj = props.listTabsGridObject.data.find(item => item.line_id == props.lineSiteId)
          // console.log(obj, 'fonuded obj')
        console.log(props.datafromInput, 'data going into function')
          if(!ValidateData(props.datafromInput)){
            console.log(props.listTabsGridObject.data, 'data whic has to be replaced')
            props.datafromInput.forEach((element) => {
              if(element.line_id === props.listTabsGridObject.data.line_id){
                if(props.listTabsGridObject.data){ 
                  let newDataTemp = {
                    ...props.listTabsGridObject.data,
                    [name]: value
                  }
                  element = newDataTemp
                  console.log(element, 'this data to be re-assigned')
                  newData.push(element);
                }

              }
            })
          }else {
            if(props.listTabsGridObject.data){
              console.log(props.datafromInput, 'data not present')
              let newDataTemp = {
                ...props.listTabsGridObject.data,
                [name]: value
              }
              newData.push(newDataTemp)
            }
          }
          console.log(newData, 'data now replace')
          // if( element.data.line_id == props.listTabsGridObject.data.line_id  || element.data.gridRowId == props.listTabsGridObject.data.gridRowId  ){
            
          //   let newTempData = {
          //     ...element.data,
          //     [name]: value
          //   }
          //   console.log(newTempData)
          //   newData.push(newTempData) 
          // } else {
          //   newData.push(element.data)
          // }   
        // })

        // props.listTabsGridObject.api.setRowData(newData)
        // props.setGridDataState(newData) 
      // }
      }

      const updateGridLinesSitesDataState = (name, value) => { 
        let newData = [] 
        if(gridAPIObj){ 
        gridAPIObj.forEachNode((element) => { 
          if(element.data.line_id == additionalInfo.line_id || element.data.gridRowId == additionalInfo.gridRowId){
            newData.push({
              ...element.data,
              [name]: value
            }) 
          } else {
            newData.push(element.data)
          }   
        })
        gridAPIObj.setRowData(newData)
        setGeneralDistributionData(newData) 
      }
      }

    //End

    //API Call

      const getPurchaseOrdersSiteLines = () => {
        setLoadingPOSites(true) 
        if(props.lineSiteId === null || props.lineSiteId === undefined || props.lineSiteId === 0 || !props.lineSiteId){ 
          setLoadingPOSites(false)
          return;
        }
        if(POSiteLineCalled){
          return;
        }
        let api = Api
        POSiteLineCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_linesites/${props.lineSiteId}`,{}).then(function(res){
          if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach(element => {
              UPDATED_STATE.push({
                "canceled_qty": element.canceled_qty,
                "delivery_date": Helper.formateDate(element.delivery_date),
                "distributionsource_id": element.distributionsource_id,
                "end_date": Helper.formateDate(element.end_date),
                "entity_id": element.entity_id === null && props.purchaseOrderObj ? props.purchaseOrderObj.entity_id : element.entity_id,
                "external_reference": element.external_reference,
                "line_id": element.line_id,
                "line_status": element.line_status,
                "ordered_qty": element.ordered_qty,
                "over_delivery_tolearance": element.over_delivery_tolearance,
                "overreceipt_action": element.overreceipt_action,
                "received_qty": element.received_qty,
                "requester_id": element.requester_id === null && props.purchaseOrderObj ? props.purchaseOrderObj.requester_id : element.requester_id,
                "requisition_id": element.requisition_id,
                "returned_qty": element.returned_qty,
                "revision": element.revision,
                "site_id": element.site_id === null && props.purchaseOrderObj ? props.purchaseOrderObj.site_id : element.site_id,
                "start_date": Helper.formateDate(element.start_date),
                "under_delivery_tolerance": element.under_delivery_tolerance,
                "version": element.version,
                "warehouse_id": element.warehouse_id
              })
            });
            setLoadingPOSites(false)
            props.setLineSiteId(null)
            setGeneralDistributionData(UPDATED_STATE.reverse())
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          setLoadingPOSites(false)
        })
      }

      const getBillingRules = () => {
        setLoadingBillingRules(true) 
        if(props.lineSiteId === null || props.lineSiteId === undefined || props.lineSiteId === 0 || !props.lineSiteId){ 
          setLoadingBillingRules(false)
          return;
        }
        if(POBillingLineCalled){
          return;
        }
        let api = Api
        POBillingLineCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+`/get_billingrule/${props.lineSiteId}`,{}).then(function(res){
          if(res.data.status==="success"){  
            let updated_state =[]
            res.data.data.forEach((element) => {
              updated_state.push({
                ...element,
                "needby_date": element.needby_date !== null ? Helper.formateDate(element.needby_date) : null,
                "start_date": element.start_date !== null ? Helper.formateDate(element.start_date) : null,
                "end_date": element.end_date !== null ? Helper.formateDate(element.end_date) : null
              })
            })
            setLoadingBillingRules(false)
            props.setLineSiteId(null)
            setBillingData(updated_state.reverse())
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          setLoadingBillingRules(false)
        })
      }

    // End

    let PurchaseOrderLinesNavs = [
        {title:Helper.getLabel(props.language,'distribution','Distribution'), isActive: true, },
        {title:Helper.getLabel(props.language,'general','General')}, 
        {title:Helper.getLabel(props.language,'setup','Setup')},
        {title:Helper.getLabel(props.language,'billing_rule','Billing Rule')},   
    ]

    let PurchaseOrderLinesInnerNavs = [
      {title:Helper.getLabel(props.language,'general','General'), isActive: true}, 
      {title:Helper.getLabel(props.language,'addtional_info','Additional Information')}, 
      {title:Helper.getLabel(props.language,'segment','Segment')},  
    ]
       
  return (
    <section className='list_tabview '>
      <Tabs id='list_view_parent_tab' tabContantsId='list_view_parent_tab_contents' navs={PurchaseOrderLinesNavs} className='purchase-orders-list-view-tabs' showAll={false} scrollOffset="300"> 
          <div className='list_view_children_tabs'>
            <Tabs id='list_view_children_tab' tabContantsId='list_view_children_tab_contents' navs={PurchaseOrderLinesInnerNavs} className='purchase-orders-list-view-inner-tabs' showAll={false} scrollOffset="300"> 
              <div className='container-fluid'>
                {loadingPOSites ? <SimpleLoading /> : ''}
                <GeneralDistributionTab getGridAPIObj={setGridAPIObj} LinesGridObject={props.listTabsGridObject.data } purchaseOrderObj={props.purchaseOrderObj} loading={loadingPOSites} reloadAPI={getPurchaseOrdersSiteLines} sites={props.sites} entities={props.entities} state={generalDistributionData} updateAdditionalInfo={setAdditionalInfo} />
              </div> 
              <div className='container-fluid'> 
                <AdditionalInfoDistributionTab updateGridDataState={updateGridLinesSitesDataState} purchaseOrderObj={props.purchaseOrderObj} state={additionalInfo} setState={setAdditionalInfo} requester={props.requester} />
              </div>
              <div className='container-fluid'>
                <SegmentsDistribution id='purchase_order_lines_site_segments' distribution_source_id={null} totalAmount={additionalInfo.line_amount} integrator={additionalInfo.line_id} source_id={12} />
              </div>
            </Tabs>
          </div>  
          <div>
            <GeneralLinesListView updateGridDataState={updateGridLinesDataState} state={props.tabsObj} setState={props.setTabsObj} requester={props.requester} ordered={props.ordered} /> 
          </div>
          <div>
            <SetupLinesTab updateGridDataState={updateGridLinesDataState} state={props.tabsObj} setState={props.setTabsObj} taxGroups={props.taxGroups} taxCodes={props.taxCodes} />
          </div>
          <div>
            {loadingBillingRules ? <SimpleLoading /> : ''}
            <BilingRuleTab state={billingData} reloadAPI={getBillingRules} setState={setBillingData} units={props.units} />
          </div>
      </Tabs>
    </section>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (ListViewTabs) 
