export const conditionData = [
    { value: 1, label: 'Where' },
    { value: 2, label: 'AND' },
    { value: 3, label: 'OR' }
]

export const operatorData = [
    { value: 1, label: "equals to" },
    { value: 2, label: "not equals to" },
    { value: 3, label: "less than" },
    { value: 4, label: "greater than" },
    { value: 5, label: "less than equals to" },
    { value: 6, label: "greater than equals to" },
    { value: 7, label: "starts with" },
    { value: 8, label: "ends with" },
    { value: 9, label: "like" }
]
export const havingData = [
    { value: 0, label: 'false' },
    { value: 1, label: 'true' },
]