import Constant_GUI from "./GuiConstants";

const LabelsGUI_ID = {
    tenant:{
        id:`11`,
        code:`12`,
        name:`13`,
        status:`14`,
        modifiedby:`15`,
        start_date:`16`,
        end_date:`17`,
        created_by:`18`,
    },
    sidepanel:{
        command_center:'201',
        request:'202',
        source:'203',
        receive:'204',
        master_data:'205',
        finance:'206',
        budget:'207',
        approval:'208',
        reports:'209',
        setup:'2010',
        administration:'2011',
    }
}
export default LabelsGUI_ID;
