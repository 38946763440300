import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    themeName:'default',
    isLoaded:false,
    settings:{
        primary_color: "",
        secondary_color:'',
        header_color:'',
        header_bg_color: "",
        sidebar_color: "",
        sidebar_bg_color: "",
    }
}
const ThemeReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_THEME_SETTINGS){
        state = {
            ...state,
            isLoaded:true,
            settings:actions.settings ? {...state.settings,...actions.settings} : state.settings,
            themeName:actions.theme,
        }
    }
    return state;
}
export default ThemeReducer;