import { Button, Card } from 'react-bootstrap';
import './WorkflowStartNode.css'
import { useDispatch, useSelector } from 'react-redux';
import { workflowAction } from '../../../store/workflow';
import { useState } from 'react';
import Popup from '../../../components/Popup/Popup';
import Helper from '../../../inc/Helper';

const WorkflowStartNode = (props) => {

  const propsNodeId = props.nodeData.target.dataset;
  console.log(propsNodeId);
  // const dispatch = useDispatch();
  // const isClose = useSelector((state) => state.workflowSlice.isOpend);
  // console.log(isClose);
  // const modalOpen = (ev) => {
  //   if (!isClose) {
  //     dispatch(workflowAction.open(ev.target.value));
  //   }
  // }

  const modalOpen = (ev) => {
    if (ev.target.value === '1') {
      if (!propsNodeId.handleid) {
        props.getActionValue(ev.target.value);
        props.openConditionalModal(true);
      } else {
        Helper.alert("You can't add any condition node after a condition node", 'error');
      }
    }
    if (ev.target.value === '2') {
      props.getActionValue(ev.target.value);
      props.openActionModal(true);
    }
    if (ev.target.value === '3') {
      props.getActionValue(ev.target.value);
      props.openTaskModal(true);
    }
  }

  // const [startNodeModal, setStartNodeModal] = useState(false);
  const closeIconUrl = '/images/icons/cross.svg';
  const closeStartModal = () => {
    props.del(false);
    // setStartNodeModal(false);
  }

  return (
    <>
      {/* {!startNodeModal ? */}
      <Popup onClose={closeStartModal} autoOpen={true} >
        <div className='d-flex justify-content-between'>
          <Card className='p-1'>
            <Card.Header className='card-header bg-green'>Condition</Card.Header>
            <Card.Body className='card-body'>
              <Card.Text>
                Lorem Ipsum is simply dummy text of the printing
              </Card.Text>
              <Button className='card-button text-green' value={1} onClick={modalOpen}>Select</Button>
            </Card.Body>
          </Card>
          <Card className='p-1 mx-1'>
            <Card.Header className='card-header bg-blue'>Action</Card.Header>
            <Card.Body>
              <Card.Text>
                Lorem Ipsum is simply dummy text of the printing
              </Card.Text>
              <Button className='card-button text-blue' value={2} onClick={modalOpen} >Select</Button>
            </Card.Body>
          </Card>
          {/* <Card className='p-1'>
          <Card.Header className='card-header bg-orange'>Fields</Card.Header>
          <Card.Body>
            <Card.Text>
              Lorem Ipsum is simply dummy text of the printing
            </Card.Text>
            <Button className='card-button text-orange' value={3} onClick={modalOpen} >Select</Button>
          </Card.Body>
        </Card> */}
          <Card className='p-1'>
            <Card.Header className='card-header bg-orange'>Task</Card.Header>
            <Card.Body>
              <Card.Text>
                Lorem Ipsum is simply dummy text of the printing
              </Card.Text>
              <Button className='card-button text-orange' value={3} onClick={modalOpen} >Select</Button>
            </Card.Body>
          </Card>
          {/* <div className='rs_popup_close' onClick={closeStartModal} > <img src={closeIconUrl} alt="Close" /> </div> */}
        </div>
        {/* : []} */}
      </Popup>
    </>
  )
}

export default WorkflowStartNode;