import React, {Fragment, useState, useEffect} from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FormContainer from '../../Form Module Layout/Forms Container/FormContainer'
import AgGrid from '../../grid/ag/ag-grid'
import Alert from '../../inc/Alert'
import $ from 'jquery'
import { ValidateArray } from '../../../inc/Validation'
import Button from '../../inc/Button'
import axios from 'axios'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Api from '../../../inc/Api'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'

const Dimensions = (props) => {

    const [dimensionsGrid, setDimensionsGrid] = useState([])
    const [loading, setLoading] = useState(true)
    let dimensionCalled = false

    useEffect(() => {
      get_dimensions_data()
    }, [])
  
    const get_dimensions_data = () => {
      if(dimensionCalled){
        return;
      }
       let api = Api
       dimensionCalled = true
        api.setUserToken()
      api.axios().get(Settings.apiItemUrl+'/item_dimension',{}).then(function(res){
        if(res.data.status==="success"){ 
          setDimensionsGrid(res.data.data.reverse())
          setLoading(false)
        } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }
   
    const onSaveClick = (rowItem) => { 
      let updatedData = []
      let newData=[];
      rowItem.forEach(item => {
          if( !item.itemdimension_id || item.itemdimension_id <=0){
            newData.push(item);
          }else{
            updatedData.push(item);
          }
      });
      if(newData.length>=1){
          addNewData(newData);
      }
      if(updatedData.length>=1){ 
          updateData(updatedData);
      }
    } 
  
  const updateData = (data) =>{ 
    let validation = ValidateArray(data) 
    if (validation){
      Helper.alert('Please Enter Data In Fields!', 'error')
      get_dimensions_data()
    }
    let api = Api
    api.setUserToken()
      data.forEach(element => {
        api.axios().put(Settings.apiItemUrl+`/itemdimension/${element.itemdimension_id}`,{
          "name": element.name
        }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            get_dimensions_data()
          }
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
        })
      })
  }
   
  const addNewData = (data) => { 
    let validation = ValidateArray(data) 
    if (validation){
      Helper.alert('Please Enter Data In Fields!', 'error')
      get_dimensions_data()
    }
    let api = Api
    api.setUserToken()
    data.forEach(element => {
      api.axios().post(Settings.apiItemUrl+'/item_dimension',{
        "name": element.name
    }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_dimensions_data()
        }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
   }) 
  }
  
  const DeleteHandler = (allRows) => {
    let validation = ValidateArray(allRows) 
    if (validation){
      Helper.alert('Please Enter Data In Fields!', 'error')
      get_dimensions_data()
    }
    let api = Api
    api.setUserToken()
    allRows.forEach(element => {
      api.axios().delete(Settings.apiItemUrl+`/itemdimension/${element.itemdimension_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            get_dimensions_data()
          }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
  })
  }
  
  const SaveHandler = () => {
    $('.rs_dimensions_grid .rs_grid_btn_save').trigger('click'); 
  }
  
  const dimensionsValidator = (params) => {
    if( params.newValue.length > 255 ){
      Helper.alert('Name limit exceeded!', 'error')
      // params.data.name = params.newValue 
      return false
    }
    params.data.name = params.newValue
    return true
  }

    let Columns_Headings = [ 
        {field:'name', valueSetter: dimensionsValidator, headerName:Helper.getLabel(props.language, 'name', 'Name') } 
    ]
 
    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.masterData.item_master_modules.dimension.dimensions_main)){
      return <Fragment> 
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }
  
  
  return (
    <Fragment> 
      <NavigationHeder backUrl='/master-data' title='Dimensions'>
      <Button isActive= {true} isDisable={!security.canEdit(frontendIds.masterData.item_master_modules.dimension.dimensions_main_save_button)} title='Save' onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>  
      {loading ? <SimpleLoading /> : null}
      <div className='rs_dimensions_grid'>
        <AgGrid addBtnHide={!security.canCreate(frontendIds.masterData.item_master_modules.dimension.dimensions_main_create_button)} hideDelete={!security.canDelete(frontendIds.masterData.item_master_modules.dimension.dimensions_main_delete_button)} data={dimensionsGrid} id="dimensions-grid" header={Columns_Headings} onDelete={DeleteHandler} onSave={onSaveClick} /> 
      </div>
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.masterData.item_master_modules.dimension.dimensions_main
  }
  
  
  export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Dimensions, SecurityOptions)))