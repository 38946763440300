import $ from 'jquery';
class Sticky{
    constructor(id,options = {}){
        this.stickyOptions = {
            ...this._getDefaultOptions(),
            ...options
        }
        this.previousTranslateY = 0;
        this.selectorId  = id;
        this.initScroll();
    }
    activeStickyToSelector(){
        if(!this.hasElement()){
            return;
        }
        this.selector.classList.add('rs-sticky-element');
        if(this.stickyOptions.extraClass){

            this.selector.classList.add(this.stickyOptions.extraClass);
        }
        this.selector.style['z-index'] = this.stickyOptions.zIndex;
    }
    _getDefaultOptions(){
        return {
            zIndex:100,
            topSpace:0,
            upateWithClick:null,
            containerId:null,
            extraClass:null,
            activeClass:'sticky-active'
        }
    }
    getContainer(){
        if(this.stickyOptions.containerId){
            return document.getElementById(this.stickyOptions.containerId)
        }
        return null;
    }
    hasElement(){
        if(document.getElementById(this.selectorId)){
            return true;
        }
        return false;
    }
    initScroll(){
        this.selector  = document.getElementById(this.selectorId);
        this.activeStickyToSelector();
        let that = this;
        $(window).on('scroll',function(){
            that.makeSticky()
        });
        if(this.stickyOptions.upateWithClick){
            $(this.stickyOptions.upateWithClick).on('click',function(){
                that.makeSticky()
            })
        }
    }
    update(options){
        this.makeSticky();
    }
    getParent(){
        return this.selector.parentElement;
    }
    makeSticky(){
        if(!this.hasElement()){
            return;
        }
        let stickyOffset = this.selector.offsetTop;
        let windowTopOffset =  window.pageYOffset;
        let translateY = ( windowTopOffset - stickyOffset  ) + this.stickyOptions.topSpace;
        
        if(stickyOffset<= (windowTopOffset + this.stickyOptions.topSpace ) ){
            let container = this.getContainer();
            let tempTranslateY = translateY;
            if(container){
                if( ( ( container.offsetTop + container.clientHeight ) - (windowTopOffset + tempTranslateY + this.selector.clientHeight ) ) <= 0 ) {
                    tempTranslateY = this.previousTranslateY;
                }
            }
            
            this.previousTranslateY = tempTranslateY;
            this.selector.style.transform =`translateY(${tempTranslateY}px)`;
            this.selector.classList.add(this.stickyOptions.activeClass);

        }else{
            this.previousTranslateY = 0;
            this.selector.style.transform =`translateY(0px)`;
            this.selector.classList.remove(this.stickyOptions.activeClass);
        }
    }
}

export default Sticky;