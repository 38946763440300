import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper'; 
import Settings from '../../../inc/Settings';
import FieldsRenderer from './FieldsRenderer';

const GeneralTab = (props) => {
  //States Dropdowns 
  const [vendorSites, setVendorSites] = useState([])
  const [warehouse, setWarehouse] = useState([])
  //

  //API Renderer Calls
  useEffect(() => { 
    WareHouseAPI()
  },[])

  useEffect(() => {
    VendorSitesAPI(props.state.vendor_id)
  }, [props.state.vendor_id])
  //End

  //API Calls Reduction 
  let vendorSitesCalled = false
  let warehouseCalled = false
  //End

  //API Calls
  
  const WareHouseAPI = () => {
    if(warehouseCalled){
        return;
    }
    let api = Api
    warehouseCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+'/get_warehouse',{
        "code":"",
        "name":""
    }).then(function(res){
      if(res.data.status==="success"){
        let Warehouse = []
        res.data.data.forEach(item => {  
        Warehouse.push({
          value: item.warehouse_id,
          label: `${item.code} - ${item.name}`
        })
       }) 
       setWarehouse(Warehouse)
      } 
  }).catch((res) => { 
    console.log(res.response.data.message)
    // Helper.alert(res.response.data.message, 'error')
  })
}

  const VendorSitesAPI = (id) => {
    if(vendorSitesCalled) {
      return;
    }
      let api = Api
      vendorSitesCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+`/vendorsites/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
        let VendorSites = []
        res.data.data.forEach(item => {  
        VendorSites.push({
          value: item.vendorsite_id,
          label: item.name
        })
      }) 
      setVendorSites(VendorSites)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  //End
 
    let textBoxInputSettings = [ 
        {label:Helper.getLabel(props.language,'document_number',"Document Number"), inputType: "text", disable:props.disableField, className:'col-lg-2', name:'document_number'},
        {label:Helper.getLabel(props.language,'version',"Version"), inputType: "text", disable:true, className:'col-lg-2', name:'version'},
        {label:Helper.getLabel(props.language,'description',"Description"), inputType: "text", name:'description'},
        {label:Helper.getLabel(props.language,'attention_info',"Attention Information"), inputType: "text", name:'attention_information'},
        {label:Helper.getLabel(props.language,'purchase_status',"Purchase Status"), inputType: "text", disable:true, name:'purchase_status'},
        {mainTitle: 'Vendor', mainClassName: 'vendor', inputBoxData: [
            {label:Helper.getLabel(props.language,'vendor_code_name',"Vendor"), id: 'vendor_id', required: true, options: props.ApiData, inputType: "dropdown", disable:false, className:'col-lg-4', id: 'vendor_id', name:'vendor_id'},
            {label:Helper.getLabel(props.language,'vendor_site',"Vendor Site"), required: true, options: vendorSites, inputType: "dropdown", disable:false, className:'col-lg-4', name:'vendorsite_id', id: 'vendorsite_id'}, 
            {label:Helper.getLabel(props.language,'warehouse',"WareHouse"), required: true, options: warehouse, inputType: "dropdown", disable:false, className:'col-lg-4', name:'warehouse_id', id: 'warehouse_id'} 
        ]},
        {mainTitle: 'Document Date', mainClassName: 'document_date', inputBoxData: [
            {label:Helper.getLabel(props.language,'document_date',"Document Date"), required: true, inputType: "datepicker", inputClassName: 'document_date', disable:false, className:'col-lg-4', name:'document_date'},
            {label:Helper.getLabel(props.language,'accounting_date',"Accounting Date"), required: true, inputType: "datepicker", inputClassName: 'accounting_date', disable:false, className:'col-lg-4', name:'accounting_date'} 
        ]},
        {mainTitle: 'Tax Information', mainClassName: 'tax_info', inputBoxData: [
            {label:Helper.getLabel(props.language,'vendortax_id',"Vendor Tax Group"), options: props.taxGroups, inputType: "dropdown", disable:false, className:'col-lg-4', name:'taxgroup_id', id: 'taxgroup_id'},
            {label:Helper.getLabel(props.language,'itemtax_code',"Item Tax Code"), options: props.taxCodes, inputType: "dropdown", disable:false, className:'col-lg-4', name:'taxcodes_id', id:'taxcodes_id'} 
        ]}
    ]

  return ( 
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
    </Fragment>
  )
}


const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (GeneralTab) 