import React from 'react';
import { connect } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import './ExternalUser.css';
import LeftSidebar from './LeftSidebar';
import RightSection from './RightSection';

const ExtHome = (props) => {
    // main component for combining both the right bar filled with feed data, and left bar for info.
  return (
<section className='external_home_user'>
      <div className='container-fluid'>
       {/* <div className='row'> 
          <div className='col-lg-12'>
            <div className='external_home_user_btns'>
              <button>External Portal</button>
              <button>Internal Portal</button>
            </div>
          </div> 
        </div> */}
        <div className='row pt-4 ps-3 pe-3 pb-4'>
          <div className='col-3 col-lg-3 col-md-12 col-xl-3 col-xxl-2 '> 
            <LeftSidebar />
          </div>

          <div className='col-9 col-lg-9 col-md-12 col-xl-9 col-xxl-10 p-0'> 
            <RightSection />
          </div>
        </div>
      </div>
</section>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.external_screens.ext_command_center
}


export default connect(mapStateToProps) (ApplySecurityRoles(ExtHome, SecurityOptions))