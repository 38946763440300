import React from 'react'
import Graph from './graph.png'

const InfoWidget = () => {
  return (
    <section className='request_info_user'>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 p-0 pt-3'>
          <h3>Request for Information</h3>
        </div>
        <div className='col-12 d-flex justify-content-center'>
          <img src={Graph} className='img-fluid mt-5' />
        </div>
      </div>
    </div>
    </section>
  )
}

export default InfoWidget