import React, {useState, useEffect, Fragment} from 'react';
import FormContainer from '../../Form Module Layout/Forms Container/FormContainer';
import FormHeader from '../../Form Module Layout/Forms Header/FormHeader';
import { connect } from "react-redux";
import axios from 'axios';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import AgGrid from '../../grid/ag/ag-grid';
import $ from 'jquery'
import { ValidateArray } from '../../../inc/Validation';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';

const Language = (props) => {
   
  const [languageGrid, setLanguageGrid] = useState([])
  const [loading, setLoading] = useState(true)
  let languageCalled = false
  useEffect(() => {
    get_languages_data()
  }, [])

  const get_languages_data = () => {
    if(languageCalled){
      return;
    }
    languageCalled = true
    axios.get(Settings.apiBaseUrl+'/language',{}).then(function(res){
      if(res.data.status==="success"){ 
        setLanguageGrid(res.data.data)
        setLoading(false)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const onSaveClick = (rowItem) => { 
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.languange_Id || item.languange_Id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){  
        addNewData(newData);
    }
    if(updatedData.length>=1){ 
        updateData(updatedData);
    }
  } 

const updateData = (data) =>{
  let validation = ValidateArray(data) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_languages_data()
  } 
    data.forEach(element => {
      axios.put(Settings.apiBaseUrl+`/language/${element.languange_Id}`,{
        "languagecode": element.languageCode,
        "language": element.language,
        "alignment": element.alignment
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_languages_data()
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }) 
}
 
const addNewData = (data) => { 
  let validation = ValidateArray(data) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_languages_data()
    return;
  }   
    data.forEach(element => {
      axios.post(Settings.apiBaseUrl+'/language',{
        "languagecode": element.languageCode,
        "language": element.language,
        "alignment": element.alignment
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_languages_data()
        }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    }) 
   }) 
}

const DeleteHandler = (allRows) => {
  let validation = ValidateArray(allRows) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_languages_data()
  }
  allRows.forEach(element => {
    axios.delete(Settings.apiBaseUrl+`/language/${element.languange_Id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
            get_languages_data()
        }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
})
} 

const SaveHandler = () => {
  $('.rs_languages_grid .rs_grid_btn_save').trigger('click'); 
}

let AlignmentOptions = [
    {value: 0, label: "Left"},
    {value: 1, label: "Right"},
]
  
let Columns_Headings = [
    {field:'languageCode', headerName:'Code' },
    {field:'language', headerName:'Language' },
    {field:'alignment', headerName:'Alignment', options: AlignmentOptions, inputType: "dropdown"} 
]

      
let security = props.security;
let frontendIds = Gui_id_list;
if(!security.canView(Gui_id_list.formModules.language.language_main)){
  return <Fragment>
  <Alert message='NO ACCESS' type='danger' />
  </Fragment>
}

  return (
<Fragment> 
    <NavigationHeder backUrl='/administration' title='Language'>
    <Button isDisable={!security.canEdit(frontendIds.formModules.language.language_main_save_button)} isActive= {true} title='Save' onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
    </NavigationHeder> 
    {loading ? <SimpleLoading /> : null}
    <div className='rs_languages_grid'>
    <AgGrid hideDelete= {!security.canDelete(frontendIds.formModules.language.language_main_delete_button)} addBtnHide={!security.canCreate(frontendIds.formModules.language.language_main_create_button)} data={languageGrid} id="languages-grid" header={Columns_Headings} onSave={onSaveClick} onDelete={DeleteHandler} />
    </div>
    </Fragment>
  )
}

  
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.formModules.language.language_main
}


export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Language, SecurityOptions)))