import React, { Component } from 'react';
import Api from '../../inc/Api';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import AgGrid from '../grid/ag/ag-grid';
import RsDataLoader from '../inc/RsDataLoader';
import GridSegmentDistributionDetails from './GridSegmentDistributionDetails';
import SegmentDistributionPopup from './popups/SegmentDistributionPopup';
/**
 * id: grid id
 * source_id: required
 * totalAmount: required
 * integrator: required
 * distribution_source_id: id | null
 */
class SegmentsDistribution extends Component {
    constructor(props){
        super(props);
        this.id = this.props.id ? this.props.id:'rs_segment_distribution_grid';
        this.grid = null;
        this.source_id = this.props.source_id;
        this.totalAmount = this.props.totalAmount;
        this.integrator = this.props.integrator;
        this.state ={
            isPopupOpen:false,
            isLoading:false,
            allCombinationDetails:[],
            allSegmentsValues:[],
            distribution_source_id: this.props.distribution_source_id ? this.props.distribution_source_id : null,
            allSegments:[],
            gridData:[]
        }
    }
    componentDidMount(){
        this.loadSegments();
        this.loadSegmentValues();
        this.loadSegmentCombinations();
    }
    loadSegments(){
        if(this.isSegmentsLoaded){
            return;
        }
        this.isSegmentsLoaded = true;
        let api = Api;
        let that = this;
        that.setState({
            isLoading:true,
            gridData:[]
        })
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment').then(res => {
            let data = res.data.data.reverse();
            that.setState({
                isLoading:false,
                allSegments:data
            })
        }).catch( error => {
            that.setState({
                isLoading:false
            })
        })
    }
    loadSegmentValues(){
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoading:true,
        })
        api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
            let responseData = res.data.data.reverse();
            that.setState({
                allSegmentsValues:responseData,
                isLoading:false,
            })
        }).catch(error =>{
            that.setState({
                isLoading:false,
            })
        })
    }
    onGridReady(grid){
        this.grid = grid;
    }
    onAddBtnClick(event){
        this.setState({
            isPopupOpen:true
        })
    }
    onPopupClose(event){
        this.setState({
            isPopupOpen:false
        })
        this.loadSegmentCombinations();
    }
    loadCombinationDetails(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        
        api.axios().get(Settings.apiUrls.segments+'/distribution_detail_by_source/'+this.source_id).then( res => {
            that.setState({
                isLoading:false,
                allCombinationDetails:res.data.data
            })
        })
    }
    loadSegmentCombinations(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        
        api.axios().get(Settings.apiUrls.segments+'/segmentcombinationvalue').then( res => {
            let responseData = res.data.data.reverse();
            that.setState({
                isLoading:false,
                gridData:responseData
            })
            that.loadCombinationDetails();
        })
    }
    getSegmentValueFromValueId(valueId){
        let output = {};
        this.state.allSegmentsValues.map(item => {
            if(item.segmentvalue_id ==valueId ){
                output = item;
            }
        })
        return output;
    }
    render() {
        let language = this.props.dataLoaderProps.language;
        let data = this.state.gridData;
        let allCombinationDetails = this.state.allCombinationDetails;
        data = data.map( (item,key) => {
            let distributionDetails = {};
            allCombinationDetails.forEach( itemDistributionDetails => {
                if(itemDistributionDetails.segmentcombination_id === item.segmentcombination_id){
                    distributionDetails = itemDistributionDetails;
                }
            })
            let label = "";
            let nameAr = item.segmentcombinationvalue.split('|');
            nameAr.forEach( nameItem => {
                let segmentValueObj = this.getSegmentValueFromValueId(nameItem);
                label+= ( label == '' ? segmentValueObj.code : '-'+segmentValueObj.code );
            })
            return{
                ...item,
                name:label,
                amount:distributionDetails.amount,
                percentage:distributionDetails.percentage
            }
        })
        let header = [
            {field:'name',headerName:Helper.getLabel(language,'segments','Segments'),segmentList:this.state.allSegments, segmentValueList: this.state.allSegmentsValues,  width:150,cellRenderer: 'agGroupCellRenderer'},
            {field:'percentage',headerName:Helper.getLabel(language,'percentage','Percentage')},
            {field:'amount',headerName:Helper.getLabel(language,'amount','Amount')}
            //{field:'date',headerName:'Date', inputType:'datepicker'},
        ]
        let masterSettings = {
            detailRowAutoHeight: true,
            masterDetail: true,
            detailCellRenderer: GridSegmentDistributionDetails,
            detailCellRendererParams: {
                detailGridOptions: {
                rowSelection: 'single',
                columnDefs: [
                    { field: 'name' , headerName:'Segments' },
                    { field: 'duration' , headerName:'Value' },
                    { field: 'switchCode' , headerName:'Description' }
                ],
                defaultColDef: {
                        sortable: true,
                        flex: 1,
                    },
                }
            }
        }
        let gridLabels = {
            btnAdd:Helper.getLabel(language,'segment_distribution','Segment distribution'),
            btnDelete:Helper.getLabel(language,'remove','Remove'),
        }
        return (
            <div className='segment_distribution_component'>
                { this.state.isPopupOpen ? <SegmentDistributionPopup  totalAmount={this.totalAmount} source_id={this.source_id} integrator={this.integrator} distribution_source_id={this.state.distribution_source_id} language ={language} onPopupClose ={this.onPopupClose.bind(this)}/> : '' }
                <AgGrid id={this.id} viewOnly={true}  deleteConfig={{apiUrl:Settings.apiUrls.segments + '/segmentcombination',idName:'segmentcombination_id',that:this,reloadFuntion:'loadSegmentCombinations'}} onAddClick={this.onAddBtnClick.bind(this)} hideSave={true} labels={gridLabels} onGridReady={ this.onGridReady.bind(this) } data={data} header={header} settings={masterSettings}/>
            </div>
        );
    }
}

export default RsDataLoader(SegmentsDistribution,{});