import React, { Component } from 'react';
/**
 *items = [
            {title:'Item Master name',subtitle:'Paper Processing Mac'},
            {title:'Item ID',subtitle:'01010-015'},
            {title:'Product type',subtitle:'Item Lorem Ipsum'},
            {title:'Product Subtype',subtitle:'Product Lorem Ipsum'}
        ]
    tags = [{title:'Confirmed',type:'success'}];
 */
class BlueCallToAction extends Component {
    displayTags(){
        let tags = this.props.tags ? this.props.tags : [];
        if(tags.length <=0){
            return <></>
        }
        return(
            <div className='actions_tags'>
                {
                   tags.map( (tag,key) => {
                    return <span className={tag.type ? tag.type : 'success'} key={key}>{tag.title}</span>
                   }) 
                }
            </div>
        )
    }
    render() {
        let items = this.props.items ? this.props.items : [];
        
        return (
            <div className='rs_calltoaction_section'>
                
                <div className='rs_calltoaction_items'>
                    {
                        items.map( (item,key) => {
                            return(
                                <div className='rs_calltoaction_item' key={key}>
                                    <h3>{item.title}</h3>
                                    <p>{item.subtitle}</p>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    this.displayTags()
                }
                
            </div>
        );
    }
}

export default BlueCallToAction;