import React, { Component } from 'react';
import TreeviewItem from './TreeviewItem';
/*
 * items = [
                {
                    title:'',
                    showChild:false,
                    items:[
                        {title:'',items:[]},
                        {title:'',items:[]},
                        {title:'',items:[]},
                    ]
                },
                {title:'',items:[]},
                {title:'',items:[]},
            ]
 */
class Treeview extends Component {
    render() {
        const items = this.props.items ? this.props.items : [];
        return (
            <div className='rs_tree_view_section'>
                <ul>
                    { items.map( (item,key) => { return ( <TreeviewItem onIconClick={this.props.onIconClick} onClick = {this.props.onClick} item={item} key={key} /> )}) }
                </ul>
            </div>
        );
    }
}

export default Treeview;