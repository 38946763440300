import React, { Component } from 'react';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import MasterComonent from '../components/Backend/MasterComonent';
import Button from '../components/inc/Button';
import RsDataLoader from '../components/inc/RsDataLoader';
import NavigationHeder from '../components/Navigations/NavigationHeder';
import Helper from '../inc/Helper';

class MyAccount extends Component {
    constructor(props){
        super(props);
        let formComponentObj= null;
        this.state = {}
    }
    onSavBtnClick(e){ 
        if(this.formComponentObj){
            this.formComponentObj.onSaveHandler()
        }
    }
    setFormComponentObj(frmObj){
        this.formComponentObj = frmObj;
    }
    render() {
        let language = this.props.dataLoaderProps.language;
        let icons = {
            basic:{default:"/images/icons/user-blue.svg",active:"/images/icons/user-blue.svg"},
            security:{default:"/images/icons/security-gray.svg",active:"/images/icons/security-gray.svg"},
            deligation:{default:"/images/icons/users-gray.svg",active:"/images/icons/users-gray.svg"},
        }
        return (
            <MasterComonent>
                <div className='my_account_page'>
                    <NavigationHeder  title={Helper.getLabel(language,'profile','Profile')}>
                        <Button isActive={true} icon={"/images/icons/save-white.svg"} title={ Helper.getLabel(language,'save','Save')} onClick={ this.onSavBtnClick.bind(this)} />
                    </NavigationHeder>
                    <div className='rs_vertical_tabs my_account_tab'>
                        <div className='rs_vertical_nav rs_vertical_tab_menu'>
                            <NavLink to="/my-account" end><img src={icons.basic.default} alt="" /> <span className='tb_label'>Basic Information</span><img className='icon_ang_right' src={'/images/icons/angle-right-blue.svg'} alt="" /></NavLink>
                            <NavLink to="/my-account/security" end><img src={icons.security.default} alt="" /><span className='tb_label'>Password & Security</span><img  className='icon_ang_right' src={'/images/icons/angle-right-blue.svg'} alt="" /></NavLink>
                            <NavLink to="/my-account/deligation" end><img src={icons.deligation.default} alt="" /><span className='tb_label'>Deligation</span><img  className='icon_ang_right' src={'/images/icons/angle-right-blue.svg'} alt="" /></NavLink>
                        </div>
                        <div className='rs_vertical_tab_contents'>
                            {/* <Routes>
                                <Route path='/security' element={ <AccountSecurity setFormObj = {this.setFormComponentObj.bind(this)}/>} />
                                <Route path='/deligation' element={ <AccountDeligation  setFormObj = {this.setFormComponentObj.bind(this)}/>} />
                                <Route path='/' element={ <AccountBasicInformation  setFormObj = {this.setFormComponentObj.bind(this)}/>} />
                            </Routes> */}
                        </div>
                    </div>
                </div>
                
            </MasterComonent>
            
        );
    }
}

export default RsDataLoader(MyAccount);