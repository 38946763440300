import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CustomHtmlCell from '../../../components/grid/ag/cellRender/CustomHtmlCell';
import Button from '../../../components/inc/Button';
import Popup from '../../../components/Popup/Popup';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import AgGridMemo from '../../../components/grid/ag/AgGridMemo';
import Api from '../../../inc/Api';
import TriggerOption from '../Trigger/TriggerOption';
import NavigationHeder from '../../../components/Navigations/NavigationHeder';
import SimpleLoading from '../../../components/Loading/SimpleLoading';

const WorkflowVersions = (props) => {

  const [loading, setLoading] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [versionsData, setVersionsData] = useState([]);

  useEffect(() => {
    getWorkflowVersionData();
  }, [])

  const statusValueFormat = (params) => {
    if (params.value === true) {
      return `<span class="rs_active_label">Active</span>`;
    }
    return `<span class="rs_inactive_label">Inactive</span>`;
  }

  const Versions_Tabele_Columns_Headings = [
    { field: 'active', headerName: 'Status', valueFormatter: statusValueFormat, cellRenderer: CustomHtmlCell },
    { field: 'name', headerName: 'Name', width: 350 },
    { field: 'version', headerName: 'Version' },
    { field: 'created_at', headerName: 'Created At' },
    { field: 'created_by', headerName: 'Created By', width: 350 },
  ]

  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            return (res.data.data);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const getDateTime = (dateTime) => {
    const date = new Date(dateTime).toLocaleString('en-us', { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" });
    return date;
  }

  const getUserName = (userData) => {
    let api = Api;
    api.setUserToken();
    const formData = {
      "name": null,
      "user_id": userData
    }
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().post(Settings.loginUrl + `/workflow/get_users/1/10`, formData).then((res) => {
          if (res.data.status === "Success") {
            return res.data.data[0].employee_display_name;
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const getWorkflowVersionData = async () => {
    setLoading(true);
    const tenantId = await getTenantId();
    axios.get(Settings.baseUrl + `workflow/get-version?tenant_id=${tenantId.tenant_id}&template_id=${props.templateId}`).then(async (res) => {
      if (res.data.success) {
        const data = res.data.data;
        const newdata = await Promise.all(data.map(async (item) => {
          return {
            ...item,
            created_by: await getUserName(item.created_by),
            created_at: getDateTime(item.created_at)
          };
        }));
        setVersionsData(newdata);
        setForceRender(!forceRender);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }

  const modalClose = () => {
    props.hideWorkflowVersions(false);
    props.setTemplateid(null);
  }

  const [triggerOptionModal, setTriggerOptionModal] = useState(false);
  const navigateToWorkflowBody = () => {
    if (!workflowid) {
      Helper.alert("Please select atleast a workflow", 'error');
      return;
    }
    setTriggerOptionModal(true);
  }

  const [workflowid, setWorkflowid] = useState('');
  const [templateid, setTemplateid] = useState('');
  const [isActive, setActive] = useState(false);
  
  const getRowData = (event) => {
    let activeSelection = event.api.getSelectedRows();
    if (activeSelection.length) {
      setActive(activeSelection[0].active);
      setWorkflowid(activeSelection[0].workflow_id);
      setTemplateid(activeSelection[0].template_id);
    } else {
      setActive(false);
      setWorkflowid(null);
      setTemplateid(null);
    }
  }

  const workflowVersionActiveHandler = () => {
    if (!workflowid) {
      Helper.alert("Select a workflow for activate", 'error');
      return;
    }
    let formData;
    if (!isActive) {
      formData = {
        "workflow_id": workflowid,
        "active": true
      }
    } else {
      Helper.alert("Your workflow is already active");
      return;
    }
    axios.post(Settings.baseUrl + `workflow/update`, formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        getWorkflowVersionData();
      };
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      {triggerOptionModal ? <TriggerOption hideTriggerOption={setTriggerOptionModal} workflowid={workflowid} templateid={templateid} /> : ""}
      <NavigationHeder hideBackButton={true} title={`${'Workflow version'}`}>
        <Button title="Back" onClick={modalClose} />
        <Button title="Design" icon={'/images/icons/eye.svg'} onClick={navigateToWorkflowBody} />
        <Button title="Activate" isActive={true} onClick={workflowVersionActiveHandler} />
      </NavigationHeder>
      {loading ? <SimpleLoading /> : null}
      <AgGridMemo id="rs_users_list" data={versionsData} header={Versions_Tabele_Columns_Headings} onSelectionChanged={getRowData} allBtnHide={true} singleRowSelect={true} viewOnly={true} forceRender={forceRender} />
    </>
  )
}

export default WorkflowVersions;