import React from 'react' 
import Banner from './ProfileBanner.png'
import Profile from './ProfileImage.png'

const ProfileWidget = () => {
  return (
    <section className='feed_identity_user'> 
        <div className="card text-center">
            <img className="card-img-top" src={Banner} alt="User Profile Banner" />
            <div className="card-body">
                <img src={Profile} alt='User Profile Image' className='img-fluid profile_image' />
                <h3>Bridge Axis</h3> 
                <p>Dealing in all Erp Solutions to provide smooth platform for its users.</p>
                <button>View Supplier Profile</button>
            </div>
        </div> 
    </section>
  )
}

export default ProfileWidget;