import React, { Component } from "react";
import ProductDetail from "../../components/PurchaseRequisition/ProductDetail";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";

export default class ProductDetailScreen extends Component {
  render() {
    return (
      <ShopLayout
        backUrl="/purchase-requisition/shop"
        title="Shop > Product detail page"
        hideMoreBtn={true}
      >
        <ProductDetail />
      </ShopLayout>
    );
  }
}
