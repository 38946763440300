import React, { Component } from "react";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import SearchBox from "./SearchBox";
import UserWidget from "./UserWidget";
import './Header.css';
import LanguageSwitch from '../../components/Language Switcher/LanguageSwitch';
import ActionTypes from "../../inc/ActionsTypes";
import { connect } from "react-redux";
import Helper from "../../inc/Helper";
import $ from 'jquery';
class Header extends Component {
  constructor(props){
    super(props);
    this.stickyHeader = null;
  }
  componentDidMount(){
    //this.stickyHeader = new Sticky('.main_header');
    Helper.makeSticky('.main_header');
  }
  onSidebarIconClickHanlder(e){
    this.props.setOption('isSidebarOpen', !this.props.appOptions.options.isSidebarOpen)
    setTimeout(function(){
      Helper.refreshAppScript();
    },300)
    
  }
  componentDidUpdate(){
    if(this.props.appOptions.options.isSidebarOpen){
      $('body').addClass('main-sidebar-open');
    }else{
      $('body').removeClass('main-sidebar-open');
    }
  }
  render() {
    // data-sticky-class="sticky-active" data-sticky-wrap="true"
    return (
      <div className="main_header" >
        <div className="main_header_inner">
          <div className="container-fluid">
            <div className="d-flex justify-content-between mh_inner">
              <div className="left_section">
                <img className='sidebar_toggle' src= { this.props.appOptions.options.isSidebarOpen ? "/images/icons/close-2.svg" : "/images/icons/menu.svg" } onClick={ e => { this.onSidebarIconClickHanlder(e) } } alt="" />
              </div>
              <div className="header_middle">
                <SearchBox />
              </div>
              <div className="d-flex right_section"> 
                <LanguageSwitch/>
                <Link to={"/dashboard"} className="note"><img src="/images/icons/note.svg" alt="" /></Link>
                <Notifications/>
                <UserWidget/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      appOptions:state.options
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      setOption:(keyOption,valueOption) => { dispatch({type:ActionTypes.SET_OPTIONS ,  key: keyOption, value: valueOption }) },
  }
}
export default connect(mapStateToProps,mapDispatchToProps) (Header);
