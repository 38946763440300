import React, { Component } from 'react';
import ActionsHistory from './ActionsHistory/ActionsHistory';
import FileUploaderList from './FileUpload/FileUploaderList';
import UploaderWithList from './FileUpload/UploaderWithList';
import Notes from './Notes/Notes';
import $ from 'jquery';
/**
 * source_id ={18} 
 * notesource_integrator= {59}  
 * attachmentsource_integrator ={59}
 */
class NoteAttachmentHistory extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeComponent:'notes',
        }
    }
    componentDidMount(){
        this.fixWidgetHeight();
    }
    fixWidgetHeight(){
        let minHeight = $('.verticle_items').outerWidth();
        $('.verticle_items').css({translate:' 0 '+minHeight+'px'});
        $('.note_attachment_history_widget').css({
            'min-height':(minHeight + 30)+'px'
        })
    }
    setActiveComponent(e,activeComponent){
        this.setState({
            activeComponent:activeComponent
        })
    }
    
    displayComponent(){

        switch(this.state.activeComponent){
            case 'notes':
                return <Notes notesource_integrator={this.props.notesource_integrator} source_id={this.props.source_id} exportable={ this.props.notesource_integrator ? false : true}/>
            case 'attachment':
                return <UploaderWithList  source_id={this.props.source_id} attachmentsource_integrator = {this.props.attachmentsource_integrator} />
            case 'action_history':
                return <ActionsHistory   source_id={this.props.source_id}/>
            default:
                break;
        }
    }

    render() {
        return (
            <div className='note_attachment_history_widget'>
                <div className='verticle_items'>
                    <span>Notes</span>
                    <span>Attachment</span>
                    <span>Action History</span>
                </div>
                <div className='note_attachment_history_widget_inner'>
                    <ul className={`menu_style_2 ${this.props.hideHistory ? "menu_style_3" : ""}`}>
                        <li className={this.state.activeComponent ==='notes' ? 'active' : ''} onClick={ e => this.setActiveComponent(e,'notes') }>Notes</li>
                        <li className={this.state.activeComponent ==='attachment' ? 'active' : ''}   onClick={ e => this.setActiveComponent(e,'attachment') }>Attachment</li>
                        {this.props.hideHistory ? null : (
                            <li className={this.state.activeComponent ==='action_history' ? 'active' : ''}   onClick={ e => this.setActiveComponent(e,'action_history') }>Action History</li>
                        )}
                    </ul>
                    <div className='widget_contents'>
                        {
                            this.displayComponent()
                        }
                    </div>
                </div>
                
            </div>
        );
    }
}

export default NoteAttachmentHistory;