import { useEffect, useRef } from 'react';

const IdleTimer = (idleTime, onIdle) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(onIdle, idleTime);
    };

    const clearTimer = () => {
      clearTimeout(timeoutRef.current);
    };

    const handleEvent = () => {
      resetTimer();
    };

    resetTimer();

    document.addEventListener('mousemove', handleEvent);
    document.addEventListener('keypress', handleEvent);
    document.addEventListener('click', handleEvent);
    document.addEventListener('scroll', handleEvent);
    // Add additional events to track user activity (e.g., click, scroll)

    return () => {
      document.removeEventListener('mousemove', handleEvent);
      document.removeEventListener('keypress', handleEvent);
      document.removeEventListener('click', handleEvent);
      document.removeEventListener('scroll', handleEvent);
      // Remove additional event listeners
      clearTimer();
    };
  }, [idleTime, onIdle]);
};

export default IdleTimer;
