import React, { useEffect, useState } from 'react'
import MasterComonent from '../../../components/Backend/MasterComonent';
import NavigationHeder from '../../../components/Navigations/NavigationHeder';
import Button from '../../../components/inc/Button';
import SimpleLoading from '../../../components/Loading/SimpleLoading';
import AgGridMemo from '../../../components/grid/ag/AgGridMemo';
import axios from 'axios';
import Settings from '../../../inc/Settings';
import Helper from '../../../inc/Helper';
import { ValidateArray } from '../../../inc/Validation';
import Api from '../../../inc/Api';
import $ from 'jquery';

const Stages = () => {

  useEffect(() => {
    getTenantId();
    getStagesData();
  }, [])

  // const [tenantId, setTenantId] = useState('');
  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            return (res.data.data.tenant_id);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const [loading, setLoading] = useState(false);
  const [stagesData, setStagesData] = useState([]);
  const [forceRender, setForceRender] = useState(false);

  const gridValidationRules = {
    name: { name: 'name', displayName: 'Name', types: ['Required'] }
  }

  const Stages_Tabele_Columns_Headings = [
    { field: 'name', headerName: 'Name', validationRule: gridValidationRules.name }
  ]

  const getStagesData = () => {
    setLoading(true);
    axios.get(Settings.baseUrl + 'stage/get').then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        setStagesData(res.data.data);
        setForceRender(!forceRender);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }

  const onSaveClick = (rowItem) => {
    if (stagesData.length) {
      let similarValues = [];
      for (let i = 0; i < stagesData.length; i++) {
        for (let j = 0; j < rowItem.length; j++) {
          if (stagesData[i].name.trim() === rowItem[j].name.trim()) {
            similarValues.push(stagesData[i].name);
          }
        }
      }
      if (similarValues.length) {
        Helper.alert("Stage name can't be same, please enter different satge name", 'error');
        return;
      }
    }
    let updatedData = []
    let newData = [];
    rowItem.forEach(item => {
      if (item.gridRowId) {
        newData.push(item);
      } else {
        updatedData.push(item);
      }
    });
    if (newData.length >= 1) {
      addStages(newData);
    }
    if (updatedData.length >= 1) {
      updateStages(updatedData);
    }
  }

  const addStages = async (data) => {
    setLoading(true);
    const tenantId = await getTenantId();
    let validation = ValidateArray(data);
    console.log(validation);
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error');
      getStagesData();
    }
    const formData = [];
    data.forEach((item) => {
      formData.push({
        "name": item.name,
        "tenant_id": tenantId,
      })
    })
    axios.post(Settings.baseUrl + 'stage/create', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        setLoading(false);
        getStagesData();
      }
    }).catch((res) => {
      Helper.alert(res.response.data.errors[0].module_id[0], 'error')
      setLoading(false);
    })
  };

  const updateStages = (data) => {
    setLoading(true);
    let validation = ValidateArray(data);
    if (validation) {
      Helper.alert('Please Enter Data In Fields!', 'error');
      getStagesData();
    }
    const formData = [];
    data.forEach((item) => {
      formData.push({
        "name": item.name,
        "stage_id": item.stage_id
      })
    })
    axios.post(Settings.baseUrl + 'stage/update', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        getStagesData();
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    })
  }

  const deleteTemplate = (getStageId) => {
    setLoading(true);
    let formData = [];
    getStageId.forEach((item) => {
      if (item.stage_id > 0) {
        formData.push(item.stage_id)
      }
    });
    axios.post(Settings.baseUrl + 'stage/delete', { "stage_id": formData }).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        getStagesData();
        setLoading(false);
      }
    }).catch((error) => {
      if (error.response.data.message) {
        Helper.alert(error.response.data.message, 'error');
        getStagesData();
      }
      setLoading(false);
    })
  }

  const saveHandler = () => {
    $('.rs_form_grid .rs_grid_btn_save').trigger('click');
  }

  return (
    <>
      <MasterComonent>
        <NavigationHeder title={`<span style="color:#000;"> Home > </span>  ${'Stages'}`} backButtonHide={true}>
          <Button isActive={true} title='Save' onClick={saveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder>
        {loading ? <SimpleLoading /> : null}
        <div className='rs_form_grid'>
          <AgGridMemo data={stagesData} id="rs_users_list" header={Stages_Tabele_Columns_Headings} onSave={onSaveClick} onDelete={deleteTemplate} forceRender={forceRender} />
        </div>
      </MasterComonent>
    </>
  )
}

export default Stages;