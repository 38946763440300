import React, { Component } from 'react';
import GoogleLogo from '../../../assets/google.svg';
class LoginWithGoogle extends Component {
    render() {
        return (
            <button className='btn btn_block btn_login_with_google'><img src={GoogleLogo} />Login with Google</button>
        );
    }
}

export default LoginWithGoogle;