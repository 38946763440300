import React from 'react'
import MasterComonent from '../../components/Backend/MasterComonent';
import TenantContainer from '../../components/Tenant/Tenant Container/TenantContainer'

const TenantScreen = () => {
  return (
    <MasterComonent>
      <TenantContainer />
    </MasterComonent>
  )
}

export default TenantScreen;

