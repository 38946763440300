import React, { useEffect } from 'react';
import './App.css';
import './rtl.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Style from './components/Item Master/Style/Style';
import ItemBuyerGroup from './components/Item Master/Item Buyer Group/ItemBuyerGroup';
import ItemGroup from './components/Item Master/Item Group/ItemGroup';
import Color from './components/Item Master/Color/Color';
import Brand from './components/Item Master/Item Brand/Brand';
import Configuration from './components/Item Master/Configuration/Configuration';
import ItemGroupAccount from './components/Item Master/Item Group Account/ItemGroupAccount';
import ItemGroupAccountType from './components/Item Master/Item Group Account Type/ItemGroupAccountType';
import AddressPopup from './components/Edit Address/Address Popup/AddressPopup';
import TenantScreen from './screens/Tenant Screen/TenantScreen';
import ItemCreate from './components/Item Master/Item/ItemCreate';
import EditItem from './components/Item Master/Item/EditItem';
import Login from './screens/LoginRegister/Login';
import Register from './screens/LoginRegister/Register';
import EditTenantPage from './screens/Tenant Screen/EditTenantPage';
import NewUser from './components/Users/NewUser/NewUser';
import EditUsers from './components/Users/EditUsers/EditUsers';
import NewNumber from './components/Numbers/New Numbers/NewNumber';
import NewOrg from './components/Organisation Unit/New Org/NewOrg';
import EditPO from './components/Purchase Orders Internal/Edit PO/EditPO';
import CreateEmail from './components/Email Template/Create Email/CreateEmail';
import VendorsArchive from './components/Vendors/Vendors Archive/VendorsArchive';
import GroupArchive from './components/Vendors/Vendors Group/Archive/GroupArchive';
import GroupEdit from './components/Vendors/Vendors Group/Edit/GroupEdit';
import GroupCreate from './components/Vendors/Vendors Group/Create/GroupCreate';
import ReasonArchive from './components/Vendors/Vendors Reason/Archive/ReasonArchive';
import BusinessArchive from './components/Vendors/Vendors Business Classification/Archive/BusinessArchive';
import EditReason from './components/Vendors/Vendors Reason/Edit/EditReason';
import CreateReason from './components/Vendors/Vendors Reason/Create/CreateReason';
import CreateBusiness from './components/Vendors/Vendors Business Classification/Create/CreateBusiness';
import EditBusiness from './components/Vendors/Vendors Business Classification/Edit/EditBusiness';
import Dimensions from './components/Item Master/Dimensions/Dimensions';
import Size from './components/Item Master/Size/Size';
import ItemArchive from './components/Item Master/Item/ItemArchive';
import LogoutComponent from './components/Auth/LogoutComponent';
import ExtHome from './components/External Screens/User Home Page/ExtHome';
import POArchive from './components/Purchase Orders Internal/Archive PO/POArchive';
import PurchaseOrderListing from './components/External Screens/Purchase Order/PurchaseOrderListing';
import PurchaseOrderDetailView from './components/External Screens/Purchase Order/PurchaseOrderDetailView';
import Parameters from './components/Procurement Settings/Parameters';
import DeliveryArchive from './components/Procurement Settings/Delivery Item/DeliveryArchive';
import ModeDeliveryArchive from './components/Procurement Settings/Mode Of Delivery/ModeDeliveryArchive';
import ParametersArchive from './components/Procurement Settings/ParametersArchive';
import ParametersEdit from './components/Procurement Settings/ParametersEdit';
import Error404 from './screens/Pages/Error404';
import MyAccount from './screens/MyAccount';
import TeamHeader from './components/Procurement Settings/Team Header/TeamHeader';
import CreateTeamHeader from './components/Procurement Settings/Team Header/CreateTeamHeader';
import EditTeamHeader from './components/Procurement Settings/Team Header/EditTeamHeader';
import ShopScreen from './screens/Purchase Requisition/ShopScreen';
import WishlistScreen from './screens/Purchase Requisition/WishlistScreen';
import ProductDetailScreen from './screens/Purchase Requisition/ProductDetailScreen';
import ShoppingCartScreen from './screens/Purchase Requisition/ShoppingCartScreen';
import CheckoutScreen from './screens/Purchase Requisition/CheckoutScreen';
import Template from './screens/Workflow Module Screen/Template/Tempalte View/Template';
import FieldDictionary from './screens/Workflow Module Screen/Template/Template Edit/FieldDictionary';
import Workflow from './screens/Workflow Module Screen/Workflow/Workflow';
import WorkflowElement from './screens/Workflow Module Screen/Workflow Element/WorkflowElement';
import WorkFlowCanvas from './screens/Workflow Module Screen/React Flow/ReactFlow';
import GanttChart from './screens/Workflow Module Screen/Gantt Chart/GanttChart';
import Stages from './screens/Workflow Module Screen/Stages/Stages';
import IdleTimer from './IdleTimer';
import Helper from './inc/Helper';
import Settings from './inc/Settings';

function App() {

  // Delete the token from cookies
  const handleIdle = () => {
    Helper.removeCookie(Settings.userTokenKey);
    document.location.reload();
  };

  IdleTimer(30 * 60 * 1000, handleIdle);

  // here all screens will rendered according to the routes!
  return (
    <React.Fragment>
      <Routes>
        <React.Fragment>
          <Route path='/address-form' element={<AddressPopup />} />
          <Route path='/dimensions' element={<Dimensions />} />
          <Route path='/size' element={<Size />} />
          <Route path='/style' element={<Style />} />
          <Route path='/color' element={<Color />} />
          <Route path='/procurement-settings' element={<ParametersArchive />} />
          <Route path='/procurement-settings-create' element={<Parameters />} />
          <Route path='/edit-procurement-settings/:id' element={<ParametersEdit />} />
          <Route path='/delivery-terms' element={<DeliveryArchive />} />
          <Route path='/mode-of-delivery' element={<ModeDeliveryArchive />} />
          <Route path='/configuration' element={<Configuration />} />
          <Route path='/item-buyer-group' element={<ItemBuyerGroup />} />
          <Route path='/brand' element={<Brand />} />
          <Route path='/external-user' element={<ExtHome />} />
          <Route path='/purchase-orders-listing' element={<PurchaseOrderListing />} />
          <Route path='/purchase-orders-details' element={<PurchaseOrderDetailView />} />
          <Route path='/team-header' element={<TeamHeader />} />
          <Route path='/team-header-edit/:id' element={<EditTeamHeader />} />
          <Route path='/team-header-create' element={<CreateTeamHeader />} />
          <Route path='/purchase-orders' element={<POArchive />} />
          <Route path='/edit-purchase-order/:id' element={<EditPO />} />
          <Route path='/item-group' element={<ItemGroup />} />
          <Route path='/item-group-account' element={<ItemGroupAccount />} />
          <Route path='/item-group-account-type' element={<ItemGroupAccountType />} />
          <Route path='/items' element={<ItemArchive />} />
          <Route path='/create-item' element={<ItemCreate />} />
          <Route path='/edit-item/:id' element={<EditItem />} />
          <Route path='/items' element={<ItemArchive />} />
          <Route path='/create-email-template' element={<CreateEmail />} />
          <Route path='/vendors' element={<VendorsArchive />} />
          <Route path='/vendors-group' element={<GroupArchive />} />
          <Route path='/vendors-reason' element={<ReasonArchive />} />
          <Route path='/vendors-business-classification' element={<BusinessArchive />} />
          <Route path='/create-vendors-business-classification' element={<CreateBusiness />} />
          <Route path='/edit-vendor-business-classification/:id' element={<EditBusiness />} />
          <Route path='/create-vendors-reason' element={<CreateReason />} />
          <Route path='/edit-vendor-reason/:id' element={<EditReason />} />
          <Route path='/edit-vendors-group/:id' element={<GroupEdit />} />
          <Route path='/create-vendors-group' element={<GroupCreate />} />
          <Route path='/new-number' element={<NewNumber />} />
          <Route path='/new-user' element={<NewUser />} />
          <Route path='/new-tenant' element={<TenantScreen />} />
          <Route path='/tenant/edit/:id' element={<EditTenantPage />} />
          <Route path='/user/edit/:id' element={<EditUsers />} />
          <Route path='/new-organisation-unit' element={<NewOrg />} />
          <Route path='/workflow' element={<Workflow />} />
          <Route path='/workflow-element' element={<WorkflowElement />}>
            <Route path=':workflow-element' element={<WorkFlowCanvas />} />
            <Route path=':ganttchart' element={<GanttChart />} />
          </Route>
          <Route path='/template' element={<Template />} />
          <Route path='/template/edit' element={<FieldDictionary />} />
          <Route path='/stages' element={<Stages />} />
          <Route path='/dashboard' element={<Template />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<LogoutComponent />} />
          <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route
            path="/purchase-requisition"
            element={<Navigate to="/purchase-requisition/shop" />}
          />
          <Route path="/purchase-requisition/shop" element={<ShopScreen />} />
          <Route path="/purchase-requisition/wishlist" element={<WishlistScreen />} />
          <Route path="/purchase-requisition/shop/:id" element={<ProductDetailScreen />} />
          <Route path="/purchase-requisition/cart" element={<ShoppingCartScreen />} />
          <Route path="/purchase-requisition/checkout" element={<CheckoutScreen />} />
          <Route path='/my-account/*' element={<MyAccount />} />
          <Route path='*' element={<Error404 />} />
        </React.Fragment>
      </Routes>
    </React.Fragment>
  );
}

export default App;
