import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";

class ShopSearchbar extends Component {
  componentDidMount() {
    console.log(this.props.searchString);
  }

  handleSearchChange(e) {
    this.props.updateSearchString(e.target.value);
  }

  handleFormSubmit(event) {
    console.log("handleFormSubmit");
    event.preventDefault();
  }

  render() {
    const { searchString } = this.props;

    return (
      <div className="pr-searchbar pr-searchbar-wrapper">
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <div className="pr-searchbar-form">
            <input
              className={`pr-searchbar-input ${
                Helper.isRtl() ? "pr-searchbar-input-rtl" : ""
              }`}
              placeholder={Helper.getLabel(
                this.props.language,
                "search_item",
                "Search Item"
              )}
              onChange={(e) => this.handleSearchChange(e)}
              value={this.props.searchString}
            />
            <div
              className={`pr-searchbar-custom-select ${
                Helper.isRtl() ? "pr-searchbar-custom-select-rtl" : ""
              }`}
            >
              <select>
                <option>All items</option>
              </select>
            </div>
          </div>
          <button className="pr-searchbar-btn">Search</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchString: state.prShop.searchString,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchString: (payload) =>
      dispatch({ type: "UPDATE_SEARCH_STRING", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopSearchbar);
