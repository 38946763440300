import React, { useEffect, useState } from 'react'
import Input from '../../Forms/Input';
import Collapse from '../../inc/Collapse';
import Tabs from '../../Widgets/Tabs/Tabs';
import Barcodes from './Barcodes';
import DropDownInput from '../../Forms/Dropdown';
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';

const EditGeneral = (props) => {
    const [openBarcode, setOpenBarcode] = useState(false) 
    const [itemMaster, setItemMaster] = useState([])
    const [itemGroup, setItemGroup] = useState([])
    const [taxCode, setTaxCode] = useState([])
    const [itemBuyerGroup, setItemBuyerGroup] = useState([])

    let GeneralNavs = [
        {title:Helper.getLabel(props.language,'identification','Identification'), isActive: true, },
        {title:Helper.getLabel(props.language,'additional_information','Additional Information')},  
    ]

    let itemBarcodeObjCalled = false 
    let itemMasterListCalled = false
    let itemGroupListCalled = false
    let taxCodesListCalled = false
    let buyerGroupListCalled = false

    useEffect(() => {
        get_item_master_list()
        get_item_group_list()
        get_tax_codes_list()
        get_buyer_group_list()
        getItemBarcodeObject()
    }, [])

    
   const get_item_master_list = () => {
    if(itemMasterListCalled){
      return;
    }
    let api = Api
    itemMasterListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/itemmastertype`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.id,
          label: element.name
        })
      })
      setItemMaster(UPDATED_STATE)
      }
    })
   }
 
   const get_item_group_list = () => {
    if(itemGroupListCalled){
      return;
    }
    let api = Api
    itemGroupListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_group`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itemgroup_id,
          label: element.name
        })
      })
      setItemGroup(UPDATED_STATE)
      }
    })
   }

   const get_tax_codes_list = () => {
    if(taxCodesListCalled){
      return;
    }
    let api = Api
    taxCodesListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/taxcodes`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.taxcodes_id,
          label: element.name
        })
      })
      setTaxCode(UPDATED_STATE)
      }
    })
   }

   const get_buyer_group_list = () => {
    if(buyerGroupListCalled){
      return;
    }
    let api = Api
    buyerGroupListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiItemUrl+`/item_buyergroup`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.itembuyergroup_id,
          label: element.name
        })
      })
      setItemBuyerGroup(UPDATED_STATE)
      }
    })
   }
 

    const getItemBarcodeObject = () => {
        if(props.id){  
          if(itemBarcodeObjCalled){
            return;
          }
          let api = Api
          itemBarcodeObjCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiItemUrl+`/get_barcodes/${props.id}`,{}).then(function(res){
            if(res.data.status ==="success"){   
              props.setItemBarcode(res.data.data)
              Helper.alert(res.data.message)
            }
          }) 
        }
      }


    const BarcodeHandler = (event) => {
        let barcode = event.target.value
        props.setEditItemBarcode((prevState) => {
        return {
            ...prevState,
            barcode: barcode
        }
        })
    }
  
  const ItemNameHandler = (event) => {
    let name = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        item_name: name
      }
    })
  }
  
  const DisplayNameHandler = (event) => {
    let name = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        display_name: name
      }
    })
  }
  
  const PurchaseUoMHandler = (event) => {
    let UoMPurchase = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        purchaseUoM: parseInt(UoMPurchase)
      }
    })
  }
  
  const ItemGroupHandler = (event) => {
    let itemgroup = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        item_group_id: parseInt(itemgroup)
      }
    })
  }
  
  const TaxCodeHandler = (event) => {
    let taxcode = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        taxcodes_id:parseInt(taxcode)
      }
    })
  }
  
  const ItemMasterHandler = (event) => {
    let item_master = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        itemmaster_type: item_master 
      }
    })
  }
   
  const BuyerGroupHandler = (event) => {
    let buyer_group = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
         item_buyergroup_id:parseInt(buyer_group)
      }
    })
  }
  
  const DescriptionHandler = (event) => {
    let description = event.target.value
    props.setEditItem((prevState) => {
      return {
        ...prevState,
        description: description
      }
    })
  }
  
  const OpenBarcodes = () => {
    setOpenBarcode(true)
  }


  return (
    <Collapse className='mb-4 general_tab_item_create' open={true} title={Helper.getLabel(props.language,'general',"General")}>
        <Tabs disableSticky={true} navs={GeneralNavs} showAll={false} scrollOffset= "500">
        <div className='container-fluid identification'>
        <div className='row'> 
          <div className='col-lg-4'>  
            <label>Barcodes<span>*</span></label>
            <button className='rs_h_controller' onClick={OpenBarcodes}><img src='/images/icons/add-new.svg' alt='create-barcode' /></button>  
            {openBarcode ? <Barcodes reloadGrid={getItemBarcodeObject} barcodes={props.itemBarcode} setBarcodes={props.setItemBarcode} item_id={props.id} setOpenBarcodePopup={setOpenBarcode} /> : ""}
          </div>
          <div className='col-lg-4'>
            <Input onChange={ItemNameHandler} value={props.editItem.item_name} label={Helper.getLabel(props.language,'name',"Name")} required={true} type='text'  />
          </div>
          <div className='col-lg-4'>
            <Input onChange={DisplayNameHandler} value={props.editItem.display_name} label={Helper.getLabel(props.language,'display_name',"Display Name")} required={true} type='text'  />
          </div>
          <div className='col-lg-4'>
            <DropDownInput onChange={PurchaseUoMHandler} value={props.editItem.purchaseUoM} options={props.purchaseUnit} label={Helper.getLabel(props.language,'purchase_unit',"Purchase Unit")} required={true} id='purchase_unit_id' />
          </div>
          <div className='col-lg-4'>
            <DropDownInput onChange={ItemGroupHandler} value={props.editItem.item_group_id} options={itemGroup} label={Helper.getLabel(props.language,'item_group',"Item Group")} required={true} id='item_group_id_dropdown' />
          </div>
          <div className='col-lg-4'>
            <DropDownInput onChange={TaxCodeHandler} value={props.editItem.taxcodes_id} options={taxCode} label={Helper.getLabel(props.language,'tax_code',"Tax Code")} required={true} id='tax_code_id' />
          </div>
          <div className='col-lg-4'>
            <DropDownInput onChange={ItemMasterHandler} value={props.editItem.itemmaster_type} options={itemMaster} label={Helper.getLabel(props.language,'item_master_type',"Item Master Type")} required={true} id='item_master_type_id' />
          </div> 
          <div className='col-lg-4'>
            <DropDownInput onChange={BuyerGroupHandler} value={props.editItem.item_buyergroup_id} options={itemBuyerGroup} label={Helper.getLabel(props.language,'buyer_group',"Buyer Group")} id='buyer_group_id' />
          </div> 
        </div>
        </div>

        <div className='container-fluid additional_information'>
          <div className='row'>
            <div className='col-lg-5'>
              <label className='mb-2'>{Helper.getLabel(props.language,'description',"Description")}</label>  
                <textarea className='descriptionTextarea' onChange={DescriptionHandler} value={props.editItem.description} />
              </div> 
            </div>
          </div>
        </Tabs>
      </Collapse>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

export default connect(mapStateToProps) (EditGeneral);