import React, { Fragment, useEffect, useRef, useState } from 'react'
import '../PO.css'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles' 
import MasterComonent from '../../Backend/MasterComonent'
import NavigationHeder from '../../Navigations/NavigationHeder'
import LinkBtn from '../../inc/LinkBtn'
import SegmentsDistribution from '../../Segments/SegmentsDistribution'
import Button from '../../inc/Button'
import Tabs from '../../Widgets/Tabs/Tabs'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Collapse from '../../inc/Collapse' 
import Helper from '../../../inc/Helper' 
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import OrderStatus from '../../Widgets/OrderStatus/OrderStatus'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Alert from '../../inc/Alert'
import { useParams } from 'react-router-dom' 
import Api from '../../../inc/Api'
import SimpleLoading from '../../Loading/SimpleLoading'
import Settings from '../../../inc/Settings'
import { ValidateArray, ValidateData } from '../../../inc/Validation'
import FormValidator from '../../../inc/FormValidator' 
import ListViewTabs from './ListViewTabs'
import ListViewGrid from './ListViewGrid'
import SwitchView from '../../../inc/SwitchView'
import HeaderViewGeneral from './HeaderViewGeneral'
import HeaderViewDelivery from './HeaderViewDelivery'
import HeaderViewPriceDiscount from './HeaderViewPriceDiscount'
import $ from 'jquery'
import POListView from './POListView'

let isDisable = true
let defaultGridData = null
let DefStateData  = null; 
let dataFromGridAndInput = []

const EditPO = (props) => {

  const {id} = useParams()
   
  //States Component - Wide
    const [viewComponent, setViewComponent] = useState("Header View") 
    const [loading, setLoading] = useState(true)
    const [poLinesLoading, setPOLinesLoading] = useState(false)
 
    // const [dataFromGridAndInput, setDataFromGridAndInput] = useState([])
    const [editPO, setEditPO] = useState({}) 
    const [listViewTabsObj, setListViewTabsObj] = useState({})
    const [listViewTabsGridObj, setListViewTabsGridObj] = useState({})
    const [lineSiteId, setLineSiteId] = useState(null)
    const [editPOLinesGrid, setEditPOLinesGrid] = useState([]) 
    const [previousPurchaseOrderLinesData, setPreviousPurchaseOrderLinesData] = useState(Object.freeze([]))
    const [taxCode, setTaxCode] = useState([])
    const [taxGroup, setTaxGroup] = useState([]) 
    const [requester, setRequester] = useState([])
    const [ordered, setOrdered] = useState([])
    const [vendorsDropdown, setVendorsDropdown] = useState([])
    const [vendors, setVendors] = useState([])
    const [unit, setUnit] = useState([])
    const [type, setType] = useState([])
    const [sites, setSites] = useState([])
    const [entities, setEntities] = useState([])

    let purchase_order_source_id = 12
  //End

  //Fields Validation

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [
        {name:'vendor_id', displayName:Helper.getLabel(props.language,'vendor_id','Vendor'),types:['Required']},
        {name:'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse_id','Warehouse'),types:['Required']},       
        {name:'accounting_date', displayName:Helper.getLabel(props.language,'accounting_date','Accounting Date'),types:['Required'] }, 
        {name:'document_date', displayName:Helper.getLabel(props.language,'document_date','Document Date'),types:['Required'] }, 
        {name:'entity_id', displayName:Helper.getLabel(props.language,'entity','Entity'),types:['Required']}, 
        {name:'currency_id', displayName:Helper.getLabel(props.language,'currency_id','Currency '),types:['Required'] }, 
        {name:'site_id', displayName:Helper.getLabel(props.language,'site_id','Site '),types:['Required'] },
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()

  //End
 
  let navs = [
    {title:Helper.getLabel(props.language,'general','General'), isActive: true, },
    {title:Helper.getLabel(props.language,'delivery','Delivery')}, 
    {title:Helper.getLabel(props.language,'price_discount','Price & Discount')},
    {title:Helper.getLabel(props.language,'segment','Segment')},   
  ];
    
  const addComponentTop = () => {
    let items = [
      {title:Helper.getLabel(props.language,'document_number',"Document No."), subtitle: editPO.document_number ? editPO.document_number : ''},
      {title:Helper.getLabel(props.language,'document_type',"Document Type"), subtitle:  !ValidateData(type) ? (type.find(id => id.label == "Purchase Order") === undefined ? '' : type.find(id => id.label == "Purchase Order").label) : '' },
      {title:Helper.getLabel(props.language,'vendor_id',"Vendor ID"), subtitle: editPO.vendor_id ? editPO.vendor_id : ''},
      {title:Helper.getLabel(props.language,'vendorname',"Vendor Name"), subtitle: !ValidateData(vendors) && editPO.vendor_id ? (vendors.find(id => id.value == editPO.vendor_id) === undefined ? 'Vendor Not Exist!' : vendors.find(id => id.value == editPO.vendor_id).label) : '' },
    ]
    let tags = [
        {title:Helper.getLabel(props.language,'confirm','Confirmed')},
    ]
 
    return(
      <Fragment>
        <BlueCallToAction items={items} tags={tags}/> 
        <SwitchView setObjectClear={setListViewTabsObj} setViewComponent={setViewComponent} viewComponent={viewComponent} />
      </Fragment>
    )
  }
  
  const addComponentRight = () => {
    <MasterSidebar>
      <OrderStatus />
      <NoteAttachmentHistory source_id ={purchase_order_source_id} notesource_integrator= {null}  attachmentsource_integrator ={null}/> 
    </MasterSidebar>
  }
 
  //API Calls Renderer
   useEffect(() => {
    getPurchaseOrders()
    getPurchaseOrdersLines()
    TypeAPI()
    VendorsAPI() 
    TaxCodeAPI()
    TaxGroupAPI() 
    SitesAPI()
    EntityAPI()
    RequesterAPI()
    OrderedAPI()
    getUnitData()
   }, [])
 
 
  //End

  //API Reduction Calls
    let taxCodeCalled = false
    let taxGroupCalled = false 
    let POCalled = false
    let POLinesCalled = false
    let typeCalled = false
    let vendorsCalled = false
    let RequesterCalled = false
    let entityCalled = false
    let sitesCalled = false
    let OrderedCalled = false
    let unitCalled = false
  //End

  //API Calls

  const getPurchaseOrders = () => {
    if(POCalled){
      return;
    }
    setLoading(true)
    let api = Api
    POCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_header/${id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setLoading(false)
        setEditPO(res.data.data)
        if(res.data.data.document_number == null){
          isDisable = false
        }
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setLoading(false)
    })
  }

  const getPurchaseOrdersLines = () => {
    if(POLinesCalled){
      return;
    } 
    setPOLinesLoading(true)
    let api = Api
    POLinesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_lines/${id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setEditPOLinesGrid(res.data.data.reverse())
        setPOLinesLoading(false)
        setPreviousPurchaseOrderLinesData(Object.freeze(res.data.data))
        if(defaultGridData === null){
            Helper.alert("Default Data Chaning",'error')
            defaultGridData = res.data.data   
        } 
        if(!DefStateData){ 
          DefStateData = {};
          res.data.data.forEach((element) => {
            DefStateData[element.line_id] = element 
          })
        }
          
      }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error') 
      setPOLinesLoading(false)
      // console.log(res.response.data.message)
    })
  }
  
  const RequesterAPI = () => {
    if(RequesterCalled) {
      return;
    }
      let api = Api
      RequesterCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let Requester = []
        res.data.data.forEach(item => {  
        Requester.push({
          value: item.employee_id,
          label: `${item.code} - ${item.displayname}`
        })
      }) 
      setRequester(Requester)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const OrderedAPI = () => {
    if(OrderedCalled) {
      return;
    }
      let api = Api
      OrderedCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let OrderedARR = []
        res.data.data.forEach(item => {  
        OrderedARR.push({
          value: item.employee_id,
          label: `${item.code} - ${item.displayname}`
        })
      }) 
      setOrdered(OrderedARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const getUnitData = () => {
    if(unitCalled){
        return;
    } 
    let api = Api
    unitCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
    if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
            UPDATED_STATE.push({
                value: element.unit_id,
                label:`${element.code} - ${element.description}`
            })
        })
        setUnit(UPDATED_STATE)
    }
    }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error') 
    })
}
 
  const TaxCodeAPI = () => {
    if(taxCodeCalled) {
      return;
    }
      let api = Api
      taxCodeCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/taxcodes',{}).then(function(res){
      if(res.data.status==="success"){
        let TaxCode = []
        res.data.data.forEach(item => {  
        TaxCode.push({
          value: item.taxcodes_id,
          label: `${item.code} - ${item.name}`
        })
      }) 
      setTaxCode(TaxCode)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const TaxGroupAPI = () => {
    if(taxGroupCalled) {
      return;
    }
      let api = Api
      taxGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
      if(res.data.status==="success"){
        let TaxGroup = []
        res.data.data.forEach(item => {  
        TaxGroup.push({
          value: item.taxgroup_id,
          label: item.name
        })
      }) 
      setTaxGroup(TaxGroup)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const VendorsAPI = () => {
    if(vendorsCalled) {
      return;
    }
      let api = Api
      vendorsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
      if(res.data.status==="success"){
        let VendorsDropdownARR = []
        let VendorsARR = []
        res.data.data.forEach(item => {  
        VendorsDropdownARR.push({
          value: item.vendor_id,
          label: `${item.code} - ${item.name}`
        })
        VendorsARR.push({ 
          value: item.vendor_id,
          label: item.name
        })
      }) 
      setVendorsDropdown(VendorsDropdownARR)
      setVendors(VendorsARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  const EntityAPI = () => {
    if(entityCalled){
        return;
    }
    let api = Api
    entityCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+'/search_entity',{
        "code":"",
        "name":""
    }).then(function(res){
      if(res.data.status==="success"){
        let EntityARR = []
        res.data.data.forEach(item => {  
        EntityARR.push({
          value: item.entity_id,
          label: `${item.code} - ${item.name}`
        })
       }) 
       setEntities(EntityARR)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const SitesAPI = () => {
    if(sitesCalled){
        return;
    }
    let api = Api
    sitesCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+'/get_site',{
        "code":"",
        "name":""
    }).then(function(res){
      if(res.data.status==="success"){
        let SitesARR = []
        res.data.data.forEach(item => {  
        SitesARR.push({
          value: item.site_id,
          label: `${item.code} - ${item.name}`
        })
      }) 
      setSites(SitesARR)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const TypeAPI = () => {
    if(typeCalled){
        return;
    }
    let api = Api
    typeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/POHeaderType',{}).then(function(res){
      if(res.data.status==="success"){
        let TypeARR = []
        res.data.data.forEach(item => {  
        TypeARR.push({
          value: item.id,
          label: item.name
        })
       }) 
       setType(TypeARR) 
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
}

  //End

 // Header View Tab
  const HeaderViewSegment = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'segment','Segment')}>
        <SegmentsDistribution  id='purchase_order_header_segments' distribution_source_id={editPO.distributionsource_id} totalAmount={20000} integrator={id} source_id={purchase_order_source_id} />
      </Collapse>  
    )
  }
 
  //End

  //Functions
  const getUpdatedData = ( dftData ,allDataWithUpdated) => { 
    // let dftData = defaultGridData 
    console.log(allDataWithUpdated, 'updated data')
    console.log(dftData, 'default data') 
      let output = [];
      allDataWithUpdated.forEach(item => {
          let tempItem = null;
          dftData.forEach(dftItem => {
              if(item.line_id == dftItem.line_id){
                  tempItem = dftItem;
              } 
          })
          if(!tempItem){
              output.push(item)
          }else{
              let isDataUpdated = false;
              for(let keyName in tempItem){
                  if(item[keyName] != tempItem[keyName]){
                      isDataUpdated =true;
                  }
              }
              if(isDataUpdated){
                  output.push(item)
              }
          }
      });
      return output;
  }
  

  // const compareArrays = ( otherArray) => { 
    // return
    // console.log('Default Data', defaultGridData)
    // console.log('Grid Data', otherArray)
    // let updatedItems = [];

    // otherArray.forEach( (otherArrayItem) => {
    //     let dataItem = null;
    //     defaultGridData.forEach( itemTest => {
    //       if(itemTest.line_id == otherArrayItem.line_id){
    //         dataItem = otherArrayItem;
    //       }
    //     })
        //defaultGridData.find( item => { return otherArrayItem.line_id == item.line_id ? item : null } )
    //     console.log(dataItem)
    //     if(!dataItem){
    //       updatedItems.push(otherArrayItem)
    //     }else{
    //       if(JSON.stringify(otherArrayItem) != JSON.stringify(dataItem) ){
    //         updatedItems.push(otherArrayItem)
    //       }
    //     }
    // }  )
    // console.log('Data that only updated',updatedItems)
  
    // mainArray = defaultGridData
  //   const updateObjectArray = [];
  //   const newObjectArray = [];
  //   otherArray.forEach(otherArrayItem => {
  //     const mainArrayItem = defaultGridData.find(item => {return item.line_id == otherArrayItem.line_id });
  //     if (!mainArrayItem) { 
  //       // Object is new
  //       newObjectArray.push(otherArrayItem);
  //     } else {
  //       // Object is found, check if its properties values are different
  //       // updateObjectArray.push(otherArrayItem)
  //       getUpdatedData()
  //       let isDifferent = false;

  //       Object.keys(otherArrayItem).forEach(key => {
  //         if (otherArrayItem[key] != mainArrayItem[key]) {
  //           isDifferent = true;
  //           return;
  //         }
  //       })
  //       if (isDifferent) {
  //         updateObjectArray.push(otherArrayItem);
  //       }
  //     }
  //   })
  //   console.log(updateObjectArray);
  //   return;
  //   if(updateObjectArray.length >= 1){
  //     ExecuteAPICallForListViewGridUpdate(updateObjectArray)
  //   }

  //   if(newObjectArray.length >= 1){
  //     ExecuteAPICallForListViewGridAdd(newObjectArray)
  //   } 
  // }

  function checkArrayValues(array) {
    for (const obj of array) {
      if (obj.advance === "on" && (!obj.payment_advance || obj.payment_advance === 0)) {
        Helper.alert('Advance Payment Is Missing!', 'error')
        return;
      }
    }
  }

  //End

  //API Executions UPDATE Requests

  const UpdatePurchaseOrderHeader = () => {
    setLoading(true)
    let api = Api 
    api.setUserToken()
    api.axios().put(Settings.apiPurchaseOrderUrl+`/PO_header/${id}`,{ 
        "type": !ValidateArray(type) ? type.find(id => id.label == "Purchase Order").value : null,
        "document_number": editPO.document_number,
        "description": editPO.description !== "" ? editPO.description : null,
        "vendor_id": editPO.vendor_id ? parseInt(editPO.vendor_id) : null,
        "vendorsite_id": editPO.vendorsite_id ? parseInt(editPO.vendorsite_id) : null,
        "vendorgroup_id": editPO.vendorgroup_id ? parseInt(editPO.vendorgroup_id) : null,
        "purchase_status": editPO.purchase_status,
        "document_status": editPO.document_status,
        "accounting_date": Helper.getDateForDb(editPO.accounting_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(editPO.accounting_date) : null,
        "document_date": Helper.getDateForDb(editPO.document_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(editPO.document_date) : null,
        "delivery_date": Helper.getDateForDb(editPO.delivery_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(editPO.delivery_date) : null,
        "period_id": editPO.period_id ? parseInt(editPO.period_id) : null,
        "delivery_address_id": editPO.delivery_address_id,
        "delivery_name": editPO.delivery_name !== "" ? editPO.delivery_name : null,
        "attention_information": editPO.attention_information !== "" ? editPO.attention_information : null,
        "mode_of_delivery_id": editPO.mode_of_delivery_id ? parseInt(editPO.mode_of_delivery_id) : null,
        "delivery_term_id": editPO.delivery_term_id ? parseInt(editPO.delivery_term_id) : null,
        "discount_amount": editPO.discount_amount !== "" ? editPO.discount_amount : null,
        "discount_percentage": editPO.discount_percentage !== "" ? editPO.discount_percentage : null,
        "taxgroup_id": editPO.taxgroup_id ? parseInt(editPO.taxgroup_id) : null,
        "taxcodes_id": editPO.taxcodes_id ? parseInt(editPO.taxcodes_id) : null,
        "priceinclude_tax": editPO.priceinclude_tax == "on" ? (editPO.priceinclude_tax == "on" ? true : false) : editPO.priceinclude_tax,
        "requester_id": editPO.requester_id ? parseInt(editPO.requester_id) : null,
        "orderer_id": editPO.orderer_id ? parseInt(editPO.orderer_id) : null,
        "currency_id": editPO.currency_id ? parseInt(editPO.currency_id) : null,
        "emailaddress": editPO.emailaddress !== "" ? editPO.emailaddress : null,
        "entity_id": editPO.entity_id ? parseInt(editPO.entity_id) : null,
        "site_id": editPO.site_id ? parseInt(editPO.site_id) : null,
        "buyergroup_id": editPO.buyergroup_id ? parseInt(editPO.buyergroup_id) : null,
        "paymentterm_id": editPO.paymentterm_id ? parseInt(editPO.paymentterm_id) : null,
        "distributionsource_id": editPO.distributionsource_id ,
        "blindreceivingof_qty": editPO.blindreceivingofqty == "on" ? (editPO.blindreceivingofqty == "on" ? true : false) : editPO.blindreceivingofqty,
        "blindreceivingof_item": null,
        "source_id": null,
        "externalreference": editPO.externalreference !== "" ? editPO.externalreference : null,
        "attachmentsource_id": null,
        "notesource_id": null,
        "document_number": editPO.document_number !== "" ? editPO.document_number : null,
        "contactsource_id": null,
        "allow_access_only_to_buyer_group": editPO.allowaccesstobuyergroup == "on" ? (editPO.allowaccesstobuyergroup == "on" ? true : false) : editPO.allowaccesstobuyergroup
    }).then(function(res){
    if(res.data.status==="success"){
      Helper.alert(res.data.message)
      setLoading(false)
      getPurchaseOrders()
      if(editPO.document_number !== null){
        isDisable = true
      }
    }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setLoading(false)
      getPurchaseOrders()
    }) 
  }

  //END


  const ExecuteAPICallForListViewGridAdd = (data) => {    
    let api = Api
        api.setUserToken()  
          api.axios().post(Settings.apiPurchaseOrderUrl+'/PO_lines',{
            "purchaseorder_id": parseInt(id), 
            "purchase_status":editPO.purchase_status,
            "document_status":editPO.document_status,
            "line_type":data.lines_type ? data.lines_type : null,
            "item_id":data.item_id ? parseInt(data.item_id) : null,
            "barcode": data.barcode ? data.barcode : null,
            "category_id": data.category_id ? parseInt(data.category_id) : null,
            "description": data.description ? data.description : null,
            "comments": data.comments ? data.comments : null,
            "itemgroup_id": data.itemgroup_id ? parseInt(data.itemgroup_id) : null,
            "ordered_qty": data.ordered_qty ? parseFloat(data.ordered_qty) : null,
            "unit": data.unit ? parseInt(data.unit) : null,
            "unit_price": data.unit_price ? parseFloat(data.unit_price) : null,
            "discount_amount": data.discount_amount ? parseFloat(data.discount_amount) : null,
            "discount_percentage": data.discount_percentage ? parseFloat(data.discount_percentage) : null,
            "line_amount": data.line_amount ? parseFloat(data.line_amount) : null,
            "taxgroup_id":data.taxgroup_id ? parseInt(data.taxgroup_id) : null,
            "taxcodes_id": data.taxcodes_id ? parseInt(data.taxcodes_id) : null,
            "external_reference": data.external_reference ? data.external_reference : null,
            "ordered_id": data.orderer_id ? parseInt(data.orderer_id) : null,
            "requester_id": data.requester_id ? parseInt(data.requester_id) : null,
            "advance_payment": data.advance_payment == 'on' ? true : false,
            "advance": data.advance ? parseFloat(data.advance) : null,
            "recoupment_rate":data.recoupment_rate ? data.recoupment_rate : null,
            "retainage_rate": data.retainage_rate ? data.retainage_rate : null,
            "max_retainage_amount": data.max_retainage_amount ? parseFloat(data.max_retainage_amount)  : null,
            "match_level":data.match_level ? parseInt(data.match_level) : null
        }).then(function(res){
            if(res.data.status==="success"){
              Helper.alert(res.data.message) 
              getPurchaseOrdersLines()
            } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          getPurchaseOrdersLines()
        }) 
  }

  const ExecuteAPICallForListViewGridUpdate = (data) => {   
    let api = Api
        api.setUserToken()   
        api.axios().put(Settings.apiPurchaseOrderUrl+`/PO_line/${data.line_id}`,{
            "purchaseorder_id": parseInt(id), 
            "purchase_status":editPO.purchase_status ? parseInt(editPO.purchase_status) : null,
            "document_status":editPO.document_status ? parseInt(editPO.document_status) : null,
            "line_type":data.lines_type ? data.lines_type : null,
            "item_id":data.item_id ? parseInt(data.item_id) : null,
            "barcode": data.barcode ? data.barcode : null,
            "category_id": data.category_id ? parseInt(data.category_id) : null,
            "description": data.description ? data.description : null,
            "comments": data.comments ? data.comments : null,
            "itemgroup_id": data.itemgroup_id ? parseInt(data.itemgroup_id) : null,
            "ordered_qty": data.ordered_qty ? parseFloat(data.ordered_qty) : null,
            "unit": data.unit ? parseInt(data.unit) : null,
            "unit_price": data.unit_price ? parseFloat(data.unit_price) : null,
            "discount_amount": data.discount_amount ? parseFloat(data.discount_amount) : null,
            "discount_percentage": data.discount_percentage ? parseFloat(data.discount_percentage) : null,
            "line_amount": data.line_amount ? parseFloat(data.line_amount) : null,
            "taxgroup_id":data.taxgroup_id ? parseInt(data.taxgroup_id) : null,
            "taxcodes_id": data.taxcodes_id ? parseInt(data.taxcodes_id) : null,
            "external_reference": data.external_reference ? data.external_reference : null,
            "ordered_id": data.orderer_id ? parseInt(data.orderer_id) : null,
            "requester_id": data.requester_id ? parseInt(data.requester_id) : null,
            "advance_payment": data.advance_payment == "on" ? true : false,
            "advance": data.advance ? parseFloat(data.advance) : null,
            "recoupment_rate":data.recoupment_rate ? data.recoupment_rate : null,
            "retainage_rate": data.retainage_rate ? data.retainage_rate : null,
            "max_retainage_amount": data.max_retainage_amount ? parseFloat(data.max_retainage_amount)  : null,
            "match_level":data.match_level ? parseInt(data.match_level) : null
        }).then(function(res){
            if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            getPurchaseOrdersLines()
            } 
        }).catch((res) => { 
            Helper.alert(res.response.data.message, 'error')
            getPurchaseOrdersLines()
        }) 
  }

 
  const SaveHandler = () => { 
    let validData = {
      ...editPO
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }

    // checkArrayValues(editPOLinesGrid)
 
    if(editPO.requester_id && editPO.orderer_id && editPO.requester_id === editPO.orderer_id ){
      Helper.alert('Orderer & Requester Cant Be Same!', 'error')
      return;
    } 

    $('.purchase_orders_lines_list_view_grid .rs_grid_btn_save').trigger('click');  
    console.log(dataFromGridAndInput)
    // let data = getUpdatedData(defaultGridData, editPOLinesGrid) 
    // data.forEach(element => {
    //   if(element.line_id){
    //     ExecuteAPICallForListViewGridUpdate(element)
    //   }else {
    //     ExecuteAPICallForListViewGridAdd(element)
    //   }
    // }) 
    $('.purchase_orders_lines_list_view_billing_rule_grid .rs_grid_btn_save').trigger('click');  
    // $('.purchase_orders_lines_list_view_distribution_general_grid .rs_grid_btn_save').trigger('click'); 
    // UpdatePurchaseOrderHeader()
    // defaultGridData = editPOLinesGrid
  }
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }
  
  return (
    <>
    <div className='container-fluid'>
      <NavigationHeder title={ `<span style="color:#000;">${Helper.getLabel(props.language,'edit_po',"Edit Purchase Order")} #</span> ${id}` }>
        <LinkBtn isActive= {false} to="/purchase-orders" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canCreate(frontendIds.procure.purchase_orders.purchase_orders_edit_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
    </div>
    {loading ? <SimpleLoading /> : ''} 
    <section className={viewComponent === 'Header View' ? 'header_view_class' : 'list_view_class'}>
      <Tabs navs={navs} tabContantsId='purchase-orders-tabs' showAll={true} scrollOffset="300" addComponentTop = {addComponentTop} addComponentRight={addComponentRight} >
        {viewComponent == "Header View" ? <div></div> : <POListView/>} 
        {
          /*
          viewComponent == "Header View" ? <HeaderViewGeneral sites={sites} entities={entities} state={editPO} setState={setEditPO} requesterAPI={requester} orderedAPI={ordered} taxCode={taxCode} isDisable={isDisable} vendorsDropdown={vendorsDropdown} taxGroup={taxGroup} /> : <ListViewGrid datafromGrid={dataFromGridAndInput} DefStateData={DefStateData} purchaseOrderObj={editPO} setPurchaseOrderObj={setEditPO} puchaseOrderId={id} listTabsGridObject={listViewTabsGridObj} setGridObject={setListViewTabsGridObj} loadingState={poLinesLoading} reloadAPI={getPurchaseOrdersLines} state={editPOLinesGrid} updateLineId={setLineSiteId} updateListViewTabs={setListViewTabsObj} currentListViewTabs={listViewTabsObj} setState={setEditPOLinesGrid} units={unit} />
        */
        } 
        {viewComponent == "Header View" ? <HeaderViewDelivery state={editPO} setState={setEditPO} /> : <ListViewTabs datafromInput={dataFromGridAndInput} purchaseOrderObj={editPO} listTabsGridObject={listViewTabsGridObj} sites={sites} entities={entities} units={unit} requester={requester} ordered={ordered} taxGroups={taxGroup} taxCodes={taxCode} lineSiteId={lineSiteId} setLineSiteId={setLineSiteId} setTabsObj={setListViewTabsObj} tabsObj={listViewTabsObj} setGridDataState={setEditPOLinesGrid} />} 
        {viewComponent == "Header View" ? <HeaderViewPriceDiscount sites={sites} entities={entities} state={editPO} setState={setEditPO} /> : <div></div>}
        {viewComponent == "Header View" ? HeaderViewSegment() : <div></div>}
      </Tabs>
    </section>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders_edit_screen
}
export default connect(mapStateToProps) ( MasterComponentWraper(ApplySecurityRoles(EditPO, SecurityOptions)) )

