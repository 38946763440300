import React, { Fragment, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import MasterComonent from '../../Backend/MasterComonent';
import AgGrid from '../../grid/ag/ag-grid';
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell';
import Alert from '../../inc/Alert';
import $ from 'jquery'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Button from '../../inc/Button';
import ButtonNew from '../../inc/ButtonNew';
import SimpleLoading from '../../Loading/SimpleLoading';
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';

const DeliveryArchive = (props) => {

    const [deliveryTerms, setDeliveryTerms] = useState([]) 
    const navigate = useNavigate() 
    const [loading, setLoading] = useState(true)

    let deliveryTermsCalled = false
    
    useEffect(() => {
      get_delivery_terms()
    }, [])

    const get_delivery_terms = () => {
      if(deliveryTermsCalled) {
        return;
      }
        let api = Api
        deliveryTermsCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+'/delivery_term',{}).then(function(res){
          if(res.data.status==='success'){   
            setDeliveryTerms(res.data.data.reverse())
            setLoading(false)
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          setLoading(false)
        })
    }
       
    const DeleteDelivery = (allRows) => {
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiPurchaseOrderUrl+`/delivery_term/${element.delivery_term_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            get_delivery_terms()
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          get_delivery_terms()
        })
      })
    }
   
    const SaveHandler = (rowItem) => {
      let updatedData = []
      let newData=[];
      rowItem.forEach(item => {
          if( !item.delivery_term_id || item.delivery_term_id <=0 ){
            newData.push(item);
          }else{
            updatedData.push(item);
          }
      });
      if(newData.length>=1){ 
          addNewData(newData);
      }
      if(updatedData.length>=1){ 
          updateData(updatedData);
      }
    }

    const addNewData = (data) => { 
      let api = Api
      api.setUserToken()  
      data.forEach(element => { 
        api.axios().post(Settings.apiPurchaseOrderUrl+'/delivery_term',{
          "name": element.name
        }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            get_delivery_terms()
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        get_delivery_terms()
      })
     }) 
    }

    const updateData = (data) => {
      let api = Api
      api.setUserToken()
      data.forEach(element => {  
        api.axios().put(Settings.apiPurchaseOrderUrl+`/delivery_term/${element.delivery_term_id}`,{
          "name": element.name
      }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            get_delivery_terms()
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          get_delivery_terms()
        })
      })
    } 

    const Columns_Headings = [
      {field:'name', headerName: Helper.getLabel(props.language,'name','Name')}, 
    ]

    const FormDataHandler = () => {
      $('.rs_delivery_term .rs_grid_btn_save').trigger('click'); 
    }
    
    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.delivery_term.delivery_term)){
      return <Fragment> 
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }

  return ( 
      <div className='delivery_term_archive'>
        <div className='container-fluid'>
            <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'delivery_term','Delivery Terms')}>
              <Button onClick={FormDataHandler} isActive={true} icon='/images/icons/save-white.svg' isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.delivery_term.delivery_term_save_button)} title={Helper.getLabel(props.language,'save','Save')} />
            </NavigationHeder>
              {loading ? <SimpleLoading /> : null}
              <div className='rs_delivery_term'>
                <AgGrid onSave={SaveHandler} id="rs_delivery_term_list" hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.delivery_term.delivery_term_delete_button)} onDelete={DeleteDelivery} header={Columns_Headings} data={deliveryTerms} addBtnHide={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.delivery_term.delivery_term_create_button)}/>
              </div> 
          </div>
      </div> 
  )
}
 
  const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.control_forms.delivery_term.delivery_term
  }
  
  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(DeliveryArchive, SecurityOptions))))