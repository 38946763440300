class User {
    constructor(auth){
        this.auth = auth;
    }
    isExernalUser(){
        if(this.auth.user.usertype === 1){
            return true;
        }
        return false;
    }
    isSupperAdmin(){
        if(this.auth.isLogin){
            let authUser = this.auth.user;
            if(authUser.user_name=='abcd@gmail.com'){
                return true;
            }
            return false;
        }
        return false;
    }
    isVendorFormRequired(){
        return true;
        if(this.isExernalUser() && !this.auth.user.employee_id){
            return true;
        }
        return false;
    }
}
export default User;