import React, { Component } from 'react';
import Button from '../../inc/Button';
import TextTranslationPopup from './TextTranslationPopup';

class TextTranslations extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpenPopup:false
        }
    }
    openPopup(e){
        this.setState({
            isOpenPopup:true
        })
    }
    onCloseHandler(e){
        this.setState({
            isOpenPopup:false
        })
    }
    displyButton(){
        if(!this.props.source_integrator || !this.props.source_id || this.props.isDisable){
            return <Button icon="/images/icons/translator.svg" className="text_trnslation_btn disable"/>
        }
        return <Button icon="/images/icons/translator.svg" className="text_trnslation_btn" onClick = { this.openPopup.bind(this)}/>
    }
    render() {
        return (
            <>
                <div className='rext_translation_btn'>
                    { this.displyButton() }
                </div>
                { this.state.isOpenPopup ? <TextTranslationPopup {...this.props} onClose={this.onCloseHandler.bind(this)}/> : ''}
            </>
            
        );
    }

}

export default TextTranslations;