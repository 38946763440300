import React, {useState, useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../inc/Gui_id_list'
import MasterComonent from '../Backend/MasterComonent'
import Input from '../Forms/Input'
import DropDownInput from '../../components/Forms/Dropdown';
import Button from '../inc/Button'
import Collapse from '../inc/Collapse'
import LinkBtn from '../inc/LinkBtn'
import SimpleLoading from '../Loading/SimpleLoading'
import NavigationHeder from '../Navigations/NavigationHeder'
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles'
import Tabs from '../Widgets/Tabs/Tabs'
import Checkbox from '../Forms/Checkbox'
import AgGrid from '../grid/ag/ag-grid'
import $ from 'jquery'
import Alert from '../inc/Alert'
import Settings from '../../inc/Settings'
import Api from '../../inc/Api'
import { useParams } from 'react-router-dom'
import Helper from '../../inc/Helper'
import { ValidateData } from '../../inc/Validation'
import MasterComponentWraper from '../Backend/MasterComponentWraper'

const ParametersEdit = (props) => {

  const {id} = useParams()
  const [numberSequenceGrid, setNumberSequenceGrid] = useState([])
  const [teamHeaderGrid, setTeamHeaderGrid] = useState([])
  const [getSettings, setGetSettings] = useState({})
  const [loading, setLoading] = useState(false)
  const [entityLoading, setEntityLoading] = useState(false)
  const [nsLoading, setNsLoading] = useState(false)
  const [teamHeaderLoading, setTeamHeaderLoading] = useState(false)
  const [teamHeaderList, setTeamHeaderList] = useState([])
  const [entityArr, setEntityArr] = useState([]) 
  const [deleteEntityArr, setDeleteEntityArr] = useState([])
  const [entityNS ,setEntityNS] = useState(null) 

  //Dropdowns State

  const [modeOfDelivery, setModeOfDelivery] = useState([])
  const [deliveryItem, setDeliveryItem] = useState([])
  const [taxGroup, setTaxGroup] = useState([])
  const [itemTaxCode, setItemTaxCode] = useState([])
  const [defaultDistribution, setDefaultDistribution] = useState([])
  const [procurementCategory, setProcurementCategory] = useState([])
  const [document, setDocument] = useState([])
  const [numberSequence, setNumberSequence] = useState([])
  const [matchLevel, setMatchLevel] = useState([])
  const [receiptAction, setReceiptAction] = useState([])
  const [receiptNumber, setReceiptNumber] = useState([])
  const [entityList, setEntityList] = useState([])

  //End

  // API Calls Reduction
  let settingsCalled= false
  let NSCalled= false
  let TeamHeaderCalled = false
  let TeamHeaderListCalled = false
  let modeOfDeliveryCalled= false
  let deliveryItemCalled= false
  let taxGroupCalled= false
  let itemTaxCodeCalled= false
  let defaultDistributionCalled= false
  let procurementCategoryCalled= false
  let documentCalled= false
  let numberSequenceCalled= false 
  let matchLevelCalled= false 
  let receiptActionCalled= false 
  let receiptNumberCalled= false 
  let entityListCalled= false
  //End

  // API Calls on-Renders/Loadings
  useEffect(() => {
      ModeOfDeliveryHandler()
      DeliveryItemHandler()
      TaxGroupHandler()
      ItemTaxCodeHandler()
      NumberSequenceHandler()
      DocumentHandler()
      MatchLevelHandler()
      ReceiptActionHandler()
      CheckProductReceiptNumberHandler()
      ProcurementCategoryHandler()
      getEntitiesList()
      getProcureSettings() 
      getTeamHeaderSettings()
      TeamHeaderAPI()
  }, [])

  useEffect(() => {
    getNSSettings()
  }, [entityNS])
 
  //End

  // GET Calls

  const getProcureSettings = () => {
      if(settingsCalled){
          return;
      }
      let api = Api
      settingsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiProcurementSettingsUrl+`/procurementsetting/${id}`,{}).then(function(res){
        if(res.data.status==="success"){ 
          setGetSettings(res.data.data)
        } 
    }) 
  }
   
  const getNSSettings = () => {
    if(NSCalled){
        return;
    }
    setNsLoading(true)
    let api = Api
    NSCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiProcurementSettingsUrl+`/numberingsequence/${parseInt(entityNS)}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setNumberSequenceGrid(res.data.data)
        setNsLoading(false)
      } 
  }).catch((err) => {
    setNsLoading(false)
  })
}

const getTeamHeaderSettings = () => {
  if(TeamHeaderCalled){
      return;
  }
  setTeamHeaderLoading(true)
  let api = Api
  TeamHeaderCalled = true
  api.setUserToken()
  api.axios().get(Settings.apiProcurementSettingsUrl+`/procurementteamheader/${id}`,{}).then(function(res){
    if(res.data.status==="success"){ 
      setTeamHeaderGrid(res.data.data)
      setTeamHeaderLoading(false)
    } 
}).catch((err) => {
  setTeamHeaderLoading(false)
})
}

const TeamHeaderAPI = () => {
  if(TeamHeaderListCalled) {
    return;
  }
  let api = Api
  TeamHeaderListCalled = true
  api.setUserToken()
  api.axios().get(Settings.apiUrl+'/teamheader',{}).then(function(res){
  if(res.data.status==="success"){
    let teamHeaderARR = []
    res.data.data.forEach(item => {  
    teamHeaderARR.push({
      value: item.teamheader_id,
      label: item.name
    })
   }) 
   setTeamHeaderList(teamHeaderARR)
  } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
}

  //End

  //Dropdowns Functions

  const getEntitiesList = () => {
    if(entityListCalled){
     return;
    }
    let api = Api
    setEntityLoading(true)
    entityListCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+'/search_entity',{
        "code":"",
        "name":""
    }).then(function(res){
      if(res.data.status==='success'){   
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
            UPDATED_STATE.push({
            value: element.entity_id,
            label: element.name
        })
        })
        setEntityLoading(false)
        setEntityList(UPDATED_STATE)
      } 
  }).catch((err) => {
    setEntityLoading(false)
  })
}

  const ModeOfDeliveryHandler = () => {
      if(modeOfDeliveryCalled){
          return;
      }
      let api = Api
      modeOfDeliveryCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiPurchaseOrderUrl+'/mode_of_delivery',{}).then(function(res){
        if(res.data.status==="success"){
          let modeOfDeliveryARR = []
          res.data.data.forEach(item => {  
          modeOfDeliveryARR.push({
            value: item.mode_of_delivery_id,
            label: item.name
          })
         }) 
         setModeOfDelivery(modeOfDeliveryARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const DeliveryItemHandler = () => {
      if(deliveryItemCalled){
          return;
      }
      let api = Api
      deliveryItemCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiPurchaseOrderUrl+'/delivery_term',{}).then(function(res){
        if(res.data.status==="success"){
          let DeliveryItemARR = []
          res.data.data.forEach(item => {  
          DeliveryItemARR.push({
            value: item.delivery_term_id,
            label: item.name
          })
         }) 
         setDeliveryItem(DeliveryItemARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const TaxGroupHandler = () => {
      if(taxGroupCalled){
          return;
      }
      let api = Api
      taxGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
        if(res.data.status==="success"){
          let taxGroupARR = []
          res.data.data.forEach(item => {  
          taxGroupARR.push({
            value: item.taxgroup_id,
            label: item.name
          })
         }) 
         setTaxGroup(taxGroupARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const ItemTaxCodeHandler = () => {
      if(itemTaxCodeCalled) {
          return;
      }
      let api = Api
      itemTaxCodeCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/taxcodes',{}).then(function(res){
        if(res.data.status==="success"){
          let itemTaxCodes = []
          res.data.data.forEach(item => {  
          itemTaxCodes.push({
            value: item.taxcodes_id,
            label: item.name
          })
         }) 
         setItemTaxCode(itemTaxCodes)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const DefaultDistributionHandler = () => {
      if(defaultDistributionCalled){
        return;
      }
      let api = Api
      defaultDistributionCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrls.segments+'/segmentdistribution',{}).then(function(res){
        if(res.data.status==="success"){
          let distributionARR = []
          res.data.data.forEach(item => {  
          distributionARR.push({
            value: item.distributionsource_id,
            label: item.name
          })
         }) 
         setDefaultDistribution(distributionARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const ProcurementCategoryHandler = () => {
      if(procurementCategoryCalled){
          return;
      }
      let api = Api
      procurementCategoryCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/categories',{}).then(function(res){
        if(res.data.status==="success"){
          let procurementARR = []
          res.data.data.forEach(item => {  
          procurementARR.push({
            value: item.category_id,
            label: item.name
          })
         }) 
         setProcurementCategory(procurementARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const DocumentHandler = () => {
      if(documentCalled){
          return;
      }
      let api = Api
      documentCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/source',{}).then(function(res){
        if(res.data.status==="success"){
          let documentsARR = []
          res.data.data.forEach(item => {  
          documentsARR.push({
            value: item.source_id,
            label: item.source_name
          })
         }) 
         setDocument(documentsARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const NumberSequenceHandler = () => {
      if(numberSequenceCalled) {
          return;
      }
      let api = Api
      numberSequenceCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/numbersequence',{}).then(function(res){
        if(res.data.status==="success"){
          let numberSeqARR = []
          res.data.data.forEach(item => {  
          numberSeqARR.push({
            value: item.sequencenumber_id,
            label: item.name
          })
         }) 
         setNumberSequence(numberSeqARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const MatchLevelHandler = () => {
      if(matchLevelCalled) {
          return;
      }
      let api = Api
      matchLevelCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/enum/matchlevel',{}).then(function(res){
        if(res.data.status==="success"){
          let matchLevelDATA = []
          res.data.data.forEach(item => {  
          matchLevelDATA.push({
            value: item.id,
            label: item.name
          })
         }) 
         setMatchLevel(matchLevelDATA)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const ReceiptActionHandler = () => {
      if(receiptActionCalled) {
          return;
      }
      let api = Api
      receiptActionCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/enum/overreceiptaction',{}).then(function(res){
        if(res.data.status==="success"){
          let receiptActionARR = []
          res.data.data.forEach(item => {  
          receiptActionARR.push({
            value: item.id,
            label: item.name
          })
         }) 
         setReceiptAction(receiptActionARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const CheckProductReceiptNumberHandler = () => {
      if(receiptNumberCalled) {
          return;
      }
      let api = Api
      receiptNumberCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/enum/checkproductreceiptnumber',{}).then(function(res){
        if(res.data.status==="success"){
          let receiptNumberARR = []
          res.data.data.forEach(item => {  
          receiptNumberARR.push({
            value: item.id,
            label: item.name
          })
         }) 
         setReceiptNumber(receiptNumberARR)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

//End


//STATES Dropdowns
const NameHandler = (event) => {
let name = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    name: name
  }
})
}

const DeliveryModeHandler = (event) => {
let mode_devl = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    mode_of_delivery_id:  mode_devl 
  }
})
}

const DeliveryTermHandler = (event) => {
let term_devr = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    delivery_term_id: term_devr
  }
})
}

const TaxGroupChangeHandler = (event) => {
let tax_id = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    taxgroup_id:  tax_id
  }
})
}

const TaxCodeHandler = (event) => {
let taxCode = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    tax_code_id: taxCode
  }
})
}

const DistributionHandler = (event) => {
let distribution = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    distributionsource_id: distribution 
  }
})
}

const ProcurementHandler = (event) => {
let procure = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    default_procurement_cateory: procure 
  }
})
}

const BlindQuantity = (name,value) => {
if(value == 'on'){
  setGetSettings((prevState) => {
    return {
      ...prevState,
      blind_receiving_of_Qty: true
    }
  })
}else {
  setGetSettings((prevState) => {
    return {
      ...prevState,
      blind_receiving_of_Qty: false
    }
  })
}
}

const BlindItems = (name, value) => {
if(value == 'on'){
  setGetSettings((prevState) => {
    return {
      ...prevState,
      blind_receiving_of_item:true
    }
  })
}else {
  setGetSettings((prevState) => {
    return {
      ...prevState,
      blind_receiving_of_item: false
    }
  })
}
}

const ActiveCharge = (name, value) => {
if(value == 'on'){
  setGetSettings((prevState) => {
    return {
      ...prevState,
      activate_change_management:true
    }
  })
}else {
  setGetSettings((prevState) => {
    return {
      ...prevState,
      activate_change_management: false
    }
  })
}
}

const AcceptUnderHandler = (name,value) => {
if(value == 'on'){
  setGetSettings((prevState) => {
    return {
      ...prevState,
      accept_under_delivery: true
    }
  })
}else {
  setGetSettings((prevState) => {
    return {
      ...prevState,
      accept_under_delivery: false
    }
  })
}
}

const AcceptOverHandler = (name,value) => {
if(value == 'on'){
  setGetSettings((prevState) => {
    return {
      ...prevState,
      accept_over_delivery: true
    }
  })
}else {
  setGetSettings((prevState) => {
    return {
      ...prevState,
      accept_over_delivery: false
    }
  })
}
}

const OverReceiptAction = (event) => {
let receiptAction = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    over_receipt_action:  receiptAction 
  }
})
}

const CheckProductNumberHandler = (event) => {
let productNum = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    check_product_receipt_number:  productNum 
  }
})
}

const MatchLevel = (event) => {
let matchLevel = event.target.value
setGetSettings((prevState) => {
  return {
    ...prevState,
    match_level: matchLevel 
  }
})
} 

const CheckBoxHandler = (enntityId, value) => {
  if(value == 'off'){
    deleteEntityArr.push(enntityId) 
     setEntityArr(entityArr.filter(id => {return enntityId == id ? null : id })) 
  } else {
    entityArr.push(enntityId) 
    setDeleteEntityArr( deleteEntityArr.filter(id => {
      if(enntityId == id){
        return false
      }else {
        return id
      }
    }))
  } 
}
const entityDropdownHandler = (event) => {
  setEntityNS(event.target.value) 
}

const DeleteAssignedEntity = (data) => { 
  let api = Api
  api.setUserToken()  
  data.forEach((element) => {
    api.axios().delete(Settings.apiProcurementSettingsUrl+`/numberingsequence/${element.record_id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)
        getNSSettings()
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
      getNSSettings()
    }) 
  })
}

const DeleteLinkedTeamHeader = (data) => {
  let api = Api
  api.setUserToken()  
  data.forEach((element) => {
    api.axios().delete(Settings.apiProcurementSettingsUrl+`/procurementteamheader/${id}/${element.teamheader_id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)
        getTeamHeaderSettings()
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
      getTeamHeaderSettings()
    }) 
  })
}

//End

const removeDuplicates = (arr) => {
let newArr = arr.filter((c, index) => {
  return arr.indexOf(c) === index;
});
return newArr;
}

const deleteEntitiesofSettings = () => {
  if(!ValidateData(deleteEntityArr)){  
    deleteEntityArr.forEach((element) => {
      let api = Api
      api.setUserToken()  
      api.axios().delete(Settings.apiProcurementSettingsUrl+`/procurementsetting_entity/${id}/${element}`,{}).then(function(res){
        if(res.data.status==="success"){ 
          console.log(res.data.message)
        }
      }).catch((res) => {  
        Helper.alert(res.response.data.message, 'error')
      }) 
    }) 
  }
}

const addEntitiesToSettings = () => {
  if(!ValidateData(entityArr)){
    let api = Api
    api.setUserToken()  
    api.axios().post(Settings.apiProcurementSettingsUrl+'/procurementsetting_entity',{
      "procurementsetting_id":id,
      "entity_ids":removeDuplicates(entityArr)
    }).then(function(res){
      if(res.data.status==="success"){ 
        console.log(res.data.message)
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
    }) 
  }
}

const SaveNS = (rowItem) => { 
  let updatedData = []
  let newData=[];
  rowItem.forEach(item => {
      if( !item.record_id || item.record_id <=0){
        newData.push(item);
      }else{
        updatedData.push(item);
      }
  });
  if(newData.length>=1){ 
      addNewDataNS(newData);
  }
  if(updatedData.length>=1){ 
      updateDataNS(updatedData);
  }
}

const addNewDataNS = (rowItem) => {
  rowItem.forEach((element) => {
    let api = Api
    api.setUserToken()  
    api.axios().post(Settings.apiProcurementSettingsUrl+'/numberingsequence',{
      "source_id": parseInt(element.source_id),
      "sequence_id": parseInt(element.sequence_id),
      "entity_id": entityNS 
  }).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)
        getNSSettings()
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
      getNSSettings()
    }) 
   }) 
}

const updateDataNS = (rowItem) => {
  rowItem.forEach((element) => {
    let api = Api
    api.setUserToken()  
    api.axios().put(Settings.apiProcurementSettingsUrl+`/numberingsequence/${element.record_id}`,{
      "source_id": parseInt(element.source_id),
      "sequence_id": parseInt(element.sequence_id),
      "entity_id": entityNS 
  }).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)
        getNSSettings()
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
      getNSSettings()
    }) 
   }) 
}

const SaveTeamHeader = (rowItem) => {
  let updatedData = []
  let newData=[];
  rowItem.forEach(item => {
      if( !item.tenant_id || item.tenant_id <=0){
        newData.push(item);
      }else{
        updatedData.push(item);
      }
  });
  if(newData.length>=1){ 
      addNewDataTeamHeader(newData);
  }
  if(updatedData.length>=1){ 
      updateDataTeamHeader(updatedData);
  }
}

const addNewDataTeamHeader = (rowItem) => {
  rowItem.forEach((element) => {
    let api = Api
    api.setUserToken()  
    api.axios().post(Settings.apiProcurementSettingsUrl+'/procurementteamheader',{
      "procurementsetting_id": id,
      "teamheader_id": parseInt(element.teamheader_id)
    }).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)
        getTeamHeaderSettings()
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
      getTeamHeaderSettings()
    }) 
   }) 
}

const updateDataTeamHeader = (rowItem) => {
  console.log(rowItem)
}


  const DefaultNavs = [
      {title:Helper.getLabel(props.language,'default','Default'), isActive: true, },
      {title:Helper.getLabel(props.language,'controls','Controls')},  
      {title:Helper.getLabel(props.language,'entity','Entity')},  
      {title:Helper.getLabel(props.language,'numbering_sequence','Numbering Sequence')},  
      {title:Helper.getLabel(props.language,'team_header','Team Header')}, 
  ]

  const Editable = (event) => {
    if(!event.data.tenant_id) {
     return true
   }else {  
     return false
   }
  }

  const Columns_Headings = [ 
      {field:'teamheader_id', editable: Editable, headerName:Helper.getLabel(props.language,'team_header','Team Header'), inputType: "dropdown", options: teamHeaderList}, 
    ]

  const Columns_Headings_NS = [
    {field:'source_id', headerName:Helper.getLabel(props.language,'document','Document'), inputType: "dropdown", options: document}, 
    {field:'sequence_id', headerName:Helper.getLabel(props.language,'number_sequence','Number Sequence'), inputType: "dropdown", options: numberSequence}, 
  ]  

  const SaveHandler = () => { 
    if(!getSettings.name){
      Helper.alert('Name field missing', 'error')
      return;
    }
    setLoading(true)
    let api = Api
    api.setUserToken()  
    api.axios().put(Settings.apiProcurementSettingsUrl+`/procurementsetting/${id}`,{
      "name": getSettings.name !== "" ? getSettings.name : null, 
      "mode_of_delivery_id": getSettings.mode_of_delivery_id == "0" ? 0 : parseInt(getSettings.mode_of_delivery_id),
      "delivery_term_id":getSettings.delivery_term_id == "0" ? 0 : parseInt(getSettings.delivery_term_id),
      "taxgroup_id":getSettings.taxgroup_id == "0" ? 0 : parseInt(getSettings.taxgroup_id),
      "taxcodes_id":getSettings.tax_code_id == "0" ? 0 : parseInt(getSettings.tax_code_id),
      "distributionsource_id":getSettings.distributionsource_id == "0" ? 0 : parseInt(getSettings.distributionsource_id),
      "blind_receiving_of_qty":getSettings.blind_receiving_of_Qty,
      "blind_receiving_of_item":getSettings.blind_receiving_of_item,
      "activate_change_management":getSettings.activate_change_management,
      "default_procurement_category":getSettings.default_procurement_cateory == "0" ? 0 : parseInt(getSettings.default_procurement_cateory),
      "accept_under_delivery":getSettings.accept_under_delivery,
      "accept_over_delivery":getSettings.accept_over_delivery,
      "check_product_receipt_number":getSettings.check_product_receipt_number == "0" ? 0 : parseInt( getSettings.check_product_receipt_number),
      "over_receipt_action":getSettings.over_receipt_action == "0" ? 0 : parseInt(getSettings.over_receipt_action),
      "match_level": getSettings.match_level == "0" ? 0 : parseInt(getSettings.match_level) 
      }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        setLoading(false)
        // addEntitiesToSettings(res.data.procurementsetting_id)
        // settings_id = res.data.procurementsetting_id 
        $('.ns_grid .rs_grid_btn_save').trigger('click') 
        $('.team_header_grid .rs_grid_btn_save').trigger('click')
        deleteEntitiesofSettings()
        addEntitiesToSettings() 
      }
    }).catch((res) => { 
      setLoading(false)
      Helper.alert(res.response.data.message, 'error')
    })  
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.procure.procurement_settings.procurement_settings_edit_screen_save_button)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }


  return (
  <>
    <div className='procurement_settings mb-5'>
        <div className='container-fluid'>
            <NavigationHeder backUrl='/procurement-settings' title={Helper.getLabel(props.language,'parameters',"Parameters")}>
                <LinkBtn isActive= {false} to="/procurement-settings" title={Helper.getLabel(props.language,'close',"Close")} />
                <Button title={Helper.getLabel(props.language,'history',"History")} />
                <Button onClick={SaveHandler} isActive={true} icon='/images/icons/save-white.svg' title={Helper.getLabel(props.language,'save',"Save")} isDisable={!security.canCreate(frontendIds.procure.procurement_settings.procurement_settings_main_button)} /> 
            </NavigationHeder>  
            {loading ? <SimpleLoading /> : ''}
            <Collapse className='mb-4 default_params_tabs' open={true} title={Helper.getLabel(props.language,'settings',"Settings")}>
                <Tabs disableSticky={true} navs={DefaultNavs} showAll={false} scrollOffset= "500">

                   <div className='container-fluid Default'>
                      <div className='row'>
                          <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                          </div>
                          <div className='col-lg-4'>
                            <Input onChange={NameHandler} value={getSettings.name} label={Helper.getLabel(props.language,'name',"Name")} id='name_dropdown'/>
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={DeliveryModeHandler} value={getSettings.mode_of_delivery_id} options={modeOfDelivery} label={Helper.getLabel(props.language,'mode_of_delivery',"Mode Of Delivery")} id='mode_of_delivery_dropdown'/>
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={DeliveryTermHandler} value={getSettings.delivery_term_id} options={deliveryItem} label={Helper.getLabel(props.language,'delivery_item',"Delivery Item")} id='delivery_item_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={TaxGroupChangeHandler} value={getSettings.taxgroup_id} options={taxGroup} label={Helper.getLabel(props.language,'tax_group',"Tax Group")} id='tax_group_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={TaxCodeHandler} value={getSettings.tax_code_id} options={itemTaxCode} label={Helper.getLabel(props.language,'item_tax_code',"Item Tax Code")} id='item_tax_code_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={DistributionHandler} value={getSettings.distribution_source_id} options={defaultDistribution} label={Helper.getLabel(props.language,'default_distribution',"Default Distribution")} id='default_distribution_dropdown' />
                          </div>
                          <div className='col-lg-4'>
                            <DropDownInput onChange={ProcurementHandler} value={getSettings.default_procurement_cateory} options={procurementCategory} label={Helper.getLabel(props.language,'procurement_category',"Procurement Category")} id='procurement_category_dropdown' />
                          </div>
                      </div>
                   </div>

                   <div className='container-fluid Controls'>
                      <div className='row mb-5 gy-4'> 
                        <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                               <div className='col-1'>
                                  <Checkbox type='checkbox' onChange={BlindQuantity} isChecked={getSettings.blind_receiving_of_Qty} /> 
                               </div>
                               <div className='col-10 border-controls'> 
                                  <h5>Blind receiving of quantity</h5>
                                  <p>Request managers to approve orders above a minimum amount</p>
                               </div>
                               <div className='col-1'>
                                    <img className='img-fluid' /> 
                               </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                    <Checkbox onChange={BlindItems} isChecked={getSettings.blind_receiving_of_item} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Blind receiving of items</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                  <Checkbox onChange={ActiveCharge} isChecked={getSettings.activate_change_management} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Active charge management</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                    <Checkbox onChange={AcceptUnderHandler} isChecked={getSettings.accept_under_delivery} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Accept under delivery</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'> 
                            <div className='row d-flex justify-content-center align-items-center'>
                                <div className='col-1'>
                                    <Checkbox onChange={AcceptOverHandler} isChecked={getSettings.accept_over_delivery} type='checkbox' /> 
                                </div>
                                <div className='col-10 border-controls'> 
                                    <h5>Accept over delivery</h5>
                                    <p>Request managers to approve orders above a minimum amount</p>
                                </div>
                                <div className='col-1'>
                                    <img className='img-fluid' /> 
                                </div>
                            </div>
                        </div>
                      </div>
                        <div className='row mt-3'> 
                            <div className='col-lg-4'>
                                <DropDownInput onChange={OverReceiptAction} value={getSettings.over_receipt_action} options={receiptAction} label={Helper.getLabel(props.language,'over_receipt_action',"Over Receipt Action")} id='over_receipt_action_dropdown' />
                            </div>
                            <div className='col-lg-4'>
                                <DropDownInput options={receiptNumber} onChange={CheckProductNumberHandler} value={getSettings.check_product_receipt_number} label={Helper.getLabel(props.language,'check_product_receipt_number',"Check Product Receipt Number")} id='check_product_receipt_number_dropdown' />
                            </div>
                            <div className='col-lg-4'>
                                <DropDownInput options={matchLevel} onChange={MatchLevel} value={getSettings.match_level} label={Helper.getLabel(props.language,'match_level',"Match Level")} id='match_level_dropdown' />
                            </div>
                        </div>
                   </div>

                   <div className='container-fluid entities'> 
                   {entityLoading == undefined ? <SimpleLoading /> : ""}
                      <div className='row'>
                      {getSettings ? <> 
                        {entityList.map((item) => {
                          return (
                            <div className='columns'>
                              {getSettings.entities == undefined ? <Checkbox onChange={CheckBoxHandler} name={item.value} isChecked={false} label={item.label + " " + item.value} /> : 
                              <Checkbox onChange={CheckBoxHandler} name={item.value} isChecked={getSettings.entities.includes(item.value) ? true : false} label={item.label + " " + item.value} />} 
                            </div> 
                            )
                        })} 
                        </> : ''}
                      </div>
                   </div>

                   <div className='container-fluid Numbering_Sequence'>
                      <div className='row'> 
                        <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                        </div>
                        {nsLoading ? <SimpleLoading /> : ''}
                        <Collapse className='mb-4 number_sequence_grid' open={true} title={Helper.getLabel(props.language,'number_sequence',"Number Sequence")}>
                          <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-3 mb-3'>
                                      <DropDownInput options={entityList} onChange={entityDropdownHandler} value={entityNS} label={Helper.getLabel(props.language,'entity',"Entity")} id='entity_dropdown' />
                                    </div>
                                    <div className='col-12 ns_grid'>
                                      <AgGrid onSave={SaveNS} onDelete={DeleteAssignedEntity} header={Columns_Headings_NS} id='number_sequence_purchase_order' data={numberSequenceGrid} />
                                    </div>
                                </div>
                          </div>
                        </Collapse>
                      </div>
                   </div>

                   <div className='container-fluid team_header'>
                      <div className='row'> 
                        <div className='col-12 mb-2'>
                            <h2>Purchase Order</h2>
                        </div>
                        {teamHeaderLoading ? <SimpleLoading /> : ''}
                        <Collapse className='mb-4 team_header_grid' open={true} title={Helper.getLabel(props.language,'team',"Team")}>
                          <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-12'>
                                      <AgGrid onDelete={DeleteLinkedTeamHeader} onSave={SaveTeamHeader} header={Columns_Headings} id='team_header_purchase_order' data={teamHeaderGrid} />
                                    </div>
                                </div>
                          </div>
                        </Collapse>
                      </div>
                   </div>

                </Tabs>
            </Collapse>
        </div>
    </div>
  </>
  )
}

 
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }

  const SecurityOptions = {
    gui_id:Gui_id_list.procure.procurement_settings.procurement_settings_edit_screen_save_button
  } 

  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ParametersEdit, SecurityOptions))))