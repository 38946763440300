import React, { Component } from 'react';
import $ from 'jquery';
class TreeviewItem extends Component {
    constructor(props){
        super(props);
        this.inSelected = '/images/icons/square-plus-blue.svg';
        this.iconBlank = '/images/icons/square-blank.svg';
        this.iconMinus = '/images/icons/square-with-minus.svg';
        this.iconPlus = '/images/icons/square-with-plus.svg';
        this.state = {
            isShowChild: this.props.item.showChild ? this.props.item.showChild :  false
        }
    }
    onClickItem(e,item){
        let that = this;
        if(that.props.onClick && typeof that.props.onClick ==='function'){
            let isExpanded = item.items.length <=0 ? true : that.state.isShowChild;
            let isLastChild = item.items.length <=0 ? true : false;
            that.props.onClick(item,{
                isExpanded:isExpanded,
                isLastChild: isLastChild
            })
        }
        $(e.target).closest('.rs_tree_view_section').find('.rs_treeview_controller').removeClass('active');
        $(e.target).closest('.rs_treeview_controller').addClass('active');
    }
    onIconClick(e,item){
        let that = this;
        this.setState({
            isShowChild:!this.state.isShowChild
        },function(){
            if(that.props.onIconClick && typeof that.props.onIconClick ==='function'){
                let isExpanded = item.items.length <=0 ? true : that.state.isShowChild;
                let isLastChild = item.items.length <=0 ? true : false;
                that.props.onIconClick(item,{
                    isExpanded:isExpanded,
                    isLastChild: isLastChild
                })
            }
        })
    }
    displayChildren(item){
        if(!item.items){
            return <></>
        }
        if(item.items.length>=1){
            return (
                <ul>
                    {item.items.map( (itemInner,key) => { return <TreeviewItem onIconClick={this.props.onIconClick} onClick ={this.props.onClick} item={itemInner} key={key} />} )}
                </ul>
                )
        }
        return <></>
    }
    getIconUrl = (item) => {
        if(item.items.length >= 1 && !this.state.isShowChild){
            return this.iconPlus; 
        }
        if(item.items.length >= 1 && this.state.isShowChild){
            return this.iconMinus; 
        }
        return this.iconBlank;
    }
    render() {
        let item = this.props.item;
        return (
            <li>
                <div className='rs_treeview_controller' >
                    <img className='icon_no_select' onClick={ e => this.onIconClick(e,item)} src={  this.getIconUrl(item) } alt={item.title}/>
                    <img className='icon_select' onClick={ e => this.onIconClick(e,item)} src={ this.inSelected } alt={item.title}/>
                    <span onClick={ e => { this.onClickItem(e,item)}}>{item.title}</span>
                </div>
                { this.state.isShowChild ? this.displayChildren(item) : ""}
            </li>
        );
    }
}

export default TreeviewItem;