import Api from "../../inc/Api";
import Settings from "../../inc/Settings";

class GridSegmentDistributionDetails {
    init(params) {
        this.params = params;
        this.eGui = document.createElement('div');
        this.eGui.innerHTML = 'Loading..';
        this.loadDistributionDetails();
    }
    
    loadDistributionDetails(){
        let details = [];
        let firstHeaderObj = this.params.api.getColumnDefs()[0];
        let segmentList = firstHeaderObj.segmentList;
        let segmentValueList = firstHeaderObj.segmentValueList;
        //let distributionCombinationArray = this.params.data.name.split('-');
        let distributionCombinationArray = this.params.data.segmentcombinationvalue.split('|');
        segmentList.forEach(segment => {
            let allValuesOfThisSegment = segmentValueList.filter( item => {
                return item.segment_id == segment.segment_id ?  item : null;
            })
            let itemDetails = {
                segment:segment.name,
                value:'',
                details:'',
            }
            allValuesOfThisSegment.forEach(valueObject => {
                distributionCombinationArray.forEach(valueId => {
                    if(valueId == valueObject.segmentvalue_id){
                        itemDetails.value = valueObject.code;
                        itemDetails.details = valueObject.name;
                    }
                })
            });

            //console.log(allValuesOfThisSegment)
            details.push(itemDetails)
        });
        this.eGui.innerHTML = this.getInnerHtml(details)
    }
    getInnerHtml(detailsArray){
        let detailsBody = '';
        detailsArray.forEach( item => {
            detailsBody+= `<tr class="rs_item_row"><td>${item.segment}</td><td>${item.value}</td><td>${item.details}</td></tr>`;
        });
        return(
            `<table class="table rs_distribution_details_table">
                <thead>
                    <tr>
                        <th scope="col">Segments</th>
                        <th scope="col">Value</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                   ${detailsBody}
                </tbody>
            </table>
            `
        )
    }
    getGui() {
        return this.eGui;
    }

    refresh(params) {
        return false;
    }
}
export default GridSegmentDistributionDetails;