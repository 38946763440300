import React, { Component } from "react";
import MacbookPro from "../../../assets/macbook-pro.png";
import Product from "../../../assets/item_type_product.svg";
import Category from "../../../assets/item_type_category.svg";
import Comment from "../../../assets/item_type_comment.svg";
import EditGrey from "../../../assets/edit-grey.svg";
import DeleteGrey from "../../../assets/delete-grey.svg";
import DropdownIcon from "../../../assets/dropdown-grey.svg";
import Helper from "../../../inc/Helper";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import DistributionTabs from "../common/DistributionTabs";
import EditOldCartModal from "../modals/EditOldCartModal";
import { connect } from "react-redux";
import ActionTypes from "../../../inc/ActionsTypes";

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      quantity: 0,
    };
  }
  componentDidMount() {
    this.setState({ quantity: this.props.cartItem.ordered_qty });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.selectedCartItems) !==
      JSON.stringify(prevProps.selectedCartItems)
    ) {
      console.log("selecting update");
      if (this.props.selectedCartItems.includes(this.props.cartItem.line_id)) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  render() {
    const { cartItem, activeDistribution, toggleDistribution, handleSelect } =
      this.props;
    return (
      <div className="cartItem-item">
        <div className="cartItem-content">
          <div className="cartItem-content-check">
            <input
              type="checkbox"
              onClick={(event) => event.stopPropagation()}
              onChange={() => handleSelect([cartItem.line_id])}
              checked={this.state.checked}
            />
            <img src={cartItem?.image} alt="item" />
          </div>
          <div className="cartItem-content-info">
            <div className="cartItem-content-info-titlebar">
              <div className="cartItem-content-info-titlebar-title">
                {cartItem?.title}
              </div>
              <div className="cartItem-content-info-titlebar-icons">
                <img
                  src={EditGrey}
                  alt="edit"
                  onClick={() => {
                    this.props.showOldCartModal(cartItem);
                  }}
                />
                <img
                  src={DeleteGrey}
                  alt="delete"
                  onClick={() => {
                    this.props.deleteCartItem(cartItem);
                  }}
                />
              </div>
            </div>
            <div className="cartItem-content-info-specs">
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "unit_price",
                    "Unit Price"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.unit_price
                    ? parseInt(cartItem?.unit_price).toFixed(2)
                    : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "line_amount",
                    "Line Amount"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  -
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "need_of_date",
                    "Need of date"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  -
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "item_type",
                    "Item Type"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.itemType === "product" ? (
                    <img src={Product} alt="product" />
                  ) : null}
                  {cartItem?.itemType === "category" ? (
                    <img src={Category} alt="category" />
                  ) : null}
                  {cartItem?.itemType === "comment" ? (
                    <img src={Comment} alt="comment" />
                  ) : null}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(this.props.language, "Discount", "discount")}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  -
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(this.props.language, "quantity", "Quantity")}
                </span>{" "}
                <div className="cartItem-content-info-specs-spec-quantity">
                  {" "}
                  <span
                    className="prshop-itemcard-content-quantity-button"
                    // onClick={this.handleQuantityDecrement}
                  >
                    -
                  </span>{" "}
                  <input
                    type="number"
                    disabled={true}
                    defaultValue={
                      cartItem?.ordered_qty
                        ? parseInt(cartItem?.ordered_qty)?.toFixed(2)
                        : 0
                    }
                    value={cartItem?.quantity}
                    // onChange={(e) => this.handleQuantityChange(e)}
                  />{" "}
                  <span
                    className="prshop-itemcard-content-quantity-button"
                    // onClick={this.handleQuantityIncrement}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="search-filters-seemore"
          onClick={() => {
            this.props.updateActiveCartItem(cartItem);
            toggleDistribution(cartItem);
          }}
        >
          <img
            src={DropdownIcon}
            alt="dropdown"
            style={
              this.state.activeDistribution === cartItem?.line_id
                ? { transform: "rotate(180deg)" }
                : {}
            }
          />
          {Helper.getLabel(
            this.props.language,
            "view_distribution",
            "View distribution"
          )}
        </div>
        {activeDistribution === cartItem.line_id ? (
          <DistributionTabs viewOnly={true} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    showOldCartModal: (payload) => {
      dispatch({ type: ActionTypes.SHOW_EDITOLDCART_MODAL, payload });
    },
    updateActiveCartItem: (payload) => {
      dispatch({ type: ActionTypes.UPDATE_ACTIVRCARTITEM, payload });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
