import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    sites:[]
}
const SiteReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_SITES){
        state = {
            ...state,
            sites:actions.payload
        }
    }
    return state;
}
export default SiteReducer;