import React from 'react'

const PurchaseLines = () => {
  return (
    <div className='purchase_lines'>
        <div className='row line_head d-flex justify-content-center align-items-center'>
            <div className='col-2'>
                <h4>Item Name</h4>
            </div>
            <div className='col-2'>
                <h4>Delivery Date</h4>
            </div>
            <div className='col-1'> 
                <h4>Quantity</h4>
            </div>
            <div className='col-1'>
                <h4>Unit Price</h4>
            </div>
            <div className='col-2'>
                <h4>Discount Amount</h4>
            </div>
            <div className='col-2'>
                <h4>Discount Percentage</h4>
            </div>
            <div className='col-2'>
                <h4>Line Total</h4>
            </div>
        </div> 

        <div className='row line_head_data d-flex justify-content-center align-items-center'>
            <div className='col-2'>
                <h4>Software License</h4>
            </div>
            <div className='col-2'>
                <h4>11/24/2015</h4>
            </div>
            <div className='col-1'> 
                <h4>12,421.00</h4>
            </div>
            <div className='col-1'>
                <h4>12,423</h4>
            </div>
            <div className='col-2'>
                <h4>13,200</h4>
            </div>
            <div className='col-2'>
                <h4>0.00</h4>
            </div>
            <div className='col-2'>
                <h4>0.24,842.00</h4>
            </div>
        </div>

        <div className='row line_head_data d-flex justify-content-center align-items-center'>
            <div className='col-2'>
                <h4>Software License</h4>
            </div>
            <div className='col-2'>
                <h4>11/24/2015</h4>
            </div>
            <div className='col-1'> 
                <h4>12,421.00</h4>
            </div>
            <div className='col-1'>
                <h4>12,423</h4>
            </div>
            <div className='col-2'>
                <h4>13,200</h4>
            </div>
            <div className='col-2'>
                <h4>0.00</h4>
            </div>
            <div className='col-2'>
                <h4>0.24,842.00</h4>
            </div>
    </div>
    </div>
  )
}

export default PurchaseLines;