import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Api from '../../../../../inc/Api';
import Collapse from '../../../../../components/inc/Collapse';
import Button from '../../../../../components/inc/Button';
import Input from '../../../../../components/Forms/Input';
import DropDownInput from '../../../../../components/Forms/Dropdown';
import Settings from '../../../../../inc/Settings';
import Helper from '../../../../../inc/Helper';
import FormValidator from '../../../../../inc/FormValidator';

const Escalation = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleSubmit() {
      // e.preventDefault();
      const data = [];
      inputFields.forEach((item) => {
        let newReason = {
          "duration_type": item.duration_type,
          "escalation_time": item.escalation_time,
          "completion_time": item.completion_time,
          "assignee_type": item.assignee_type,
          "assignee": item.assignee,
          "completion_policy": item.completion_policy,
          "percentage_of_approver": item.percentage_of_approver,
        }
        let validData = {
          ...newReason
        }
        if (!validator.isValid(validData)) {
          validator.displayMessage();
          validator.reset()
          return;
        }
        data.push({
          "completion_policy": item.assignee_type !== 1 ? item.completion_policy : null,
          "completion_time": item.completion_time > 0 ? item.completion_time : null,
          "duration_type": item.completion_time > 0 || item.escalation_time > 0 ? item.duration_type : null,
          "escalation_time": item.escalation_time > 0 ? item.escalation_time : null,
          "assignee": item.assignee,
          "assignee_type": item.assignee_type,
          "percentage_of_approver": item.assignee_type !== 1 && item.completion_policy === 3 ? item.percentage_of_approver : null,
        })
      })
      let submitChecker;
      if (data.length) {
        submitChecker = false;
        const escalationTimeData = data.filter((item) => item.escalation_time > 0);
        if (data.length - 1 === escalationTimeData.length) {
          if (data.length === 1) {
            console.log("all are good");
            submitChecker = true;
          } else {
            for (let i = 0; i < data.length - 1; i++) {
              if (data[i].escalation_time === 0) {
                console.log("All are bad");
                Helper.alert("You have remove the last escalation time that are required", 'error');
              } else {
                submitChecker = true;
              }
            }
          }
        } else {
          console.log("All are bad");
          Helper.alert("You have to add a escalation or remove escalation time", 'error');
        }
        console.log(escalationTimeData);
      }
      if (submitChecker) {
        console.log(data);
        return data;
      }
    }
  }));

  const [teamsData, setTeamsData] = useState([]);
  useEffect(() => {
    getUserData();
    getUserDataASPerPosition();
    if (props.escalation.length) {
      setInputFields(props.escalation);
      let formData = [];
      Promise.all(props.escalation.map(async (item) => {
        const teamData = await getTeamsData(item.assignee);
        formData.push(teamData);
        setTeamsData(formData);
      }))
    }
  }, [])

  const userTypes = [
    { value: 1, label: 'User' },
    { value: 2, label: 'Team' },
    { value: 3, label: 'Position' },
    { value: 4, label: 'Hierarchy' }
  ]

  const days = [
    { value: 1, label: 'Hour' },
    { value: 2, label: 'Day' }
  ]

  const completionType = [
    { value: 1, label: 'Single approver' },
    { value: 2, label: 'Majority of approvers' },
    { value: 3, label: 'Percenatge of approvers' },
    { value: 4, label: 'All approvers' }
  ]

  const [inputFields, setInputFields] = useState([
    {
      duration_type: null,
      escalation_time: null,
      completion_time: null,
      assignee_type: null,
      assignee: null,
      completion_policy: null,
      percentage_of_approver: null
    }
  ])


  let validator;

  const validationConfigure = () => {
    let fieldConfig = [
      { name: 'assignee_type', displayName: 'Escalation type', types: ['Required'] },
      { name: 'assignee', displayName: 'Escalation to', types: ['Required'] },
      {
        name: 'duration_type', displayName: 'Escalation Nature', custom: (formData, field) => {
          if ((formData.escalation_time > 0 || formData.completion_time > 0) && (formData.duration_type === null || formData.duration_type === 0)) {
            return {
              type: false,
              message: field.displayName + " " + "is required"
            }
          }
        }
      },
      {
        name: 'completion_policy', displayName: 'Completion Policy', custom: (formData, field) => {
          if (formData.assignee_type > 1 && (formData.completion_policy === null || formData.completion_policy === 0)) {
            return {
              type: false,
              message: field.displayName + " " + "is required"
            }
          }
        }
      },
      {
        name: 'percentage_of_approver', displayName: 'Percentage', custom: (formData, field) => {
          if (formData.completion_policy === 3 && formData.percentage_of_approver <= 0) {
            return {
              type: false,
              message: "Valid percentage is required"
            }
          }
          if (formData.completion_policy === 3 && !/^(0|[1-9][0-9]?|100)$/.test(formData.percentage_of_approver)) {
            return {
              type: false,
              message: "Please give the value between 1 to 100"
            }
          }
        }
      },
      {
        name: 'escalation_time', displayName: 'Escalation Time', custom: (formData, field) => {
          if (formData.escalation_time > 0 && formData.completion_time > 0) {
            if (formData.completion_time > formData.escalation_time) {
              return {
                type: false,
                message: field.displayName + " " + "should be greater than or equal to completion time"
              }
            }
          }
        }
      },
    ]
    if (!validator) {
      validator = new FormValidator(fieldConfig);
    }
  }
  validationConfigure()

  const handleFormChange = (e, index) => {
    const { name, value } = e.target;
    const newFields = [...inputFields];
    newFields[index][name] = +value;
    setInputFields(newFields);
  }

  const handleRemoveField = (index) => {
    console.log(index);
    const newFields = [...inputFields];
    const getPreviousArrayOfObject = newFields[index - 1];
    if (newFields.length === index + 1 && getPreviousArrayOfObject?.escalation_time > 0) {
      getPreviousArrayOfObject.escalation_time = null;
      if (getPreviousArrayOfObject?.completion_time === 0 || getPreviousArrayOfObject?.completion_time === null) {
        getPreviousArrayOfObject.duration_type = null;
      }
    }
    newFields.splice(index, 1);
    setInputFields(newFields);
  }

  const handleAddForm = () => {
    let newfield = {
      duration_type: null,
      escalation_time: null,
      completion_time: null,
      assignee_type: null,
      assignee: null,
      completion_policy: null,
      percentage_of_approver: null
    }
    const value = [...inputFields];
    const getPreviousArrayOfObject = value[value.length - 1];
    if (getPreviousArrayOfObject.escalation_time > 0) {
      setInputFields([...inputFields, newfield]);
    }
    else {
      Helper.alert("Please give escalation time for add next escalation", 'error');
    }
  }

  const [formUserData, setFormUserData] = useState([])
  const getUserData = () => {
    let api = Api;
    api.setUserToken()
    const formData = { "name": null }
    api.axios().post(Settings.loginUrl + `/workflow/get_users/1/100`, formData).then((res) => {
      if (res.data.status === "Success") {
        const data = res.data.data;
        const formData = [];
        data.forEach((item) => {
          formData.push({
            "value": item.user_id,
            "label": item.employee_display_name
          })
        })
        setFormUserData(formData)
      }
    }).catch((error) => {
      console.log(error);
    }
    )
  }

  const getTeamsData = (id) => {
    let api = Api;
    api.setUserToken();
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + `/workflow/get_teams/1/10?team_id=${id}`).then((res) => {
          if (res.data.status === "success") {
            const data = res.data.data;
            const formData = [];
            // data.forEach((item) => {
            // formData.push({
            return {
              "value": data[0].teamheader_id,
              "label": data[0].name
            }
            // })
            // })
            return formData;
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const [positionData, setPositionData] = useState([]);
  const getUserDataASPerPosition = () => {
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiOrgUrl + '/workflow/get_positions').then((res) => {
      if (res.data.status === "success") {
        const data = res.data.data;
        const formData = [];
        data.forEach((item) => {
          formData.push({
            "value": item.position_id,
            "label": item.name
          })
        })
        setPositionData(formData);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  let getTeamsDataAsPerPage = {
    ajax: {
      method: 'GET',
      url: (args) => {
        let pageNumber = args.page ? args.page : 1;
        let pageSize = 10;
        return Settings.loginUrl + `/workflow/get_teams/` + pageNumber + '/' + pageSize;
      },
      data: (dataParams) => {
        var query = {
          item_code_name: dataParams.term
        }
        return query;
      },
      processResults: function (data, params) {
        let result = data.data.map(item => {
          return {
            id: item.teamheader_id,
            text: item.name
          }
        })
        console.log(result);
        return {
          results: result,
          pagination: {
            more: (params.page * 10) >= data.total_records ? false : true
          }
        };
      }
    }
  }

  // useEffect(()=>{
  //   props.createFunctionToCall(()=>handleSubmit);
  // },[props.createFunctionToCall])

  return (
    <>
      <Collapse title='General Information' open={true}>
        <form>
          {inputFields.map((input, index) => {
            return (
              <div key={index}>
                {inputFields.length > 1 ?
                  <div className='d-flex justify-content-between'>
                    <h5 style={{ color: '#2D74E0', fontWeight: 600 }}>Remove {index + 1} escalation</h5>
                    <Button title='Delete' type="button" onClick={() => handleRemoveField(index)} />
                  </div>
                  // <div>
                  //   <h5 style={{ color: '#2D74E0', fontWeight: 600 }}>Add escalation</h5>
                  //   <ToggleSwitch
                  //     margin={'justify-content-between'}
                  //     value={true}
                  //     onChange={() => handleRemoveField(index)}
                  //     titlePosition={TitlePosition.left}
                  //     title="Do you want to escalate further if this assignee don't take action?"
                  //   />
                  // </div>
                  : null}
                {/* <Checkbox id="" type="" name="" disable={false} label="Active Escalation" isChecked={false} /> */}
                <div className='row'>
                  <div className='row label-form'>
                    <div className='col-lg-6 col-md-3'>
                      <DropDownInput name="duration_type" value={input.duration_type} onChange={(e) => handleFormChange(e, index)} label="Escalation Nature" options={days} id={'task_id' + index + 1} />
                    </div>
                    <div className='col-lg-6 col-md-3'>
                      <Input hasError={validator.hasError('escalation_time')} name="escalation_time" value={input.escalation_time} onChange={(e) => handleFormChange(e, index)} inputType='number' label="Escalation Time" id={'escalation_time' + index + 1} />
                    </div>
                    <div className='col-lg-6 col-md-3'>
                      <Input inputType='number' value={input.completion_time} onChange={(e) => handleFormChange(e, index)} name="completion_time" label="Completion Time" id={'duration_id' + index + 1} />
                    </div>
                    <div className='col-lg-6 col-md-3'>
                      <DropDownInput name="assignee_type" value={input.assignee_type} onChange={(e) => handleFormChange(e, index)} label="Escalation Type" required options={userTypes} id={'task_ids' + index + 1} />
                    </div>
                    {input.assignee_type === null &&
                      <div className='col-lg-6 col-md-3'>
                        <DropDownInput name="assignee" required label="Escalation To" options={[]} id={'tasks_id' + index} />
                      </div>
                    }
                    {input.assignee_type === 1 &&
                      <div className='col-lg-6 col-md-3'>
                        <DropDownInput name="assignee" value={input.assignee} onChange={(e) => handleFormChange(e, index)} label="Escalation To" required options={formUserData.length ? formUserData : []} id={'tasks_id' + index + 1} />
                      </div>
                    }
                    {input.assignee_type === 2 &&
                      <div className='col-lg-6 col-md-3'>
                        <DropDownInput select2Settings={getTeamsDataAsPerPage} name="assignee" value={input.assignee} onChange={(e) => handleFormChange(e, index)} label="Escalation To" required options={teamsData.length ? teamsData : []} id={'tasks_id' + index + 1} />
                      </div>
                    }
                    {input.assignee_type === 3 &&
                      <div className='col-lg-6 col-md-3'>
                        <DropDownInput name="assignee" value={input.assignee} onChange={(e) => handleFormChange(e, index)} label="Escalation To" required options={positionData.length ? positionData : []} id={'tasks_id' + index + 1} />
                      </div>
                    }
                    {/* {input.assignee_type === 4 && 
                    <div className='col-lg-6 col-md-3'>
                      <DropDownInput name="assignee" value={input.assignee} onChange={(e) => handleFormChange(e, index)} label="Escalation To" required options={input.escalation_type === '1' ? teamsData : []} id={'tasks_id' + index + 1} />
                    </div>
                    } */}
                    {input.assignee_type && input.assignee_type !== 1 &&
                      <>
                        <div className='col-lg-6 col-md-3'>
                          <DropDownInput name="completion_policy" value={input.completion_policy} onChange={(e) => handleFormChange(e, index)} label="Completion Policy" required options={completionType} id={'tasked_id' + index + 1} />
                        </div>
                        {input.completion_policy && input.completion_policy === 3 &&
                          <div className='col-lg-6 col-md-3'>
                            <Input name="percentage_of_approver" value={input.percentage_of_approver} onChange={(e) => handleFormChange(e, index)} inputType='number' label="Percentage" required id={'percentage' + index + 1} />
                          </div>}
                      </>
                    }
                  </div>
                </div>
              </div>
            )
          })}
          {/* <div>
                      <h5 style={{ color: '#2D74E0', fontWeight: 600 }}>Add escalation</h5>
                      <ToggleSwitch
                        margin={'justify-content-between'}
                        value={false}
                        onChange={ToggleChangeHandler}
                        titlePosition={TitlePosition.left}
                        title="Do you want to escalate further if this assignee don't take action?"
                      />
                    </div> */}
          <div className='d-flex justify-content-between mt-2'>
            <div>
              <h5 style={{ color: '#2D74E0', fontWeight: 600 }}>Add {inputFields.length + 1} escalation</h5>
            </div>
            <div>
              <Button className="me-2" title="Add" type='button' isActive={true} onClick={handleAddForm} />
              {/* <Button title="Submit" isActive={true} onClick={handleSubmit} /> */}
            </div>
          </div>
        </form>
      </Collapse>
    </>
  )
})

export default Escalation;