import AgGrid from './ag-grid';

import React from 'react';

const AgGridMemo = React.memo((props) => {
    return (
        <AgGrid {...props} />
    );
}, (prevProps, nextProps) => {
    return prevProps.forceRender === nextProps.forceRender;
});

export default AgGridMemo;