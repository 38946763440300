import React from 'react';
import Graph from './submitted.svg';

const TabInfoWidget = () => {
    //Accept Props based on type of info being sended
  return (
    <div className='user_feed_info_tab'>
       <div className='row pt-3'>
        <div className='col-7'>
          <div className='feed_info_data ms-3 mt-2'>
           <h2>43.69 %</h2>
           <p>45 Submitted</p>
          </div>
        </div>
        <div className='col-5'>
            <img className='icon' src={Graph} />
        </div>
       </div>
    </div>
  )
}

export default TabInfoWidget;