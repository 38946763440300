import React from 'react'

const OrderTotal = () => {
  return (
    <div className='order_total'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <h3>Total</h3>
          </div>
        </div>

        <div className='mt-2 row'>
           <div className='col-6 col-md-12 col-lg-6'>
                <div className='numeric_text_orders'>
                    <h2>
                    Total discount
                    <span className='ms-4'>1.0000</span>
                    </h2> 
                </div>
           </div>
           <div className='col-6 col-md-12 col-lg-6'>
                <div className='numeric_text_orders'>
                    <h2>
                    Charges
                    <span className='ms-4'>18,750.00</span>
                    </h2> 
                </div>
           </div>
        </div>

        <div className='row'>
            <div className='col-6 col-md-12 col-lg-6'>
                <div className='numeric_text_orders'>
                    <h2>
                    Sales Tax
                    <span className='ms-4'>74,062.50</span>
                    </h2> 
                </div>
            </div>
            <div className='col-6 col-md-12 col-lg-6'>
                <div className='numeric_text_orders'>
                    <h2>
                    Round off
                    <span className='ms-4'>1,41,250.00</span>
                    </h2> 
                </div>
            </div>
        </div>

        <div className='row mt-1 mb-2 subtotal_text'>
          <div className='col-6 col-md-12 col-lg-6'>
            <div className='numeric_text_orders d-flex justify-content-start'>
                <h2>
                Subtotal
                </h2> 
            </div>
          </div>
          <div className='col-6 col-md-12 col-lg-6'>
            <div className='numeric_text_orders d-flex justify-content-end'>
                <h2>
                    <span>1,41,250.00</span>
                </h2> 
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-6 col-lg-6'>
            <div className='numeric_text_orders d-flex justify-content-start'>
                <h2>
                Cash discount
                </h2> 
            </div>
          </div>
          <div className='col-6 col-lg-6'>
            <div className='numeric_text_orders d-flex justify-content-end'>
                <h2>
                    <span>1.0000</span>
                </h2> 
            </div>
          </div>
        </div>

        <div className='row mt-1 mb-1 '>
          <div className='col-6 col-lg-6'>
            <div className='numeric_text_orders d-flex justify-content-start'>
                <h2>
                Adjusted holding Tax
                </h2> 
            </div>
          </div>
          <div className='col-6 col-lg-6'>
            <div className='numeric_text_orders d-flex justify-content-end'>
                <h2>
                    <span>18,750.00</span>
                </h2> 
            </div>
          </div>
        </div> 
      </div>
    </div>
  )
}

export default OrderTotal;