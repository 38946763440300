import React from 'react'
import CollapsedAddress from '../Collapsed Address/CollapsedAddress'
import './AddressDetails.css'
import Add from '../../../assets/add.svg'
import Edit from '../../../assets/edit.svg'

const AddressDetails = ({buttonRequired}) => {
  return (
    <CollapsedAddress title='Address Details' defaultExpanded='true'>
    <div className='address-details'>
    <div className='container'>
    
    {buttonRequired === 'true' ?
    <div className='row mb-2'>
      <div className='col-12'>
      <div className='address-btns d-flex justify-content-end'>
          <button><img src={Add} className='img-fluid' /> Add</button>
          <button><img src={Edit} className='img-fluid' /> Edit</button>
      </div>
      </div>
    </div> 
    : null 
    }
    <div className='row'>

    <div className='col-12'>
    <div className='row address-details-form gy-0 mt-2 mb-2'>

    <div className='col-lg-4 col-md-6'>
     <label>Country/Region </label>
     <input type='text' placeholder='Pakistan' />
    </div>

    <div className='col-lg-4 col-md-6'>
    <label>State </label>
    <input type='text' placeholder='Sindh' />
    </div>

    <div className='col-lg-4 col-md-6'>
     <label>City </label>
     <input type='text' placeholder='Karachi' />
    </div>
 
    </div>

    <div className='row address-details-form gy-0 mb-2'>

    <div className='col-lg-5 col-md-6'>
     <label>Address Line 1 </label>
     <input type='text' placeholder='Line 1' />
     </div>

     <div className='col-lg-5 col-md-6'>
     <label>Address Line 2 </label>
     <input type='text' placeholder='Line 2' />
     </div>

     <div className='col-lg-1 col-md-6 mt-auto'>
        <label><input type='checkbox' /> Primary</label>
     </div>

     <div className='col-lg-1 col-md-6 mt-lg-auto'>
     <label><input type='checkbox' /> Private</label>
     </div>

    </div>
    
    </div>
    </div>
    </div>
  </div>
  </CollapsedAddress>
  )
}

export default AddressDetails



