import { Component } from "react";
import { Alert } from "react-bootstrap";

import DeleteGrey from "../../../assets/delete-grey.svg";

class CheckDeleteActionBar extends Component {
  render() {
    const {
      icon,
      title,
      itemCount,
      toggleSelectAll,
      selectAll,
      deleteAll,
      disable,
    } = this.props;

    return (
      <Alert className="pr-wishlist-actionbar">
        <div>
          <input
            className="pr-wishlist-checkbox"
            type="checkbox"
            onChange={toggleSelectAll}
            checked={selectAll}
          />
          <div>
            <img src={icon} alt="wishlist" />
            <span className="pr-wishlist-actionbar-highlight">{title}</span> (
            {itemCount} items)
          </div>
        </div>
        <img
          className={`pr-wishlist-actionbar-delete ${
            disable ? "pr-wishlist-actionbar-deleteDisable" : ""
          }`}
          src={DeleteGrey}
          alt="delete"
          onClick={disable ? null : () => deleteAll()}
        />
      </Alert>
    );
  }
}

export default CheckDeleteActionBar;
