import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
    notes: ""
}
const NoteReducers = (state = DefaultState , actions) => {
    if(actions.type === ActionTypes.SET_NOTES){
        state = {
            ...state,
            notes:actions.payload
        }
    }
    if(actions.type === ActionTypes.CLEAR_NOTES){
        state = {
            ...state,
            notes:""
        }
    }
    return state;
}
export default NoteReducers;