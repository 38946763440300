import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Helper from "../../../inc/Helper";
import CartIconBlue from "../../../assets/cartIcon-blue.svg";
import DeleteGrey from "../../../assets/delete-grey.svg";
import Product from "../../../assets/item_type_product.svg";
import Category from "../../../assets/item_type_category.svg";
import Comment from "../../../assets/item_type_comment.svg";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import OverlayLoader from "../common/OverlayLoader";

class ShoppingCartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      cartItems: [],
      subTotal: 0,
      imagesFetched: false,
    };

    this.getCartItems = this.getCartItems.bind(this);
    this.deleteCartItem = this.deleteCartItem.bind(this);
    this.getSubTotal = this.getSubTotal.bind(this);
  }

  componentDidMount() {
    this.getCartItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.visibility !== this.props.visibility &&
      this.props.visibility === true
    )
      this.getCartItems();
  }

  getCartItems() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartlines")
      .then(function (res) {
        that.setState(
          {
            isLoading: false,
            cartItems: [...res.data.data],
          },
          () => {
            that.getItemsImages();
          }
        );
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  deleteCartItem(item) {
    let api = Api;
    this.setState({ isLoading: true });
    api.setUserToken();
    let that = this;
    api
      .axios()
      .delete(
        Settings.apiPurchaseRequisitionUrl + "/pr_cartline/" + item.line_id
      )
      .then(function (res) {
        if (res.status === 202) {
          that.setState((prevState) => {
            const cartItems = [...prevState.cartItems];
            const index = cartItems.indexOf(item.line_id);
            cartItems.splice(index, 1);
            return { cartItems };
          });
          that.setState({ isLoading: false });
          that.props.decrementCartCount();
        }
      })
      .catch(function (err) {
        console.log(err);
        that.setState({ isLoading: false });
      });
  }

  getItemsImages() {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.state.cartItems.forEach((item, index) => {
      if (item?.item_id) {
        api
          .axios()
          .get(Settings.apiItemUrl + "/itemimage/" + item.item_id)
          .then(function (res) {
            if (res.data.status === "success") {
              const image = res.data?.data[0];
              that.setState((prevState) => {
                const cartItemsTemp = [...prevState.cartItems];
                cartItemsTemp[index].image = image;
                return { cartItems: cartItemsTemp };
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  getSubTotal() {
    let subtotal = 0;
    this.state.cartItems.map((item) => {
      if (item.ordered_qty && item.unit_price)
        subtotal += item.ordered_qty * item.ordered_qty;
    });
    return subtotal;
  }

  render() {
    const { visibility, hide } = this.props;
    return (
      <Modal show={visibility} onHide={hide} className="shoppingcart-modal">
        <Modal.Body className="shoppingcart-modal-body">
          <div className="shoppingcart-modal-content">
            <div className="shoppingcart-modal-content-header">
              <div>
                <img src={CartIconBlue} alt="cart" />
                <label>
                  {Helper.getLabel(
                    this.props.language,
                    "shopping_cart",
                    "Shopping Cart"
                  )}
                </label>
              </div>
              <span>({this.state.cartItems.length ?? 0}) Item</span>
            </div>
            <div className="shoppingcart-modal-content-items">
              {this.state.cartItems?.map((item) => (
                <div className="shoppingcart-modal-content-item">
                  <div className="shoppingcart-modal-content-item-image">
                    {item.image ? (
                      <img src={`${item?.image?.image}`} alt="cart item" />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="shoppingcart-modal-content-item-info">
                    <div>
                      <label className="shoppingcart-modal-content-item-title">
                        {item.title}
                      </label>
                      <img
                        className="shoppingcart-modal-content-item-delete"
                        src={DeleteGrey}
                        alt="delete"
                        onClick={() => {
                          this.deleteCartItem(item);
                        }}
                      />
                    </div>
                    <div>
                      <label>
                        {Helper.getLabel(
                          this.props.language,
                          "item_type",
                          "Item Type"
                        )}
                      </label>
                      {item.itemType ? null : "-"}
                      {item.itemType === "product" ? (
                        <img src={Product} alt="product" />
                      ) : null}
                      {item.itemType === "category" ? (
                        <img src={Category} alt="category" />
                      ) : null}
                      {item.itemType === "comment" ? (
                        <img src={Comment} alt="comment" />
                      ) : null}
                    </div>
                    <div>
                      <label>
                        {Helper.getLabel(
                          this.props.language,
                          "quantity",
                          "Quantity"
                        )}
                      </label>
                      <span>
                        {parseInt(item?.ordered_qty).toFixed(2) ?? "-"}
                      </span>
                    </div>
                    <div>
                      <label>
                        {Helper.getLabel(
                          this.props.language,
                          "unit_price",
                          "Unit Price"
                        )}
                      </label>
                      <span>
                        ${" "}
                        {item?.unit_price
                          ? parseInt(item?.unit_price).toFixed(2)
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {this.state.isLoading ? <OverlayLoader /> : null}
            </div>
            <div className="shoppingcart-modal-content-action">
              <div className="shoppingcart-modal-content-action-total">
                <label>
                  {Helper.getLabel(this.props.language, "subtotal", "Subtotal")}
                </label>
                <span>$ {this.getSubTotal()}</span>
              </div>
              <div className="shoppingcart-modal-content-action-total">
                <label>
                  {Helper.getLabel(this.props.language, "tax", "Tax")}
                </label>
                <span>
                  <span>$ -</span>
                </span>
              </div>
              <div className="shoppingcart-modal-content-action-separator"></div>
              <div className="shoppingcart-modal-content-action-total">
                <label>
                  {Helper.getLabel(this.props.language, "total", "Total")}
                </label>
                <span className="shoppingcart-modal-content-action-total-highlightedprice">
                  <span>$ {this.getSubTotal()}</span>
                </span>
              </div>
              <Link to="/purchase-requisition/cart">
                <button
                  className="pr-primary-btn pr-primary-btn-outline"
                  onClick={hide}
                >
                  {Helper.getLabel(
                    this.props.language,
                    "view_cart",
                    "View Cart"
                  )}
                </button>
              </Link>
              <Link to="/purchase-requisition/checkout">
                <button
                  className="pr-primary-btn pr-primary-btn-solid"
                  type="submit"
                >
                  {Helper.getLabel(this.props.language, "checkout", "Checkout")}
                </button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(ShoppingCartModal);
