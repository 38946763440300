export const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Start' },
    position: { x: window.innerWidth/2, y: 25 },
    className: "round-flow start-flow-bg"
  },
  {
    id: '2',
    type: 'output',
    data: { label: 'End' },
    position: { x: window.innerWidth/2, y: 600 },
    className: "round-flow end-flow-bg"
  }
];
  
export const nodeColor = (node) => {
  switch (node.type) {
    case 'input':
      return '#FFCD29';
    case 'output':
      return '#303030';
    case 'default':
      return actionTypeNodeColor(node);
    default:
      return '#FC5E24';
  }
};
    
  const actionTypeNodeColor = (node) => {
    switch (node.className) {
      case 'action-type__step-node bg-green':
        return '#02A0A0';
      case 'action-type__step-node bg-blue':
        return '#4E5DA3';
      case 'action-type__step-node bg-orange':
        return '#FF7752';
      case 'action-type__step-node bg-pink':
        return '#FF4697';
    }
  };
    
    
    
    
    
    
    
    
// import { useCallback, useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
// import ReactFlow, {
//   MiniMap,
//   Controls,
//   useNodesState,
//   useEdgesState,
//   MarkerType,
// } from 'reactflow';

// import 'reactflow/dist/style.css';
// import WorkflowStartNode from '../Workflow Start Node/WorkflowStartNode';
// import "./ReactFlow.css";
// import { useSelector } from 'react-redux';
// import ConditionalDecisionModal from '../Conditional Decision Modal/ConditionalDecisionModal';

// const WorkFlowCanvas = () => {

//   const isClose = useSelector((state) => state.workflowSlice.isOpend);

//   const [workflowStartNodeModal, setworkflowStartNodeModal] = useState(false);
//   const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
//   const [edges, setEdges, onEdgesChange] = useEdgesState([]);

//   const getNodeId = () => `randomnode_${+new Date()}`;
  
//   const onAdd = useCallback((_, { nodeId }) => {
//     let newNode;
//     if (nodeId == 1) {
//       newNode = {
//         id: getNodeId(),
//         data: { label: <div className='flow-add-container'><WorkflowStartNode addToLocalStorage={onSave} addNode={onAdd} /></div> },
//         position: { x: 289, y: 100 },
//         className: "flow-add-wrapper",
//       }
//     } else {
//       newNode = {
//         id: getNodeId(),
//         data: { label: 'Added node' },
//         position: {
//           x: Math.random() * window.innerWidth - 100,
//           y: Math.random() * window.innerHeight,
//         },
//       };
//     }
//     setNodes((nds) => nds.concat(newNode));
//     setEdges((eds) => eds.concat({ id: `eId-${newNode.id}`, source: nodeId, target: newNode.id, type: 'step', style: {
//       strokeWidth: 2,
//       stroke: '#FF0072',
//     }, markerEnd: {
//       type: MarkerType.ArrowClosed,
//       width: 20,
//       height: 20,
//       color: '#FF0072',
//     }, }));
//   }, [setNodes, setEdges]);

//   const [rfInstance, setRfInstance] = useState(null);
//   const onSave = useCallback(() => {
//     if (rfInstance) {
//       const flow = rfInstance.toObject();
//       localStorage.setItem('flowKey', JSON.stringify(flow));
//     }
//   }, [rfInstance]);

//   const [currentNode, setCurrentNode] = useState()
//   const workflowStartNodeModalOpen = (data) => {
//     setCurrentNode(data['target']['dataset']?.['nodeid'])
//     setworkflowStartNodeModal(true);
//   }

//   const latestNode = useSelector((state) => state.workflowSlice.nodeId);
//   console.log(latestNode)
//   useEffect(() => {
//     onAdd('', {nodeId: latestNode})
//   }, [latestNode])

//   return (
//     <>
//     { isClose ? <ConditionalDecisionModal addNode={onAdd} currentNode={currentNode} /> : "" }
//       <ReactFlow
//         nodes={nodes}
//         edges={edges}
//         onNodesChange={onNodesChange}
//         onEdgesChange={onEdgesChange}
//         // onConnectStart={workflowStartNodeModalOpen}
//         onConnectStart={onAdd}
//         onInit={setRfInstance}
//       // onConnect={onConnect}
//       // onNodeClick={test}
//       // onClickConnectStart={test}
//       >
//         {workflowStartNodeModal ? <WorkflowStartNode hideworkflowStartNodeModal={setworkflowStartNodeModal} addNode={onAdd} currentNode={currentNode} /> : ""}
//         <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
//         <Button className='btn-node-save' onClick={onSave}>Save</Button>
//         <Controls />
//         {/* <Background /> */}
//       </ReactFlow>
//     </>
//   );
// }
// export default WorkFlowCanvas;