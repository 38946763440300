import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { ValidateArray } from '../../../inc/Validation';
import FormContainer from '../../Form Module Layout/Forms Container/FormContainer';
import AgGrid from '../../grid/ag/ag-grid';
import $ from 'jquery'
import Alert from '../../inc/Alert';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';

const ItemGroupAccount = (props) => {

  const [itemGroupAccountGrid, setItemGroupAccountGrid] = useState([])
  const [itemGroups, setItemGroups] = useState([])
  const [itemGroupAccounts, setItemGroupAccounts] = useState([])
  const [loading, setLoading] = useState(true)
  let groupAccCalled = false
  let itemGroupCalled = false
  let groupAccListCalled = false

  useEffect(() => {
    get_item_group_account()
    itemGroupsList()
    itemGroupsAccountsList()
  }, [])

  const itemGroupsList = () => {
    if(itemGroupCalled){
      return;
    }
    let api = Api
    itemGroupCalled = true
    api.setUserToken()
  api.axios().get(Settings.apiItemUrl+'/item_group',{}).then(function(res){
    if(res.data.status==="success"){ 
      let ITEM_GROUP_List_API = []
      res.data.data.forEach(item => {  
       ITEM_GROUP_List_API.push({
         value: item.itemgroup_id,
         label: item.name
       })
      })
      setItemGroups(ITEM_GROUP_List_API)
    } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const itemGroupsAccountsList = () => {
    if(groupAccListCalled) {
      return;
    }
    let api = Api
    groupAccListCalled = true
    api.setUserToken()
  api.axios().get(Settings.apiItemUrl+'/item_groupaccount_type',{}).then(function(res){
    if(res.data.status==="success"){ 
      let ITEM_GROUP_ACCOUNT_TYPE_List_API = []
      res.data.data.forEach(item => {  
       ITEM_GROUP_ACCOUNT_TYPE_List_API.push({
         value: item.itemgroupaccounttype_id,
         label: item.name
       })
      })
      setItemGroupAccounts(ITEM_GROUP_ACCOUNT_TYPE_List_API)
    } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const get_item_group_account = () => {
    if(groupAccCalled) {
      return;
    }
      let api = Api
      groupAccCalled = true
      api.setUserToken()
    api.axios().get(Settings.apiItemUrl+'/item_groupaccount',{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATE_GRID_DATA = []
        res.data.data.forEach(element => {
          UPDATE_GRID_DATA.push({
            "account": element.account,
            "itemgroup_id": element.itemgroup_id,
            "itemgroupaccounttype_id": element.itemgroupaccounttype_id,
            "group_account_id": Date.now()
        })
        })
        setItemGroupAccountGrid(res.data.data.reverse())
        setLoading(false)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }
 
  const onSaveClick = (rowItem) => { 
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.group_account_id || item.group_account_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){
        addNewData(newData);
    }
    if(updatedData.length>=1){ 
        updateData(updatedData);
    }
  } 

const updateData = (data) =>{ 
  let validation = ValidateArray(data) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_item_group_account()
  }
        let api = Api
      api.setUserToken()
    data.forEach(element => {
      api.axios().put(Settings.apiItemUrl+`/item_groupaccount/${element.itemgroup_id}/${element.itemgroupaccounttype_id}`,{ 
      "groupaccounttype_id":null,
      "itemgroup_id":null,
      "account": element.account
    }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_item_group_account()
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    })
}
 
const addNewData = (data) => { 
  let validation = ValidateArray(data) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_item_group_account()
  }
  let api = Api
  api.setUserToken()
  data.forEach(element => {
    api.axios().post(Settings.apiItemUrl+'/item_groupaccount',{
      "groupaccounttype_id": parseInt(element.itemgroupaccounttype_id),
      "itemgroup_id": parseInt(element.itemgroup_id),
      "account": element.account
  }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        get_item_group_account()
      }else {
        get_item_group_account()
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
 }) 
}

const DeleteHandler = (allRows) => { 
  let api = Api
  api.setUserToken()
  allRows.forEach(element => {
    api.axios().delete(Settings.apiItemUrl+`/item_groupaccount/${element.itemgroup_id}/${element.itemgroupaccounttype_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_item_group_account()
        }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
})
}

const SaveHandler = () => {
  $('.rs_item_group_account_grid .rs_grid_btn_save').trigger('click'); 
}

const ITGPAccountValidator = (params) => {
  if( params.newValue.length > 80 ){
    Helper.alert('Account limit exceeded!', 'error')
    // params.data.account = params.newValue 
    return false
  }
  params.data.account = params.newValue
  return true
}

  let Columns_Headings = [ 
    {field:'itemgroup_id', headerName: Helper.getLabel(props.language, 'item_group', 'Item Group'), inputType: "dropdown", options: itemGroups},
    {field:'itemgroupaccounttype_id', headerName: Helper.getLabel(props.language, 'item_group_account_type', 'Item Group Account Type'), inputType: "dropdown", options: itemGroupAccounts},
    {field:'account', valueSetter: ITGPAccountValidator, headerName: Helper.getLabel(props.language, 'account', 'Account') } 
  ]
  
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item_group_account.item_group_account_main)){
    return <Fragment> 
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return (
    <Fragment> 
      <NavigationHeder backUrl='/master-data' title='Item Group Account'>
      <Button isActive= {true} isDisable={!security.canEdit(frontendIds.masterData.item_master_modules.item_group_account.item_group_account_main_save_button)} title='Save' onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>  
      {loading ? <SimpleLoading /> : null}
      <div className='rs_item_group_account_grid'>
        <AgGrid addBtnHide={!security.canCreate(frontendIds.masterData.item_master_modules.item_group_account.item_group_account_main_create_button)} hideDelete={!security.canDelete(frontendIds.masterData.item_master_modules.item_group_account.item_group_account_main_delete_button)} data={itemGroupAccountGrid} id="item-group-account-grid" header={Columns_Headings} onDelete={DeleteHandler} onSave={onSaveClick} /> 
      </div>
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id: Gui_id_list.masterData.item_master_modules.item_group_account.item_group_account_main
}


export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(ItemGroupAccount, SecurityOptions)))