import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Helper from "../../../inc/Helper";
import Dropdown from "../../Forms/Dropdown";
import Input from "../../Forms/Input";
import InputDatePicker from "../../Forms/InputDatePicker";
import Collapse from "../../inc/Collapse";
import Popup from "../../Popup/Popup";
import DistributionTabs from "../common/DistributionTabs";

export default class EditNewCartModal extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    return (
      <Popup autoOpen={true} width="750px" className="editCartModal">
        <Collapse
          title={Helper.getLabel(
            this.props.language,
            "general_distribution",
            "General Information"
          )}
          open={true}
        >
          <form
            onSubmit={(e) => this.handleFormSubmit(e)}
            className="generalInformation"
          >
            <div className="generalInformation-newCartItem">
              <div className="generalInformation-newCartItem-grid">
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "quantity",
                    "Quantity"
                  )}
                  inputType="number"
                />
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "unit_price",
                    "Unit Price"
                  )}
                  inputType="number"
                />
                <Input
                  label={Helper.getLabel(
                    this.props.language,
                    "discount",
                    "Discount"
                  )}
                  inputType="number"
                />
                <InputDatePicker
                  label={Helper.getLabel(
                    this.props.language,
                    "need_by_date",
                    "Need by date"
                  )}
                />
              </div>
            </div>
          </form>
        </Collapse>
        <Collapse open={true}>
          <DistributionTabs />
        </Collapse>
      </Popup>
    );
  }
}
