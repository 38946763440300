import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Language from '../../../components/Form Modules/Language/Language';
import Input from '../../../components/Forms/Input';
import AgGridMemo from '../../../components/grid/ag/AgGridMemo';
import Button from '../../../components/inc/Button';
import Collapse from '../../../components/inc/Collapse';
import SimpleLoading from '../../../components/Loading/SimpleLoading';
import Popup from '../../../components/Popup/Popup';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';

const NewWorkflow = (props) => {

  const New_Workflow_Tabele_Columns_Headings = [
    { field: 'name', headerName: 'Name' },
    { field: 'description', headerName: 'Description' },
  ]
  const [loading, setLoading] = useState(false);
  const [templateId, seTemplateId] = useState(null);
  const [workFlowName, setWorkFlowName] = useState();
  const [workFlowDescription, setWorkFlowDescription] = useState();
  const [isWorkflow, setIsWorkflow] = useState(false);

  const getAllWorkflowData = (templateid) => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.baseUrl + `workflow/get-version?template_id=${templateid}&tenant_id=${props.tenantId}`).then((res) => {
          if (res.data.success) {
            let data;
            if (res.data.data.length) {
              setIsWorkflow(true);
            } else {
              setIsWorkflow(false);
            }
            data = res.data.data.filter(element => {
              return element.active;
            })
            return data;
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const onSaveClick = (event) => {
    let activeSelection = event.api.getSelectedRows();
    if (activeSelection.length) {
      getAllWorkflowData(activeSelection[0].template_id);
      seTemplateId(activeSelection[0].template_id);
    } else {
      setIsWorkflow(false);
      seTemplateId(null);
    }
  }

  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            return (res.data.data);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const createWorkflowHandler = async () => {
    if (!workFlowName) {
      Helper.alert("Please give a workflow name", 'error');
      return;
    }
    if (!templateId) {
      Helper.alert("Please select a template", 'error');
      return;
    }
    setLoading(true);
    const getInactiveVersion = await getAllWorkflowData(templateId);
    if (isWorkflow && getInactiveVersion.length < 1) {
      Helper.alert("No active workflow found", 'error');
      setLoading(false);
      return;
    }
    setLoading(true);
    const loginUserDetails = await getTenantId();
    if (!loginUserDetails) {
      Helper.alert('Somthing went wrong, please try again', 'error');
    }
    const formData = {
      "template_id": templateId,
      "name": workFlowName,
      "tenant_id": loginUserDetails.tenant_id,
      "description": workFlowDescription,
      "user_id": loginUserDetails.user_id,
    };
    axios.post(Settings.baseUrl + 'workflow/create', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        props.hideNewWorkflow(false);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      if (error.response.status === 400) {
        Helper.alert("First fill the all field data", 'error');
        setLoading(false);
      }
    })
  }

  return (
    <>
      <Popup onClose={() => props.hideNewWorkflow(false)} autoOpen={true} >
        <h5 className=''>{Helper.getLabel(Language, 'new_workflow', 'Create Workflow')}</h5>
        <p className=''>Enter workflow details and select template</p>
        <Collapse title={Helper.getLabel(Language, 'general', 'Add workflow name and description')} open={true}>
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <Input id="workflow_name" value={workFlowName} label="Workflow name" placeholder="Type workflow name here" required={true} onChange={(e) => { setWorkFlowName(e.target.value) }} />
            </div>
            <div className='col-xs-12 col-sm-6'>
              <Input id="workflow_description" value={workFlowDescription} label="Workflow description" placeholder="Type workflow description here" onChange={(e) => { setWorkFlowDescription(e.target.value) }} />
            </div>
          </div>
        </Collapse>
        <Collapse title={Helper.getLabel(Language, 'general', 'Select Template')} open={true}>
          {loading ? <SimpleLoading /> : null}
          <AgGridMemo id="rs_users_list" data={props.templateData} header={New_Workflow_Tabele_Columns_Headings} onSelectionChanged={onSaveClick} tableTitle={"Select template"} height={"300px"} allBtnHide={true} hideHeaderRightContents={true} singleRowSelect={true} viewOnly={true} />
        </Collapse>
        <div className='d-flex justify-content-end  mt-4 '>
          <Button className="mx-2" title="Cancel" onClick={() => props.hideNewWorkflow(false)} />
          <Button title={isWorkflow ? "Create Workflow Version" : "Create Workflow"} onClick={createWorkflowHandler} isActive={true} />
        </div>
      </Popup>
    </>
  )
}

export default NewWorkflow;