import React, { Component } from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';

class FileListItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAction:false,
            isLoading:false,
            isDeleted:false,
        }
    }
    fileDeleteHandler(e){
        let file = this.props.file;
        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoading:true
        })
        api.axios().delete(Settings.apiUrl + '/attachment/'+file.attachment_id).then(function(res){
            if(res.data.status ==='success'){
                Helper.alert(res.data.message)
                that.setState({
                    isDeleted:true,
                    isLoading:false
                })
            }else{
                that.setState({
                    isLoading:false
                })
                Helper.alert(res.data.message,'error')
            }
            
        })
    }
    getIconUrl (){
        let file = this.props.file;
        let icon = '/images/icons/folder.svg';
        let fileType = file.filetype.toLowerCase();
        if( fileType === 'application/pdf'){
            icon = '/images/icons/pdf.svg';
        }
        else if(fileType.search('image') >=0){
            icon = '/images/icons/picture.svg';
        }
        
        return icon;
    }
    downloadFile(e){
        let file = this.props.file;
        let api = Api;
        api.setUserToken();
        let that = this;
       
        api.axios().get(Settings.apiUrl + '/get_attachment/'+file.attachment_id).then(function(res){
            if(res.data.status ==='success'){
                let mimeType = res.data.data.file_type;
                let bytesBase64 = res.data.data.file;
                bytesBase64 = bytesBase64.replace("b'", "");
                bytesBase64 = bytesBase64.replace("'", "");
                var fileUrl = "data:" + mimeType + ";base64," + bytesBase64;
                window.open(fileUrl);
                /*
               
                const fileObj = new File([res.data.data], "File name",{ type: "image/png" })
                const link = document.createElement('a')
                const url = URL.createObjectURL(fileObj)
            
                link.href = url
                link.download = fileObj.name
                document.body.appendChild(link)
                link.click()
            
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
                /*
                console.log(res.data.data)
                
                console.log(fileObj)
                */
            }
        })
        
    }
    render() {
        let icon = this.getIconUrl ();
        let file = this.props.file;
        
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        if(this.state.isDeleted){
            return <></>
        }
        return (
            <div className='file_list_item'>
                <img src= {icon} alt="file type"  className='file_type_icon'  onClick={ e => this.downloadFile(e)}/>
                <div className='file_details'>
                    <div className= {this.state.showAction ? 'file_actions show' : 'file_actions'}>
                        <div className='file_action_controller' onClick={ e=> { this.setState({ showAction:!this.state.showAction })} }>...</div>
                        <div className='file_actions_option'>
                            <span onClick={ e => this.fileDeleteHandler(e) }>Delete</span>
                        </div>
                    </div>
                    <h3 onClick={ e => this.downloadFile(e)}>{file.filename}</h3>
                    <p>{Number.parseFloat(file.filesize).toFixed(3)} Mb</p>
                </div>
                
            </div>
        );
    }
}

export default FileListItem;