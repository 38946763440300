import React, { Component } from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import NoteExportable from './NoteExportable';
import NoteItem from './NoteItem';
/**
 * notesource_integrator : Unique id of table
 * source_id : Source id
 */
class Notes extends Component {
    constructor(props){
        super(props);
        this.isLoadNotes = false;
        this.state = {
            isPosting:false,
            noteText:'',
            isLoading:false,
            source_id: this.props.source_id ? this.props.source_id : null,
            notesource_id: null,
            notesource_integrator: this.props.notesource_integrator ? this.props.notesource_integrator : null,
            allNotes:[]
        }
    }
    componentDidMount(){
       
        this.loadNotes();
    }
    componentDidUpdate(privProps){
        let that = this;
        if(privProps.notesource_integrator != this.props.notesource_integrator){
            this.setState({
                notesource_integrator: this.props.notesource_integrator,
                notesource_id:null,
            },function(){
                that.isLoadNotes= false;
                that.loadNotes();
            })
        }
    }
    createNotes(){
        let api = Api;
        api.setUserToken();
        
        let that = this;
        that.setState({
            isPosting:true
        })
        let postUrl = "";
        let noteData = {
            
        }
        if(this.state.notesource_id){
            postUrl = Settings.apiUrl + '/note';
            noteData.notesource_id = this.state.notesource_id;
            noteData.note = this.state.noteText;
        }else{
            postUrl = Settings.apiUrl + '/notesource';
            noteData.source_id = this.state.source_id;
            noteData.notes = [this.state.noteText];
            noteData.notesource_integrator = this.state.notesource_integrator;
        }
        api.axios().post(postUrl,noteData).then(function(res){
            
            that.setState({
                isPosting:false,
                noteText:''
            },function(){
                that.isLoadNotes= false;
                that.loadNotes();
            })

        })
    }
    loadNotes(){
        if(this.isLoadNotes){
            return;
        }
        let api = Api;
        api.setUserToken();
        let that = this;
        this.setState({
            isLoading:true,
            allNotes:[]
        })
        
        let notUrl = Settings.apiUrl+'/note';
        if(this.state.source_id && this.state.notesource_integrator){
            notUrl = Settings.apiUrl+'/note/'+this.state.source_id+'/'+this.state.notesource_integrator;
        }
        this.isLoadNotes = true;
        api.axios().get(notUrl).then(function(res){
            if(res.data.status==='success'){
                let allNotes = res.data.data;
                if(Array.isArray(allNotes)){
                    that.setState({
                        allNotes:allNotes.reverse(),
                        notesource_id: ( allNotes[0] && allNotes[0].notesource_id ) ? allNotes[0].notesource_id : null,
                        isLoading:false
                    })
                }else{
                    that.setState({
                        allNotes:allNotes.notes.reverse(),
                        notesource_id: allNotes.notesource_id,
                        notesource_integrator: allNotes.notesource_integrator,
                        source_id: allNotes.source_id,
                        isLoading:false
                    })
                }
                
            }else{
                that.setState({
                    isLoading:false
                })
            }
        }).catch( error=> {
            that.setState({
                isLoading:false
            })
        })
        
    }
    displayNotes(){
        if(this.state.isLoading){
            return <SimpleLoading />
        }
        return(
            <div className='note_lists'>
                {
                    this.state.allNotes.map((item,key) => { return <NoteItem note={item} key={key} />})
                }
            </div>
        )
    }
    onCreateNoteHanlder(e){
        if(this.state.noteText === ""){
            Helper.alert('Please type note','error');
            return;
        }
        this.createNotes();
    }
    onChangeNote(e){
        this.setState({
            noteText:e.target.value
        })
    }
    render() {
        if(this.props.exportable){
            return <NoteExportable {...this.props}/>
        }
        return (
            <div className='notes_widgets'>
                <div className='note_form'>
                    <input value={this.state.noteText} onChange={e=>this.onChangeNote(e) } type="text" placeholder='Write a new note'/>
                    <button onClick={ e => this.onCreateNoteHanlder(e)}>{this.state.isPosting ? <SimpleLoading width="15px"/> : <img src="/images/icons/paper-plane.svg" alt="Submit" /> }</button>
                </div>
                {this.displayNotes()}
            </div>
        );
    }
}

export default Notes;