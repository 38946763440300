import React, { Component } from 'react';
import MasterComonent from '../../components/Backend/MasterComonent';
import Alert from '../../components/inc/Alert';

class Error404 extends Component {
    render() {
        return (
            <MasterComonent>
                <div className=' text-center error_page' style={{paddingTop:'30px'}}>
                    <Alert type="danger" message="No page found"/>
                </div>
            </MasterComonent>
            
        );
    }
}

export default Error404;