import { createSlice } from '@reduxjs/toolkit';

const dataGrid = createSlice({
    name:'Grid Data From Dropdown',
    initialState: {
        source_id: undefined,
        language_id: undefined,
        form_id: undefined,
        module_id: undefined,
        resource_id: undefined,
        previleges_id: undefined,
        user_name: '',
        first_name: '',
        last_name: '',
        number_sequence: {}
    },
    reducers: {
        SourceIdChange (state, action) { 
            state.source_id = action.payload 
        }, 
        LanguageIdChange (state, action) { 
            state.language_id = action.payload 
        }, 
        FormIdChange (state, action) { 
            state.form_id = action.payload 
        },
        ModuleIdChange(state, action) {
            state.module_id = action.payload
        },
        ResourcesIdChange(state, action) {
            state.resource_id = action.payload
        },
        PrevilegesIdChange(state, action) {
            state.previleges_id = action.payload
        },
        UserNameChange(state, action) {
            state.user_name = action.payload.payload
        },
        FirstNameChange(state, action) {
            state.first_name = action.payload.payload
        },
        LastNameChange(state, action) {
            state.last_name = action.payload.payload
        },
        NumberSequenceObjectHandler (state, action) {
            state.number_sequence = action.payload.payload
        } 
    }

});

export const gridActions = dataGrid.actions;
export default dataGrid;
