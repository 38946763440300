import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ActionTypes from '../../../inc/ActionsTypes'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper' 
import $ from 'jquery'
import './Item.css';
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Alert from '../../inc/Alert'
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import LinkBtn from '../../inc/LinkBtn'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Tabs from '../../Widgets/Tabs/Tabs' 
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import FormValidator from '../../../inc/FormValidator'
import AgGrid from '../../grid/ag/ag-grid'
import { useNavigate } from 'react-router-dom'
import Entities from './Entities'
import SimpleLoading from '../../Loading/SimpleLoading' 
import {  checkIfArrayIsEmpty, checkObjectProperties, checkObjectResponseCodes, ValidateData } from '../../../inc/Validation'
import CreateItemGeneral from './CreateItemGeneral'
import CreateItemAttr from './CreateItemAttr'
import CreateItemPriceDiscount from './CreateItemPriceDiscount'

// Component Wide Variable to keep data presisted!

let response_vendor_id = []
let response_entity_id = []
let APIResponses = {
  itemAPI: null,
  priceAPI: null,
  discountAPI: null,
  vendorAPI: null,
  entityAPI: null,
}
let response_item_id = null
let response_item_master_id = null
let response_price_id = null
let response_discount_id = null

//End

// Function/Componnent Initialisation

const ItemCreate = (props) => {  
  const [createItem, setCreateItem] = useState({
    "item_name": null,
    "display_name": null,
    "description":null,
    "lastpurchaseprice":null,
    "purchaseUoM": null,
    "translationsource_id":null,
    "ItemStyle_id":null,
    "item_configuration_id":null,
    "item_color_id":null,
    "item_size_id":null,
    "item_dimension_id":null,
    "notesource_id":null,
    "attachmentsource_id":null,
    "item_group_id": null,
    "segment_combination_id":null,
    "taxcodes_id":null,
    "item_buyergroup_id":null,
    "item_brand_id":null,
    "itemmaster_type": null
  })
  const [createItemDiscount, setCreateItemDiscount] = useState({
    "item_id": null,
    "itemdiscounttype_id": null,
    "name": null,
    "discount_unit": null,
    "discount": null,
    "effective_date": null,
    "expire_date":null 
  })
  const [createItemPricing, setCreateItemPricing] = useState({
    "item_id": null,
    "pricing_type": null,
    "price_unit": null,
    "price":null
  })
  const navigate = useNavigate()

  const [itemVendor, setItemVendor] = useState([])
  const [gridReadyObj, setGridReadyObj] = useState([])
  const [loading, setLoading] = useState(false)
  const [openEntity, setOpenEntity] = useState(false)
  const [itemBarcode, setItemBarcode] = useState([]) 
  const [itemEntity, setItemEntity] = useState([])
  const [itemVendorGrid, setItemVendorGrid] = useState([])
  let item_source_id = 24

  //API Reduction Calls

  let purchaseUnitListCalled = false
  let entityCodeNameListCalled = false
  let vendorsCodeNameListCalled = false
  let itemEntityReasonListCalled = false

  //End

  // Validations

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'item_name', max: 255, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
        {name:'display_name', max: 255, displayName:Helper.getLabel(props.language,'display_name','Display Name'),types:['Required']}, 
        {name:'purchaseUoM', displayName:Helper.getLabel(props.language,'purchaseUoM','Purchase UoM'),types:['Required']}, 
        {name:'item_group_id', displayName:Helper.getLabel(props.language,'item_group','Item Group'),types:['Required']}, 
        {name:'taxcodes_id', displayName:Helper.getLabel(props.language,'taxcode','Tax Code'),types:['Required']}, 
        {name:'itemmaster_type', displayName:Helper.getLabel(props.language,'item_master','Item Master Type'),types:['Required']}, 
      ] 
    if(!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }
  validationConfigure()

  let navs = [
    {title:Helper.getLabel(props.language,'general','General'), isActive: true, },
    {title:Helper.getLabel(props.language,'price_&_discount','Price & Discount')}, 
    {title:Helper.getLabel(props.language,'attributes','Attributes')},
    {title:Helper.getLabel(props.language,'entity','Entity')},
    {title:Helper.getLabel(props.language,'vendor','Vendor')}, 
  ]

  const addComponentTop = () => {
    let items = [
        {title:Helper.getLabel(props.language,'item_id',"Item Id"),subtitle: ''},
        {title:Helper.getLabel(props.language,'display_name',"Display Name"),subtitle: createItem.display_name},
        {title:Helper.getLabel(props.language,'name',"Name"),subtitle: createItem.item_name},
        {title:Helper.getLabel(props.language,'purchase_unit_of_measurement',"Purchase Unit Of Measurement"),subtitle: createItem.purchaseUoM ? getPurchaseTitleLabel() : ''}
    ]
    let tags = [
        {title:Helper.getLabel(props.language,'confirm','Confirmed')},
    ];
    return(
      <BlueCallToAction items={items} tags={tags}/>
    )
  }

  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <NoteAttachmentHistory source_id ={item_source_id} notesource_integrator= {null}  attachmentsource_integrator ={null}/> 
      </MasterSidebar>
    )
  }

  const getPurchaseTitleLabel = () => {
    if(!document.getElementById('purchase_unit_id')) {
      return ''
    }
    let Result = ''
    let innerid = document.getElementById('purchase_unit_id').value
    purchaseUnit.forEach(element => {
      if (element.value == innerid){
        Result = element.label
      } 
    })
    return Result
  }
  
  // ----

  //Component-Rendered Functions:

  useEffect(() => { 
    get_purchase_unit_list() 
    get_entity_code_name_list()
    get_vendors_code_name_list()
    get_item_entity_reason_list()
  }, [])

  //Dropdowns -- States

  const [purchaseUnit, setPurchaseUnit] = useState([])
  const [entityReason, setEntityReason] = useState([])
  const [entityCode, setEntityCode] = useState([])
  const [entityName, setEntityName] = useState([])
  const [vendorCode, setVendorCode] = useState([])
  const [vendorName, setVendorName] = useState([])

  //Dropdowns -- Setup

  const get_entity_code_name_list = () => {
    if(entityCodeNameListCalled){
      return;
    }
    let api = Api
    entityCodeNameListCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+`/search_entity`,{
      "code":"",
      "name":""
    }
    ).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE_CODE = []
        let UPDATED_STATE_NAME = []
        res.data.data.forEach((element) => {
          UPDATED_STATE_CODE.push({
          value: element.entity_id,
          label: element.code
        })
      })
      res.data.data.forEach((element) => {
        UPDATED_STATE_NAME.push({
        value: element.entity_id,
        label: element.name
      })
    })
      setEntityCode(UPDATED_STATE_CODE)
      setEntityName(UPDATED_STATE_NAME)
      }
    })
   }

   const get_vendors_code_name_list = () => {
    if(vendorsCodeNameListCalled){
      return;
    }
    let api = Api
    vendorsCodeNameListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/vendor`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE_CODE = []
        let UPDATED_STATE_NAME = []
        res.data.data.forEach((element) => {
          UPDATED_STATE_CODE.push({
          value: element.vendor_id,
          label: element.code
        })
      })
      res.data.data.forEach((element) => {
        UPDATED_STATE_NAME.push({
        value: element.vendor_id,
        label: element.name
      })
    })
      setVendorCode(UPDATED_STATE_CODE)
      setVendorName(UPDATED_STATE_NAME)
      }
    })
   }
 
   const get_purchase_unit_list = () => {
    if(purchaseUnitListCalled){
      return;
    }
    let api = Api
    purchaseUnitListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.unit_id,
          label: `${element.description} - ${element.code}`
        })
      })
      setPurchaseUnit(UPDATED_STATE)
      }
    })
   } 

   const get_item_entity_reason_list = () => {
    if(itemEntityReasonListCalled){
      return;
    }
    let api = Api
    itemEntityReasonListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/vendorreason`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.reason_id,
          label: element.description
        })
      })
      setEntityReason(UPDATED_STATE)
      }
    })
   }
  //End
 
// Create Item & Item Modules

  const ItemCreateAPI = () => {
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().post(Settings.apiItemUrl+'/item', {
      "item_name": createItem.item_name !== "" ? createItem.item_name : null,
      "display_name": createItem.display_name !== "" ? createItem.display_name : null,
      "description": createItem.description !== "" ? createItem.description : null,
      "lastpurchaseprice": createItem.lastpurchaseprice !== "" ? createItem.lastpurchaseprice : null,
      "purchaseUoM": createItem.purchaseUoM ? parseInt(createItem.purchaseUoM) : null,
      "translationsource_id":null,
      "ItemStyle_id": createItem.ItemStyle_id ? parseInt(createItem.ItemStyle_id) : null,
      "item_configuration_id": createItem.item_configuration_id ? parseInt(createItem.item_configuration_id) : null,
      "item_color_id": createItem.item_color_id ? parseInt(createItem.item_color_id) : null,
      "item_size_id": createItem.item_size_id ? parseInt(createItem.item_size_id) : null,
      "item_dimension_id": createItem.item_dimension_id ? parseInt(createItem.item_dimension_id) : null,
      "notesource_id":null,
      "attachmentsource_id":null,
      "item_group_id": createItem.item_group_id ? parseInt(createItem.item_group_id) : null,
      "segment_combination_id":null,
      "taxcodes_id": createItem.taxcodes_id ? parseInt(createItem.taxcodes_id) : null,
      "item_buyergroup_id": createItem.item_buyergroup_id ? parseInt(createItem.item_buyergroup_id) : null,
      "item_brand_id":  createItem.item_brand_id ? parseInt(createItem.item_brand_id) : null,
      "itemmaster_type": createItem.itemmaster_type ? parseInt(createItem.itemmaster_type) : null
    }).then(function(res){
      if(res.data.status ==="success"){
        response_item_id = res.data.item_id 
        response_item_master_id = res.data.itemmaster_id
        Promise.all([ItemDiscountCreateAPI(),  ItemPricingCreateAPI(), AddItemToVendorAPI(), AddItemToEntityAPI()])
        .then((results) => {
          // All API calls have succeeded 
          if(checkObjectResponseCodes(APIResponses)){ 
            ItemBarcodeCreateAPI()
          }
        })
        .catch((error) => {
          // At least one API call has failed
          console.error(error);  
        });
        console.log(res.data.message)
      }else {
        Helper.alert(res.data.message, 'error')
        setLoading(false)
      }
    }).catch((res) => {
      console.log(res)
        Helper.alert(res.response.data.message, 'error')
        setLoading(false)
      })
    }

    const AddItemToVendorAPI = () => {
      console.log(response_item_id, 'vendor');
      if (!response_item_id) {
        return Promise.resolve();
      }
      let updated_vendor = [];
      itemVendor.api.forEachNode((element) => {
        if (!element.data.name) {
          return;
        }
        response_vendor_id.push(element.data.name);
        updated_vendor.push({
          "vendor_id": parseInt(element.data.name),
          "ispreferred": element.data.preferred == "yes" ? true : false,
          "item_id": parseInt(response_item_id)
        });
      });
      if (checkIfArrayIsEmpty(updated_vendor)) {
        return Promise.resolve();
      }
      let api = Api;
      api.setUserToken();
      return new Promise((resolve, reject) => {
        api.axios().post(Settings.apiItemUrl + '/itemvendor', updated_vendor)
          .then(function (res) {
            if (res.data.status === "success") {
              console.log(res.data.message);
              APIResponses = {
                ...APIResponses,
                vendorAPI: 200
              };
              setLoading(false);
              resolve();
            } else {
              Helper.alert(res.data.message, 'error');
              setLoading(false);
              APIResponses = {
                ...APIResponses,
                vendorAPI: 500
              };
              reject();
            }
          })
          .catch((res) => {
            Helper.alert(res.response.data.message, 'error');
            APIResponses = {
              ...APIResponses,
              vendorAPI: 500
            };
            DeleteEntity();
            DeleteDiscount();
            DeletePrice();
            DeleteItem();
            setLoading(false);
            reject();
          });
      });
    };

    const AddItemToEntityAPI = async () => { 
      console.log(response_item_master_id, 'entity')
      if (!response_item_master_id || checkIfArrayIsEmpty(itemEntity)) {  
        return Promise.resolve();
      }
    
      const promises = itemEntity.map(async element => {
        if (!element.name) {
          return Promise.resolve();
        }
    
        let api = Api;
        api.setUserToken();
        try {
          const res = await api.axios().post(Settings.apiItemUrl + '/itementity', {
            "entity_id": parseInt(element.name),
            "itemmaster_id": response_item_master_id,
            "IsActive": element.active == "yes" ? true : false,
            "Reason_Id": element.reason,
            "comments": element.comments
          })
          if (res.data.status === "success") {
            console.log(res.data.message)
            APIResponses = {
              ...APIResponses,
              entityAPI: 200
            }
          } else {
            Helper.alert(res.data.message, 'error')
            APIResponses = {
              ...APIResponses,
              entityAPI: 500
            }
          }
        } catch (res_1) {
          Helper.alert(res_1.response.data.message, 'error')
          APIResponses = {
            ...APIResponses,
            entityAPI: 500
          }
          DeleteVendors()
          DeleteDiscount()
          DeletePrice()
          DeleteItem()
          throw new Error("Failed to make the API call")
        }
      });
    
      await Promise.all(promises)
      setLoading(false)
    }

    const ItemDiscountCreateAPI = () => {
      console.log(response_item_id, 'discount');
      if (!response_item_id || checkIfArrayIsEmpty(createItemDiscount)) {
        return Promise.resolve();
      }
      if (!checkObjectProperties(createItemDiscount)) {
        return Promise.resolve();
      }
      let api = Api;
      api.setUserToken();
      return new Promise((resolve, reject) => {
        api.axios()
          .post(Settings.apiItemUrl + '/item_discount', {
            "item_id": response_item_id,
            "itemdiscounttype_id": createItemDiscount.itemdiscounttype_id ? parseInt(createItemDiscount.itemdiscounttype_id) : null,
            "name": createItemDiscount.name !== "" ? createItemDiscount.name : null,
            "discount_unit": createItemDiscount.discount_unit !== "" ? parseInt(createItemDiscount.discount_unit) : null,
            "discount": createItemDiscount.discount !== "" ? parseInt(createItemDiscount.discount) : null,
            "effective_date": Helper.getDateForDb($('.effective_date_id').val()) !== "NaN-NaN-NaN" ? Helper.getDateForDb($('.effective_date_id').val()) : null,
            "expire_date": Helper.getDateForDb($('.expiry_date_id').val()) !== "NaN-NaN-NaN" ? Helper.getDateForDb($('.expiry_date_id').val()) : null
          })
          .then(function (res) {
            if (res.data.status === "success") {
              APIResponses = {
                ...APIResponses,
                discountAPI: 200
              };
              response_discount_id = res.data.item_discount_id;
              console.log(res.data.message);
              resolve(res.data);
            } else {
              Helper.alert(res.data.message, 'error');
              APIResponses = {
                ...APIResponses,
                discountAPI: 500
              };
              setLoading(false);
              reject(res.data.message);
            }
          })
          .catch((res) => {
            Helper.alert(res.response.data.message, 'error');
            APIResponses = {
              ...APIResponses,
              discountAPI: 500
            };
            DeleteVendors();
            DeleteEntity();
            DeletePrice();
            DeleteItem();
            setLoading(false);
            reject(res.response.data.message);
          });
      });
    };

    const ItemPricingCreateAPI = () => {
      console.log(response_item_id, 'pricing')
      if (!response_item_id || checkIfArrayIsEmpty(createItemPricing)) {
        return Promise.resolve();
      }
      if (!checkObjectProperties(createItemPricing)) {
        return Promise.resolve();
      }
      let api = Api;
      api.setUserToken();
      return new Promise((resolve, reject) => {
        api.axios().post(Settings.apiItemUrl+'/item_pricing', {
          "item_id":  response_item_id,
          "pricing_type": createItemPricing.pricing_type ? parseInt( createItemPricing.pricing_type) : null,
          "price_unit": createItemPricing.price_unit !== "" ? parseInt(createItemPricing.price_unit) : null,
          "price": createItemPricing.price !== "" ? parseInt(createItemPricing.price) : null
        })
        .then(function(res) {
          if (res.data.status === "success") {
            response_price_id = res.data.item_price_id;
            console.log(res.data.message);
            APIResponses = {
              ...APIResponses,
              priceAPI: 200
            };
            resolve(res.data);
          } else {
            Helper.alert(res.data.message, 'error');
            APIResponses = {
              ...APIResponses,
              priceAPI: 500
            };
            setLoading(false);
            reject(new Error(res.data.message));
          }
        })
        .catch(function(res) {
          Helper.alert(res.response.data.message, 'error');
          APIResponses = {
            ...APIResponses,
            priceAPI: 500
          };
          DeleteVendors();
          DeleteEntity();
          DeleteDiscount();
          DeleteItem();
          setLoading(false);
          reject(new Error(res.response.data.message));
        });
      });
    };

    const ItemBarcodeCreateAPI = () => {
      console.log(response_item_id, 'barcode') 
      if( !response_item_id || checkIfArrayIsEmpty(itemBarcode)){
        return;
      }
      let api = Api
      api.setUserToken()
      itemBarcode.forEach((element) => {
        api.axios().post(Settings.apiItemUrl+'/itembarcode',{
          "item_id":  response_item_id,
          "barcode": element.barcode
      }).then(function(res){
          if(res.data.status ==="success"){
            Helper.alert("Item Created Successfully!")
            navigate('/items') 
            setLoading(false)
          }
        }).catch((res) => {
          Helper.alert(res.response.data.message, 'error')
          DeleteVendors()
          DeleteEntity()
          DeleteDiscount() 
          DeletePrice() 
          DeleteItem()
          setLoading(false)
        })
      })
    }

// ----
 

//Deleting Handlers

  const DeleteItem = () => {
    if(!response_item_id){
      return;
    }
    let api = Api
    api.setUserToken()
    api.axios().delete(Settings.apiItemUrl+`/item/${response_item_id}`,{}).then(function(res){
      if(res.data.status === "success"){
        response_item_id = null 
        Helper.alert(res.data.message) 
      } else{
        Helper.alert(res.data.message, 'error')
      }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error') 
    })
    response_item_id = null 
  }

  const DeleteEntity = () => {
    if(!response_item_master_id || checkIfArrayIsEmpty(response_entity_id) ){
      return;
    }
    response_entity_id.forEach((element) => {
      let api = Api
      api.setUserToken()
      api.axios().delete(Settings.apiItemUrl+`/itementity/${response_item_master_id}/${element}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message) 
          response_entity_id = []
          response_item_master_id = null
        }else {
          Helper.alert(res.data.message, 'error')
        }
      }).catch((res) => {
        Helper.alert(res.response.data.message, 'error')
      })
    }) 
    response_entity_id = []
    response_item_master_id = null
  }

  const DeletePrice = () => {
    if(!response_price_id){
      return;
    }
    let api = Api
    api.setUserToken()
    api.axios().delete(Settings.apiItemUrl+`/itemprice/${response_price_id}`,{}).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        response_price_id= null
      }else {
        Helper.alert(res.data.message, 'error')
      }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error') 
    })
    response_price_id = null
  }
 
  const DeleteDiscount = () => {
    if(!response_discount_id ){
      return;
    }
    let api = Api
    api.setUserToken()
    api.axios().delete(Settings.apiItemUrl+`/itemdiscount/${response_discount_id}`,{}).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        response_discount_id = null
      } else {
        Helper.alert(res.data.message, 'error')
      }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error') 
    })
    response_discount_id = null
  }

  const DeleteVendors = () => {
    if(checkIfArrayIsEmpty(response_vendor_id) || !response_item_id  ){
      return;
    }
    response_vendor_id.forEach((element) => {
      let api = Api
      api.setUserToken()
      api.axios().delete(Settings.apiItemUrl+`/itemvendor/${response_item_id}/${element}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message) 
          response_vendor_id = []
        } else {
          Helper.alert(res.data.message, 'error')
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error') 
      })
    })
    response_vendor_id = []
  }


// ----
 
// --- grid objects

  const onEntityGridReady = (data) => {
    setGridReadyObj(data)
  }

  const onCellValue = (event) => {
    event.node.setDataValue("code", event.data.name)  
  } 

  const OpenEntityHandler = () => {
    setOpenEntity(true)
  }

  const onVendorGridReady = (data) => {
    setItemVendor(data)
  }

// ----

//Modules

  const EntityModule = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'entity',"Entity")}>
        <div className='container-fluid entity'>
          <AgGrid data={itemEntity} onAddClick={OpenEntityHandler} onGridReady={ onEntityGridReady } id="entity-grid" hideDelete={false} header={Columns_Headings_ENTITY} />
        </div>
        {openEntity ? <Entities entityGridObj={gridReadyObj} setEntityGridObj={setGridReadyObj} setEntites={setItemEntity} setOpenEntites={setOpenEntity} dataExportable={true} /> : ""}
      </Collapse>
    )
  }


  const VendorsModule = () => {
    return (
      <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'vendor',"Vendor")}>
        <div className='container-fluid vendor'>
          <AgGrid data={itemVendorGrid} onCellValueChanged={onCellValue} onGridReady={ onVendorGridReady } id="vendors-grid" hideDelete={false} header={Columns_Headings_VENDOR} />
        </div>  
      </Collapse>
    )
  }

// ---

  const SaveHandler = () => { 
    $('.vendors-grid .rs_grid_btn_save').trigger('click'); 
    $('.entity-grid .rs_grid_btn_save').trigger('click'); 
    let newItem = {
      "item_name": createItem.item_name,
      "display_name": createItem.item_name,
      "purchaseUoM": createItem.purchaseUoM,
      "item_group_id":  createItem.item_group_id,
      "taxcodes_id":  createItem.taxcodes_id,
      "itemmaster_type": createItem.itemmaster_type 
    }
    let validData = {
      ...newItem
    } 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }
    if(ValidateData(itemBarcode)){
      Helper.alert('Barcodes are missing', 'error')
      return;
    }
    if(checkObjectProperties(createItemDiscount)){
      if(!createItemDiscount.itemdiscounttype_id){
        Helper.alert('Discount Type Field is missing!', 'error')
        return;
      }
    }
    if(checkObjectProperties(createItemPricing)){
      if(!createItemPricing.pricing_type){
        Helper.alert('Pricing Type Field is missing!', 'error')
        return;
      }
    }
    ItemCreateAPI() 
  }

  //Grid Things:

  const Columns_Headings_ENTITY = [
    {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), inputType: "dropdown", options: entityName},
    {field:'code', headerName:Helper.getLabel(props.language,'code',"Code"), inputType: "dropdown", options: entityCode, editable: false},
    {field:'active', headerName:Helper.getLabel(props.language,'active',"Active"), inputType: "checkbox"},
    {field:'reason', headerName:Helper.getLabel(props.language,'reason',"Reason"), inputType: "dropdown", options: entityReason},
    {field:'comments', headerName:Helper.getLabel(props.language,'comments',"Comments") },
  ]

  const Columns_Headings_VENDOR = [
    {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), inputType: "dropdown", options: vendorName},
    {field:'code', headerName:Helper.getLabel(props.language,'code',"Code"), inputType: "dropdown", options: vendorCode, editable:false},
    {field:'preferred', headerName:Helper.getLabel(props.language,'preffered',"Preferred"), inputType: "checkbox"}, 
  ]

  // ----

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.item.item_create_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
    <div className='container-fluid'>
      <NavigationHeder title={Helper.getLabel(props.language,'create_new_item',"Create New Item")}>
        <LinkBtn isActive= {false} to="/master-data" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canCreate(frontendIds.masterData.item_master_modules.item.item_create_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
      {loading ? <SimpleLoading /> : ''}
      <div className={loading ? 'd-none': ''}>
        <Tabs navs={navs} showAll={true} scrollOffset="300" addComponentTop = {addComponentTop} addComponentRight={addComponentRight}>
          <Fragment>{<CreateItemGeneral createItem={createItem} purchaseUnit={purchaseUnit} setItemBarcode={setItemBarcode} itemBarcode={itemBarcode} setCreateItem={setCreateItem} />}</Fragment>
          <Fragment>{<CreateItemPriceDiscount createItemDiscount={createItemDiscount} setCreateItemDiscount={setCreateItemDiscount} createItemPricing={createItemPricing} setCreateItemPricing={setCreateItemPricing} />}</Fragment>
          <Fragment>{<CreateItemAttr createItem={createItem} setCreateItem={setCreateItem} />}</Fragment>
          {EntityModule()}
          {VendorsModule()}
        </Tabs>
      </div>
    </div> 
  )
}

// -----

const mapDispatchToProps = (dispatch) => {
  return {
    clearNotes: () => { dispatch({type:ActionTypes.CLEAR_NOTES})}
  }
}
  const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      notes:state.notes,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.masterData.item_master_modules.item.item_create_screen
  }

export default connect(mapStateToProps, mapDispatchToProps) (MasterComponentWraper((ApplySecurityRoles(ItemCreate, SecurityOptions))))