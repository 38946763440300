import React, { Component } from "react";
import Cart from "../../components/PurchaseRequisition/Cart";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";

export default class ShoppingCartScreen extends Component {
  render() {
    return (
      <ShopLayout
        backUrl="/purchase-requisition/shop"
        title="Shop > Cart"
        hideMoreBtn={true}
      >
        <Cart />
      </ShopLayout>
    );
  }
}
