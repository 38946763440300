import DropDownInput from '../../../../../../components/Forms/Dropdown';
import Input from '../../../../../../components/Forms/Input';

const AssignmentType = (props) => {

  const userType = [
    { value: 1, label: 'User' },
    { value: 2, label: 'Team' },
    { value: 3, label: 'Position' },
    { value: 4, label: 'Hierarchy' }
  ]

  const getAssignType = (e) => {
    props.getAssignParticipantData(+e.target.value)
  }

  return (
    <div className="card mb-3">
      <h5 className="card-header text-primary border-bottom">Assign users to this workflow element</h5>
      <div className="card-body">
        <div className="p-2">
          <div className='row'>
            <div className='row label-form'>
              <div className='col-lg-6 col-md-3'>
                <DropDownInput label="Participant" required options={userType} id='task_id' onChange={getAssignType} value={props.actionTaskData ? props.actionTaskData.assignee_type : ""} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignmentType;