import React, { Component } from "react";
import SimpleLoading from "../../Loading/SimpleLoading";

export default class OverlayLoader extends Component {
  render() {
    return (
      <div
        className="overlay_loader"
        onClick={(event) => event.stopPropagation()}
      >
        <SimpleLoading />
      </div>
    );
  }
}
