import React, { Fragment, useState, useEffect } from 'react'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Button from '../../inc/Button';
import MasterComonent from '../../Backend/MasterComonent'
import Alert from '../../inc/Alert';
import Collapse from '../../inc/Collapse';
import DropDownInput from '../../../components/Forms/Dropdown';
import $ from 'jquery';
import LinkBtn from '../../inc/LinkBtn';
import NavigationHeder from '../../Navigations/NavigationHeder';
import SimpleLoading from '../../Loading/SimpleLoading'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Tabs from '../../Widgets/Tabs/Tabs';
import Input from '../../Forms/Input';
import Checkbox from '../../Forms/Checkbox';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import InputDatePicker from '../../Forms/InputDatePicker';
import FormValidator from '../../../inc/FormValidator';
import { useNavigate } from 'react-router-dom';

const CreateTeamHeader = (props) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [teamHeader, setTeamHeader] = useState({ 
        "employee_id": null,
        "from_date":null,
        "to_date":null,
        "description":null,
        "PR_view_access": null,
        "RFP_view_access":null,
        "name": null,
        "teamheader_id": null
    })
    const [nameCheck, setNameCheck] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const [teamHeaderList, setTeamHeaderList] = useState([])
 
    const DefaultNavs = [
        {title:Helper.getLabel(props.language,'team_header','Team Header'), isActive: true, },
        {title:Helper.getLabel(props.language,'team_header_lines','Team Header Lines')},  
    ]

    let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [
        {name:'employee_id', displayName:Helper.getLabel(props.language,'employee','Employee'),types:['Required']},  
        {name:'description', displayName:Helper.getLabel(props.language,'description','Description'),types:['Required'] }, 
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()

    useEffect(() => {
        getEmployees()
        getTeamHeaderList()
    }, [])

    let employeeCalled = false
    let teamHeaderCalled = false

    const getEmployees = () => {
        if(employeeCalled) {
            return;
        }
        let api = Api
        employeeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
          if(res.data.status==="success"){
            let employeeARR = []
            res.data.data.forEach(item => {  
            employeeARR.push({
              value: item.employee_id,
              label: item.displayname
            })
           }) 
           setEmployeeList(employeeARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const getTeamHeaderList = () => {
        if(teamHeaderCalled) {
            return;
        }
        let api = Api
        teamHeaderCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/teamheader',{}).then(function(res){
          if(res.data.status==="success"){
            let teamHeaderARR = []
            res.data.data.forEach(item => {  
            teamHeaderARR.push({
              value: item.teamheader_id,
              label: item.name
            })
           }) 
           setTeamHeaderList(teamHeaderARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }
  
    const NameCheckHandler = (event) => {
        setNameCheck(!nameCheck)
    }

    const FromDateHandler = () => {
        setTeamHeader((prevState) => {
            return {
                ...prevState,
                from_date: $('.from_date').val()
            }
        })
    }

    const ToDateHandler = () => {
        setTeamHeader((prevState) => {
            return {
                ...prevState,
                to_date: $('.to_date').val()
            }
        })
    }

    const onInputChangeHandler = (e) => { 
        setTeamHeader((prevState) => {
            return {
                ...prevState,
                [e.target.name]:e.target.value
            }
        })
    }

    const PRhandler = (name, value) => {
        if(value == 'on'){
            setTeamHeader((prevState) => {
              return {
                ...prevState,
                PR_view_access: true
              }
            })
          }else {
            setTeamHeader((prevState) => {
              return {
                ...prevState,
                PR_view_access: false
              }
            })
          }
    }

    const RFPhandler = (name, value) => {
        if(value == 'on'){
            setTeamHeader((prevState) => {
              return {
                ...prevState,
                RFP_view_access : true
              }
            })
          }else {
            setTeamHeader((prevState) => {
              return {
                ...prevState,
                RFP_view_access: false
              }
            })
          }
    }

    const SaveHandler = () => {
        let validData = {
            ...teamHeader
          }
          if((nameCheck && !teamHeader.teamheader_id) || (!nameCheck && !teamHeader.name)){
            Helper.alert('Name Field missing!', 'error')
            return;
          }
          if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        }  
        setLoading(true)
        if(!nameCheck){ 
            let api = Api
            api.setUserToken()
            api.axios().post(Settings.apiUrl+'/teamheader',{
                "name": teamHeader.name,
                "team_lines":[
                    {
                        "employee_id": parseInt(teamHeader.employee_id),
                        "from_date": Helper.getDateForDb(teamHeader.from_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(teamHeader.from_date) : null,
                        "to_date": Helper.getDateForDb(teamHeader.to_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(teamHeader.to_date) : null,
                        "description": teamHeader.description,
                        "PR_view_access": teamHeader.PR_view_access,
                        "RFP_view_access": teamHeader.RFP_view_access
                    }
                ]
            }).then(function(res){
            if(res.data.status==="success"){
            Helper.alert(res.data.message)   
                setLoading(false)
                navigate('/team-header') 
            }
            }).catch((res) => { 
            Helper.alert(res.response.data.message, 'error') 
            setLoading(false)
            })
        }
        if(nameCheck){
            let api = Api
            api.setUserToken()
            api.axios().post(Settings.apiUrl+'/teamlines',{
                "employee_id": parseInt(teamHeader.employee_id),
                "teamheader_id": parseInt(teamHeader.teamheader_id),
                "from_date": Helper.getDateForDb(teamHeader.from_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(teamHeader.from_date) : null,
                "to_date": Helper.getDateForDb(teamHeader.to_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(teamHeader.to_date) : null,
                "description": teamHeader.description,
                "PR_view_access": teamHeader.PR_view_access,
                "RFP_view_access": teamHeader.RFP_view_access
            }).then(function(res){
            if(res.data.status==="success"){
            Helper.alert(res.data.message)   
                setLoading(false)
                navigate('/team-header') 
            }
            }).catch((res) => { 
            Helper.alert(res.response.data.message, 'error') 
            setLoading(false)
            })
        }
    }

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header_create_screen)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }


  return ( 
    <>
        <div className='procurement_settings'>
            <div className='container-fluid'>
                <NavigationHeder backUrl='/team-header' title={Helper.getLabel(props.language,'team_header_create',"Create Team Header")}>
                    <LinkBtn isActive= {false} to="/team-header" title={Helper.getLabel(props.language,'close',"Close")} /> 
                    <Button onClick={SaveHandler} isActive={true} icon='/images/icons/save-white.svg' title={Helper.getLabel(props.language,'save',"Save")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_create_screen_save_button)} /> 
                </NavigationHeder>  
                {loading ? <SimpleLoading /> : ''}
                <Collapse className='mb-4 default_team_header_tabs' open={true} title={Helper.getLabel(props.language,'defaults',"Defaults")}>
                    <Tabs disableSticky={true} navs={DefaultNavs} showAll={false} scrollOffset= "500">
                        <div className='container-fluid team_header'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                <Checkbox type='checkbox' onChange={NameCheckHandler} isChecked={nameCheck} label={Helper.getLabel(props.language,'name_exist',"Name Exist?")} />
                                </div>
                                {!nameCheck ? 
                                <div className='col-lg-3'>
                                <Input required={true} onChange={onInputChangeHandler} value={teamHeader.name} label={Helper.getLabel(props.language,'name',"Name")} name='name' />
                                </div>
                                :
                                <div className='col-lg-3'>
                                <DropDownInput required={true} onChange={onInputChangeHandler} value={teamHeader.teamheader_id} options={teamHeaderList} label={Helper.getLabel(props.language,'name',"Name")} name='teamheader_id' />
                                </div>}
                            </div>
                        </div>

                        <div className='container-fluid team_header_lines'>
                            <div className='row'>
                            <div className='col-lg-3'>
                                <DropDownInput required={true} onChange={onInputChangeHandler} value={teamHeader.employee_id} options={employeeList} label={Helper.getLabel(props.language,'employee',"Employee")} name='employee_id' />
                            </div>
                            <div className='col-lg-3'>
                                    <InputDatePicker label={Helper.getLabel(props.language,'from_date',"From Date")} inputClassName="from_date" value={teamHeader.from_date} onChange={FromDateHandler} name="from_date"/>
                            </div>
                            <div className='col-lg-3'>
                                    <InputDatePicker label={Helper.getLabel(props.language,'to_date',"To Date")} inputClassName="to_date" value={teamHeader.to_date} onChange={ToDateHandler} name="to_date"/>
                            </div>
                            <div className='col-lg-3'>
                                    <Input required={true} onChange={onInputChangeHandler} value={teamHeader.description} label={Helper.getLabel(props.language,'description',"Description")} name='description' />
                            </div>
                            <div className='col-lg-3'>
                                    <Checkbox type='checkbox' onChange={PRhandler} isChecked={teamHeader.PR_view_access} label={Helper.getLabel(props.language,'pr_view_access',"PR View Access")} name='PR_view_access' />
                            </div>
                            <div className='col-lg-3'>
                                    <Checkbox type='checkbox' onChange={RFPhandler} isChecked={teamHeader.RFP_view_access} label={Helper.getLabel(props.language,'rfp_view_access',"RFP View Access")} name='RFP_view_access' />
                            </div>
                            </div>
                        </div>
                    </Tabs>
                </Collapse>
            </div>
        </div>
    </>
    )
}

  
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
const SecurityOptions = {
gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header_create_screen
}
  
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(CreateTeamHeader, SecurityOptions))))