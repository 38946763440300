import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";

class SortSelect extends Component {
  render() {
    const { sortOptions } = this.props;
    return (
      <div
        className={`sort-select ${Helper.isRtl() ? "sort-select-rtl" : ""}
      `}
      >
        <select>
          {sortOptions.map((option, index) => (
            <option value={option.option} key={index + option.key}>
              Sort by:{" "}
              {Helper.getLabel(this.props.language, option.key, option.option)}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(SortSelect);
