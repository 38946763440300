import React from 'react'
import Input from '../../../../../../components/Forms/Input';

const CompletionPolicy = (props) => {

  const getApproverType = (e) => {
    props.getApproversType(e.target.value);
  }

  return (
    <div className="card mb-3">
      <h5 className="card-header text-primary border-bottom">Select the type of approver</h5>
      <div className="card-body">
        <div className="p-2">
          <div className="form-check">
            <label className="form-check-label" htmlFor="exampleRadios1">
              <input className="form-check-input me-2" type="radio" name="exampleRadios" id="exampleRadios1" onChange={getApproverType} value="1" checked={props.approversType == 1 ? true : false} />
              Single approver
            </label>
          </div>
          <div className="form-check">
            <label className="form-check-label" htmlFor="exampleRadios2">
              <input className="form-check-input me-2" type="radio" name="exampleRadios" id="exampleRadios2" onChange={getApproverType} value="2" checked={props.approversType == 2 ? true : false} />
              Majority of approvers
            </label>
          </div>
          <div className='d-flex'>
            <div className="form-check me-5">
              <label className="form-check-label" htmlFor="exampleRadios3">
                <input className="form-check-input me-2" type="radio" name="exampleRadios" id="exampleRadios3" onChange={getApproverType} value="3" checked={props.approversType == 3 ? true : false} />
                Percenatge of approvers
              </label>
            </div>
            {props.approversType == '3' ?
              <div className='col-lg-6 col-md-3'>
                <Input label="Percentage" required id='task_type_id' value={props.percenatgeValue} onChange={(e) => { props.setPercenatge(+e.target.value) }} />
              </div>
              : null}
          </div>
          <div className="form-check">
            <label className="form-check-label" htmlFor="exampleRadios4">
              <input className="form-check-input me-2" type="radio" name="exampleRadios" id="exampleRadios4" onChange={getApproverType} value="4" checked={props.approversType == 4 ? true : false} />
              All approvers
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompletionPolicy;