import React, { useState } from 'react'
import Input from '../../../../../../components/Forms/Input';
import DropDownInput from '../../../../../../components/Forms/Dropdown';

const TimeLimit = (props) => {

  const days = [
    { value: 1, label: 'Hour' },
    { value: 2, label: 'Day' }
  ]

  return (
    <div className="card mb-3">
      <h5 className="card-header text-primary border-bottom">Select the deadline for the assigned user(s) to complete this task</h5>
      <div className="card-body">
        <div className="p-2">
          <div className='row'>
            <div className='row label-form'>
              <div className='col-lg-6 col-md-3'>
                <DropDownInput label="Duration Type" options={days} onChange={(e) => { props.getTimeLimitType(+e.target.value) }} id='duration_type_id' value={props.timeLimitType} />
              </div>
              <div className='col-lg-6 col-md-3'>
                <Input inputType='number' label="Completion Time" id='completion_id' value={props.timeDuration} onChange={(e) => { props.getTimeLimit(+e.target.value) }} />
              </div>
              <div className='col-lg-6 col-md-3'>
                <Input inputType='number' label="Escalation Time" id='escalation_id' value={props.escalationTime} onChange={(e) => { props.getEscalationTime(+e.target.value) }} />
              </div>
            </div>
          </div>
          {/* <div className='mt-3 col-md-10'>
            <p>Days available per week</p>
            <div className='row'>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Monday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Tuesday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Wednesday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Thursday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Friday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Saturday
                </label>
              </div>
              <div className='form-check col-lg-3 col-md-3'>
                <label className='form-check-label'>
                  <input type="checkbox" className='form-check-input me-2' />
                  Sunday
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default TimeLimit;