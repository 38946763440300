import React, { Component } from 'react';

class Pagination extends Component {
    constructor(props){
        super(props);
        this.state = {
            agGrid: this.getCurrentAgObject(),
        }
    }
    componentDidMount(){
        this.setState({
            agGrid: this.getCurrentAgObject()
        })
    }
    afterChangeHander(){
        this.setState({
            agGrid: this.getCurrentAgObject()
        })
    }
    goFirstPage(e){
        this.state.agGrid.api.paginationGoToFirstPage();
        this.afterChangeHander();
    }
    goLastPage(e){
        this.state.agGrid.api.paginationGoToLastPage();
        this.afterChangeHander();
    }
    goNextPage(e){
        this.state.agGrid.api.paginationGoToNextPage();
        this.afterChangeHander();
    }
    goPrevPage(e){
        this.state.agGrid.api.paginationGoToPreviousPage();
        this.afterChangeHander();
    }
    goPageByNumber(e,pageNumber){
        this.state.agGrid.api.paginationGoToPage(--pageNumber);
        this.afterChangeHander();
    }
    getCurrentPageNumber(){

    }
    getCurrentAgObject(){
        let agGrid =this.props.getAgObject();
        return agGrid;
    }
    displayPaginationNumbers(totalPages){
        let agGrid = this.state.agGrid;
        let tempAr = [];
        let currentPage  = agGrid.api.paginationGetCurrentPage() + 1;
        for(let i = 1; i<=totalPages; i++){
            tempAr.push(<li key={i} className= {currentPage === i ? "page-item active" : "page-item"} onClick={ e=> this.goPageByNumber(e,i) }><span className="page-link">{i}</span></li>)
        }
        return (
            tempAr
        )
    }
    render() {
       
        let iconArrowLeft = "/images/icons/angle-left.svg";
        let iconArrowRight = "/images/icons/angle-right.svg";
        let agGrid = this.state.agGrid;
        if(!agGrid || !agGrid.api){
            return <></>
        }
        let totalPages = agGrid.api.paginationGetTotalPages();
        return (
            <nav className='d-flex align-items-center rs_grid_pagination'>
                <p className='rs_grid_page_status'>Showing {agGrid.api.paginationGetCurrentPage() +1 } to {agGrid.api.paginationGetTotalPages()} of {this.props.data.length} entries</p>
                <ul className="pagination">
                    <li className="page-item" onClick={ e=> this.goPrevPage(e) }><span className="page-link nex_prev">{<img src={iconArrowLeft} alet="Prev" />}</span></li>
                    { this.displayPaginationNumbers(totalPages) }
                    <li className="page-item"  onClick={ e=> this.goNextPage(e) }><span className="page-link nex_prev" >{<img src={iconArrowRight} alet="Next" />}</span></li>
                </ul>
            </nav>
        );
    }
}

export default Pagination;