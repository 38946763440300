import { Component } from "react";
import { Carousel, Modal, ModalBody } from "react-bootstrap";

class ImagePopupCarousel extends Component {
  render() {
    const { show, hide, activeIndex, children } = this.props;
    console.log(children);
    return (
      <Modal className="imagepopup" show={show} onHide={hide}>
        <ModalBody>
          <Carousel className="imagepopup-carousel" activeIndex={activeIndex}>
            {children?.map((child) => (
              <Carousel.Item className="imagepopup-carousel-item">
                {child}
              </Carousel.Item>
            ))}
          </Carousel>
        </ModalBody>
      </Modal>
    );
  }
}

export default ImagePopupCarousel;
