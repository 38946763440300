import React, { Component } from 'react';
import $ from 'jquery';
import Helper from '../../inc/Helper';
class Collapse extends Component {
    constructor(props){
        super(props);
        this.id = this.props.id ? this.props.id : Date.now();
        this.isEventApply = false;
        this.state = {
            isCollapsed: !this.props.open,
            className: this.props.className ? this.props.className : ''
        }
    }
    componentDidMount(){
        let selector = '#'+ this.id +' .rs_collaps_title';
        if(!this.isEventApply){
            $(selector).on('click',function(event){
                event.preventDefault();
                $(this).next('.rs_collapse_contents').slideToggle('fast','linear',function(){
                    $(this).closest('.rs_collapse').toggleClass('collapsed')
                    Helper.refreshAppScript();
                });
                return false;
            })
            this.isEventApply = true;
        }
        
    }
    render() {
        let className = 'rs_collapse ' + this.state.className;
        if(this.state.isCollapsed){
            className += ' collapsed';
        }
        return (
            <div id={this.id} className={className}>
                <div className='rs_collaps_title' open={true}>
                    <p>{this.props.title}</p>
                    <span className='cl_icon'></span>
                </div>
                <div className='rs_collapse_contents'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Collapse;