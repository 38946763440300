import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../inc/Api';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import MasterComonent from '../../../Backend/MasterComonent'; 
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import AgGrid from '../../../grid/ag/ag-grid';
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell';
import Alert from '../../../inc/Alert';
import ButtonNew from '../../../inc/ButtonNew';
import LinkBtn from '../../../inc/LinkBtn';
import SimpleLoading from '../../../Loading/SimpleLoading';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';

const GroupArchive = (props) => {

    const [vendorGroup, setVendorGroup] = useState([]) 
    const [loading, setLoading] = useState(true)
    let vendorGroupCalled = false
    const navigate = useNavigate()

    useEffect(() => {
      get_vendors_group_data() 
    }, [])

    const CellHandler = (event) => {   
      if(event.colDef.field === 'name') { 
        navigate(`/edit-vendors-group/${event.data.vendorgroup_id}`)
      }
    }

    const get_vendors_group_data = () => {
      if(vendorGroupCalled) {
        return;
      }
      let api = Api
      vendorGroupCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{}).then(function(res){
        if(res.data.status==="success"){  
          setVendorGroup(res.data.data.reverse())
          setLoading(false)
        }
      }).catch((res) => {
        Helper.alert(res.response.data.message, 'error') 
        setLoading(false)
      })
    }
  
    const DeleteHandler = (allRows) => {
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiVendorUrl+`/vendorgroup/${element.vendorgroup_id}`,{}).then(function(res){
            if(res.data.status==="success"){
              get_vendors_group_data()
              Helper.alert(res.data.message)
            }
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          get_vendors_group_data()
        })
    })
    }
  
    const gridLinkValueFormat = (params) => {
      return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }

    const Columns_Headings = [
      {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat},
      {field:'external_code', headerName:Helper.getLabel(props.language,'code',"Code"), editable: false},
    ]
    
    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.masterData.vendor_group.vendors_group)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }

  return ( 
    <div className='vendors_group_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/master-data' title={Helper.getLabel(props.language,'vendors_group',"Vendors Group")}>
        <ButtonNew to="/create-vendors-group" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.vendor_group.vendors_group_main_create_button)} /> 
        </NavigationHeder> 
          {loading ? <SimpleLoading /> : null}
          <AgGrid id="rs_vendors_group_list" hideDelete={!security.canDelete(!security.canCreate(frontendIds.masterData.vendor_group.vendors_group_main_delete_button))} onCellClicked={CellHandler} onDelete={DeleteHandler} header={Columns_Headings} data={vendorGroup} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.masterData.vendor_group.vendors_group
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(GroupArchive, SecurityOptions))))