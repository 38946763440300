import React, { Component } from "react";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import ShopResults from "../../components/PurchaseRequisition/ShopResults";

export default class ShopScreen extends Component {
  render() {
    return (
      <ShopLayout
        backUrl="/purchase-requisition/shop"
        title="Shop"
        hideMoreBtn={true}
      >
        <ShopResults />
      </ShopLayout>
    );
  }
}
