import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    accessLevels:{},
    isCalled:{}
}
const SecurityRolesReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_ACCESS_LEVELS){
        let oldAccessLevels = state.accessLevels;
        state = {
            ...state,
            accessLevels:{
                ...oldAccessLevels,
                [actions.gui_id]:actions.payload
            }
        }
    }
    if(actions.type === ActionTypes.RESET_ACCESS_LEVELS || actions.type === ActionTypes.SET_LOGOUT ){
        state = {
            ...state,
            accessLevels:{}
        }
    }
    return state;
}
export default SecurityRolesReducer;