import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Carousel, CarouselItem, Dropdown } from "react-bootstrap";

import Helper from "../../../inc/Helper";
import ShopItem from "../../../assets/shop-item.png";
import Rating from "../../../assets/rating.svg";
import Options from "../../../assets/options-dots.svg";
import Wishlist from "../../../assets/wishlist-icon.svg";
import WishlistActive from "../../../assets/wishlist-icon-active.svg";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import OverlayLoader from "./OverlayLoader";
import RsWithRouter from "../../inc/RsWithRouter";

class ShopItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      wishlisted: false,
      checked: false,
      quantity: 0,
    };

    this.addToCart = this.addToCart.bind(this);
    this.addToWishlist = this.addToWishlist.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.handleWishlistClick = this.handleWishlistClick.bind(this);
  }

  componentDidMount() {
    if (this.props.wishlistIds?.includes(this.props.item.item_id))
      this.setState({ wishlisted: true });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.selectedItems) !==
      JSON.stringify(prevProps.selectedItems)
    ) {
      if (this.props.selectedItems.includes(this.props.item.item_id)) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  addToCart(item) {
    console.log(item);
    let itemData = {
      type: 0,
      item_id: item.item_id,
      unit_price: parseInt(item.purchase_price) ?? null,
      ordered_qty: this.state.quantity,
    };

    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isLoading: true });
    api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/pr_cartline", itemData)
      .then(function (res) {
        that.setState({ isLoading: false });
        if (res.data?.status === "success") {
          that.props.incrementCartCount();
          that.setState({ quantity: 0 });
          Helper.alert("Item added to cart", "success");
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  addToWishlist(item) {
    let itemData = {
      item_id: item.item_id,
    };

    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/wishlist", itemData)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.props.incrementWishlistCount();
          Helper.alert(res.data?.message, "success");
        }
      })
      .catch(function (error) {
        that.setState({ wishlisted: !this.state.wishlisted });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  deleteFromWishlist = (item) => {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .delete(Settings.apiPurchaseRequisitionUrl + "/wishlist/" + item.item_id)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.props.decrementWishlistCount();
          that.setState({ wishlisted: !this.state.wishlisted });
          Helper.alert(res.data?.message, "success");
        }
      })
      .catch(function (error) {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  handleWishlistClick = (item) => {
    console.log("yeah");
    this.setState({ wishlisted: !this.state.wishlisted });
    if (this.state.wishlisted) {
      this.deleteFromWishlist(item);
    } else this.addToWishlist(item);
  };

  toggleChecked(id) {
    this.setState({ checked: !this.state.checked });
    this.props.handleSelect(id);
  }

  routeToDetailPage = (id) => {
    console.log(this.props);
    this.props.rs_router.navigate("/purchase-requisition/shop/" + id);
  };

  handleQuantityChange = (event) => {
    if (event.target.value < 0) return;
    this.setState({ quantity: event.target.value });
  };

  handleQuantityIncrement = () => {
    this.setState({ quantity: parseInt(this.state.quantity) + 1 });
  };

  handleQuantityDecrement = () => {
    if (this.state.quantity > 0)
      this.setState({ quantity: parseInt(this.state.quantity) - 1 });
  };

  render() {
    const { showCheckbox, item, showWishlistIcon } = this.props;

    return (
      <div
        className="prshop-itemcard"
        key={item?.item_id}
        onClick={() => this.routeToDetailPage(item.item_id)}
      >
        <div
          className="prshop-itemcard-carousel"
          onClick={(event) => event.stopPropagation()}
        >
          {item?.images ? (
            <Carousel controls={false}>
              {item.images.map((image) => (
                <CarouselItem className="prshop-itemcard-carousel-slide">
                  <img
                    src={`${image?.image}`}
                    alt={image?.image_name ?? "carousel slide"}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          ) : (
            <div className="prshop-itemcard-default-bg"></div>
          )}
        </div>
        <div className="prshop-itemcard-content">
          <div className="prshop-itemcard-content-titlerating">
            <div className="prshop-itemcard-content-titlerating-title">
              {item?.display_name}
            </div>
            <div className="prshop-itemcard-content-titlerating-rating">
              <img src={Rating} alt="rating" />
              {this.state.rating ?? "-"}
            </div>
          </div>
          <div className="prshop-itemcard-content-price">
            AED{" "}
            {item.purchase_price
              ? parseInt(item.purchase_price)?.toFixed(2)
              : "-"}
          </div>
          <div className="prshop-itemcard-content-quantity">
            <div>
              {Helper.getLabel(this.props.language, "quantity", "Quantity")}
            </div>
            <div>
              <span
                className="prshop-itemcard-content-quantity-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleQuantityDecrement();
                }}
              >
                -
              </span>{" "}
              <input
                type="number"
                value={this.state.quantity}
                onChange={(e) => this.handleQuantityChange(e)}
              />{" "}
              <span
                className="prshop-itemcard-content-quantity-button"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleQuantityIncrement();
                }}
              >
                +
              </span>
            </div>
          </div>
          <div className="prshop-itemcard-content-buttons">
            <Button
              className="prshop-itemcard-content-buttons-addtocart"
              variant="outline-primary"
              onClick={(event) => {
                event.stopPropagation();
                this.addToCart(item);
              }}
              disabled={this.state.quantity === 0}
            >
              {Helper.getLabel(
                this.props.language,
                "add_to_cart",
                "Add to Cart"
              )}
            </Button>
            <Dropdown
              className="prshop-itemcard-content-buttons-options"
              onClick={(event) => event.stopPropagation()}
            >
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                <img src={Options} alt="options" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="prshop-itemcard-content-buttons-options-wrapper">
                <Dropdown.Item className="prshop-itemcard-content-buttons-options-option">
                  {Helper.getLabel(
                    this.props.language,
                    "add_more_details",
                    "Add more details"
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {showWishlistIcon ? (
          <div
            className="prshop-itemcard-wishlist"
            onClick={(event) => {
              event.stopPropagation();
              this.handleWishlistClick(item);
            }}
          >
            <img
              src={this.state.wishlisted ? WishlistActive : Wishlist}
              alt="wishlist"
            />
          </div>
        ) : null}
        {showCheckbox ? (
          <div className="prshop-itemcard-checkbox">
            <input
              className="pr-wishlist-checkbox"
              type="checkbox"
              onClick={(event) => event.stopPropagation()}
              onChange={() => this.toggleChecked(item.item_id)}
              checked={this.state.checked}
            />
          </div>
        ) : null}
        {this.state.isLoading ? <OverlayLoader /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistIds: state.prShop.wishlistIds,
  };
};

export default connect(mapStateToProps)(RsWithRouter(ShopItemCard));
