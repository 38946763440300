import React, { useEffect, useState } from 'react'
import AgGridMemo from '../../../../../../components/grid/ag/AgGridMemo';
import Api from '../../../../../../inc/Api';
import Settings from '../../../../../../inc/Settings';
import Pagination from './Pagination';
import SimpleLoading from '../../../../../../components/Loading/SimpleLoading';

const User = (props) => {

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [forceRender, setForceRender] = useState(false);
  const [totalRecord, setTotalRecord] = useState(null);

  useEffect(() => {
    if (props.assignmentType === 1) {
      getUserData(1);
    }
    if (props.assignmentType === 2) {
      getTeamsData(1);
    }
    if (props.assignmentType === 3) {
      getUserDataASPerPosition();
    }
  }, [props.assignmentType])

  const getUserData = (pageNumber) => {
    setLoading(true);
    setTotalRecord(null);
    let api = Api;
    api.setUserToken()
    const formData = { "name": null }
    api.axios().post(Settings.loginUrl + `/workflow/get_users/${pageNumber}/10`, formData).then((res) => {
      if (res.data.status === "Success") {
        setTotalRecord(res.data.total_records);
        const data = res.data.data;
        const newArray = [];
        data.forEach((item) => {
          newArray.push({
            ...item,
            "id": item.user_id
          })
        })
        setUserData(newArray);
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    }
    )
  }

  const [teamLoading, setTeamLoading] = useState(false);
  const getTeamsData = (pageNumber) => {
    setTeamLoading(true);
    setTeamsData([]);
    setTotalRecord(null);
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.loginUrl + `/workflow/get_teams/${pageNumber}/10`).then((res) => {
      if (res.data.status === "success") {
        const data = res.data.data;
        setTotalRecord(res.data.total_records);
        const newArray = [];
        data.forEach((item) => {
          newArray.push({
            ...item,
            "id": item.teamheader_id
          })
        })
        setTeamsData(newArray);
        setForceRender(!forceRender);
        setTeamLoading(false);
      }
    }).catch((error) => {
      setTeamLoading(false);
      console.log(error);
    })
  }

  const [newPositionData, setNewPositionData] = useState([]);
  const getUserDataASPerPosition = () => {
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiOrgUrl + '/workflow/get_positions').then((res) => {
      if (res.data.status === "success") {
        const data = res.data.data;
        const newArrays = [];
        if (props.actionTaskData) {
          const saveData = props.actionTaskData.assignee;
          // saveData.map((item) => {
          //   const newData = data.filter((fItem) => fItem.position_id === item)[0];
          //   newArrays.push(newData);
          // })
          setNewPositionData(saveData);
        }
        const newArray = [];
        data.forEach((item) => {
          newArray.push({
            ...item,
            "id": item.position_id
          })
        })
        setPositionData(newArray);
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const User_Table_Column_Headings = [
    { field: 'user_id', headerName: 'Id' },
    { field: 'email_address', headerName: 'Email' },
    { field: 'employee_display_name', headerName: 'Name' }
  ]

  const Team_Table_Column_Headings = [
    { field: 'teamheader_id', headerName: 'Id' },
    { field: 'name', headerName: 'Name' }
  ]

  const Group_Table_Column_Headings = [
    { field: 'position_id', headerName: 'Position Id' },
    { field: 'name', headerName: 'Group Name' },
    { field: 'code', headerName: 'Code' }
  ]

  const getData = (event) => {
    const data = event.api.getSelectedRows();
    const arrayData = []
    data.forEach((item) => {
      arrayData.push(item.id);
    })
    props.userEntityData(arrayData);
  }

  const onFirstDataRendered = (params) => {
    params.api.forEachNode((node) => {
      const data = node.data.id;
      const existData = props.actionTaskData.assignee;
      if (existData.includes(data)) {
        node.setSelected(true);
      }
    });
  }

  const goPageByNumberUser = (pageNumber) => {
    setPageNumber(pageNumber);
    getUserData(pageNumber);
  };

  const goPageByNumberTeam = (pageNumber) => {
    setPageNumber(pageNumber);
    getTeamsData(pageNumber);
  };

  return (
    <>
      {props.assignmentType === 1 && userData.length ? (
        <>
          {loading ? <SimpleLoading /> : null}
          <AgGridMemo data={userData} header={User_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} onFirstDataRendered={props.actionTaskData && props.actionTaskData.assignee.length ? onFirstDataRendered : ""} hideFooter={true} />
          <Pagination totalPages={Math.ceil(totalRecord / 10)} currentPage={pageNumber} onPageChange={goPageByNumberUser} totalRecord={totalRecord} />
        </>
      ) : null}
      {props.assignmentType === 2 && teamsData.length ? (
        <>
        {!teamsData.length ? <SimpleLoading /> : null}
          <AgGridMemo data={teamsData} header={Team_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} onFirstDataRendered={props.actionTaskData && props.actionTaskData.assignee.length ? onFirstDataRendered : ""} hideFooter={true} forceRender={forceRender} />
          <Pagination totalPages={Math.ceil(totalRecord / 10)} currentPage={pageNumber} onPageChange={goPageByNumberTeam} totalRecord={totalRecord} />
        </>
      ) : null}
      {props.assignmentType === 3 && positionData.length ? (
        <>
          <AgGridMemo data={positionData} header={Group_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} onFirstDataRendered={props.actionTaskData && props.actionTaskData.assignee.length ? onFirstDataRendered : ""} />
        </>
      ) : null}
      {/* {
        props.assignmentType === '4' ?
        <AgGridMemo data={positionData} header={Group_Table_Column_Headings} height={"300px"} allBtnHide={true} onSelectionChanged={getData} viewOnly={true} singleRowSelect={true} hideFooter={true} />
      : null } */}
    </>
  )
}

export default User;