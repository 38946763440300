import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComonent from '../../Backend/MasterComonent'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'
import LinkBtn from '../../inc/LinkBtn'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'

const ItemArchive = (props) => {
    const [items, setItems] = useState([])
    const [barcodes, setBarcodes] = useState([])
    const [loading, setLoading] = useState(true)
    let itemsCalled = false 
    const navigate = useNavigate()
  
    useEffect(() => {
      get_items_data()
    }, [])
  
    const CellHandler = (event) => {   
      if(event.colDef.field === 'name') {  
        navigate(`/edit-item/${event.data.item_id}`)
      }
    }
  
    const get_items_data = () => {
      if(itemsCalled){
        return;
      }
      let api = Api
      itemsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiItemUrl+'/item',{}).then(function(res){
        if(res.data.status==="success"){   
          setItems(res.data.data.reverse())
          setLoading(false)
        }
      }).catch((res) => { 
        setLoading(false)
        Helper.alert(res.response.data.message, 'error')
      })
    }
   
    const DeleteHandler = (allRows) => {
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiItemUrl+`/item/${element.item_id}`,{}).then(function(res){
            if(res.data.status==="success"){
              get_items_data()
              Helper.alert(res.data.message)
            }
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          get_items_data()
        })
    })
    }
  
    const gridLinkValueFormat = (params) => {
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }
  
    const Columns_Headings = [ 
        {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat, editable: false},
        {field:'display_name', headerName:Helper.getLabel(props.language,'display_name',"Display Name"), editable: false}, 
        {field:'item_id', headerName:Helper.getLabel(props.language,'item_id',"Item"), editable: false},
        // {field:'barcodes', headerName:Helper.getLabel(props.language,'barcodes',"Barcodes"), options: barcodes, inputType: "dropdown", editable: false},
    ]
  
  
    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.masterData.item_master_modules.item.item)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }
  
  return ( 
    <div className='items_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/master-data' title={Helper.getLabel(props.language,'items',"Items")}>
        <ButtonNew to="/create-item" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.item_master_modules.item.item_main_create_button)} /> 
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : null}
        <AgGrid id="rs_items_list" hideDelete={!security.canDelete(!security.canCreate(frontendIds.masterData.item_master_modules.item.item_main_delete_button))} onCellClicked={CellHandler} onDelete={DeleteHandler} header={Columns_Headings} data={items} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div> 
  )
}


const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.masterData.item_master_modules.item.item
  }
  
  
  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ItemArchive, SecurityOptions))))