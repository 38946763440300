import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    vendor:null,
    isVendorLoaded:false
}
const VendorReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_USER_VENDOR){
        state = {
            ...state,
            isVendorLoaded:true,
            vendor:actions.payload
        }
    }
    return state;
}
export default VendorReducer;