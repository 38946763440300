import React, { Component } from "react";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import Wishlist from "../../components/PurchaseRequisition/Wishlist";

class WishlistScreen extends Component {
  render() {
    return (
      <ShopLayout
        backUrl="/purchase-requisition/shop"
        title="Shop > Wishlist"
        hideMoreBtn={true}
      >
        <Wishlist />
      </ShopLayout>
    );
  }
}

export default WishlistScreen;
