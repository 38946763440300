import React from 'react'
import './AddressPopup.css'
import AddressContainer from '../Address Container/AddressContainer'
import $ from 'jquery'
const AddressPopup = (props) => {

  const CloseModalHandler = (e) => {
      e.preventDefault()
      props.closeBtnClick()
  }
  if(props.close){
    $('html').removeClass('popup_open');
    return <></>
  }
  $('html').addClass('popup_open');
  return (
    <div className='address-modal-overlay'>
      <div className='address_pop_inner'>
        <div className='container mt-md-5 mt-lg-3'>
            <div className='row gx-1'>
                <div className='col-11 d-flex flex-column justify-content-start'>
                  <AddressContainer {...props} />
                </div>
              <div className='col-1 d-flex align-items-center '>
                  <div className='close-modal mb-auto'>
                    <button onClick={CloseModalHandler}>X</button>
                  </div>
              </div>
            </div>
        </div>
      </div>
      
  </div>
  )
}

export default AddressPopup;

