import React from 'react'
import { connect } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Tabs from '../../Widgets/Tabs/Tabs';
import TabInfoWidget from './TabInfoWidget';
import Invoice from './invoice.svg';
import Close from './close.svg';
import UserFeed from './UserFeed';

const RightSection = (props) => {
  let navs = [
    {title:Helper.getLabel(props.language,'request_for_quotation','Request for Quotation') },
    {title:Helper.getLabel(props.language,'purchase_orders','Purchase Orders')},
    {title:Helper.getLabel(props.language,'release_orders','Release Orders')},
    {title:Helper.getLabel(props.language,'contract','Contract')},
    {title:Helper.getLabel(props.language,'blanked_agreement','Blanked Agreement')},
    {title:Helper.getLabel(props.language,'good_receiving_note','Good receiving note')},
    {title:Helper.getLabel(props.language,'work_completion','Work Completion')},
    {title:Helper.getLabel(props.language,'invoice','Invoice'), isActive: true},
];
  return (
    <section className='user_feed_area'>
      <div className='container-fluid'>
        <div className='row feed_header'>
          <Tabs navs={navs} showAll={false} scrollOffset="300">  
            <div className='feed_tabs_invoice'>
              <div className='invoice_info_header'>
                <div className='row'>
                  <div className='col-6'>
                    <h2 className='p-2'>Total Request for Quotations: 264</h2>
                  </div>
                  <div className='col-6'> 
                    <div className='invoice_info_header_close'>
                      <img src={Close} className='img-fluid' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-row d-flex justify-content-start align-items-start'>                  <div className='invoice_btn'>
                <button>
                <span className='me-1'><img src={Invoice} className='img-fluid' /></span>
                Create New Invoice</button>
              </div>
              </div>
              <div className='flex-row d-flex justify-content-start align-items-start'>
                <TabInfoWidget /> 
                <TabInfoWidget /> 
                <TabInfoWidget /> 
                <TabInfoWidget />   
              </div> 
             </div>
          </Tabs>  
        </div>
 
        <div className='row'>
          <UserFeed />
          <UserFeed />
          <UserFeed />
        </div>
         
      </div>
    </section>
  )
}
 


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list
}


export default connect(mapStateToProps) (ApplySecurityRoles(RightSection, SecurityOptions))