import React, {useState, useEffect, Fragment} from 'react'
import { connect } from 'react-redux' 
import Button from '../../inc/Button'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import LinkBtn from '../../inc/LinkBtn'
import Settings from '../../../inc/Settings'
import NavigationHeder from '../../Navigations/NavigationHeder'
import '../Organisation Unit.css'
import Helper from '../../../inc/Helper'
import Checkbox from '../../Forms/Checkbox'
import Api from '../../../inc/Api'
import DropDownInput from '../../Forms/Dropdown' 
import { useNavigate } from 'react-router-dom'
import FormValidator from '../../../inc/FormValidator'
import Input from '../../Forms/Input'
import Gui_id_list from '../../../inc/Gui_id_list'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Alert from '../../inc/Alert'
import SimpleLoading from '../../Loading/SimpleLoading'

const NewOrg = (props) => {
  const [createOrganisation, setCreateOrganisation] = useState({})
  const [orgs, setOrgs] = useState([]) 
  const [parentOrg, setParentOrg] = useState([])
  const [owner, setOwner] = useState([])
  const [delegated, setDelegated] = useState([])
  const [managerPos, setManagerPos] = useState([])
  const [delegatedManagerPos, setDelegatedManagerPos] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'organizationtype_id', displayName:Helper.getLabel(props.language,'organization','Organization'),types:['Required'] },
        {name:'name', max: 40, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required'] }, 
        {name:'shortname', max: 20, displayName:Helper.getLabel(props.language,'shortname','Short Name'),types:['Required']}, 
        {name:'status', displayName:Helper.getLabel(props.language,'status','Status'),types:['Required']}
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()

    //API Reduction Call 
      let orgTypeListCalled = false 
      let employeeCalled = false
      let orgDataCalled = false
    //End

  useEffect(() => { 
    get_organisation_type_list()
    employee_Data()
    get_orgs_data() 
  }, [])

  const get_organisation_type_list = () => {
    if(orgTypeListCalled){
      return;
    }
    let api = Api
    orgTypeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/organizationType',{}).then(function(res){
      if(res.data.status==="success"){ 
        let Organisation_List_Type_API = []
        res.data.data.forEach(item => {  
         Organisation_List_Type_API.push({
           value: item.organizationtype_id,
           label: item.name
         })
        })
        setOrgs(Organisation_List_Type_API)
      }  
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const get_orgs_data = () => {
    if(orgDataCalled){
      return;
    }
    let api = Api
    orgDataCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+`/organization`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
         UPDATED_STATE.push({
          value: element.organization_id,
          label: element.name
        })
      })
      setParentOrg(UPDATED_STATE)
      }
    })
  }

  const employee_Data = () => {
    if(employeeCalled){
      return;
    }
    let api = Api
    employeeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){  
        let Dropdown_DATA = []
        res.data.data.forEach(item => {  
          Dropdown_DATA.push({
            value: item.employee_id,
            label: `${item.code} - ${item.displayname}`
          })
        })
        setOwner(Dropdown_DATA)
        setDelegated(Dropdown_DATA)
        setManagerPos(Dropdown_DATA)
        setDelegatedManagerPos(Dropdown_DATA)
      }
    })
  }

  const Status = [
    {
      label: 'Active',
      value: true
    },
    {
      label: 'In-Active',
      value: false
    }
  ]

  const ChangeOrgHandler = (event) => { 
    let Organisation_ID = event.target.value
     setCreateOrganisation((prevState) => {
      return {...prevState, organizationtype_id: Organisation_ID}
     })
  }
  const ChangeNameHandler = (event) => {
    let Name = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, name: Name}
    })
  }
  const ChangeShortNameHandler = (event) => {
    let ShortName = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, shortname: ShortName}
    })
  }
  const ChangeParentOrgHandler = (event) => {
    let ParentOrg = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, organization_id: ParentOrg}
    })
  }
  const ChangeStatusHandler = (event) => {
    let Status = event.target.value 
    console.log(Status)
    setCreateOrganisation((prevState) => {
      return {...prevState, status:Status }
    })
  }
  const ChangeExtCodeHandler = (event) => {
    let ExternalCode = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, external_code: ExternalCode}
    })
  }
  const ChangeOwnerHandler = (event) => {
    let Owner = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, owner: Owner}
    })
  }
  const ChangeDelegatedHandler = (event) => {
    let DelegatedOwner = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, delegated_owner: DelegatedOwner}
    })
  }
  const ChangeManagerHandler = (event) => {
    let Manager = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, manager_position: Manager}
    })
  }
  const ChangeDelegatedManagerHandler = (event) => {
    let DelegatedManager = event.target.value
    setCreateOrganisation((prevState) => {
      return {...prevState, delegated_manager_position: DelegatedManager}
    })
  }
  const ChangeWorkflowHandler = (name, value) => {
    if(value == "on"){
      setCreateOrganisation((prevState) => {
        return {...prevState, workflow_execution_method: true}
      }) 
    }else {
      setCreateOrganisation((prevState) => {
        return {...prevState, workflow_execution_method: false}
      }) 
    } 
  }

  const OrganizationModule = () => {
    return (
    <div className='row mb-3'>
      <div className='col-lg-3'> 
        <DropDownInput label={Helper.getLabel(props.language,'organization_type','Organization Type')} options={orgs} id='organization_id' required={true} value={createOrganisation.organizationtype_id} onChange={ChangeOrgHandler} />
      </div>

      <div className='col-lg-3'> 
        <Input type='text' label={Helper.getLabel(props.language,'name','Name')} value={createOrganisation.name} required={true} onChange={ChangeNameHandler} />
      </div>

      <div className='col-lg-3'> 
        <Input type='text' label={Helper.getLabel(props.language,'shortname','Short Name')} value={createOrganisation.shortname} required={true} onChange={ChangeShortNameHandler} />
      </div>

      <div className='col-lg-3'> 
        <DropDownInput value={createOrganisation.organization_id} id='parent_organization' label={Helper.getLabel(props.language,'parent_organization','Parent Organisation')} onChange={ChangeParentOrgHandler} options={parentOrg} />
      </div>

      <div className='col-lg-3'> 
        <DropDownInput label={Helper.getLabel(props.language,'status','Status')} options={Status} id='status_org'  required={true}  value={createOrganisation.status} onChange={ChangeStatusHandler} />
      </div>

      <div className='col-lg-3'> 
        <Input type='text' label={Helper.getLabel(props.language,'external_code','External Code')} value={createOrganisation.external_code} onChange={ChangeExtCodeHandler} />
      </div>

      <div className='col-lg-3'> 
        <DropDownInput id='owner' value={createOrganisation.owner} onChange={ChangeOwnerHandler} label={Helper.getLabel(props.language,'owner','Owner')} options={owner} />
      </div>

      <div className='col-lg-3'> 
        <DropDownInput id='delegated' value={createOrganisation.delegated_owner} onChange={ChangeDelegatedHandler} label={Helper.getLabel(props.language,'delegated','Delegated')} options={delegated}  />
      </div>

      <div className='col-lg-3'> 
        <DropDownInput id='manager_position' value={createOrganisation.manager_position} onChange={ChangeManagerHandler} label={Helper.getLabel(props.language,'manager_position','Manager Position')} options={managerPos} />
      </div>

      <div className='col-lg-3'> 
        <DropDownInput id='delegated_manager_position' value={createOrganisation.delegated_manager_position} onChange={ChangeDelegatedManagerHandler} label={Helper.getLabel(props.language,'delegated_manager_position','Delegated Manager Position')} options={delegatedManagerPos} />
      </div>

      <div className='col-lg-3'>
        <Checkbox type='checkbox' isChecked={createOrganisation.workflow_execution_method} onChange={ChangeWorkflowHandler} label={Helper.getLabel(props.language,'workflow_method','Workflow Method Execution')} /> 
      </div> 
    </div>)}

  const SaveHandler = () => { 
    let validData = {
      ...createOrganisation
    } 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
  } 
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().post(Settings.apiOrgUrl+`/organization`,{
      "organizationtype_id": createOrganisation.organizationtype_id ? parseInt(createOrganisation.organizationtype_id) : null,
      "name":createOrganisation.name !== "" ? createOrganisation.name : null,
      "shortname": createOrganisation.shortname !== "" ? createOrganisation.shortname : null,
      "owner":createOrganisation.owner ? parseInt(createOrganisation.owner) : null,
      "delegated_owner": createOrganisation.delegated_owner ? parseInt(createOrganisation.delegated_owner) : null,
      "manager_position": createOrganisation.manager_position ? parseInt(createOrganisation.manager_position) : null,
      "delegated_manager_position": createOrganisation.delegated_manager_position ? parseInt(createOrganisation.delegated_manager_position) : null,
      "workflow_execution_method": createOrganisation.workflow_execution_method == true ? 1 : 0,
      "external_code": createOrganisation.external_code !== "" ? createOrganisation.external_code : null,
      "translationsource_id": createOrganisation.translationsource_id ,
      "status": createOrganisation.status ? parseInt(createOrganisation.status) : null,
      "parent_organization_id": createOrganisation.organization_id ? parseInt(createOrganisation.organization_id) : null
    }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        setLoading(false)
        navigate('/organisation-unit')
      }
    }).catch((res) => { 
      setLoading(false)
      Helper.alert(res.response.data.message, 'error')
    }) 
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.unit_organization.unit_organization_create_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
    <div className='new-organsation-unit'>
      <div className='container-fluid'>
        <NavigationHeder title={Helper.getLabel(props.language,'new_org_unit',"New Organisation Unit")}>
          <LinkBtn isActive= {false} to="/organisation-unit" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
          <Button isDisable={!security.canCreate(frontendIds.setupForms.unit_organization.unit_organization_create_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder>

        {loading ? <SimpleLoading /> : ( 
        <div className='new-organisation-section'>
          {OrganizationModule()}
        </div>)}
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.unit_organization.unit_organization_create_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(NewOrg, SecurityOptions))))