import React, { Component } from 'react';
import Helper from '../../inc/Helper';
import Button from '../inc/Button';
import RsWithRouter from '../inc/RsWithRouter';
import $ from 'jquery';
/**
 * leftItems: Functions
 * moreOptions: function
 */
class NavigationHeder extends Component {
    componentDidMount(){
        let mainHeaderHeight = $('.main_header').outerHeight();
        Helper.makeSticky('app_navigation',{topSpace: mainHeaderHeight})
    }
    onBackClickHandler(e){
        //this.props.history.goBack()
        if(this.props.backUrl){
            this.props.rs_router.navigate(this.props.backUrl)
        }else{
            this.props.rs_router.navigate(-1)
        }
    }
    render() {
        const arrowIcon = '/images/icons/arrow-left.svg';
        return (
            <div className='archive_header_section app_navigation' id="app_navigation">
                <div className='app_navigation_left'>
                    {!this.props.hideBackButton  ? <div className='app_navigation_back'><Button icon={arrowIcon} onClick={ e=> this.onBackClickHandler(e)}/></div> : ''}
                    {this.props.title ? <div className='app_nav_title' dangerouslySetInnerHTML={{__html:this.props.title}}></div> : '' }
                    {this.props.leftItems ? this.props.leftItems() : ''}
                </div>
                <div className='app_navigation_right'>
                    { this.props.children }
                    {this.props.hideMoreBtn ? null : (
                        <div className='rs_nav_more_options'>
                        <Button icon="/images/icons/more-vertical.svg" className="btn_more" />
                        {
                            ( this.props.moreOptions &&  typeof this.props.moreOptions === 'function' ) ? <div className='btn_more_dropdown'>{this.props.moreOptions() } </div>: ''
                        }
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default RsWithRouter(NavigationHeder);