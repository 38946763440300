import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Helper from "../../../inc/Helper";

class OrderSummary extends Component {
  getTotal() {
    const subtotal = this.props.subtotal;
    const tax = this.props.tax;
    const discount = this.props.discount;
    return (parseInt(subtotal) + parseInt(tax) - parseInt(discount))?.toFixed(
      2
    );
  }
  render() {
    let { title, itemCount, subtotal, discount, tax } = this.props;
    return (
      <div className="widget_order_summary">
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-title">{title}</span>
          {itemCount ? (
            <span className="widget_order_summary-row-label">
              {itemCount} items
            </span>
          ) : null}
        </div>
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-label">
            {Helper.getLabel(this.props.language, "subtotal", "Subtotal")}
          </span>
          <span className="widget_order_summary-row-value">
            $ {subtotal ? parseInt(subtotal)?.toFixed(2) : "0.00"}
          </span>
        </div>
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-label">
            {Helper.getLabel(this.props.language, "discount", "Discount")}
          </span>
          <span className="widget_order_summary-row-value">
            $ {discount ? parseInt(discount)?.toFixed(2) : "0.00"}
          </span>
        </div>
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-label">Tax</span>
          <span className="widget_order_summary-row-value">
            $ {tax ? parseInt(tax)?.toFixed(2) : "0.00"}
          </span>
        </div>
        <hr />
        <div className="widget_order_summary-row">
          <span className="widget_order_summary-row-total">Grand Total</span>
          <span
            className="widget_order_summary-row-value"
            style={{ colo: "white" }}
          >
            $ {this.getTotal()}
          </span>
        </div>
        <hr />
        {!this.props.hideCheckoutButton ? (
          <Link to={itemCount > 0 ? "/purchase-requisition/checkout" : ""}>
            <button
              className={itemCount === 0 ? "widget_order_summary-disabled" : ""}
              disabled={itemCount === 0}
            >
              Proceed to Checkout
            </button>
          </Link>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(OrderSummary);
