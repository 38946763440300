import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Gui_id_list from '../../../../inc/Gui_id_list';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import Helper from '../../../../inc/Helper';
import MasterComonent from '../../../Backend/MasterComonent';
import Alert from '../../../inc/Alert';
import Button from '../../../inc/Button';
import LinkBtn from '../../../inc/LinkBtn';
import Input from '../../../Forms/Input';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import {  useParams, useNavigate } from 'react-router-dom';
import FormValidator from '../../../../inc/FormValidator';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import Collapse from '../../../inc/Collapse';
import SimpleLoading from '../../../Loading/SimpleLoading';

const EditBusiness = (props) => {

  const [editBusiness, setEditBusiness] = useState({})
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  let businessCalled = false

  useEffect(() => {
    getVendorBusinessData()
  }, [])

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'name', max: 50, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
   validationConfigure()
   
   const getVendorBusinessData = () => {
    if(businessCalled){
      return;
    }
    setLoading(true)
    let api = Api
    businessCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/businessclassification/${id}`,{}).then(function(res){
      if(res.data.status==="success"){  
        setEditBusiness(res.data.data)
        setLoading(false)
      }
    }).catch((res) => {
      setLoading(false)
    })
}

  const NameChangeHandler = (event) => {
    let name = event.target.value
    setEditBusiness((prevState) => {
      return {
        ...prevState,
        description: name
      }
    })
  }

  const BussinessClassModule = () => {
    return (
      <Collapse title={Helper.getLabel(props.language,'vendor_business_group','Vendor Business Group')} className="mb-4 general_tabs" open={true}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3'> 
              <Input label={Helper.getLabel(props.language,'name',"Name")} type='text' required={true} hasError={validator.hasError('name')} value={editBusiness.description} onChange={NameChangeHandler} />
            </div>
          </div>
        </div>
      </Collapse>
    )
  }

  const SaveHandler = () => {
    let newBusiness = { 
      "name": editBusiness.description, 
    }
    let validData = {
      ...newBusiness
    } 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }   
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiVendorUrl+`/businessclassification/${editBusiness.bussinessclassification_id}`,{
      "description": editBusiness.description !== "" ? editBusiness.description : null 
  }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        getVendorBusinessData()
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
    getVendorBusinessData()
  })
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_business_classification.vendors_business_classification)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return (
    <div className='container-fluid'>
      <NavigationHeder title={ `<span style="color:#000;">${Helper.getLabel(props.language,'edit_vendor_business_classification_group',"Edit Vendor Business Classification Group")} #</span> ${editBusiness.description} ` }>
        <LinkBtn isActive= {false} to="/master-data" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canCreate(frontendIds.masterData.vendor_business_classification.vendors_business_classification_edit_screen_save_button)}  isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
      {loading ? <SimpleLoading /> : (BussinessClassModule())}
    </div> 
  )
}
  
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_business_classification.vendors_business_classification
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditBusiness, SecurityOptions))))