import $ from 'jquery';
import '../../../../assets/js/chosen/chosen.jquery.js';
import '../../../../assets/js/chosen/chosen.min.css';
import Helper from '../../../../inc/Helper.js';
class SimpleDropdownSelector {
    init(params) {
        this.value = params.value;
        this.input = document.createElement("select");
        //Create and append the options
        var placeHolderOption = document.createElement("option");
        placeHolderOption.value = "";
        placeHolderOption.text = "Select";
        placeHolderOption.setAttribute("disabled", "");
        placeHolderOption.setAttribute("selected", "");
        placeHolderOption.setAttribute("hidden", "");
        this.input.appendChild(placeHolderOption);
        let values = params.values;
        if (params.colDef.optionsFilter) {
            values = params.colDef.optionsFilter(params.values, params);
        }
        for (var i = 0; i < values.length; i++) {
            var option = document.createElement("option");
            option.value = values[i].value;
            option.text = values[i].label;
            this.input.appendChild(option);
        }
        const att = document.createAttribute("class");
        // Set the value of the class attribute:
        att.value = "rs_grid_simple_dropdown";
        this.input.setAttributeNode(att);
        //this.input.id = 'input';
        //this.input.type = 'select';
        if (params.colDef.inputId) {
            this.input.id = params.colDef.inputId;
        }
        this.input.value = this.value;

        this.input.addEventListener('input', (event) => {
            this.value = event.target.value;
        });

    }
    getGui() {
        return this.input;
    }
    getValue() {
        // this simple editor doubles any value entered into the input
        return this.value;
    }

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    }
    focusIn() {
        $('.ag-cell').removeClass('ag-cell-focus');
        $(this.input).closest('.ag-cell').addClass('ag-cell-focus');
        if (typeof this.input.select === 'function') {
            this.input.select();
        }
        this.input.select();
    }
    focusOut() {
        $(this.input).closest('.ag-cell').removeClass('ag-cell-focus');
    }
    destroy() {
        //alert('destroy')
        $(this.input).chosen('destroy');
    }
    // after this component has been created and inserted into the grid
    afterGuiAttached() {
        let that = this;
        $(this.input).closest('.ag-cell').css({ overflow: 'visible' })
        $(this.input).chosen({
            disable_search_threshold: 1,
            rtl: Helper.isRtl()
        }).change(function (event) {
            that.value = event.target.value;
        });
    }
}
export default SimpleDropdownSelector;