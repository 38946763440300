import React, { Component } from 'react';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import FileListItem from './FileListItem';

class FileUploaderList extends Component {
    constructor(props){
        super(props);
        this.state = {
            files:[],
            isLoading:false
        }
    }
    componentDidMount(){
        this.loadFiles();
    }
    loadFiles(){
        let that = this;
        let api = Api;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        let apiUrl = Settings.apiUrl + '/attachment';
        let attachmentsource_integrator= this.props.attachmentsource_integrator;
        let source_id= this.props.source_id;
        if(attachmentsource_integrator &&  source_id ){
            apiUrl = Settings.apiUrl + '/get_attachments/'+source_id+'/'+attachmentsource_integrator;
        }
        api.axios().get(apiUrl  ).then(function(res){
            if(res.data.status==="success"){
                let files = res.data.data;
                let  fileTypesFiles = files.filter( (fileItem) => {
                     return fileItem.attachmenttype_id == that.props.attachmenttype_id
                     //return true;
                })
                that.setState({
                    isLoading:false,
                    files: fileTypesFiles.reverse()
                })
                if(that.props.onFilesLoaded){
                    that.props.onFilesLoaded(files)
                }
            }
        })
    }
    componentDidUpdate(nextProps){
        if(this.props.attachmenttype_id != nextProps.attachmenttype_id){
            this.loadFiles();
        }
    }
    render() {
        let items = this.state.files;
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        return (
            <div className='uploaded_files_list'>
                {
                    items.map( (item,key) => { return <FileListItem file={item} key={key}/>} )
                }
            </div>
        );
    }
}

export default FileUploaderList;