import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
    country: [],
    state:[],
    city:[]
}
const Locations = (state = DefaultState , actions) => {
    if(actions.type === ActionTypes.SET_COUNTRY){
        state = {
            ...state,
            country:actions.payload
        }
    }
    if(actions.type === ActionTypes.SET_STATE){
        state = {
            ...state,
            state:actions.payload
        }
    }
    if(actions.type === ActionTypes.SET_CITY){
        state = {
            ...state,
            city:actions.payload
        }
    }
    return state;
}
export default Locations;