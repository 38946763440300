import { createSlice } from "@reduxjs/toolkit";

const useWorkflow = createSlice({
    name: "workflow_slice",
    initialState: {
        isOpend: false,
        currentData: [],
        isAdded: false,
        nodeId: null,
        isOptionModal: false,
        actionValue: '',
    },
    reducers: {
        open(state, action) {
            state.isOpend = true;
            state.actionValue = action.payload;
        },
        close(state) {
            state.isOpend = false;
        },
        toggle(state) {
            state.isOpend = !state.isOpend;
        },
        updatedData(state, action) {
            console.log(action);
            state.currentData = action.payload;
        },
        updateEdge(state, action) {
            console.log(state, action);
            state.currentEdge = action.payload;
        },
        updatedId(state, action) {
            console.log(action);
            state.nodeId = action.payload;
        },
        addNodeData(state) {
            state.isAdded = true;
        },
    }
});

// export const workflowInit = (state) => state.workflowSlice;
export const workflowAction = useWorkflow.actions;
export default useWorkflow;