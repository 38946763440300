import React, {useState, useEffect, Fragment} from 'react'
import { connect, useDispatch } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import Api from '../../../../inc/Api';
import FormValidator from '../../../../inc/FormValidator';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import MasterComonent from '../../../Backend/MasterComonent';
import Input from '../../../Forms/Input';
import Alert from '../../../inc/Alert';
import Collapse from '../../../inc/Collapse';
import Button from '../../../inc/Button';
import LinkBtn from '../../../inc/LinkBtn';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import SimpleLoading from '../../../Loading/SimpleLoading';

const CreateBusiness = (props) => {
  const [createBusiness, setCreateBusiness] = useState({
    "name": null
  })
  const navigate = useNavigate()
   const [loading, setLoading] = useState(false)
  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'name',max: 50, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()
   

  const NameChangeHandler = (event) => {
    let name = event.target.value
    setCreateBusiness((prevState) => {
      return {
        ...prevState,
        name: name
      }
    })
  }

  const BussinessClassModule = () => {
    return (
    <Collapse title={Helper.getLabel(props.language,'vendor_business_group','Vendor Business Group')} className="mb-4 general_tabs" open={true}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3'> 
            <Input label={Helper.getLabel(props.language,'name',"Name")} type='text' required={true} hasError={validator.hasError('name')} value={createBusiness.name} onChange={NameChangeHandler} />
          </div>
        </div>
      </div>
    </Collapse>
    )
  }
 
  const SaveHandler = () => {
    let newBusiness = { 
      "name": createBusiness.name, 
    }
    let validData = {
      ...newBusiness
    } 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }    
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().post(Settings.apiVendorUrl+'/businessclassification',{
      "description": createBusiness.name !== "" ? createBusiness.name : null
  }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        setLoading(false)
        navigate('/vendors-business-classification')
      }
  }).catch((res) => {  
    setLoading(false)
    Helper.alert(res.response.data.message, 'error')
  })
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_business_classification.vendors_business_classification)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
    <div className='container-fluid'>
      <NavigationHeder title={Helper.getLabel(props.language,'business_classification',"Create Vendor Business Classification")}>
        <LinkBtn isActive= {false} to="/vendors-reason" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canCreate(frontendIds.masterData.vendor_business_classification.vendors_business_classification_create_screen_save_button)}  isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
      {loading ? <SimpleLoading /> : (BussinessClassModule())} 
    </div>
  )
}
  
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_business_classification.vendors_business_classification
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(CreateBusiness, SecurityOptions))))