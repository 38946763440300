import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    periods:[],
    isLoaded:false
}
const TaxtSettlementPeriodsRedcer = (state = defaultState,actions) => {
    if(actions.type === ActionTypes.SET_SETTLEMENT_PERIODS){
        state = {
            ...state,
            isLoaded:true,
            periods:actions.payload
        }
    }
    return state;
}
export default TaxtSettlementPeriodsRedcer;