import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import { ValidateArray } from '../../../inc/Validation'
import FormContainer from '../../Form Module Layout/Forms Container/FormContainer'
import AgGrid from '../../grid/ag/ag-grid'
import Alert from '../../inc/Alert'
import $ from 'jquery'
import Button from '../../inc/Button'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'

const Configuration = (props) => {

  const [loading, setLoading] = useState(false) // make it true
  const [configurationGrid, setConfigurationGrid] = useState([])
  let configCalled = false
 
  useEffect(() => {
    get_item_configurations()
  }, [])

  const get_item_configurations = () => {
    if(configCalled){
      return;
    }
      let api = Api
      configCalled = true
      api.setUserToken()
    api.axios().get(Settings.apiItemUrl+'/item_configuration',{}).then(function(res){
      if(res.data.status==="success"){ 
        setConfigurationGrid(res.data.data.reverse())
        setLoading(false)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }
 
  const onSaveClick = (rowItem) => { 
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.itemconfiguration_id || item.itemconfiguration_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){
        addNewData(newData);
    }
    if(updatedData.length>=1){ 
        updateData(updatedData);
    }
  } 

const updateData = (data) =>{ 
  let validation = ValidateArray(data) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_item_configurations()
  }
        let api = Api
      api.setUserToken()
    data.forEach(element => {
      api.axios().put(Settings.apiItemUrl+`/itemconfiguration/${element.itemconfiguration_id}`,{
        "name": element.name
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_item_configurations()
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    })
}
 
const addNewData = (data) => { 
  let validation = ValidateArray(data) 
  if (validation){
    Helper.alert('Please Enter Data In Fields!', 'error')
    get_item_configurations()
  }
  let api = Api
  api.setUserToken()
  data.forEach(element => {
    api.axios().post(Settings.apiItemUrl+'/item_configuration',{
      "name":element.name
  }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)
        get_item_configurations()
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
 }) 
}

const DeleteHandler = (allRows) => { 
  let api = Api
  api.setUserToken()
  allRows.forEach(element => {
    api.axios().delete(Settings.apiItemUrl+`/itemconfiguration/${element.itemconfiguration_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_item_configurations()
        }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
})
}

const SaveHandler = () => {
  $('.rs_configuration_grid .rs_grid_btn_save').trigger('click'); 
}

const configsValidator = (params) => {
  if( params.newValue.length > 255 ){
    Helper.alert('Name limit exceeded!', 'error')
    // params.data.name = params.newValue 
    return false
  }
  params.data.name = params.newValue
  return true
}
  const Columns_Headings = [
    {field: 'name', valueSetter: configsValidator, headerName: Helper.getLabel(props.language, 'name', 'Name') }
  ]

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.item_master_modules.configuration.configuration_main)){
    return <Fragment> 
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return (
    <Fragment> 
      <NavigationHeder backUrl='/master-data' title='Configuration'>
      <Button isActive= {true} isDisable={!security.canEdit(frontendIds.masterData.item_master_modules.configuration.configuration_main_save_button)} title='Save' onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>  
      {loading ? <SimpleLoading /> : null}
      <div className='rs_configuration_grid'>
        <AgGrid addBtnHide={!security.canCreate(frontendIds.masterData.item_master_modules.configuration.configuration_main_create_button)} hideDelete={!security.canDelete(frontendIds.masterData.item_master_modules.configuration.configuration_main_delete_button)} data={configurationGrid} id="configuration-grid" header={Columns_Headings} onDelete={DeleteHandler} onSave={onSaveClick} /> 
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.item_master_modules.configuration.configuration_main
}


export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Configuration, SecurityOptions)))

 