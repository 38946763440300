import React, {useState, useEffect} from 'react';
import $ from 'jquery'
import Popup from '../../Popup/Popup';
import Collapse from '../../inc/Collapse';
import Helper from '../../../inc/Helper';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import { connect } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import SimpleLoading from '../../Loading/SimpleLoading';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { ValidateArray, ValidateData } from '../../../inc/Validation';
import FormValidator from '../../../inc/FormValidator';
import { useNavigate } from 'react-router-dom';

const CreatePOPopup = (props) => {
    
    //States

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [sitesLoading, setSitesLoading] = useState(false)
    const [sitesCurrencyLoading, setSitesCurrencyLoading] = useState(false)
    const [createPO, setCreatePO] = useState({
        "vendor_id": null,
        "vendorsite_id": null,
        "vendorgroup_id": null,
        "purchase_status": null,
        "document_status": null,
        "accounting_date": Helper.formateDate(Date.now()),
        "description":null,
        "document_date": Helper.formateDate(Date.now()),
        "delivery_date": Helper.formateDate(Date.now()), 
        "entity_id": null,
        "site_id": null,
        "currency_id": null,
        "document_number": null,
        "warehouse_id": null
    })

    //Fields Validation
    let validator ; 

    const validationConfigure = () => {
      let fieldConfig = [
          {name:'vendor_id', displayName:Helper.getLabel(props.language,'vendor_id','Vendor'),types:['Required']},
          {name:'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse_id','Warehouse'),types:['Required']},      
          // {name:'document_number', displayName:Helper.getLabel(props.language,'document_number','Document Number'),types:['Required']},  
          {name:'vendorgroup_id', displayName:Helper.getLabel(props.language,'vendorgroup_id','Vendor Group'),types:['Required']},  
          // {name:'purchase_status', displayName:Helper.getLabel(props.language,'purchase_status','Purchase Status'),types:['Required'] },
          // {name:'document_status', displayName:Helper.getLabel(props.language,'document_status','Document Status'),types:['Required']},  
          {name:'accounting_date', displayName:Helper.getLabel(props.language,'accounting_date','Accounting Date'),types:['Required'] }, 
          {name:'document_date', displayName:Helper.getLabel(props.language,'document_date','Document Date'),types:['Required'] },
          {name:'delivery_date', displayName:Helper.getLabel(props.language,'delivery_date','Delivery Date'),types:['Required']},   
          {name:'entity_id', displayName:Helper.getLabel(props.language,'entity','Entity'),types:['Required']}, 
          {name:'currency_id', displayName:Helper.getLabel(props.language,'currency_id','Currency '),types:['Required'] }, 
          {name:'site_id', displayName:Helper.getLabel(props.language,'site_id','Site '),types:['Required'] },
      ]
      if(!validator) {
        validator = new FormValidator(fieldConfig,props.language)
      }
    }
  
    validationConfigure()

    //End

    //Dropdowns States

    const [type, setType] = useState([])
    const [vendor, setVendor] = useState([])
    const [vendorGroup, setVendorGroup] = useState([])
    const [vendorSite, setVendorSite] = useState([])
    const [purchaseStatus, setPurchaseStatus] = useState([])
    const [documentStatus, setDocumentStatus] = useState([]) 
    const [entity, setEntity] = useState([])
    const [sites, setSites] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [currencyList, setCurrencyList] = useState([])

    //End

    
    //Calls Reduction

    let typeCalled = false
    let vendorCalled = false
    let vendorGroupCalled = false
    let vendorSiteCalled = false
    let purchaseStatusCalled = false
    let documentStatusCalled = false 
    let entityCalled = false
    let sitesCalled = false
    let currencyCalled = false
    let vendorSiteCurrencyCalled = false
    let warehouseCalled = false

    //End

    
    //API RENDERS
    useEffect(() => {
      SitesAPI()
      TypeAPI()
      DocumentStatusAPI() 
      PurchaseStatusAPI()
      VendorGroupAPI()
      vendorAPI()
      EntityAPI()
      CurrencyAPI()
      WareHouseAPI()
  }, [])

  //End

    // Dropdown API
    const TypeAPI = () => {
        if(typeCalled){
            return;
        }
        let api = Api
        typeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderType',{}).then(function(res){
          if(res.data.status==="success"){
            let TypeARR = []
            res.data.data.forEach(item => {  
            TypeARR.push({
              value: item.id,
              label: item.name
            })
           }) 
           setType(TypeARR) 
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }
  
    const vendorAPI = () => {
        if(vendorCalled){
            return;
        }
        let api = Api
        vendorCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
          if(res.data.status==="success"){
            let VENDORARR = []
            res.data.data.forEach(item => {  
            VENDORARR.push({
              value: item.vendor_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setVendor(VENDORARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const VendorGroupAPI = () => {
        if(vendorGroupCalled){
            return;
        }
        let api = Api
        vendorGroupCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+'/vendorgroup',{}).then(function(res){
          if(res.data.status==="success"){
            let GroupARR = []
            res.data.data.forEach(item => {  
            GroupARR.push({
              value: item.vendorgroup_id,
              label: item.name
            })
           }) 
           setVendorGroup(GroupARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const VendorSiteAPI = (id) => {
        if(vendorSiteCalled){
            return;
        }
        let api = Api
        vendorSiteCalled = true
        setSitesLoading(true)
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/vendorsites/${id}`,{}).then(function(res){
          if(res.data.status==="success"){
            let vendorSite = []
            res.data.data.forEach(item => {  
            vendorSite.push({
              value: item.vendorsite_id,
              label: item.name
            })
           }) 
           setVendorSite(vendorSite)
           setSitesLoading(false)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        setSitesLoading(false)
      })
    }

    const getVendorCurrentCurrencyAPI = (id) => {
      if(vendorSiteCurrencyCalled){
          return;
      }
      let api = Api
      vendorSiteCurrencyCalled = true
      setSitesCurrencyLoading(true)
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+`/vendorsite/${id}`,{}).then(function(res){
        if(res.data.status==="success"){
          setSitesCurrencyLoading(false)
            setCreatePO((prevState) => {
              return {
                ...prevState,
                currency_id: res.data.data.currency_id
              }
            })
            setCreatePO((prevState) => {
              return {
                ...prevState,
                vendorgroup_id: res.data.data.vendor_group_id
              }
            })
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setSitesCurrencyLoading(false)
    })
  }

    const PurchaseStatusAPI = () => {
        if(purchaseStatusCalled){
            return;
        }
        let api = Api
        purchaseStatusCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderPurchaseStatus',{}).then(function(res){
          if(res.data.status==="success"){
            let PurchaseStatus = []
            res.data.data.forEach(item => {  
            PurchaseStatus.push({
              value: item.id,
              label: item.name
            })
           }) 
           setPurchaseStatus(PurchaseStatus)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const DocumentStatusAPI = () => {
        if(documentStatusCalled){
            return;
        }
        let api = Api
        documentStatusCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/POHeaderDocumentStatus',{}).then(function(res){
          if(res.data.status==="success"){
            let docsStatus = []
            res.data.data.forEach(item => {  
            docsStatus.push({
              value: item.id,
              label: item.name
            })
           }) 
           setDocumentStatus(docsStatus)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const CurrencyAPI = () => {
        if(currencyCalled){
            return;
        }
        let api = Api
        currencyCalled = true 
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
          if(res.data.status==="success"){ 
            let CurrencyARR = []
            res.data.data.forEach(item => {  
                CurrencyARR.push({
                    value: item.currency_id,
                    label: `${item.currency_symbol} - ${item.currency_name}`
                })
           })
           setCurrencyList(CurrencyARR)
        } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const EntityAPI = () => {
        if(entityCalled){
            return;
        }
        let api = Api
        entityCalled = true
        api.setUserToken()
        api.axios().post(Settings.apiUrl+'/search_entity',{
            "code":"",
            "name":""
        }).then(function(res){
          if(res.data.status==="success"){
            let EntityARR = []
            res.data.data.forEach(item => {  
            EntityARR.push({
              value: item.entity_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setEntity(EntityARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const SitesAPI = () => {
        if(sitesCalled){
            return;
        }
        let api = Api
        sitesCalled = true
        api.setUserToken()
        api.axios().post(Settings.apiUrl+'/get_site',{
            "code":"",
            "name":""
        }).then(function(res){
          if(res.data.status==="success"){
            let SitesARR = []
            res.data.data.forEach(item => {  
            SitesARR.push({
              value: item.site_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setSites(SitesARR)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
      })
    }

    const WareHouseAPI = () => {
      if(warehouseCalled){
          return;
      }
      let api = Api
      warehouseCalled = true
      api.setUserToken()
      api.axios().post(Settings.apiUrl+'/get_warehouse',{
          "code":"",
          "name":""
      }).then(function(res){
        if(res.data.status==="success"){
          let Warehouse = []
          res.data.data.forEach(item => {  
          Warehouse.push({
            value: item.warehouse_id,
            label: `${item.code} - ${item.name}`
          })
         }) 
         setWarehouse(Warehouse)
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

    //End
  
    //Handlers
    
    const onPopupClose = () => { 
        $('html').removeClass('rs_popup_open');
        props.ClosePopup(false)
    }

    const ClosePopupHandler = () => {
        $('html').removeClass('rs_popup_open');
        props.ClosePopup(false)
    }

    const InputChangeHandler = (e) => { 
        if(e.target.name == "vendor_id"){
            VendorSiteAPI(e.target.value)
        }
        if(e.target.name == "vendorsite_id"){
            getVendorCurrentCurrencyAPI(e.target.value)
        }
        setCreatePO((prevState) => {
            return {
                ...prevState,
                [e.target.name]:e.target.value
            }
        })
    }

    const DocumentDatePickerHandler = (e) => {
       setCreatePO((prevState) => {
            return {
                ...prevState,
                document_date: $('.document_date').val()
            }
       })
    }

    const AccountDatePickerHandler = (e) => {
        setCreatePO((prevState) => {
            return {
                ...prevState,
                accounting_date: $('.accounting_date').val()
            }
        })
    }

    const DeliveryDatePickerHandler = (e) => {
        setCreatePO((prevState) => {
            return {
                ...prevState,
                delivery_date: $('.delivery_date').val()
            }
        })
    }
    //End

    const CreatePurchaseOrder = () => {
        let validData = {
            ...createPO
          }
          if(!validator.isValid(validData)){
            validator.displayMessage();
            validator.reset() 
            return;
        }  
        if(!ValidateData(vendorSite) && !createPO.vendorsite_id){
          Helper.alert('Vendor Site Field missing!', 'error')
          return;
        }
       setLoading(true)
       let api = Api 
        api.setUserToken()
        api.axios().post(Settings.apiPurchaseOrderUrl+'/PO_header',{ 
            "type": !ValidateArray(type) ? type.find(id => id.label == "Purchase Order").value : null,
            "vendor_id": parseInt(createPO.vendor_id),
            "vendorsite_id": parseInt(createPO.vendorsite_id),
            "vendorgroup_id": parseInt(createPO.vendorgroup_id),
            "purchase_status": !ValidateArray(purchaseStatus) ? purchaseStatus.find(id => id.label == "Open").value : null,
            "document_status": !ValidateArray(documentStatus) ? documentStatus.find(id => id.label == "Draft").value : null,
            "accounting_date": Helper.getDateForDb(createPO.accounting_date),
            "description": createPO.description,
            "document_date": Helper.getDateForDb(createPO.document_date),
            "delivery_date": Helper.getDateForDb(createPO.delivery_date), 
            "entity_id": parseInt(createPO.entity_id),
            "site_id": parseInt(createPO.site_id),
            "currency_id": parseInt(createPO.currency_id),
            // "document_number": null,
            // "document_number": '', 
            // createPO.document_number
            "warehouse_id": parseInt(createPO.warehouse_id)
        }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            setLoading(false)
            navigate(`/edit-purchase-order/${res.data.PO_header_id}`)
            $('html').removeClass('rs_popup_open');
            props.ClosePopup(false)
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        setLoading(false)
      })
    }

  return (
    <div className='close-popup_po'>
        <Popup onClose={ onPopupClose} autoOpen={true} width="752px">
           <div className='container-fluid'>
               <div className='row'>
                   <div className='col-12'>
                       <h2 className='mb-4'>Create purchase order</h2>
                   </div>
               </div>
           </div>
           {loading ? <SimpleLoading /> : ''}
           <Collapse className='mb-4 info_po_tabs' open={true} title={Helper.getLabel(props.language,'info',"Enter the following information")}>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Input label={Helper.getLabel(props.language,'description',"Description")} value={createPO.description} onChange={InputChangeHandler} name='description' />
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='type' disable={true} label={Helper.getLabel(props.language,'type',"Type")} options={type} value={!ValidateData(type) ? type.find(id => id.label == "Purchase Order").label : null} name='type' onChange={InputChangeHandler} />
                        </div> 
                        <div className='col-lg-6'>
                            <DropDownInput id='vendor_id' required={true} label={Helper.getLabel(props.language,'vendor_code_name',"Vendor")} options={vendor} value={createPO.vendor_id} name='vendor_id' onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            {sitesLoading ? <SimpleLoading /> : ''}
                            <DropDownInput id='vendorsite_id' required={true} label={Helper.getLabel(props.language,'vendor_site',"Vendor Site")} options={vendorSite} value={createPO.vendorsite_id} name='vendorsite_id' onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            {sitesCurrencyLoading ? <SimpleLoading /> : ''}
                            <DropDownInput id='vendorgroup_id' required={true} label={Helper.getLabel(props.language,'vendor_group',"Vendor Group")} isDisable={true} options={vendorGroup} value={createPO.vendorgroup_id} name='vendorgroup_id' onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            {sitesCurrencyLoading ? <SimpleLoading /> : ''}
                            <DropDownInput id='currency_id' required={true} label={Helper.getLabel(props.language,'currency_id',"Currency")} options={currencyList} value={createPO.currency_id} name='currency_id' onChange={InputChangeHandler} />
                        </div> 
                        <div className='col-lg-6'>
                            <InputDatePicker value={createPO.document_date} label={Helper.getLabel(props.language,'document_date',"Document Date")} name='document_date' inputClassName='document_date' required={true} onChange={DocumentDatePickerHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <InputDatePicker value={createPO.accounting_date} label={Helper.getLabel(props.language,'accounting_date',"Accounting Date")} name='accounting_date' inputClassName='accounting_date' required={true} onChange={AccountDatePickerHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <InputDatePicker value={createPO.delivery_date} label={Helper.getLabel(props.language,'delivery_date',"Delivery Date")} name='delivery_date' inputClassName='delivery_date' required={true} onChange={DeliveryDatePickerHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='entity_id' value={createPO.entity_id} label={Helper.getLabel(props.language,'entity_id',"Entity")} name='entity_id' required={true} options={entity} onChange={InputChangeHandler}/>
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='site_id' value={createPO.site_id} label={Helper.getLabel(props.language,'site_id',"Site")} name='site_id' required={true} options={sites} onChange={InputChangeHandler} />
                        </div>
                        <div className='col-lg-6'>
                            <DropDownInput id='warehouse_id' value={createPO.warehouse_id} label={Helper.getLabel(props.language,'warehouse_id',"Warehouse")} name='warehouse_id' required={true} options={warehouse} onChange={InputChangeHandler} />
                        </div>
                    </div>
                </div> 
           </Collapse>
           <div className='container-fluid'>
              <div className='row mt-3 mb-3'>
                <div className='col-12'>
                    <div className='action_btns_po d-flex justify-content-end align-items-center'>
                        <button className='cancel_btn me-3' onClick={ClosePopupHandler}>Cancel</button>
                        <button className='create_btn' onClick={CreatePurchaseOrder}>Create</button>
                    </div>
                </div>  
              </div>
           </div>
        </Popup>
    </div>
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders_create_screen
}


export default connect(mapStateToProps) (ApplySecurityRoles(CreatePOPopup, SecurityOptions))
