import React, { Component, version } from 'react';
import { connect } from 'react-redux';
import ActionTypes from '../../../inc/ActionsTypes';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import AgGrid from '../../grid/ag/ag-grid';
import SimpleLoading from '../../Loading/SimpleLoading';
/**
 * contactsource_id : Contact source id
 * contactsource_integrator : id such as tenant id
 * source_id : source_id // Tenant , Site etc source id
 * address_id : id of existing address
 * dataExportable
 */
class ContactAddress extends Component {
    constructor(props){
        super(props);
        this.isCalledCantactType = false;
        this.gridObj = null;
        this.state = {
            contactType: [],
            dataExportable: this.props.dataExportable ? true : false,
            contacts:this.props.data ? this.props.data : [],
            contactsource_id: this.props.contactsource_id ? this.props.contactsource_id  : null,
            source_id: this.props.source_id ? this.props.source_id  : null,
            contactsource_integrator: this.props.contactsource_integrator ? this.props.contactsource_integrator  : null,
            address_id: this.props.address_id ? this.props.address_id  : null,
            isLoading:false,
            isFirstItem:false
        }
    }
    componentDidMount(){
        this.loadContacts()
        this.loadContactsTypes()
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.contactsource_id && this.props.contactsource_id  != this.state.contactsource_id){
            let that = this;
            this.setState({
                contactsource_id: this.props.contactsource_id
            },function () {
                that.loadContacts();
            })
        }
        if(this.props.address_id && this.props.address_id  != this.state.address_id){
            let that = this;
            this.setState({
                address_id: this.props.address_id
            },function () {
                that.loadContacts();
            })
        }
    }
    loadContactsTypes (){
        if(this.isCalledCantactType){
            return;
        }
        if(this.props.contactTypes.types.lenght >=1){
            return;
        }
        this.isCalledCantactType = true;
        let api  = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiUrl + '/enum/contacttype').then(function(res){
            if(res.data.status ==='success'){
                let allTypes = res.data.data.reverse();
                that.props.setContactTypes(allTypes.map(itemType => { return  { label:itemType.name, value: itemType.id }}))
            }
            
        })
    }
    
    hasErrors(){
        let gridData = Helper.getDataFromGrid(this.gridObj);
        if(gridData.length >=1){
            let hasPrimary = false;
            gridData.forEach(item => {
                if(item.is_primary =='yes'){
                    hasPrimary = true
                }
            })
            if(!hasPrimary){
                Helper.alert(Helper.getLabel(null,15236899,"Please select a primary contact"),'error');
                return true;
            }
        }
        return false;
    }

    onSaveClick(contactItems){
        if(this.hasErrors()){
            
            return;
        }
        let that = this;
        let newContacts = [];
        contactItems.forEach(contactItem => {
            if(!contactItem.contact_id){
                newContacts.push(contactItem)
            }else{
                that.updateExistingContact(contactItem)
            }
        });
        if(newContacts.length >=1){
           this.createNewContact(newContacts)
        }
    }
    createNewContact(contacts){
        if(this.state.dataExportable){
            return;
        }
        let that = this;
        that.setState({
            isLoading:true
        })
        if(!that.state.source_id){
            Helper.alert("Source not found",'error');
        }
        let api = Api;
        api.setUserToken();
        let newContacts = [];
        contacts.forEach(contactItem => {
            newContacts.push(
                {
                    address_id: that.state.address_id,
                    type: contactItem.type,
                    description: contactItem.description,
                    contact:contactItem.contact,
                    extension: Helper.getNullableValue( contactItem.extension),
                    isprimary:contactItem.is_primary && contactItem.is_primary === "yes" ? true : false,
                    isactive:contactItem.is_active && contactItem.is_active === "yes" ? true : false,
                    mobile:contactItem.mobile && contactItem.mobile === "yes" ? true : false,
                }
            )
        })
        let newData ={
            contact:{
                contactsource_id: that.state.contactsource_id,
                source_id: that.state.contactsource_id ? null : that.state.source_id,
                contactsource_integrator: that.state.contactsource_id ? null : that.state.contactsource_integrator,
                contacts:newContacts
            }
        }
        
        if(newData.contactsource_id && parseInt(newData.contactsource_id) >= 1){
            newData.source_id = null;
            newData.contactsource_integrator = null;
        }
        api.axios().post(Settings.apiUrl + '/add_contact',newData).then(function(res){
            if(res.data.Status ==='Success'){
                Helper.alert(res.data.Message)
                /*
                if(res.data.contact_source_id){
                    that.state.contactsource_id = res.data.contact_source_id;
                }
                */
                if(that.props.onContactCreate && typeof that.props.onContactCreate ==='function'){
                    that.props.onContactCreate(res.data);
                }

            }else{
                Helper.alert(res.data.Message,'error')
            }
            that.setState({
                isLoading:false
            })
            that.loadContacts();
            
        }).catch( (errors) => {
            Helper.alert(errors.response.data.message,'error')
            that.setState({
                isLoading:false
            })
        })
    }
    updateExistingContact(contactItem){
        let that = this;
        that.setState({
            isLoading:true
        })
        if(!that.state.source_id){
            Helper.alert("Source not found",'error');
        }
        let api = Api;
        api.setUserToken();
        let existingData ={
            type:contactItem.type,
            description:contactItem.description,
            contact:contactItem.contact,
            extension:contactItem.extension,
            isprimary:contactItem.is_primary && contactItem.is_primary === "yes" ? true : false,
            isactive:contactItem.is_active && contactItem.is_active === "yes" ? true : false,
            mobile:contactItem.mobile && contactItem.mobile === "yes" ? true : false,
        }
        api.axios().put(Settings.apiUrl + '/contact/'+contactItem.contact_id,existingData).then(function(res){
            if(res.data.status ==='success'){
                Helper.alert(res.data.message)
            }else{
                Helper.alert(res.data.message,'error')
            }
            that.setState({
                isLoading:false
            })
            that.loadContacts();
        }).catch( errors => {
            Helper.alert(errors.response.data.message,'error')
            that.setState({
                isLoading:false
            })
        })
    }
    getFormatedData(){
        let output = {}
        return output;
    }
    loadContacts(){
        let contactsource_id = this.state.contactsource_id;
        if(!contactsource_id){
            return;
        }
        let that = this;
        let api = Api;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        api.axios().get(Settings.apiUrl+'/get_contact/'+contactsource_id,{}).then(function(res){
            if(res.data.Status ==='Success'){
                let RsContacts = res.data.data.contact.reverse();
                let allContacts = RsContacts.map( (contact) => {
                    contact.is_active = contact.is_active ? 'yes' : 'no';
                    contact.is_primary = contact.is_primary ? 'yes' : 'no';
                    contact.mobile = contact.mobile ? 'yes' : 'no';
                    return contact;
                })
                that.setState({
                    contacts: allContacts,
                    contactsource_id: res.data.data.contactsource_id,
                    source_id: res.data.data.source_id,
                    isLoading:false
                })
            }
            
        }).catch( errors => {
            //Helper.alert(errors.response.data.message,'error')
            that.setState({
                isLoading:false
            })
        })
    }
    onContactDeleteHanlder(data){
        let api = Api;
        api.setUserToken();
        let that =this;
        let totalReqeustCount = 0;
        data.forEach(contact => {
            
            if(contact.contact_id){
                api.axios().delete(Settings.apiUrl + '/contact/'+contact.contact_id,{}).then(function(res){
                    totalReqeustCount++;
                    if(res.data.status === 'success'){
                        if(totalReqeustCount >= data.length){
                            Helper.alert(res.data.message )
                            that.loadContacts();
                        }
                    }else{
                        Helper.alert(res.data.message,"error" )
                    }
                }).catch(error => {
                    totalReqeustCount++;
                    that.loadContacts();
                    Helper.alert(error.response.data.message,"error" )
                })
            }else{
                totalReqeustCount++;
            }
        })
    }
    onContactGridReady(event){
        this.gridObj = event;
        if(this.props.onGridReady && typeof this.props.onGridReady ==='function'){
            this.props.onGridReady({
                ...event,
                contactComponent:this
            })
        }
    }
    canCreateEditDelete(){
        if(typeof this.props.canEdit === "undefined"){
            return true;
        }
        return (this.props.canEdit && this.props.canEdit === true) ? true : false;
    }
    isViewOnly(){
        return !this.canCreateEditDelete();
    }
    onRowValueChanged(rowObject){
        let rowData = rowObject.data;
        
        if(rowData.is_primary && rowData.is_primary ==='yes') {
            this.setState({
                isLoading:true
            })
            let newData = [];
            rowObject.api.forEachNode( gridItem => {
                let newDataItem =gridItem.data;
                if( (gridItem.data.contact_id &&  gridItem.data.contact_id == rowObject.data.contact_id ) || (gridItem.data.gridRowId && gridItem.data.gridRowId == rowObject.data.gridRowId ) ){
                    newDataItem.is_primary = 'yes';
                }else{
                    newDataItem.description = newDataItem.description+' Updated';
                    newDataItem.is_primary = 'no';
                }
                newData.push(newDataItem)
            })
            this.setState({
                isLoading:false
            },function(){
                rowObject.api.setRowData(newData)
            })
        }
    }
    getContactValidationRules(gridNode){
        let rules = {
            types:['Required'],
            displayName:Helper.getLabel(null,11234,'Contact Number'),
            min:5, max:80
        }
        let nodeData = gridNode.data;
        if(nodeData.type == 1){ // phone
            rules.types = ['Required','Phone'];
            rules.max = 15;
            rules.min = 10;
        }else if(nodeData.type == 2){ // email
            rules.displayName = Helper.getLabel(null,11234,'Contact');
            rules.types = ['Required','Email'];
        }
        else if(nodeData.type == 3){ // url
            //rules.types = ['Required','Url'];
        }
        else if(nodeData.type == 4){ // Telex

        }
        else if(nodeData.type == 5){ // Fax

        }
        if(nodeData.mobile =='yes'){
            rules.types = ['Required','Phone'];
        }
        return rules;
    }
    render() {
        if(this.state.isLoading){
            return <SimpleLoading />
        }
        let language = this.props.language;
        const gridValidationRules ={
            description: {name:'description',displayName:Helper.getLabel(language,1188,'Description'),types:['Required'], min:1, max:80},
            type: {name:'type',displayName:Helper.getLabel(language,1189,'Type'),types:['Required']},
            extension: {max:80},
            contact: this.getContactValidationRules.bind(this) 
        }
        //console.log(this.props.contactTypes.types);
        //console.log(this.state.contacts);
        let ctTypesList = this.props.contactTypes.types;
        let Columns_Headings = [
            {field:'description', headerName: Helper.getLabel(language,1188,'Description') , validationRule: gridValidationRules.description },
            { field:'type', headerName: Helper.getLabel(language,1189,'Type') , inputType : 'dropdown' , options:ctTypesList, validationRule: gridValidationRules.type } ,
            {field:'contact', headerName: Helper.getLabel(language,11234,'Contact Number / Address'), width: 260 , validationRule: gridValidationRules.contact },  
            {field:'extension', headerName: Helper.getLabel(language,11235,'Extension'), validationRule: gridValidationRules.extension},
            {field:'is_primary', headerName: Helper.getLabel(language,11230,'Primary'), inputType:'checkbox' },
            {field:'is_active', headerName: Helper.getLabel(language,11231,'Active'), inputType:'checkbox', defaultValue:'yes' } ,
            {field:'mobile', headerName: Helper.getLabel(language,11232,'Mobile') , inputType:'checkbox' } 
        ]
        let contactGridId = this.props.id ? this.props.id : 'rs_contact_grid';
        let contactList = this.state.contacts;
        return (
            <div className='contact_address'>
                <AgGrid id={contactGridId} onRowValueChanged ={ this.onRowValueChanged.bind(this) }  allBtnHide = {!this.canCreateEditDelete()} dissableCheckBox = {!this.canCreateEditDelete()} viewOnly={ this.isViewOnly() } onGridReady={ this.onContactGridReady.bind(this)} onDelete = { this.onContactDeleteHanlder.bind(this)} data={ contactList }   header={Columns_Headings} onSave={this.onSaveClick.bind(this)} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        contactTypes:state.contactTypes,
        language:state.language,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        setContactTypes: (types) => { dispatch({type:ActionTypes.SET_ALL_CONTACT_TYPE,payload:types})}
    }
}
   
export default connect (mapStateToProps,mapDispatchToProps) ( ContactAddress );