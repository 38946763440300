import React from 'react'
import $ from 'jquery'

const SwitchView = (props) => {

    const onChangeViewComponent = (title) => {
        props.setViewComponent(title) 
        if(title == "Header View"){
            props.setObjectClear({})
        }
    }

    $('.view_btn_class').on('click',function(){
        $('.view_btn_class').removeClass('view_btn_class_active');
        $(this).addClass('view_btn_class_active') 
    });

  return (
    <section className='view_display_components mt-2 mb-2'>
        <div className='view_btns'>
            <button className='view_btn_class view_btn_class_active' onClick={() =>  onChangeViewComponent("Header View")}>Header View</button>
            <button className='ms-3 view_btn_class' onClick={() =>  onChangeViewComponent("List View")}>
                <span><img className='img-fluid mb-1 me-2 img_class' src={props.viewComponent == "Header View" ? '/images/icons/list-bullets.svg' : '/images/icons/list-bullets-colored.svg'} /></span>
                List View
            </button>
        </div>
    </section>
  )
}

export default SwitchView;