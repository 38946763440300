import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import DropdownIcon from "../../../assets/dropdown-grey.svg";
import StarFilled from "../../../assets/review-star-filled.svg";
import StarEmpty from "../../../assets/review-star-empty.svg";

// this component returns a particular section of filters with certain customisable options that can be added via props. business logic is to be handled via callback functions.
// by default standard filter options will show which can be nested.
//
// PROPS
//
// 1. filters: nested object consisting of only 3 options 'label: string (default text to show)', 'key: string (key to match from translations)' & 'options: array of objects', can be 1 level deep , looks like this:
//    {
//      label: "Department",
//      key: "department"
//      options: [
//        {
//          label: "Smart Phones & Mobiles",
//          key: "smart_phones_&_mobiles"
//          options: [{ label: "Smart Phones" }, { label: "Basic Mobiles" }],
//        },
//      ],
//    };
// 2. returnReviewFilters: boolean (returns only rating based review filter option)
//    No filters prop is needed in case of reviewFilters
// 3. addSearchbar: boolean (adds search bar below main label to search through filter options)
// 4. addCheckboxes: boolean  (adds checkboxes before filter options)

class SearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeMore: true,
      search: "",
      filtersToShow: {},
    };
    this.toggleSeeMore = this.toggleSeeMore.bind(this);
  }

  componentDidMount() {
    this.setState({ filtersToShow: this.props.filters });
  }

  filledStar() {
    return (
      <img
        className="search-filters-ratings-star"
        src={StarFilled}
        alt="filled star"
      />
    );
  }

  emptyStar() {
    return (
      <img
        className="search-filters-ratings-star"
        src={StarEmpty}
        alt="empty star"
      />
    );
  }

  toggleSeeMore() {
    this.setState({ seeMore: !this.state.seeMore });
  }

  handleSearchChange(event) {
    this.setState({ search: event.target.value });
    this.setState({
      filtersToShow: {
        ...this.props.filters,
        options: this.props.filters.options.filter(
          (item) => item.label.toLowerCase().indexOf(event.target.value) >= 0
        ),
      },
    });
  }

  render() {
    const {
      filters,
      returnReviewFilters,
      returnPriceFilters,
      addSearchbar,
      addCheckboxes,
    } = this.props;

    if (returnReviewFilters)
      return (
        <div className="search-filters">
          <div className="search-filters-label">
            {Helper.getLabel(this.props.language, "reviews", "Reviews")}
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.filledStar()}
            {this.filledStar()}
            {this.filledStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.filledStar()}
            {this.filledStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.filledStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
          <div className="search-filters-ratings search-filters-option">
            {this.filledStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            {this.emptyStar()}
            &nbsp;&nbsp;& Up
          </div>
        </div>
      );

    if (returnPriceFilters)
      return (
        <div className="search-filters">
          <div className="search-filters-label">
            {Helper.getLabel(this.props.language, "price", "Price")}
          </div>
          <div className="search-filters-options">
            <div className="search-filters-option search-filters-selectableoption">Under AED1000</div>
            <div className="search-filters-option search-filters-selectableoption">AED1000-AED5000</div>
            <div className="search-filters-option search-filters-selectableoption">AED10000 AED20000</div>
            <div className="search-filters-option search-filters-selectableoption">Over AED20000</div>
          </div>
        </div>
      );

    return filters ? (
      <div className="search-filters">
        <div className="search-filters-label">
          {Helper.getLabel(this.props.language, filters.key, filters.label)}
        </div>
        {addSearchbar ? (
          <input
            className="search-filters-searchbar"
            type="text"
            placeholder={Helper.getLabel(
              this.props.language,
              "search",
              "Search"
            )}
            onChange={(e) => this.handleSearchChange(e)}
          />
        ) : null}
        <div className="search-filters-options">
          {this.state.filtersToShow.options?.map((option, index) =>
            this.state.seeMore && index >= 5 ? null : (
              <div key={index + option?.label}>
                <div
                  className={`search-filters-option ${
                    !addCheckboxes
                      ? "search-filters-selectableoption"
                      : "search-filters-checkboxoption"
                  }`}
                >
                  {addCheckboxes ? (
                    <input
                      className="search-filters-checkbox"
                      type="checkbox"
                    />
                  ) : null}
                  <span>
                    {Helper.getLabel(
                      this.props.language,
                      option.key,
                      option.label
                    )}
                  </span>
                </div>
                {option.options?.map((nestedOption, index) => (
                  <div
                    className={`search-filters-option search-filters-nestedoption ${
                      !addCheckboxes
                        ? "search-filters-selectableoption"
                        : "search-filters-checkboxoption"
                    }`}
                    key={index + nestedOption?.label}
                  >
                    {addCheckboxes ? (
                      <input
                        className="search-filters-checkbox"
                        type="checkbox"
                      />
                    ) : null}
                    <span>
                      {Helper.getLabel(
                        this.props.language,
                        option.key,
                        option.label
                      )}
                    </span>
                  </div>
                ))}
              </div>
            )
          )}
          {this.state.filtersToShow.options?.length > 5 &&
          this.state.seeMore ? (
            <div
              className="search-filters-seemore"
              onClick={this.toggleSeeMore}
            >
              <img src={DropdownIcon} alt="dropdown" />
              See more
            </div>
          ) : null}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(SearchFilters);
