import React from 'react' 
import ContactInfo from '../../Master Contact/ContactInfo'
import AddressDetails from '../Address Details/AddressDetails'
import Address from '../Address/Address' 
import GeneralInfo from '../General Information/GeneralInfo'
import './AddressContainer.css'

const AddressContainer = () => {
  return (
    <div className='address-modal-container ms-auto'> 
    
    <Address />
    <AddressDetails buttonRequired='false' /> 
    <GeneralInfo />
    <ContactInfo />

    </div>
  )
}

export default AddressContainer;
