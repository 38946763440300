let Settings ={
    baseUrl:'http://207.154.212.225/api/',
    apiBaseUrl:'http://157.245.156.40:5001', // assets
    loginUrl:'http://157.245.156.40:5008',
    apiUrl:'http://157.245.156.40:5008',
    apiOrgUrl: 'http://157.245.156.40:5002',
    apiVendorUrl: 'http://157.245.156.40:5003',
    apiItemUrl: 'http://157.245.156.40:5004',
    apiPurchaseOrderUrl: 'http://157.245.156.40:5007',
    apiProcurementSettingsUrl: 'http://157.245.156.40:5006',
    apiPurchaseRequisitionUrl: 'http://157.245.156.40:5009',
    apiUrls: {
        segments:'http://157.245.156.40:5005'
    },
    defaultLang:'en',
    userTokenKey:'lynkaq_user_token',
    dateFormateKey:'lynkaq_dateformate',
    languageKey:'app_lang',
    userTokenExpireDate:1,
    redirectUrlAfterLogin:'template',
    loginLink:'login',
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    debug:true,
    defaultDateFormate:'mm/dd/yyyy',
    source_id:{
        tenant:1,warehouse:4,site:3,entity:2,address:5,vendor:6,vendor_registration:7,bank:8
    },
    validator:{
        max:{
            code:10,
            name:80
        }
    }
}
export default Settings;
