import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import { ValidateArray, ValidateData } from '../../../inc/Validation'
import MasterComonent from '../../Backend/MasterComonent'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button' 
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import CreatePOPopup from '../Create PO/CreatePOPopup'

const POArchive = (props) => {
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [openCreateModePO, setOpenCreateModePO] = useState(false)
    const [vendors, setVendors] = useState([])
    const [vendorsName, setVendorsName] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    let POList = false
    let vendorCalled = false

    useEffect(() => {
      get_purchase_orders_data()
      get_vendors_data()
    }, [])

    const CellHandler = (event) => {  
        if(event.colDef.field === 'document_number') { 
          navigate(`/edit-purchase-order/${event.data.purchase_order_id}`)
        }
        if(event.colDef.field === 'vendor_code') { 
          navigate(`/edit-vendor/${event.data.vendor_id}`)
        }
    }
 
    const get_purchase_orders_data = () => {
      if(POList){
        return;
      }
      let api = Api
      POList = true
      api.setUserToken()
      api.axios().get(Settings.apiPurchaseOrderUrl+'/PO_header',{}).then(function(res){
        if(res.data.status==="success"){  
          let UPDATED_DATA = []
          res.data.data.forEach(element => {
            UPDATED_DATA.push({
              "document_number": element.document_number,
              "vendor_code": element.vendor_id,
              "vendor_name": element.vendor_id,
              "purchase_status": element.purchase_status,
              "document_date": Helper.formateDate(element.document_date),
              "creationtime": Helper.formateDate(element.creationtime),
              "vendor_id": element.vendor_id,
              "purchase_order_id": element.purchase_order_id
            })
          });
          setPurchaseOrders(UPDATED_DATA.reverse())
          setLoading(false)
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        setLoading(false)
      })
    }

    const get_vendors_data = () => {
      if(vendorCalled){
        return;
      }
      let api = Api
      vendorCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
        if(res.data.status==="success"){  
          let UPDATED_STATE = [] 
          let UPDATED_STATE_NAME = []
          res.data.data.forEach((element) => {
           UPDATED_STATE.push({
            value: element.vendor_id,
            label: element.code
          })
         })    
          res.data.data.forEach((element) => {
            UPDATED_STATE_NAME.push({
           value: element.vendor_id,
           label: element.name
         }) 
          })
          setVendors(UPDATED_STATE) 
          setVendorsName(UPDATED_STATE_NAME) 
        }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error') 
      })
    }

    // const DeleteHandler = (allRows) => {
    //   let api = Api
    //   api.setUserToken() 
    //   allRows.forEach(element => {
    //     api.axios().delete(Settings.apiOrgUrl+`/`,{}).then(function(res){
    //         if(res.data.status==="success"){
    //           get_purchase_orders_data()
    //           Helper.alert(res.data.message)
    //         }
    //     }).catch((res) => { 
    //       Helper.alert(res.response.data.message, 'error')
    //       get_purchase_orders_data()
    //     })
    // })
    // }
  
    const gridLinkValueFormat = (params) => {
      return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }

    const gridLinkValueCodeFormat = (params) => { 
        if(vendors.find(id => id.value == params.value) == undefined){
          return `<div class="rs_grid_simple_link">${params.value}</div>`
        }else {
          let label = !ValidateData(vendors)? vendors.find(id => id.value == params.value).label : ''
          return `<div class="rs_grid_simple_link">${label}</div>`
        } 
    }
    
    const statusValueFormat = (params) => {
        if(params.value === 0){
          return `<span class="rs_active_label">Closed</span>`;
        }
        return `<span class="rs_inactive_label">Open</span>`;
      }
      
      const billingstatusValueFormat = (params) => {
        if(params.value === true){
            return `<span class="rs_active_billing">Billed</span>`;
        }
        return `<span class="rs_inactive_billing">Non Billed</span>`;
    }  
    
    const CreateModePurchaseOrder = () => {
      setOpenCreateModePO(true)
    }
    
    const Columns_Headings = [
      {field:'document_number', headerName:Helper.getLabel(props.language,'document_number',"Document Number"), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat },
      {field:'vendor_code', headerName:Helper.getLabel(props.language,'vendor_code',"Vendor Code"), editable: false, options: vendors, inputType: "dropdown",  cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueCodeFormat},
      {field:'vendor_id', headerName:Helper.getLabel(props.language,'vendor_name',"Vendor Name"), editable: false, options: vendorsName, inputType: "dropdown"},
      {field:'purchase_status', headerName:Helper.getLabel(props.language,'purchase_status',"Purchase Status"), editable: false, options:[true,false], valueFormatter: statusValueFormat,  cellRenderer: CustomHtmlCell}, 
      {field:'document_date', headerName:Helper.getLabel(props.language,'document_date', "Document Date"), },
      {field:'creationtime', headerName:Helper.getLabel(props.language,'creationdatetime',"Creation Date Time"), editable: false}, 
    ]

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders.purchase_orders)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }

// !security.canDelete(frontendIds.procure.purchase_orders.purchase_orders_main_delete_button)


  return (

    <div className='purchase_orders_archive'>
    {openCreateModePO ? <CreatePOPopup ClosePopup={setOpenCreateModePO} /> : ''}
      <div className='container-fluid'>
        <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'purchase_orders',"All Purchase Orders")}>
        <Button onClick={CreateModePurchaseOrder} isActive={true} icon='/images/icons/plus-white.svg' title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders.purchase_orders_main_create_button)} /> 
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : null}
        <AgGrid id="rs_purchase_orders_list" hideDelete={true} onCellClicked={CellHandler} header={Columns_Headings} data={purchaseOrders} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div>

  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.purchase_orders
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(POArchive, SecurityOptions))))