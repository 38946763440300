import React, { Component } from 'react' 
import './TenantForm.css' 
import TenantFields from '../Tenant Fields/TenantFields'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { connect } from 'react-redux'
import SimpleLoading from '../../Loading/SimpleLoading'
import Alert from '../../inc/Alert'
import LinkBtn from '../../inc/LinkBtn'
import Button from '../../inc/Button'
import NavigationHeder from '../../Navigations/NavigationHeder'
import Helper from '../../../inc/Helper'
import TextTranslations from '../../Popup/TextTranslation/TextTranslations'
import Collapse from '../../inc/Collapse'
import ContactAddress from '../Address/ContactAddress'
import FormValidator from '../../../inc/FormValidator'
import RsWithRouter from '../../inc/RsWithRouter'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Form_gui_ids from '../../../inc/Form_gui_ids'


class TenantForm extends Component {
  constructor(props){
    super(props);
    this.validationConfigure();
    this.state = {
      userTypes:[],
      tenantFormData:{
        isactive:false,
        enable:false,
      },
      tenant:null,
      isLoading:false,
      displaySearchResultPopup:false,
      isSarchingTenants:false,
      message:null,
      messageType:'success'
    }
    this.addressGrid = null;
    this.contactGrid = null;
  }
  validationConfigure(){
    const language = this.props.language;
    let fieldConfig = [
        {name:'code',displayName:Helper.getLabel(language,'code','Code'),types:['Required'],max:10 },
        {name:'name',displayName:Helper.getLabel(language,'name','Name'),types:['Required'],max:100 },
        {name:'start_date',displayName:Helper.getLabel(language,'start_date','Started Date'),types:['Required']},
        {name:'end_date',displayName:Helper.getLabel(language,'end_date','End Date'),types:['Required']},
        {name:'first_name',displayName:Helper.getLabel(language,'first_name','First name'),types:['Required']},
        {name:'user_email',displayName:Helper.getLabel(language,'user_email','Email'),types:['Required','Email']},
        {name:'user_type',displayName:Helper.getLabel(language,'user_type','User Type'),types:['Required']},
        {name:'isactive',displayName:Helper.getLabel(language,'active','Active'),types:['Required']},
    ]
    this.validator = new FormValidator(fieldConfig,language);
  }
  getAddressList(){
    if(!this.addressGrid ){
      return null;
    }
    let addressList = {
      addresses: Helper.getAddressFromGrid(this.addressGrid)
    }
    return addressList;
  }
  getContactList(){
    if(!this.contactGrid ){
      return null;
    }
    let contactList = {
      contacts: Helper.getContactsFromGrid(this.contactGrid)
    }
    return contactList;
  }
  onTanentSaveHandler(){
    let formData  = this.state.tenantFormData;

    let validData = {
        ...formData
    }
    if(!this.validator.isValid(validData,[this.contactGrid ])){
        this.validator.displayMessage(this);
        return;
    }
    if(this.contactGrid.contactComponent.hasErrors()){
        return;
    }
    let that = this;
    that.setState({
      isSarchingTenants:false,
      tenant:{},
      isLoading:true,
      message:null,
      messageType:'fail'
    })
    let api = Api;
    api.setUserToken();
    
    let tenantData = {
        code:formData.code,
        name:formData.name,
        isactive:formData.isactive === "true" ? true : false,
        start_date: Helper.getDateForDb(formData.start_date),
        end_date:Helper.getDateForDb(formData.end_date),
        first_name:formData.first_name,
        last_name:formData.last_name,
        middle_name:formData.middle_name,
        user_email:formData.user_email,
        enable:formData.isactive === "true" ? true : false,
        is_deleted:false,
        default_login_entity:formData.default_login_entity,
        SID:formData.SID ,
        language_id:1,
        usertype:formData.user_type,
        createdby:this.props.auth.user.user_id,
        modifiedby:this.props.auth.user.user_id,
        address: this.getAddressList(),
        contact: this.getContactList()
    }
    
    api.axios().post(Settings.apiUrl + '/add_tenant',tenantData).then(function(res){
      if(res.data.Status ==='Success'){
        Helper.alert(res.data.Message)
        that.props.rs_router.navigate('/tenants');
      }else{

      }
      that.setState({
        isLoading:false,
        message:null,
        messageType:res.data.Status,
      })
    }).catch(function(e){
      that.setState({
        isLoading:false,
        message:e.response.data.message,
        messageType:e.response.data.Status,
      })
    })
  }
  
  setFormData(data){
    this.setState({
      message:null,
      tenantFormData:data
    })
  }
  onAddClickHandler(){
    this.setState({
      isSarchingTenants:false,
      tenant:null
    })
  }
  onSearchClickHandler(){
    this.setState({
      displaySearchResultPopup:true,
      isSarchingTenants:true
    })
  }
  onUserPopupClose(){
    this.setState({
      displaySearchResultPopup:false,
      isSarchingTenants:true
    })
  }
  onPopupTenantSelectHandler(tenantData){
    this.setState({
      displaySearchResultPopup:false,
      isSearchMode:false,
      tenant:{
      }
    })
  }
  render() {
    let tenant = this.state.tenant;
    let language =this.props.language;
    let security = this.props.security;
    if(!security.canView(Form_gui_ids.tenant.newForm.page)){
        return <Alert type="danger" message={Helper.getLabel(language,'no_access','No Access')} />
    }
    
    return (
      <section className='tenant-form-section'>
        <div className='container-fluid'>
          <NavigationHeder backUrl="/tenants" title={ Helper.getLabel(language,'new_tenant',"New tenant") }>
            {<TextTranslations/> }
            <LinkBtn isActive= {false} to="/tenants"  title={Helper.getLabel(language,'cancel',"Cancel")} />
            <Button onClick={ e => this.onTanentSaveHandler()} icon={"/images/icons/save-white.svg"} isActive= {true} title={ Helper.getLabel(language,'save',"Save") }/>
          </NavigationHeder>
          { this.state.isLoading ?  <SimpleLoading width="50px"/> : ''}
          { this.state.message ? <Alert message = {this.state.message} type={ this.state.messageType === 'Success' ? 'success' : 'danger' } /> : '' }
          <div className='address_contact_section'>
              <TenantFields validator={this.validator} data={this.state.tenant ? this.state.tenant : {}} isSearchMode={this.state.isSarchingTenants} setFormData = { this.setFormData.bind(this)} />
              
              {   
              security.canView(Form_gui_ids.tenant.newForm.createContact) ? 
              <Collapse title={ "Contact"} open={true}>
                  <ContactAddress dataExportable ={ true}  onGridReady ={ (gridObj) => {this.contactGrid = gridObj }}  contactsource_id ={null} source_id = {1} address_id = {null} />
              </Collapse>
                : ''
              }
            </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      language:state.language
  }
}
const SecurityOptions = {
  gui_id:Form_gui_ids.tenant.newForm.page
}
export default connect (mapStateToProps) ( ApplySecurityRoles( RsWithRouter(TenantForm) ,SecurityOptions)   )