import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper' 
import Collapse from '../../inc/Collapse'
import FieldsRenderer from './FieldsRenderer'

const SetupLinesTab = (props) => { 

  let textBoxInputSettings = [ 
    {label:Helper.getLabel(props.language,'tax_group',"Tax Group") ,options: props.taxGroups, inputType: "dropdown", name:'taxgroup_id', id:'tax_group_id'},
    {label:Helper.getLabel(props.language,'item_tax_code',"Tax Code") ,options: props.taxCodes, inputType: "dropdown", name:'taxcodes_id', id:'tax_code_id'},
    {label:Helper.getLabel(props.language,'advancepayment',"Advance Payment"), inputType: "checkbox", name:'advance_payment', id:'advancepayment_id'},
    {label:Helper.getLabel(props.language,'advance_amount',"Advance Amount"), required: true,inputType: "text", name:'advance', id:'advance_amount'},
    {label:Helper.getLabel(props.language,'recoupment_rate',"Recoupment Rate"), inputType: "text", name:'recoupment_rate', disable: true},
    {label:Helper.getLabel(props.language,'retainage_rate',"Retainage Rate"), inputType: "text", name:'retainage_rate', disable: true},
    {label:Helper.getLabel(props.language,'maximum_retainage_amount',"Maximum Retainage Amount"), inputType: "text", disable: true, name:'max_retainage_amount' },
  ] 

  return (
    <Collapse className='mb-4 setup_list_view_lines' open={true} title={Helper.getLabel(props.language,'setup',"Setup")}>
      <div className='container-fluid'>
        <FieldsRenderer updateGridState={props.updateGridDataState} updateState={props.setState} currentState={props.state} fieldsArray={textBoxInputSettings} />
      </div>
    </Collapse>
  )
}

 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}



export default connect(mapStateToProps) (SetupLinesTab) 