import { Link } from 'react-router-dom';
import SupplierIcon from '../../assets/supplier.svg';
import EmailIcon from '../../assets/email.svg';
import PhoneIcon from '../../assets/phone.svg';

const AuthFooter = () => {
    return(
        <div className="login_fooer">
            <div className="container">
                <div className="log_footer_menu">
                    <Link to="/"><img src={SupplierIcon}  /> <span>Supplier Help Guides</span></Link>
                    <a href="tel:+92 002341 24125"><img src={PhoneIcon}  /> <span>+92 002341 24125</span></a>
                    <a href="mailto:markalice@gmail.com"><img src={EmailIcon}  /> <span>markalice@gmail.com</span></a>
                </div>
            </div>
        </div>
    )
}
export default AuthFooter;