import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import FormValidator from '../../../inc/FormValidator';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import Input from '../../Forms/Input';
import DropDownInput from '../../Forms/Dropdown';
class RegisterForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            isSucess:false,
            isLoading:false,
            isFormSubmited:false,
            message:null,
            messageType:'',
            vendorTypes:[],
            data:{
                first_name:"",
                last_name:"",
                middle_name:"",
                user_name:"",
                enable:true,
                SID:"",
                network_domain:"",
                network_alias:"",
                language_id:1,
                tenant_id:'2963270784274924807',
                vendor_type:0,
                company_name:""
            }
        }
        this.validationConfigure();
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'first_name',displayName:Helper.getLabel(language,'first_name','First Name'),types:['Required'],max:80 },
            {name:'last_name',displayName:Helper.getLabel(language,'last_name','Last Name'),types:['Required'],max:80 },
            {name:'email',displayName:Helper.getLabel(language,'email','Email'),types:['Required','Email'],max:80 },
            {name:'password',displayName:Helper.getLabel(language,'password','Password'),types:['Required'],min:5,max:80 },
            {name:'password_confirm',displayName:Helper.getLabel(language,'password_confirm','Password Confirm'),types:['Required'],min:5,max:80 },
            //{name:'isactive',displayName:Helper.getLabel(language,'active','Active'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        this.loadVendorTypes();
    }
    loadVendorTypes(){
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl+'/enum/vendortype').then(res => {
            that.setState({
                vendorTypes:res.data.data
            })
        })
    }
    onChangeHandeler(e){
        let data = this.state.data;
        this.setState({
            data:{
                ...data,
                [e.target.name]:e.target.value
            }
        })
    }
    registerHandler(e){
        if(this.state.isLoading){
            return;
        }
        let validData = this.state.data;
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage(this);
            return;
        }
        if(validData.password != validData.password_confirm){
            Helper.alert("Password and confirm password field need to same",'error');
            this.setState({
                message:'Password and confirm password field need to same',
                messageType:'fail'
            })
            return;
        }
        let api = Api;
        api.setUserToken();
        let data = this.state.data;
        data.user_name = data.email;
        if(!Helper.getNullableValue(data.company_name) && data.vendor_type  == 2){
            data.company_name = data.first_name + ' ' +data.last_name ;
        }
        
        let that = this;
        this.setState({
            isLoading:true
        })
        api.axios().post(Settings.apiUrl+'/register_vendor',data).then(res => {
            that.setState({
                message:res.data.message,
                messageType:res.data.status,
                isLoading:false
            })
        }).catch(error=>{
            that.setState({
                message:error.response.data.message,
                messageType:'error',
                isLoading:false
            })

        })
    }
    onKeyPreshHander(event){
        if(event.key =='Enter'){
            this.registerHandler(event);
        }
    }
    render() {
        let vendor_type = this.state.vendorTypes.map(item=>{
            return{label:item.name,value:item.id}
        })
        let data = this.state.data;
        
        return (
            <div className='login_form_group registration_page'>
                <div className='lf_title_subtitle'>
                    <h2>Join with us</h2>
                    <p>Please use your company account to sign in.</p>
                </div>
                <div className='row'>
                    <div className='col-xs-12 col-md-6'><Input label="First Name" hasError={this.validator.hasError('first_name')}  name="first_name" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.first_name} onChange= { e => this.onChangeHandeler(e) }/></div>
                    <div className='col-xs-12 col-md-6'><Input label="Last Name" hasError={this.validator.hasError('last_name')}  name="last_name" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.last_name} onChange= { e => this.onChangeHandeler(e) } /></div>
                </div>
                <DropDownInput name="vendor_type" value={data.vendor_type} options={vendor_type} onChange={this.onChangeHandeler.bind(this)}  label={Helper.getLabel(null,"vendor_type","Vendor type")} />
                {data.vendor_type == 1 ? <Input label="Company name" hasError={this.validator.hasError('company_name')}  name="company_name" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.company_name} onChange= { e => this.onChangeHandeler(e) } /> : ''}
                <Input label="Email" hasError={this.validator.hasError('email')}  inputType="email" name="email" onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.email} onChange= { e => this.onChangeHandeler(e) } />
                
                <Input label="Password" hasError={this.validator.hasError('password')} inputType="password"  onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.password} onChange= { e => this.onChangeHandeler(e) } name="password" />
                <Input label="Password Confirm" hasError={this.validator.hasError('password_confirm')} inputType="password"  onKeyPress={ this.onKeyPreshHander.bind(this)} value={data.password_confirm} onChange= { e => this.onChangeHandeler(e) } name="password_confirm"/>
                { this.state.message ? <p className={this.state.messageType ==='success' ? 'alert alert-success' : 'alert alert-danger' }>{this.state.message}</p> : ''}
                <button className='btn btn_block btn_blue sb_btn' onClick={ e => this.registerHandler(e)}>Join now{this.state.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''}</button>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        language:state.language,
        auth:state.auth
    }
}
export default connect(mapStateToProps) (RegisterForm);