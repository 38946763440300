import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import Input from '../../Forms/Input'
import Collapse from '../../inc/Collapse'
import FieldsRenderer from './FieldsRenderer'

const GeneralLinesListView = (props) => {
    const [itemGroups, setItemGroups] = useState([]) 

    //API renderers

      useEffect(() => {
        ItemGroupAPI()
      }, [])
    
    //End

    //API Reduction Calls
      let itemGroupCalled = false
    //End
 
    //API Calls
      const ItemGroupAPI = () => {
        if(itemGroupCalled) {
          return;
        }
        let api = Api
        itemGroupCalled = true
        api.setUserToken() 
        api.axios().get(Settings.apiItemUrl+'/item_group',{}).then(function(res){
        if(res.data.status==="success"){
          let itemGroups = []
          res.data.data.forEach(item => {  
          itemGroups.push({
            value: item.itemgroup_id,
            label: item.name
          })
        }) 
        setItemGroups(itemGroups)
        } 
      }).catch((res) => { 
        console.log(res.response.data.message)
        // Helper.alert(res.response.data.message, 'error')
      })
      }
    //End
  
    let textBoxInputSettings = [ 
      {label:Helper.getLabel(props.language,'description',"Description"), inputType: "text", name:'description'},
      {label:Helper.getLabel(props.language,'comments',"Comments"), inputType: "text", name:'comments'},
      {label:Helper.getLabel(props.language,'item_group',"Item Group"),options: itemGroups, inputType: "dropdown", name:'itemgroup_id', id: 'itemgroup_id_1'},
      {label:Helper.getLabel(props.language,'external_reference',"External Reference"), inputType: "text", name:'external_reference'},
      {label:Helper.getLabel(props.language,'ordered',"Ordered"), options: props.ordered, inputType: "dropdown", id: 'ordered_id', name:'orderer_id'},
      {label:Helper.getLabel(props.language,'requester',"Requester"), options: props.requester, inputType: "dropdown", id: 'requester_id_lines', name:'requester_id'}, 
    ]
 
   
  return (
    <Collapse className='mb-4 lines_details_po' open={true} title={Helper.getLabel(props.language,'lines_details',"Lines Details")}>
      <div className='container-fluid'>  
        <FieldsRenderer updateGridState={props.updateGridDataState} fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
      </div>
    </Collapse>
  )
}


const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }

export default connect(mapStateToProps) (GeneralLinesListView) 