import { connect } from "react-redux"
import Helper from "../../inc/Helper"
import Button from "./Button"
import LinkBtn from "./LinkBtn"

const ButtonNew = (props) => {
    if(props.to){
        return <LinkBtn isActive={true} icon="/images/icons/plus-white.svg" title={ Helper.getLabel(props.language,'new','New') } {...props} />
    }
    return <Button isActive={true} icon="/images/icons/plus-white.svg" title={ Helper.getLabel(props.language,'new','New') } {...props} />
}
const mapStateToProps = (state) => {
    return {
        language:state.language
    }
}
export default   connect (mapStateToProps)  ( ButtonNew );