import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import Alert from '../../inc/Alert';
import Button from '../../inc/Button';
import LinkBtn from '../../inc/LinkBtn';
import SimpleLoading from '../../Loading/SimpleLoading';
import ContactAddress from '../Address/ContactAddress';
import TenantFields from '../Tenant Fields/TenantFields';
import $ from "jquery";
import NavigationHeder from '../../Navigations/NavigationHeder';
import Helper from '../../../inc/Helper';
import TextTranslations from '../../Popup/TextTranslation/TextTranslations';
import Collapse from '../../inc/Collapse';
import Form_gui_ids from '../../../inc/Form_gui_ids';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import FormValidator from '../../../inc/FormValidator';
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory';
import User from '../../../inc/Auth/User';
class EditTenant extends Component {
    constructor(props){
        super(props);
        this.contactGridObj = null;
        this.addressGridObj = null;
        this.validationConfigure();
        this.isFirstCalled = false;
        this.state = {
            tenant:null,
            isLoading:false,
            message:null,
            
            messageType:'danger'
        }
    }
    componentDidMount(){
        
        this.loadTenant()
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'name',displayName:Helper.getLabel(language,'name','Name'),types:['Required'],max:100 },
            //{name:'isactive',displayName:Helper.getLabel(language,'active','Active'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
      }
    loadTenant (){
        let tenantID = this.props.params.id ? this.props.params.id : null;
        if(this.isFirstCalled || !tenantID){
            return;
        }
        let that = this;
        let api = Api;
        this.isFirstCalled = true;
        that.setState({
            isLoading:true,
        })
        //api.axios().post(Settings.apiUrl + '/tenant',requstData).then(function(res){
        api.axios().get(Settings.apiUrl + '/tenant/'+tenantID).then(function(res){
            if(res.data.status === 'success'){
                let item = res.data.data;
                let tenantUser = item.user;
                that.setState({
                    isLoading:false,
                    tenant: {
                        ...item,
                        user_type:tenantUser.usertype,
                        tenant_name:item.name,
                        first_name:tenantUser.first_name,
                        last_name:tenantUser.last_name,
                        middle_name:tenantUser.middle_name,
                        SID:tenantUser.SID,
                        user_email:tenantUser.user_name,
                        isactive:item.isactive ? "true" : 'false',
                        enable:item.isactive ? "true" : 'false',
                    },
                    message:null,
                    messageType:'success'
                }) 
                
                
            }else{
                that.setState({
                    isLoading:false,
                    tenant: null,
                    message:"Not founn or could be no access",
                    messageType:'error'
                }) 
            }
            
        })
    }
    onSaveHandler(e){
        let newData = {
            name:this.state.tenant.name,
            isactive:this.state.tenant.isactive === 'true' ? true: false
        }

        let validData = {
            ...newData
        }
        if(!this.validator.isValid(validData,[this.addressGridObj,this.contactGridObj])){
            this.validator.displayMessage(this);
            return;
        }
        if(this.contactGridObj.contactComponent.hasErrors()){
            return;
        }


        this.setState({
            isLoading:true,
            //tenant: null,
        })
        let that = this;
        let api  = Api;
        let tenantId = this.state.tenant.tenant_Id;
        
        api.setUserToken();
        //console.log(this.contactGridObj);
        //this.contactGridObj.componentObj.saveBtnClickHandler();
        $('.address_contact_section .rs_grid_btn_save').trigger('click');
        api.axios().put(Settings.apiUrl + '/tenant/'+ tenantId,newData).then(function(res){
            if(res.data.status ==='success'){
                Helper.alert(res.data.message)
                that.setState({
                    isLoading:false,
                    message: res.data.message,
                    messageType:'success'
                })
            }else{
                that.setState({
                    isLoading:false,
                    message: res.data.message,
                    messageType:'danger'
                })
                Helper.alert(res.data.message,'error')
            }
            
            
        }).catch(function(enm){
            that.setState({
                isLoading:false,
                message: enm.response.data.message,
                messageType:'danger'
            })
            Helper.alert(enm.response.data.message,'error')
        })
        setTimeout(function(){
            
        },300)
    }
    onFormDataChange(data){
        this.setState({
            tenant:data
        })
    }
    onContactCreate(data){
        if(!this.state.tenant.contactsource_id){
            let that = this;
            let api  = Api;
            let tenantId = this.state.tenant.tenant_Id;
            api.setUserToken();
            let newData ={
                name:null,
                isactive:null,
                addresssource_id:null,
                contactsource_id:data.contact_source_id
            }
            api.axios().put(Settings.apiUrl + '/tenant/'+ tenantId,newData).then(function(res){
                that.isFirstCalled = false;
                that.loadTenant();
            })
        }
    }
    onAddressCreate(data){
        if(!this.state.tenant.addresssource_id){
            let that = this;
            let api  = Api;
            let tenantId = this.state.tenant.tenant_Id;
            api.setUserToken();
            let newData ={
                name:null,
                isactive:null,
                addresssource_id:data.address_source_id,
                contactsource_id:null
            }
            that.setState({
                tenant:{
                    ...that.state.tenant,
                    addresssource_id:data.address_source_id
                }
            })
            api.axios().put(Settings.apiUrl + '/tenant/'+ tenantId,newData).then(function(res){
                //that.isFirstCalled = false;
                //that.loadTenant();
                
            })
        }
    }
    onContactGridReady(grid){
        this.contactGridObj = grid;
    }
    displayContactAndAddress(security){
        if(!this.state.tenant){
            return <></>
        }
        let user = new User(this.props.auth);
        /*
        if(user.isSupperAdmin()){
            return;
        }
        */
        return(
            <>

                <Collapse title={ "Contact"} open={true}>
                    <ContactAddress onGridReady={this.onContactGridReady.bind(this)} canEdit={ security.canEdit() } onContactCreate = {this.onContactCreate.bind(this)} contactsource_id ={this.state.tenant.contactsource_id} source_id = {1} address_id = {null} contactsource_integrator={this.state.tenant.tenant_Id} />
                </Collapse>
            </>
        )
    }
    render() {
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        let language =this.props.language;
        let security = this.props.security;
        if(!security.canRead()){
            return <Alert type="danger" message={Helper.getLabel(language,'no_access','No Access')} />
        }
        let tenant = this.state.tenant ? this.state.tenant : {};
        return (
            <div className='container-fluid'>
                <NavigationHeder  backUrl="/tenants"  title={ `<span style="color:#000;">${Helper.getLabel(language,'tenant',"Tenant")} #</span>  ${tenant.code}` }>
                    <TextTranslations isDisable={ !security.canEdit()}  source_id="1" source_integrator = {tenant.tenant_Id} translationsource_id={tenant.translationsource_id} original_text={tenant.name}  product_number={tenant.code}/>
                    <LinkBtn isActive= {false} to="/tenants" title={Helper.getLabel(language,'cancel',"Cancel")} />
                    <Button isDisable={ !security.canEdit() || this.state.isLoading } isActive= {true} onClick = { this.onSaveHandler.bind(this)} title={Helper.getLabel(language,'save',"Save")} icon={'/images/icons/save-white.svg'} />
                </NavigationHeder>
                <div className='address_contact_section'>
                    {
                        this.state.tenant ? <TenantFields  validator={this.validator} security = {security} isAdminEditor={true}  isNotNewForm = {true} data = { this.state.tenant} setFormData = { this.onFormDataChange.bind(this) }/> : <Alert message ={this.state.message} type={this.state.messageType ==='error' ? "danger" : 'success' } /> 
                    }
                    { this.displayContactAndAddress(security) }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
const SecurityOptions = {
    gui_id:Form_gui_ids.tenant.main_id
}
export default   connect (mapStateToProps) ( ApplySecurityRoles( EditTenant,SecurityOptions));

