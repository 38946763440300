import React, { Component } from 'react';
import ActionTypes from '../../inc/ActionsTypes';
import {connect} from 'react-redux';
import SimpleLoading from '../Loading/SimpleLoading';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import { Navigate } from "react-router-dom";
import Header from '../Header/Header';
import Sidebar from '../Header/Sidebar';
import Helper from '../../inc/Helper';
import User from '../../inc/Auth/User';
import {Helmet} from "react-helmet";
import ThemeSettings from '../../inc/ThemeSettings';
class MasterComonent extends Component {
    constructor(props){
        super(props);
        this.isTenantSettingLoaded = false;
        this.isVendorDetailsCalled = false;
        this.state = {
            isUserChecked:false,
        }
    }
    componentDidMount(){
        let api = Api;
        let auth = this.props.auth;
        let that = this;
        if(!auth.isLogin && api.getUserToken()){
            api.setJsonHeader();
            api.setUserToken();
            api.axios().get(Settings.apiUrl+'/user_details',{}).then(function({data}){
                if(data && data.Status === "Success"){
                    that.props.setUser(data.data);
                }else{
                    that.props.logOut()
                }
                that.setState({
                    isUserChecked:true
                })
            }).catch(error => {
                
                that.props.logOut();
            })
        }else{
            that.setState({
                isUserChecked:true
            })
        }
        this.loadAppLanguage();
        this.loadUserSettings();
    }
    loadAppLanguage(){
        
    }
    loadUserSettings(){
        this.loadUserDateFormate();
        this.loadTenantSettings();
        this.laodVendorDeails();
    }
    loadUserDateFormate(){
        let dateFormateString = Helper.getCookie(Settings.dateFormateKey) ? Helper.getCookie(Settings.dateFormateKey) : Settings.defaultDateFormate;
        this.props.setDateFormate(dateFormateString);
    }
    laodVendorDeails(){
        if(this.props.vendor.isVendorLoaded){
            return;
        }
        if(this.isVendorDetailsCalled){
            return;
        }
        this.isVendorDetailsCalled = true;
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiVendorUrl + '/get_vendor_by_user').then(res => {
            let responseData = res.data.data;
            that.props.setVendor(responseData);
        }).catch(error => {
            that.props.setVendor(null);
        })
    }
    loadTenantSettings(){
        if(this.isTenantSettingLoaded){
            return;
        }
        this.isTenantSettingLoaded = true;
        if(this.props.theme.isLoaded){
            return;
        }
        let that = this;
        let api = Api
        api.setUserToken();
        api.axios().get(Settings.apiUrl+ '/tenant_configuration').then(res=>{
            if(res.data.status ==='success'){
                that.props.setTheme(res.data.data.theme_setting.theme,res.data.data.theme_setting)
            }
        })
    }
    addThemesStyle(){
        let themeSet = new ThemeSettings(this.props.theme);
        return(
            <Helmet>
                <style>
                { themeSet.getCss() }
                </style>
            </Helmet>
        )
    }
    needToRedirectVendorRegistration(){
        let userVendor = this.props.vendor;
        if(userVendor.isVendorLoaded && userVendor.vendor && userVendor.vendor.status ===0){
            return true
        }
        return false;
    }
    render() {
        const props = this.props;
        const user = new User(props.auth);
        let userVendor = this.props.vendor;
        if(!props.auth.isLogin && this.state.isUserChecked){
            return <Navigate to="/login"/>
        }else if(!props.auth.isLogin || !userVendor.isVendorLoaded){
            return <SimpleLoading/>
        }else if(this.needToRedirectVendorRegistration()){
            return (
                <div className="app_master_area">
                    <div className='sidebar_contact_wrapper'>
                        <div className='contents_with_header'>
                            <Header/>
                            <div className='rs_master_canvas_wraper'>
                                <div className='rs_master_canvas'>
                                    <div className='clearfix'></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            )
        }else{
            //console.log('Current user',user)
            return (
                <div className="app_master_area">
                     { this.addThemesStyle()}
                    <div className='sidebar_contact_wrapper'>
                        <Sidebar/>
                        <div className='contents_with_header'>
                            <Header/>
                            <div className='rs_master_canvas_wraper'>
                                <div className='rs_master_canvas'>
                                    { props.children }
                                    <div className='clearfix'></div>
                                </div>
                            </div>
                            
                            <div className='clearfix'></div>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        vendor:state.vendor,
        theme:state.theme
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logOut:() => { dispatch({type:ActionTypes.SET_LOGOUT}) },
        setDateFormate:(formate) => { dispatch({type:ActionTypes.SET_DATE_FORMATE , payload:formate}) },
        setUser:(user) => { dispatch({type:ActionTypes.SET_USER , payload:user}) },
        setAuthLoadState:(loadState) => { dispatch({type:ActionTypes.SET_AUTH_LOADING , payload:loadState}) },
        setTheme: (themeName,settings) => { dispatch({ type:ActionTypes.SET_THEME_SETTINGS,settings:settings,theme: themeName})},
        setVendor: (vendor) => { dispatch({ type:ActionTypes.SET_USER_VENDOR,payload:vendor})}
    }
}
export default connect (mapStateToProps,mapDispatchToProps) ( MasterComonent);