import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import '../../../../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.min.css';
import datetimepicker from 'jquery-datetimepicker';
import Helper from '../../../../inc/Helper';
class DatePicker {
    init(params) {
      this.eInput = document.createElement('input');
      if(params.value){
        if(Helper.isFrontendFormatedDate(params.value)){
          this.eInput.value = params.value;
        }else{
          this.eInput.value = Helper.convertDateFromDb(params.value);
        }
      }else{
        this.eInput.value = '';
      }
      this.eInput.classList.add('ag-date-picker-input');
      this.eInput.style.height = '100%';
      this.eInput.style.width = '100%';
      $(this.eInput).datetimepicker({
          timepicker: false,
          format: Helper.getDatePickerFormate(),
          mask:true,
      });
      
    }
  
    // gets called once when grid ready to insert the element
    getGui() {
      return this.eInput;
    }
  
    // focus and select can be done after the gui is attached
    afterGuiAttached() {
      //this.eInput.focus();
      //this.eInput.select();
    }
    getValue() {
      if(this.eInput.value === 'undefined'){
        return '';
      }
      return this.eInput.value;
    }
    destroy() {

    }
    isPopup() {
      // and we could leave this method out also, false is the default
      return false;
    }
    focusIn(){
      this.eInput.focus();
    }
  
  }
  export default DatePicker;