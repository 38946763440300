import React, {useState, useMemo, useRef, useEffect, Fragment} from 'react';
import JoditEditor from 'jodit-react';
import '../Email.css'
import MasterComonent from '../../Backend/MasterComonent';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Input from '../../Forms/Input';
import LinkBtn from '../../inc/LinkBtn';
import Button from '../../inc/Button';
import Helper from '../../../inc/Helper';
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import { ValidateData } from '../../../inc/Validation';
import DropDownInput from '../../Forms/Dropdown'; 
import Settings from '../../../inc/Settings';
import { useNavigate } from 'react-router-dom';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import Collapse from '../../inc/Collapse';
import SimpleLoading from '../../Loading/SimpleLoading';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';

const CreateEmail = (props) => { 
  const [createEmails, setCreateEmails] = useState({
    subject: '',
    senderemail: '',
    sendername: '',
    defaultlanguage_id: null,
    description: '',
    body: ''
  })
   const navigate = useNavigate()
  const editor = useRef(null);
  const [loading, setLoading] = useState(false)
	const [content, setContent] = useState(createEmails.body);
  const [Languages, setLanguages] = useState([])

  let languagesCalled = false

  useEffect(() => {
    get_languages() 
  }, [])
 

  const get_languages = () => {
    if(languagesCalled) {
      return;
    }
    let api = Api
    languagesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/language',{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
         res.data.data.forEach((element) => {
          UPDATED_STATE.push({
            value: element.language_id,
            label: element.language
          })
         })
        setLanguages(UPDATED_STATE)
      }
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
}

  const DescChangeHandler = (event) => {
    let Desc = event.target.value
     setCreateEmails((prevState) => {
      return {...prevState, description: Desc}
     })
  }
  const SubjectChangeHandler = (event) => {
    let Subject = event.target.value
     setCreateEmails((prevState) => {
      return {...prevState, subject: Subject}
     })
  }
  const NameChangeHandler = (event) => {
    let Name = event.target.value
    setCreateEmails((prevState) => {
     return {...prevState, sendername: Name}
    })
  }
  const EmailChangeHandler = (event) => {
    let Email = event.target.value
    setCreateEmails((prevState) => {
     return {...prevState, senderemail: Email}
    })
  }
  const LanguageChangeHandler = (event) => {
    let Lang = event.target.value
    setCreateEmails((prevState) => {
     return {...prevState, defaultlanguage_id: Lang}
    })
  }
  const SaveHandler = () => { 
    setLoading(true)
      let api = Api
      api.setUserToken()
      let validation = ValidateData(createEmails) 
      if (validation){
        Helper.alert('Please Enter Data In Fields!', 'error') 
      } 
        api.axios().post(Settings.apiUrl+`/emailheader`,{
          "description":createEmails.description,
          "defaultlanguage_id":createEmails.defaultlanguage_id,
          "sendername":createEmails.sendername,
          "senderemail":createEmails.sendername,
          "priority":1,
          "emails":[ 
            {
              "subject":createEmails.subject,
              "body":createEmails.body
            }
          ]
      }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            setCreateEmails({
              subject: '',
              senderemail: '',
              sendername: '',
              defaultlanguage_id: null,
              description: '',
              body: ''
            }) 
            setLoading(false)
            navigate('/email-templates')
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error') 
        setLoading(false)
      })

  }
 

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.email_template.email_template_create_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }
  
  

  return ( 
    <div className='container-fluid create-emails-section'>
       <NavigationHeder title= 'Create Email Template'>
          <LinkBtn isActive= {false} to="/email-templates" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
          <Button isDisable={!security.canCreate(frontendIds.setupForms.email_template.email_template_create_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
       </NavigationHeder>
        {loading ? <SimpleLoading /> : ''}
       <Collapse open={true} title='General'>
 
       <div className='row mb-3'>

       <div className='col-lg-3'> 
       <Input label={Helper.getLabel(props.language,'description',"Description")} type='text' onChange={DescChangeHandler} value={createEmails.description} />
       </div>

      <div className='col-lg-3'> 
       <Input label={Helper.getLabel(props.language,'sender_name',"Sender Name")} type='text' onChange={NameChangeHandler} value={createEmails.sendername} />
      </div> 

      <div className='col-lg-3'>  
       <Input label={Helper.getLabel(props.language,'sender_email',"Sender Email")} type='text' onChange={EmailChangeHandler} value={createEmails.senderemail} />
      </div>

     <div className='col-lg-3'> 
     <DropDownInput label={Helper.getLabel(props.language,'default_language',"Default Language")} options={Languages} onChange={LanguageChangeHandler} value={createEmails.defaultlanguage_id} />
     </div>
 
     </div> 

       </Collapse>  

       <Collapse title= 'Email Template' open={true}>

       <div className='col-lg-3 mb-4'>  
       <Input label={Helper.getLabel(props.language,'subject',"Subject")} type='text' onChange={SubjectChangeHandler} value={createEmails.subject}/>
      </div>

    <JoditEditor
    ref={editor}
    value={content} 
    tabIndex={1} // tabIndex of textarea 
    onChange={(newContent) => {
      setCreateEmails((prevState) => {
        let Body = newContent
        return {...prevState, body: Body}
      })
    }}
  />

  </Collapse>
  </div> 
  )
}
 

 
  
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.email_template.email_template_create_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(CreateEmail, SecurityOptions))))