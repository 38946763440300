import React, { Component } from 'react';

class Notifications extends Component {
    render() {
        return (
            <div className='notification_controller'>
                <img src='/images/icons/notification.svg' alt="Notifications"/>
                <span></span>
            </div>
        );
    }
}

export default Notifications;