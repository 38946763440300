import React, { Component } from 'react';
import TabsNavItem from './TabsNavItem';
import $ from 'jquery';
import Helper from '../../../inc/Helper';
/*
    addComponentTop: Function for Blue box
    addComponentRight: Function for Blue box
    tabContantsId: '', // Required for multiple tabs 
    disableSticky: true / false
    navs : [
        {
            title:'',
            link:'',
            isDisable: true | false
            isActive:false | true
        }
    ]
    stickyTopMargin: 0
    scrollOffset: "0px"
*/
class Tabs extends Component {
    constructor (props){
        super(props);
        
        this.scrollOffset = this.props.scrollOffset ? this.props.scrollOffset : 0;
        this.stickyTopMargin = this.props.stickyTopMargin ? this.props.stickyTopMargin : 0;
        this.id = this.props.id ? this.props.id : Date.now();
        this.tabItemIdPrefix = 'tab_item_'+this.id +'_';
        this.showAll = this.props.showAll ? true: false;
        this.hasSidebar = this.props.addComponentRight ? true : false;
        this.state ={
            activeItem:{}
        }
    }
    componentDidMount(){
        this.setDefaultActive();
        let mainHeaderHeight = $('.main_header').outerHeight();
        let navigationBarHeight = $('.app_navigation').outerHeight();
        this.stickyTopMargin = this.props.stickyTopMargin ? this.props.stickyTopMargin : (mainHeaderHeight+navigationBarHeight);
        if(!this.props.disableSticky){
            Helper.makeSticky('lynk_tab_m_id_'+this.id,{zIndex:1000 ,topSpace: this.stickyTopMargin,extraClass:'rs_secondery_header'})
            this.showActiveOnScroll()
        }
        //new Sticky('.lynkaz_tab_nav_section');
    }
    setDefaultActive(){
        let navs = this.props.navs ? this.props.navs : [];
        let i =0;
        navs.forEach(item => {
            if(item.isActive){
                this.setState({
                    activeItem: {
                        ...item,
                        key: this.tabItemIdPrefix+i
                    }
                })
            }
            i++;
        });
    }
    navOnClickHandler(item){
        let that = this;
        Helper.refreshAppScript();
        this.setState({
            activeItem:item
        },function(){
            if(that.props.showAll){
                Helper.scroll2id(that.state.activeItem.key,that.scrollOffset)
            }
        })


    }
    getActiveClass(item){
        //console.log(item)
        if(this.state.activeItem.key && this.state.activeItem.key=== item.key){
            return 'active'
        }
        return ''
    }
    displayChidren(){
        let tabIdPrefix = this.tabItemIdPrefix;
        if(!Array.isArray(this.props.children)){
            return this.props.children;
        }
        let navs = this.props.navs ? this.props.navs : [];
        return this.props.children.map( (ChildItem,key) => {
            let itemId = tabIdPrefix+key;
            if(navs[key].isDisable){
                return <span id= {itemId} key={key}></span>
            }
            let contactClassName = 'lynkaz_tab_content ';
            if(!this.showAll){
                if(this.state.activeItem.key && this.state.activeItem.key === itemId){
                    contactClassName += ' active';
                }
            }
            return (<div className= {contactClassName} id= {itemId} key={key}>{ChildItem}</div>)
        }) 
        
    }
    showActiveOnScroll(){
        let linkTab = $('#lnk_tb_'+this.id);
        let linkTabMenu = linkTab.find('.lynkaz_tab_nav');
        let linkTabMenuOffset = linkTabMenu.offset();
        let scrollTimer;
        $(window).on('scroll',function(){
            clearTimeout(scrollTimer);
            scrollTimer=setTimeout(function(){
                let allTabContents = document.getElementsByClassName("lynkaz_tab_content")
                let windowTopOffset =  window.pageYOffset;
                if(linkTabMenuOffset){
                    let navItemOffset = windowTopOffset+linkTabMenuOffset.top;
                    //$('.lynkaz_tab_nav_item').removeClass('active')
                    for (let contentItem of allTabContents) {
                        let navItem = document.getElementById('nav_'+contentItem.id)
                        if(navItemOffset >= contentItem.offsetTop && navItemOffset <= ( contentItem.offsetTop + contentItem.clientHeight ) ){
                            navItem.classList.add('active');
                        }else{
                            navItem.classList.remove('active');
                        }
                        
                    }
                }
                
                
            },100);
        })
    }
    render() {
        let navs = this.props.navs ? this.props.navs : [];
        let tabIdPrefix = this.tabItemIdPrefix;
        return (
            <div id={'lnk_tb_'+this.id} className={'lynkaz_tab ' + ( !this.showAll ? 'style_1' : 'style_2')} >
                <div className='lynkaz_tab_nav_section' id={'lynk_tab_m_id_'+this.id}>
                    { this.props.addComponentTop ? this.props.addComponentTop() : "" }

                    {
                        !this.props.isHideNav ?
                        <div className='lynkaz_tab_nav'>
                            {
                                navs.map( (item ,key) => { return ( <TabsNavItem offset={this.scrollOffset} getActiveClass = { this.getActiveClass.bind(this)} onClickHandler = {this.navOnClickHandler.bind(this)} key ={key} item={{...item,key:tabIdPrefix+key}}/> ) })
                            }
                            
                        </div>
                        :''
                    }
                    
                </div>
                
                <div id={this.props.tabContantsId ? this.props.tabContantsId : 'lynkaz_tab_contents' } className={ 'lynkaz_tab_contents ' + ( this.props.isHideNav ? ' mt-4 ' : ' ' )}>
                    <div className='lynkaz_tab_contents_content'>
                        {this.displayChidren()}
                    </div>
                    {this.hasSidebar ? <div className='lynkaz_tab_contents_sidebar'>{this.props.addComponentRight()}</div> : ''}
                </div>
            </div>
        );
    }
}

export default Tabs;