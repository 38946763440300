import MasterComonent from "../../../components/Backend/MasterComonent";
import NavigationHeder from "../../../components/Navigations/NavigationHeder";
import WorkflowVersions from '../Workflow Versions/WorkflowVersions';
import { useEffect, useState } from 'react';
import NewWorkflow from '../New Workflow/NewWorkflow';
import Button from '../../../components/inc/Button';
import axios from 'axios';
import Settings from '../../../inc/Settings';
import SimpleLoading from "../../../components/Loading/SimpleLoading";
import Helper from "../../../inc/Helper";
import CustomHtmlCell from "../../../components/grid/ag/cellRender/CustomHtmlCell";
import AgGridMemo from "../../../components/grid/ag/AgGridMemo";
import Popup from "../../../components/Popup/Popup";
import Api from "../../../inc/Api";
import TriggerOption from "../Trigger/TriggerOption";
import { Tab, Tabs } from "react-bootstrap";
import $ from 'jquery';

const Workflow = () => {

  const [tab, setTab] = useState('Active');
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [workflowVersionsModal, setWorkflowVersions] = useState(false);
  const [newWorkflowModal, setNewWorkflow] = useState(false);
  const [workFlowData, setWorkFlowData] = useState([]);
  const [activeForceRender, setActiveForceRender] = useState(false);
  const [inActiveForceRender, setInActiveForceRender] = useState(false);

  useEffect(() => {
    getActiveWorkflow();
  }, [newWorkflowModal, workflowVersionsModal])

  const [tenantId, setTenantId] = useState('');
  const getTenantId = () => {
    let api = Api;
    api.setUserToken()
    return new Promise((resolve, reject) => {
      resolve(
        api.axios().get(Settings.loginUrl + '/user_details').then((res) => {
          if (res.data.Status === "Success") {
            return (res.data.data);
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  useEffect(() => {
    getInactiveWorkflow();
  }, [workflowVersionsModal])
  const [inactiveWorkFlowData, setInactiveWorkFlowData] = useState([]);
  const getInactiveWorkflow = async () => {
    setLoading(true);
    const tenantid = await getTenantId();
    setTenantId(tenantid.tenant_id);
    const data = await getTemplateData();
    axios.get(Settings.baseUrl + `workflow/get/inactive?tenant_id=${tenantid.tenant_id}`).then((res) => {
      if (res.data.success) {
        const workflowData = res.data.data;
        const newtemplateDataArry = workflowData.map((item) => {
          const itemM = data.filter((fItem) => fItem.template_id === item.template_id);
          return {
            ...item,
            template_name: itemM?.[0]?.name,
            viewOrEdit: 'Design'
          }
        });
        setInactiveWorkFlowData(newtemplateDataArry);
        setInActiveForceRender(!inActiveForceRender);
        setLoading(false);
      };
    }).catch((error) => {
      console.log(error);
    })
  }

  const getActiveWorkflow = async () => {
    setLoading(true);
    const tenantid = await getTenantId();
    setTenantId(tenantid.tenant_id);
    const data = await getTemplateData();
    setTemplateData(data);
    axios.get(Settings.baseUrl + `workflow/get/active?tenant_id=${tenantid.tenant_id}`).then((res) => {
      if (res.data.success) {
        const workflowData = res.data.data;
        const newtemplateDataArry = workflowData.map((item) => {
          const itemM = data.filter((fItem) => fItem.template_id === item.template_id);
          return {
            ...item,
            template_name: itemM?.[0]?.name,
            viewOrEdit: 'Design'
          }
        });
        setWorkFlowData(newtemplateDataArry);
        setActiveForceRender(!activeForceRender);
        setLoading(false);
      };
    }).catch((error) => {
      console.log(error);
    })
  }

  const getTemplateData = () => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.baseUrl + 'template/get-all').then((res) => {
          if (res.data.success) {
            return res.data.data;
          };
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const redirectValueFormatter = (params) => {
    return `<span class="rs_active_label">${params.value}</span>`;
  }

  let Workflow_Headings = [
    { field: 'workflow_id', headerName: 'Workflow ID' },
    { field: 'name', headerName: 'Name', width: 350 },
    { field: 'description', headerName: 'Description', width: 350 },
    { field: 'template_name', headerName: 'Template', width: 350 },
    { field: 'instance', headerName: 'Instances', width: 250 },
    { field: 'version', headerName: 'Versions', width: 250 },
    // { field: 'viewOrEdit', headerName: 'Action', cellRenderer: CustomHtmlCell, valueFormatter: redirectValueFormatter }
  ]

  const getAllWorkflowData = (templateid) => {
    return new Promise((resolve, reject) => {
      resolve(
        axios.get(Settings.baseUrl + `workflow/get-version?template_id=${templateid}&tenant_id=${tenantId}`).then((res) => {
          if (res.data.success) {
            let data;
            data = res.data.data.filter(element => {
              return element.active;
            })
            return data;
          }
        })).catch((error) => reject(
          console.log(error)
        ))
    })
  }

  const deleteWorkflow = (workflowId) => {
    let formData;
    workflowId.forEach((item) => {
      formData = {
        "template_id": item.template_id,
        "tenant_id": item.tenant_id
      }
    });
    console.log(formData);
    axios.post(Settings.baseUrl + 'workflow/delete', formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
      }
    }).catch((error) => {
      if (error.response.data.message) {
        Helper.alert(error.response.data.message, 'error');
        getActiveWorkflow();
        getInactiveWorkflow();
      }
      console.log(error);
    })
  }

  // const workflowDeleteWithAllVersion = () => {
  //   if (!workflowid) {
  //     Helper.alert("Please select atleast one wrokflow", 'error');
  //     return;
  //   }
  //   $('.rs_form_grid .btn_delete').trigger('click');
  // }

  const [templateid, setTemplateid] = useState(null);
  const [workflowid, setWorkflowid] = useState(null);
  const getRowData = (event) => {
    let activeSelection = event.api.getSelectedRows();
    if (activeSelection.length) {
      setTemplateid(activeSelection[0].template_id);
      setWorkflowid(activeSelection[0].workflow_id);
    } else {
      setTemplateid(null);
      setWorkflowid(null);
    }
  }

  const workflowVersionHandler = () => {
    if (!templateid) {
      Helper.alert("Please select atleast one wrokflow", 'error');
      return;
    }
    setWorkflowVersions(true);
    setWorkflowid(null);
  }

  //all type of trigger execution on trigger modal 

  const [triggerOption, setTriggerOption] = useState(false);

  const designHandler = () => {
    if (!workflowid) {
      Helper.alert("Please select atleast one wrokflow", 'error');
      return;
    }
    setTriggerOption(true);
  }

  const workflowActiveHandler = () => {
    if (!workflowid) {
      Helper.alert("Select a workflow for activate", 'error');
      return;
    }
    let formData;
    formData = {
      "workflow_id": workflowid,
      "active": tab === 'Active' ? false : true
    }
    axios.post(Settings.baseUrl + `workflow/update`, formData).then((res) => {
      if (res.data.success) {
        Helper.alert(res.data.message);
        getActiveWorkflow();
        getInactiveWorkflow();
      };
    }).catch((error) => {
      console.log(error);
    })
  }

  const tabSelectionHandler = (e) => {
    setTab(e);
    setTemplateid(null);
    setWorkflowid(null);
  }

  return (
    <>
      {newWorkflowModal ? <NewWorkflow hideNewWorkflow={setNewWorkflow} templateData={templateData} tenantId={tenantId} /> : ""}
      {triggerOption ? <TriggerOption hideTriggerOption={setTriggerOption} workflowid={workflowid} templateid={templateid} /> : ""}
      <MasterComonent>
        {workflowVersionsModal ? <WorkflowVersions hideWorkflowVersions={setWorkflowVersions} templateId={templateid} setTemplateid={setTemplateid} /> :
          <>
            <NavigationHeder backButtonHide={true} title={`<span style="color:#000;"> Home > </span>  ${`${tab} workflows`}`}>
              {/* <Button icon={'/images/icons/delete.svg'} onClick={workflowDeleteWithAllVersion} /> */}
              <Button title={tab === 'Active' ? 'Inactive' : 'Active'} onClick={workflowActiveHandler} icon={'/images/icons/edit.svg'} />
              <Button title="Design" icon={'/images/icons/eye.svg'} onClick={designHandler} />
              <Button title="Version" icon={'/images/icons/finance.svg'} onClick={workflowVersionHandler} />
              <Button isActive={true} title="Workflow" icon={'/images/icons/plus-white.svg'} onClick={() => setNewWorkflow(true)} />
            </NavigationHeder>
            <div className='dashboard-nav-main'>
              <Tabs
                defaultActiveKey={tab}
                className="mt-4 mb-4 border-bottom"
                onSelect={tabSelectionHandler}
              >
                <Tab className='mb-4' eventKey="Active" title="Active">
                  {loading ? <SimpleLoading /> : null}
                  {tab === 'Active' &&
                    <div className='rs_form_grid'>
                      <AgGridMemo id="rs_users_list" data={workFlowData} header={Workflow_Headings} onDelete={deleteWorkflow} onSelectionChanged={getRowData} singleRowSelect={true} viewOnly={true} addBtnHide={true} forceRender={activeForceRender} />
                    </div>
                  }
                </Tab>
                <Tab className='mb-4' eventKey="Inactive" title="Inactive">
                  {loading ? <SimpleLoading /> : null}
                  {tab === 'Inactive' &&
                    <div className='rs_form_grid'>
                      <AgGridMemo data={inactiveWorkFlowData} header={Workflow_Headings} onDelete={deleteWorkflow} onSelectionChanged={getRowData} singleRowSelect={true} viewOnly={true} forceRender={inActiveForceRender} addBtnHide={true} />
                    </div>
                  }
                </Tab>
              </Tabs>
            </div>
          </>
        }
      </MasterComonent>
    </>
  )
}
export default Workflow;