import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import FieldsRenderer from './FieldsRenderer'

const PriceDiscountTab = (props) => {

  //States For Dropdowns
    const [paymentTerms, setPaymentTerms] = useState([])
  //End

  //API Calls Renderer
   useEffect(() => {
    PaymentTermsAPI()
   }, [])
  // End

  //API Reduction 
   let paymentTermsCalled = false
  //End

  
  // API Calls
  const PaymentTermsAPI = () => {
    if(paymentTermsCalled) {
      return;
    }
      let api = Api
      paymentTermsCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/paymentterm',{}).then(function(res){
      if(res.data.status==="success"){
        let PaymentARR = []
        res.data.data.forEach(item => {  
        PaymentARR.push({
          value: item.paymentterm_id,
          label: `${item.code} - ${item.name}`
        })
      }) 
      setPaymentTerms(PaymentARR)
      } 
    }).catch((res) => { 
      console.log(res.response.data.message)
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  //End

    let textBoxInputSettings = [ 
        {label:Helper.getLabel(props.language,'price_inclusive_of_tax',"Price Inclusive Of Tax"), inputType: "checkbox", name:'priceinclude_tax'},
        {label:Helper.getLabel(props.language,'payment_term',"Payment Term"), options: paymentTerms, inputType: "dropdown", id: 'paymentterm_id', name:'paymentterm_id'},
        {label:Helper.getLabel(props.language,'discount_percentage',"Discount Percentage"), inputType: "text", name:'discount_percentage'},
        {label:Helper.getLabel(props.language,'discount_amount',"Discount Amount"), inputType: "text", name:'discount_amount'},
    ]

  return (
    <Fragment>
        <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={props.state} />
    </Fragment>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (PriceDiscountTab) 