import React from 'react'  
import TenantForm from '../../Tenant/Tenant Form/TenantForm'; 
import MasterHeader from '../../Master Header/MasterHeader'; 
import {connect} from 'react-redux'; 
import Helper from '../../../inc/Helper'; 
import Tenants from '../Tenants';
const TenantContainer = ({language}) => {
  return (
    <React.Fragment> 
      <div className='container-fluid background-layout-forms'>
        
        <div className='row d-md-flex flex-md-row flex-row-md-reverse justify-content-md-center gx-2'>
          <div className='col-xxl-12 col-lg-12 col-md-12'>
            <TenantForm /> 
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      language:state.language
  }
}
export default connect (mapStateToProps) (TenantContainer);
