import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import FormValidator from '../../../../inc/FormValidator'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import MasterComonent from '../../../Backend/MasterComonent'
import Checkbox from '../../../Forms/Checkbox'
import Input from '../../../Forms/Input'
import Alert from '../../../inc/Alert'
import Button from '../../../inc/Button'
import Collapse from '../../../inc/Collapse'
import LinkBtn from '../../../inc/LinkBtn'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

const CreateReason = (props) => {
  const [createVendorReason, setCreateVendorReason] = useState({
    "description": null,
    "reason_for_vendor": null,
  })
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'description', max:255, displayName:Helper.getLabel(props.language,'description','Description'),types:['Required']},
        {name:'reason_for_vendor', displayName:Helper.getLabel(props.language,'reason_for_vendor','reason_for_vendor'),types:['Required']},  
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()

  const VendorReasonChangeHandler = (event, value) => {
    if (value === "on") { 
      setCreateVendorReason((prevState) => {
        return {
          ...prevState,
          reason_for_vendor: true
        }
      })
    }else {
      setCreateVendorReason((prevState) => {
        return {
          ...prevState,
          reason_for_vendor: false
        }
      })
    }
  }

  const DescriptionChangeHandler = (event) => {
    let desc = event.target.value
    setCreateVendorReason((prevState) => {
      return {
        ...prevState,
        description: desc
      }
    })
  } 

  const VNReasonModule = () => {
    return (
    <Collapse title={Helper.getLabel(props.language,'vendor_reason','Vendor Reason')} className="mb-4 general_tabs" open={true}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3'> 
            <Input label={Helper.getLabel(props.language,'description',"Description")} type='text' required={true} hasError={validator.hasError('description')} value={createVendorReason.description} onChange={DescriptionChangeHandler} />
          </div>

          <div className='col-lg-3'> 
            <Checkbox isChecked={createVendorReason.reason_for_vendor} label={Helper.getLabel(props.language,'vendor_reason',"Reason For*")} type='text' required={true} hasError={validator.hasError('vendor_reason')} onChange={VendorReasonChangeHandler} />
          </div>
        </div>
      </div>
    </Collapse>
    )
  }

  const SaveHandler = () => {
    let newReason = { 
      "description": createVendorReason.description, 
      "reason_for_vendor": createVendorReason.reason_for_vendor
    }
    let validData = {
      ...newReason
    } 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }   
    setLoading(true)
    let api = Api
    api.setUserToken()
    api.axios().post(Settings.apiVendorUrl+'/vendorreason',{
      "description": createVendorReason.description !== "" ? createVendorReason.description : null,
      "reason_for_vendor": createVendorReason.reason_for_vendor
  }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message) 
        setLoading(false)
        navigate('/vendors-reason')
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
    setLoading(false)
  })
  }

 
  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_reason.vendors_reason_create_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
    <div className='container-fluid'>
      <NavigationHeder title={Helper.getLabel(props.language,'vendor_reasons',"Create Vendor Reason")}>
        <LinkBtn isActive= {false} to="/vendors-reason" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canCreate(frontendIds.masterData.vendor_reason.vendors_reason_create_screen_save_button)}  isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder>
      {loading ? <SimpleLoading /> : (VNReasonModule())} 
    </div>  
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_reason.vendors_reason_create_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(CreateReason, SecurityOptions))))