import React, { Component } from 'react';
import './popup.css';
import $ from "jquery";
/**
 * onClose : Function()
 * autoOpen : boolean / default: false
 * width: String / Default: '500px'
 * 
 */
class Popup extends Component {
    constructor(props){
        super(props);
        this.state ={
            isOpen:true
        }
    }
    componentDidMount(){
        $('html').addClass('rs_popup_open');
    }
    onCloseHandler(){
        $('html').removeClass('rs_popup_open');
        this.setState({
            isOpen:false
        })
        if(this.props.onClose &&  typeof this.props.onClose === 'function' ){
            this.props.onClose();
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.autoOpen !== this.state.isOpen) {
          this.setState({ isOpen:nextProps.autoOpen  });
        }
    }
    render() {
        if(!this.state.isOpen){
            return <></>
        }
        let styleContents = {};
        if(this.props.width){
            styleContents.width = this.props.width;
        }
        const closeIconUrl = '/images/icons/cross.svg';
        return (
            <div className={this.props.className ? 'rs_popup_section '+this.props.className : 'rs_popup_section'}>
                {/* <div className='rs_popup_section_bg'  onClick={ e => this.onCloseHandler()}></div> */}
                <div className='popup_contents' style={styleContents}>
                    <div className='rs_popup_close' onClick={ e => this.onCloseHandler()}> <img src={closeIconUrl} alt="Close" /> </div>
                    { this.props.children }
                </div>
            </div>
        );
    }
}

export default Popup;