import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import PriceDiscountTab from './PriceDiscountTab'

const HeaderViewPriceDiscount = (props) => {
  return (
    <Collapse className='mb-4' open={true} title={Helper.getLabel(props.language,'price_&_discount','Price & Discount')}>
      <div className='container-fluid'>
        <PriceDiscountTab setState={props.setState} state={props.state} />
      </div>
    </Collapse> 
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (HeaderViewPriceDiscount) 