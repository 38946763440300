import React, {useState, useEffect} from 'react'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import $ from 'jquery'
import Settings from '../../../inc/Settings'
import AgGrid from '../../grid/ag/ag-grid'
import Popup from '../../Popup/Popup'
import { connect } from 'react-redux'
import { ValidateData } from '../../../inc/Validation'

const Barcodes = ({item_id, barcodes, setBarcodes, setOpenBarcodePopup, reloadGrid, language }) => {
     
    const Editable = (event) => {
      if(!event.data.item_id) {
        return true
      }
      return false
    }

    const CloseHandler = () => {
      setOpenBarcodePopup(false)
      $('.item_barcodes .rs_popup_close').trigger('click');
    }

    const GridValidationBarcode = (params) => {
      if(  params.newValue.length > 13 ){
        Helper.alert('Barcode Length Exceeded 13 characters!', 'error')
        // params.data.barcode = params.newValue 
        return false
    } 
    params.data.barcode = params.newValue
    return true
    }

    const Columns_Headings_BARCODES = [
      {field:'barcode', valueSetter: GridValidationBarcode, headerName: Helper.getLabel( language,'barcode',"Barcode"), editable:Editable},
    ]
 
    function removeObjectWithId(arr, id) {
      const objWithIdIndex = arr.findIndex((obj) => obj.gridRowId === id)
      if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
      }
      return arr;
    }

    const DeleteHandler = (allRows) => {
      if(!item_id) {
        let array = []
        allRows.forEach(element => {
          array = removeObjectWithId(barcodes, element.gridRowId);
        })
        setBarcodes(array)
        return;
      }
        let api = Api
        api.setUserToken()
        allRows.forEach(element => {
          api.axios().delete(Settings.apiItemUrl+`/barcode/${item_id}/${element.barcode}`,{}).then(function(res){
            if(res.data.status==="success"){
              Helper.alert(res.data.message)
              reloadGrid()
            } 
          }).catch((res) => { 
            Helper.alert(res.response.data.message, 'error')
            reloadGrid()
          })
        })
    }

    const SaveHandler = (data) => {
      if(!item_id){   
        let isDataDuplicated = false
        data.forEach((element) => {
          let variable = element.barcode
          let i = 0
          data.forEach(item => {
            if(item.barcode == variable){
              i++
            }
          }) 
          if(i >= 2) {
            isDataDuplicated = true
          }
        })
        if(!ValidateData(barcodes)){
          for (let i = 0; i < barcodes.length; i++) {
            let object1 = barcodes[i];
            let object2 = data[i];
            if (object1.barcode === object2.barcode ) {
              isDataDuplicated = true
            } 
          }
        }
        if(isDataDuplicated){
          Helper.alert('Barcode Duplicated!', 'error')
          return;
        }
        
        setBarcodes(barcodes.concat(data))
        $('.item_barcodes .rs_popup_close').trigger('click');
        setOpenBarcodePopup(false) 
      }

      if(item_id){
        let api = Api
        api.setUserToken()
        data.forEach((element) => {
          api.axios().post(Settings.apiItemUrl+'/itembarcode',{
            "item_id":item_id,
            "barcode":element.barcode
          }).then(function(res){
          if(res.data.status==="success"){ 
            Helper.alert(res.data.message) 
            setOpenBarcodePopup(false)
            reloadGrid()
            $('.item_barcodes .rs_popup_close').trigger('click');
          } 
          }).catch((res) => { 
            Helper.alert(res.response.data.message, 'error') 
            setOpenBarcodePopup(false)
            reloadGrid()
          }) 
        })
      }
    }
 
  return (
    <div className='item_barcodes'>
      <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
        <div className='rs_item_barcodes_grid'>
          <AgGrid id="rs_popup_open_barcodes_roles" onDelete={DeleteHandler} onSave={SaveHandler} header={Columns_Headings_BARCODES} data={barcodes} />
        </div>
      </Popup> 
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (Barcodes)