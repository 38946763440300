import React from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import './ActionSpecificUserModal.css';

export default function ActionSpecificUserModal(props) {
  return (
    <div className='m-4 p-3 border-primary user-main-container'>
      <div className='d-flex justify-content-between '>
        <div className='col-2'>
          <img className="img-fluid rounded-circle  float-start userImg" src={ props.taskDetail.image || '/images/blank-profile.jpg'} />
        </div>
        <div className='col-4 text-right'>
          <h4 className='m-0'>{props.taskDetail.name || 'David Antanio' }</h4>
          <h5 className='text-primary m-0'>Stage:</h5>
          <p className='text-muted'>Lorem, Ipsum.</p>
        </div>
        <div className='col-3 text-right mt-auto'>
          <h5 className='text-primary m-0'>Action:</h5>
          <p className='text-muted'>Sendback</p>
        </div>
        <div className='col-3 text-right mt-auto'>
          <h5 className='text-primary m-0'>Date:</h5>
          <p className='text-muted'>12/04/2023</p>
        </div>
      </div>
      <div className='mt-3'>

        <div className=''>
          <Tabs
            className="mb-3 conditional_tab_menu"
          >
            <Tab eventKey="comments" title="Comments" tabClassName="conditional_tab_item" >
              <div className='mt-3 text-muted'>
                {props.taskDetail.comment ||'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione culpa reiciendis modi laudantium, aspernatur architecto'}
              </div>
            </Tab>
            <Tab eventKey="kpis" title="KPIs" tabClassName="conditional_tab_item" >
              <div className='mt-3 text-muted'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione culpa reiciendis modi laudantium, aspernatur architecto
              </div>
            </Tab>
          </Tabs>
          {/* <span className='headingText'>Comments</span> <span className='ms-4 headingText2'>KPIs</span>
          <hr className='hrLine' />
          <div className='mt-3 text-muted'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione culpa reiciendis modi laudantium, aspernatur architecto
          </div> */}
          <div className=''>
            <Button className='btn btn-lg' onClick={() => props.hideUserActionPopup(false)}>Close</Button>
          </div>
        </div>

      </div>
    </div>
  )
}
