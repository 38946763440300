import React, { Component } from 'react';
import Input from '../../Forms/Input';
import {connect} from 'react-redux';
import { Link ,Navigate } from 'react-router-dom';
import LoginWithGoogle from './LoginWithGoogle';
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import ActionTypes from '../../../inc/ActionsTypes';
import FormValidator from '../../../inc/FormValidator';
class LoginForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            isFormSubmited:false,
            message:null,
            messageType:'fail',
            isLogin:false
        }
        this.validationConfigure();
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'email',displayName:Helper.getLabel(language,'email','Email'),types:['Required','Email'],max:80 },
            {name:'password',displayName:Helper.getLabel(language,'password','Password'),types:['Required'],min:5,max:80 },
            //{name:'isactive',displayName:Helper.getLabel(language,'active','Active'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    onChangeHandeler(e){
        this.setState({
            ...this.state,
            [e.target.name]:e.target.value
        })
    }
    onLoginClickHandler(e){
        if(this.state.isFormSubmited){
            return;
        }
        let validData = {
            email: this.state.email,
            password: this.state.password,
        }
        if(!this.validator.isValid(validData)){
            this.validator.displayMessage(this);
            return;
        }
        let formData = {
            user_name: this.state.email,
            password: this.state.password
        };
        let that = this;
        this.setState({
            isFormSubmited:true,
            message:null,
            messageType:'fail',
            isLogin:false
        });
        let api = Api;
        api.setJsonHeader();
        api.axios().post(Settings.apiUrl + '/login',formData).then(function({data}){
            if(data && data.status === "Success"){
                const aditionalData = data.data;
                //set user token to browser
                Helper.setCookie(Settings.userTokenKey,data.jwttoken,Settings.userTokenExpireDate);
                Helper.setCookie(Settings.dateFormateKey,aditionalData.tenant.date_format);
                that.props.setDateFormate(aditionalData.tenant.date_format);
                that.setState({
                    isFormSubmited:false,
                    messageType:data.status,
                    message: `Welcome ${aditionalData.first_name} ${aditionalData.first_name}`,
                    isLogin:true
                },function(){
                    that.props.login(aditionalData);
                });

            }else{
                that.setState({
                    isFormSubmited:false,
                    messageType:data.status,
                    message: data.message,
                    isLogin:true
                });
            }
            
        }).catch(error => {
            that.setState({
                message: error && error.response ? error.response.data.message : '',
                isFormSubmited:false
            });
        })
    }
    onKeyPreshHander(event){
        if(event.key =='Enter'){
            this.onLoginClickHandler(event);
        }
    }
    render() {
        if(this.state.isLogin){
            return <Navigate to = {'/' + Settings.redirectUrlAfterLogin}/>
        }
        return (
            <div className='login_form_group'>
                <div className='lf_title_subtitle'>
                    <h2>Sign in to your account</h2>
                    <p>Please use your company account to sign in.</p>
                </div>
                <Input label="Email" hasError={this.validator.hasError('email')}  inputType="email" placeholder="Email" name="email" onKeyPress={ this.onKeyPreshHander.bind(this)} value={this.state.email} onChange= { e => this.onChangeHandeler(e) } placeHolder = "Please enter your email" />
                <Input label="Password" hasError={this.validator.hasError('password')} inputType="password" placeholder="password"  onKeyPress={ this.onKeyPreshHander.bind(this)} value={this.state.password} onChange= { e => this.onChangeHandeler(e) } name="password" placeHolder="Enter password" />
                <p className="pwr_link"><Link to="/rest-password">Forgot your password?</Link></p>
                { this.state.message ? <p className={this.state.messageType ==='Success' ? 'alert alert-success' : 'alert alert-danger' }>{this.state.message}</p> : ''}
                <button className='btn btn_block btn_blue sb_btn' onClick={ e => this.onLoginClickHandler(e)}>SIGN IN {this.state.isFormSubmited ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''}</button>
                <LoginWithGoogle/>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        language:state.language,
        auth:state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        //date_format
        setDateFormate:(formate) => { dispatch({type:ActionTypes.SET_DATE_FORMATE , payload:formate}) },
        login:(user) => { dispatch({type:ActionTypes.SET_LOGIN , payload:user}) },
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (LoginForm);