import React from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import AgGrid from '../../grid/ag/ag-grid' 
import SimpleLoading from '../../Loading/SimpleLoading'

const BilingRuleTab = (props) => {

  //API CALLS

    const SaveHandler = (rowItem) => {
      let updatedData = []
      let newData=[];
      rowItem.forEach(item => {
        if( !item.billingrule_id || item.billingrule_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
      });
      if(newData.length>=1){ 
          addNewData(newData);
      }
      if(updatedData.length>=1){ 
          updateData(updatedData);
      }
    }

    const updateData = (data) =>{  
      let api = Api
      api.setUserToken() 
      data.forEach(element => {  
        api.axios().put(Settings.apiPurchaseOrderUrl+`/billingrule/${element.billingrule_id}`,{
          "description":element.description ? element.description : null,
          "amount":element.amount ? parseInt(element.amount) : null,
          "needby_date":element.needby_date !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.needby_date) : null,
          "start_date":element.start_date !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.start_date) : null,
          "end_date":element.end_date !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.end_date) : null,
          "remaining_percentage": element.remaining_percentage ? parseFloat(element.remaining_percentage) : null,
          "remaining_amount": element.remaining_amount ? parseFloat(element.remaining_amount) : null,
          "owner": element.owner ? parseFloat(element.owner) : null,
          "quantity": element.quantity ? parseFloat(element.quantity) : null,
          "unit": element.unit ? parseInt(element.unit) : null,
          "percentage": element.percentage ? parseFloat(element.percentage) : null,
          "unit_price": element.unit_price ? parseFloat(element.unit_price) : null 
      }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            props.reloadAPI()
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          props.reloadAPI()
        })
      })
  }

    const addNewData = (data) => {    
      let api = Api
      api.setUserToken() 
      data.forEach(element => {
        api.axios().post(Settings.apiPurchaseOrderUrl+'/billingrule',{
          "line_id": element.line_id ? parseInt(element.line_id) : null,
          "type": element.type ? parseInt(element.type) : null,
          "description":element.description ? element.description : null,
          "amount":element.amount ? parseInt(element.amount) : null,
          "needby_date":element.needby_date !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.needby_date) : null,
          "start_date":element.start_date !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.start_date) : null,
          "end_date":element.end_date !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.end_date) : null,
          "remaining_percentage": element.remaining_percentage ? parseFloat(element.remaining_percentage) : null,
          "remaining_amount": element.remaining_amount ? parseFloat(element.remaining_amount) : null,
          "owner": element.owner ? parseFloat(element.owner) : null,
          "quantity": element.quantity ? parseFloat(element.quantity) : null,
          "unit": element.unit ? parseInt(element.unit) : null,
          "percentage": element.percentage ? parseFloat(element.percentage) : null,
          "unit_price": element.unit_price ? parseFloat(element.unit_price) : null 
      }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            props.reloadAPI()
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        props.reloadAPI()
      })
     }) 
  }
 
    const DeleteHandler = (allRows) => { 
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiPurchaseOrderUrl+`/billingrule/${element.billingrule_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message) 
          props.reloadAPI()
        } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          props.reloadAPI()
        })
    })
    } 

  //End

  const Columns_Headings = [
    {field:'line_number', headerName:Helper.getLabel(props.language,'line_no',"Line No."),minWidth: 150, editable: false },
    {field:'type', headerName:Helper.getLabel(props.language,'type',"Type"), editable: false, options:[],minWidth: 150, inputType: "dropdown" },
    {field:'description', headerName:Helper.getLabel(props.language,'description',"Description"),minWidth: 350, },
    {field:'quantity', headerName:Helper.getLabel(props.language,'quantity',"Quantity"),minWidth: 200, },
    {field:'unit', headerName:Helper.getLabel(props.language,'unit',"Unit"), options: props.units, inputType: "dropdown",minWidth: 200, },
    {field:'unit_price', headerName:Helper.getLabel(props.language,'unit_price',"Unit Price"),minWidth: 200, options:[], inputType: "dropdown"},
    {field:'percentage', headerName:Helper.getLabel(props.language,'percentage',"Percentage"),minWidth: 200, },
    {field:'amount', headerName:Helper.getLabel(props.language,'amount',"Amount"),minWidth: 250,},
    {field:'needby_date', headerName:Helper.getLabel(props.language,'needbydate',"Need By Date"), inputType: "datepicker",minWidth: 250, },
    {field:'start_date', headerName:Helper.getLabel(props.language,'start_date',"Start Date"), inputType: "datepicker",minWidth: 250,},
    {field:'end_date', headerName:Helper.getLabel(props.language,'end_date',"End Date"), inputType: "datepicker",minWidth: 250,},
    {field:'owner', headerName:Helper.getLabel(props.language,'owner',"Owner"), options:[], inputType: "dropdown",minWidth: 200,},
    {field:'status', headerName:Helper.getLabel(props.language,'status',"Status"), options:[], inputType: "dropdown",minWidth: 200, },
  ]

  return ( 
    <div className='container-fluid purchase_orders_lines_list_view_billing_rule_grid'> 
      <AgGrid onSave={SaveHandler} onDelete={DeleteHandler} data={props.state} id="rs_purchase_orders_lines_list_view_billing_rule" header={Columns_Headings} /> 
    </div>
  )
}

 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}



export default connect(mapStateToProps) (BilingRuleTab) 