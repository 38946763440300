import React from 'react'
import InfoWidget from './InfoWidget'
import ProfileWidget from './ProfileWidget'
import RateWidget from './RateWidget'

const LeftSidebar = () => {
  return (
    <section className='left_sidebar'> 
        <div className='row'>
          <div className='col-12 col-md-4 col-lg-12 mb-3'>
            <ProfileWidget />
          </div>

          <div className='col-12 col-md-4 col-lg-12 mb-3'>
            <InfoWidget />
          </div>

          <div className='col-12 col-md-4 col-lg-12 mb-3'>
            <RateWidget />
          </div>
        </div> 
    </section>
  )
}

export default LeftSidebar