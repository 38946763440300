import React, { Component } from "react";
import DottedArrow from "../../../assets/dotted-arrow.svg";

export default class ManageApprovals extends Component {
  render() {
    return (
      <div className="manage-approvals">
        <div className="manage-approvals-capsule">Requisition Submitted</div>
        <img
          className="manage-approvals-arrow"
          src={DottedArrow}
          alt="right arrow"
        />
        <div className="manage-approvals-capsule">Respective Head</div>
        <img
          className="manage-approvals-arrow"
          src={DottedArrow}
          alt="right arrow"
        />
        <div className="manage-approvals-capsule">Procurement Group</div>
        <img
          className="manage-approvals-arrow"
          src={DottedArrow}
          alt="right arrow"
        />
        <div className="manage-approvals-capsule">Finance Group</div>
      </div>
    );
  }
}
