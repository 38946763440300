import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import AgGrid from "../../grid/ag/ag-grid";
import SimpleLoading from "../../Loading/SimpleLoading";

class LineDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPRLineSiteDataLoading: false,
      isSitesLoading: false,
      isDeleting: false,
      lineSitesData: [],
      sitesList: [],
      requesterList: [{ ...this.props.userData }],
    };

    this.saveHandler = this.saveHandler.bind(this);
    this.getPRLineSitesData = this.getPRLineSitesData.bind(this);
  }

  componentDidMount() {
    this.getPRLineSitesData();
    this.getSites();
  }

  getPRLineSitesData() {
    let lineId = this.props.cartItem?.line_id;
    if (lineId === null || lineId === undefined || lineId === 0 || !lineId) {
      this.setState({ isPRLineSiteDataLoading: false });
      return;
    }
    let api = Api;
    let that = this;
    this.setState({ isPRLineSiteDataLoading: true });
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartline/" + lineId)
      .then(function (res) {
        if (res.data?.status === "success") {
          let lineSitesData = [];
          res.data?.data?.sites?.forEach((site) => {
            lineSitesData.push({
              ...site,
              ordered_qty: parseInt(site.ordered_qty),
            });
          });
          that.setState({ lineSitesData });
        }
        that.setState({ isPRLineSiteDataLoading: false });
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
        that.setState({ isPRLineSiteDataLoading: false });
      });
  }

  getSites() {
    let api = Api;
    this.setState({ isSitesLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .post(Settings.loginUrl + "/get_site", { code: null, name: null })
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isSitesLoading: false, sitesList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isSitesLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  saveHandler(rowItem) {
    const { site_id, requester_id, ordered_qty, delivery_date } = rowItem?.data;
    if (!site_id) {
      Helper.alert("Site not selected", "error");
      return;
    }
    if (!requester_id) {
      Helper.alert("Requester not selected", "error");
      return;
    }
    if (!ordered_qty) {
      Helper.alert("Ordered quantity not entered", "error");
      return;
    }
    if (isNaN(ordered_qty)) {
      Helper.alert("Order Quantity Invalid", "error");
      return false;
    }
    if (
      Number.parseFloat(ordered_qty) >
      Number.parseFloat(this.props.cartItem?.ordered_qty)
    ) {
      Helper.alert(
        "Order Quantity cannot be greater from line order quantity!",
        "error"
      );
      return false;
    }
    if (!delivery_date) {
      Helper.alert("Delivery date not set", "error");
      return;
    }
    let updatedData = [];
    let newData = [];
    if (!rowItem.data?.linesite_id) {
      newData.push(rowItem.data);
    } else {
      updatedData.push(rowItem.data);
    }
    if (newData.length >= 1) {
      this.addNewData(newData);
    }
    if (updatedData.length >= 1) {
      this.updateData(updatedData);
    }
  }

  updateData = (data) => {
    let api = Api;
    api.setUserToken();
    let that = this;
    data.forEach((element) => {
      api
        .axios()
        .put(
          Settings.apiPurchaseOrderUrl +
            `/pr_cartlinesite/${element.linesite_id}`,
          {
            linestatus: 0,
            line_id: that.props.cartItem?.line_id
              ? that.props.cartItem?.line_id
              : null,
            ordered_qty: parseInt(element.quantity) ?? null,
            delivery_date:
              Helper.getDateForDb(element.delivery_date) !== "NaN-NaN-NaN"
                ? Helper.getDateForDb(element.delivery_date)
                : null,
            start_date: null,
            end_date: null,
            distributionsource_id: that.props?.cartItem?.distributionsource_id
              ? that.props?.cartItem?.distributionsource_id
              : null,
            requester_id: parseInt(element.requester) ?? null,
            entity_id: null,
            site_id: parseInt(element.site_id) ?? null,
            warehouse_id: null,
          }
        )
        .then(function (res) {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
            that.getPRLineSitesData();
          }
        })
        .catch((res) => {
          Helper.alert(res.response.data.message, "error");
          that.getPRLineSitesData();
        });
    });
  };

  addNewData = (data) => {
    let api = Api;
    api.setUserToken();
    let that = this;
    data.forEach((element) => {
      api
        .axios()
        .post(Settings.apiPurchaseRequisitionUrl + "/pr_cartline_site", {
          linestatus: 0,
          line_id: that.props.cartItem?.line_id
            ? that.props.cartItem?.line_id
            : null,
          ordered_qty: parseInt(element.ordered_qty) ?? null,
          delivery_date:
            Helper.getDateForDb(element.delivery_date) !== "NaN-NaN-NaN"
              ? Helper.getDateForDb(element.delivery_date)
              : null,
          start_date: null,
          end_date: null,
          distributionsource_id: that.props?.cartItem?.distributionsource_id
            ? that.props?.cartItem?.distributionsource_id
            : null,
          requester_id: parseInt(element.requester_id) ?? null,
          entity_id: null,
          site_id: parseInt(element.site_id) ?? null,
          warehouse_id: null,
        })
        .then(function (res) {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
            that.getPRLineSitesData();
          }
        })
        .catch((err) => {
          Helper.alert(err.response.data.message, "error");
          that.getPRLineSitesData();
        });
    });
  };

  DeleteHandler = (allRows) => {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isDeleting: true });
    allRows.forEach((element, index) => {
      api
        .axios()
        .delete(
          Settings.apiPurchaseRequisitionUrl +
            `/pr_cartlinesite/` +
            element.linesite_id
        )
        .then(function (res) {
          if (res.data.status === "success") {
            Helper.alert(res.data.message);
            if (allRows.length - 1 === index) {
              that.setState({ isDeleting: false });
              that.getPRLineSitesData();
            }
          }
        })
        .catch((res) => {
          Helper.alert(res.response.data.message, "error");
          if (allRows.length - 1 === index) {
            that.setState({ isDeleting: false });
            that.getPRLineSitesData();
          }
        });
    });
  };

  render() {
    const Columns_Headings_Distribution = [
      {
        field: "site_id",
        headerName: Helper.getLabel(
          this.props.language,
          "site_name",
          "Site name"
        ),
        options: this.state.sitesList.map((item) => {
          return {
            value: item.site_id,
            label: item.name,
          };
        }),
        inputType: "dropdown",
      },
      {
        field: "requester_id",
        headerName: Helper.getLabel(
          this.props.language,
          "requester",
          "Requester"
        ),
        options: this.state.requesterList.map((item) => {
          return {
            value: item.employee_id,
            label: item.first_name,
          };
        }),
        inputType: "dropdown",
      },
      {
        field: "ordered_qty",
        headerName: Helper.getLabel(
          this.props.language,
          "quantity",
          "Quantity"
        ),
      },
      {
        field: "delivery_date",
        headerName: Helper.getLabel(
          this.props.language,
          "delivery_date",
          "Delivery Date"
        ),
        name: "delivery_date_pr_line",
        inputType: "datepicker",
      },
    ];

    return (
      <div className="container-fluid purchase_orders_lines_list_view_distribution_general_grid">
        {this.state.isLoading ||
        this.state.isPRLineSiteDataLoading ||
        this.state.isSitesLoading ||
        this.state.isDeleting ? (
          <SimpleLoading />
        ) : (
          <AgGrid
            singleRowSelect={true}
            onRowEditingStopped={this.saveHandler}
            onDelete={this.DeleteHandler}
            data={this.state.lineSitesData}
            id="rs_purchase_orders_lines_list_view_distribution_general_grid"
            header={Columns_Headings_Distribution}
            hideFooter={true}
            viewOnly={this.props.viewOnly ? true : false}
            addBtnHide={this.props.viewOnly ? true : false}
            hideDelete={this.props.viewOnly ? true : false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(LineDistribution);
