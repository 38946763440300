import React, {useState, useEffect, Fragment} from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Button from '../../inc/Button';
import AgGrid from '../../grid/ag/ag-grid';
import $ from 'jquery';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Settings from '../../../inc/Settings';
import MasterComonent from '../../Backend/MasterComonent';
import SimpleLoading from '../../Loading/SimpleLoading';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../inc/Alert';
import Helper from '../../../inc/Helper';
import { useNavigate } from 'react-router-dom';
import ButtonNew from '../../inc/ButtonNew';
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';

const TeamHeader = (props) => {
    
  const [teamHeader, setTeamHeader] = useState([])  
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  let teamHeaderCalled = false 

  const getTeamHeaderData = () => {
     if(teamHeaderCalled){
      return;
     }
     let api = Api
     teamHeaderCalled = true
     api.setUserToken()
     api.axios().get(Settings.apiUrl+'/teamheader',{}).then(function(res){
      if(res.data.status==='success'){   
        setTeamHeader(res.data.data.reverse())
        setLoading(false)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setLoading(false)
    })
  }
 
  useEffect(() => {
    getTeamHeaderData() 
  }, [])

  const CellHandler = (event) => {
    if(event.node.data.teamheader_id !== ''){ 
    if(event.colDef.field === 'name') { 
      navigate(`/team-header-edit/${event.data.teamheader_id}`) 
    }
    }else {
      Helper.alert('Team Header Doesnt Exists!', 'error')
    }
  }

  const gridLinkValueFormat = (params) => { 
      return `<div class="rs_grid_simple_link">${params.value}</div>`;
  }

  const DeleteParamters = (allRows) => {
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiUrl+`/teamheader/${element.teamheader_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message)
            getTeamHeaderData()
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          getTeamHeaderData()
        })
      })
  }

  const Columns_Headings = [
    {field:'name', headerName: Helper.getLabel(props.language,'name','Name') , editable: false,  cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat }, 
  ]

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header)){
  return <Fragment>
  <Alert message='NO ACCESS' type='danger' />
  </Fragment>
  }


  return ( 
    <div className='team_header_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'team_header','Team Header')}>
            <ButtonNew isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_main_create_button)} to="/team-header-create" title={Helper.getLabel(props.language,'new','New')} />
        </NavigationHeder> 
          {loading ? <SimpleLoading /> : null}
          <div className='rs_team_header_grid'>
            <AgGrid id="rs_team_header_list" hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_main_delete_button)} onCellClicked={CellHandler} onDelete={DeleteParamters} header={Columns_Headings} data={teamHeader} addBtnHide={true} hideSave={true} viewOnly={true}/>
          </div>
        </div>
    </div> 
  )
}
 

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header
  }
  
  export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(TeamHeader, SecurityOptions))))