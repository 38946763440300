import React, { Fragment, useState, useEffect, } from 'react' 
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import MasterComonent from '../../../Backend/MasterComonent'
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'
import AgGrid from '../../../grid/ag/ag-grid'
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../../inc/Alert'
import ButtonNew from '../../../inc/ButtonNew'
import LinkBtn from '../../../inc/LinkBtn'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'

const ReasonArchive = (props) => {

  const [vendorsReason, setVendorsReason] = useState([]) 
  let vendorReason = false
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const Status = [
    {
      value: true,
      label: 'Active'
    },
    {
      value: false,
      label: 'In-Active'
    },
  ]

  useEffect(() => {
    get_vendors_reasons() 
  }, [])

  const CellHandler = (event) => {   
      if(event.colDef.field === 'description') { 
        navigate(`/edit-vendor-reason/${event.data.reason_id}`)
      }
  }

  const get_vendors_reasons = () => {
    if(vendorReason) {
      return;
    }
    let api = Api
    vendorReason = true
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+'/vendorreason',{}).then(function(res){
      if(res.data.status==="success"){  
        setVendorsReason(res.data.data.reverse())
        setLoading(false)
      }
    }).catch((res) => {
      Helper.alert(res.response.data.message, 'error') 
      setLoading(false)
    })
  } 

  const DeleteHandler = (allRows) => {
    let api = Api
    api.setUserToken() 
    allRows.forEach(element => {
      api.axios().delete(Settings.apiVendorUrl+`/vendorreason/${element.reason_id}`,{}).then(function(res){
          if(res.data.status==="success"){
            get_vendors_reasons()
            Helper.alert(res.data.message)
          }
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        get_vendors_reasons()
      })
  })
  }

  const gridLinkValueFormat = (params) => {
    return `<div class="rs_grid_simple_link">${params.value}</div>`;
  }

  const statusValueFormat = (params) => {
    if(params.value === true){
        return `<span class="rs_active_label">Active</span>`;
    }
    return `<span class="rs_inactive_label">Inactive</span>`;
  }

  const Columns_Headings = [
      {field:'description', headerName:Helper.getLabel(props.language,'description',"Description"), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat},
      {field:'reason_for_vendor', headerName:Helper.getLabel(props.language,'status',"Status"), inputType:"select", options:[true,false], valueFormatter: statusValueFormat ,  cellRenderer: CustomHtmlCell, inputType: "dropdown", options: Status, editable: false}, 
  ]

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_reason.vendors_reason)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
    <div className='vendors_reason_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/master-data' title={Helper.getLabel(props.language,'vendors_reason',"Vendors Reason")}>
        <ButtonNew to="/create-vendors-reason" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.vendor_reason.vendors_reason_main_create_button)} /> 
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : null}
        <AgGrid id="rs_vendors_reason_list" hideDelete={!security.canDelete(!security.canCreate(frontendIds.masterData.vendor_reason.vendors_reason_main_delete_button))} onCellClicked={CellHandler} onDelete={DeleteHandler} header={Columns_Headings} data={vendorsReason} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div> 
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_reason.vendors_reason
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ReasonArchive, SecurityOptions))))