import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import Dropdown from "../../Forms/Dropdown";
import Input from "../../Forms/Input";
import InputDatePicker from "../../Forms/InputDatePicker";
import Button from "../../inc/Button";
import Collapse from "../../inc/Collapse";
import NavigationHeder from "../../Navigations/NavigationHeder";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import MasterSidebar from "../../Sidebars/MasterSidebar";
import NoteAttachmentHistory from "../../Widgets/NoteAttachmentHistory";
import Tabs from "../../Widgets/Tabs/Tabs";
import OrderSummary from "../common/OrderSummary";
import ManageApprovals from "./ManageApprovals";
import MasterComonent from "../../Backend/MasterComonent";
import NavCartWishlist from "../common/NavCartWishlist";
import RsWithRouter from "../../inc/RsWithRouter";
import ActionTypes from "../../../inc/ActionsTypes";
import OverlayLoader from "../common/OverlayLoader";
import SimpleLoading from "../../Loading/SimpleLoading";
import axios from "axios";

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      cartItems: [],
      selectedLineIds: [],
      requisition_header_id: null,
      discount: 0,
      tax: 0,

      documentTitle: null,
      delivery: null,
      contractType: null,
      attentionInformation: null,
      documentDate: null,
      accountingDate: null,
      deliveryDate: null,
      requesterList: [{ ...this.props.userData }],
      employeeList: [],
      requester: null,
      submitterList: [{ ...this.props.userData }],
      submitter: null,
      onBehalfRequest: false,
      email: null,
      externalReference: null,
      currencyList: [],
      currency: null,
      entitylist: [],
      entity: null,
      siteList: [],
      site: null,
      warehouseList: [],
      warehouse: null,
      convertTo: null,
      createdBy: null,
      modifiedBy: null,
      createdDateTime: null,
      modifiedDateTime: null,
    };

    this.handlePRSubmit = this.handlePRSubmit.bind(this);
  }
  componentDidMount() {
    this.getCartItems();
    this.getAllEmployees();
    this.getSites();
    this.getCurrencies();
    this.getEntities();
    this.getWarehouses();
    this.getCartCount();
    this.getWishlistCount();
  }

  getCartItems() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartlines")
      .then(function (res) {
        if (that.props?.checkoutLineIds?.length === 0) {
          const checkoutIds = [];
          res.data?.data.forEach((item) => {
            checkoutIds.push(item.line_id);
          });
          that.props.updateCheckoutIds(checkoutIds);
        }
        that.setState({
          isLoading: false,
          cartItems: [...res.data.data],
        });
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getAllEmployees() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + "/employee")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, employeeList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getSites() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .post(Settings.loginUrl + "/get_site", { code: null, name: null })
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, siteList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getCurrencies() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/currency")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, currencyList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getEntities() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .post(Settings.loginUrl + "/view_entity", { user_id: null })
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, entitylist: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getWarehouses() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .post(Settings.loginUrl + "/get_warehouse", { code: null, name: null })
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, warehouseList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getCartCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartlines")
      .then(function (res) {
        that.props.changeCartCount(res.data.data?.length);
      });
  }

  getWishlistCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/wishlist")
      .then(function (res) {
        that.props.changeWishlistCount(res.data.data?.length);
        that.props.updateWishlistIds([...res.data.data]);
      });
  }

  getSubtotal() {
    let subtotal = 0;
    if (this.props.checkoutLineIds?.length > 0) {
      this.state.cartItems.forEach((item) => {
        if (this.props.checkoutLineIds.includes(item.line_id)) {
          if (item.unit_price && item.ordered_qty) {
            subtotal += item.unit_price * item.ordered_qty;
          }
        }
      });
    }
    return subtotal;
  }

  addComponentRight() {
    return (
      <MasterSidebar sidebarStckyContainerId="abc_tabs_sections">
        <OrderSummary
          title={Helper.getLabel(
            this.props.language,
            "order_summary",
            "Order summary"
          )}
          itemCount={this.props.checkoutLineIds.length}
          subtotal={this.getSubtotal()}
          discount={this.state.discount}
          tax={this.state.tax}
          hideCheckoutButton={true}
        />
        <NoteAttachmentHistory
          hideHistory={true}
          source_id={9}
          notesource_integrator={59}
          attachmentsource_integrator={59}
        />
      </MasterSidebar>
    );
  }

  async handlePRSubmit() {
    console.log("handlePRSubmit");
    const {
      documentTitle,
      delivery,
      attentionInformation,
      documentDate,
      accountingDate,
      deliveryDate,
      email,
      entity,
      site,
      warehouse,
    } = this.state;
    if (documentTitle === "" || documentTitle === null) {
      Helper.alert("Provide document title", "error");
      return;
    }
    if (delivery === "" || delivery === null) {
      Helper.alert("Provide delivery name", "error");
      return;
    }
    if (attentionInformation === "" || attentionInformation === null) {
      Helper.alert("Provide attention information", "error");
      return;
    }
    if (deliveryDate === null) {
      Helper.alert("Provide delivery date", "error");
      return;
    }
    if (documentDate === null) {
      Helper.alert("Provide document date", "error");
      return;
    }
    if (accountingDate === null) {
      Helper.alert("Provide accounting date", "error");
      return;
    }
    if (entity === null) {
      Helper.alert("Provide select entity", "error");
      return;
    }
    if (site === null) {
      Helper.alert("Provide select site", "error");
      return;
    }
    if (warehouse === null) {
      Helper.alert("Provide select warehouse", "error");
      return;
    }
    if (email === "" || email === null) {
      Helper.alert("Provide email address", "error");
      return;
    } else {
      let validEmailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!email.match(validEmailRegex)) {
        Helper.alert("Provide valid email address", "error");
        return;
      }
    }

    console.log("yes");
    let api = Api;
    api.setUserToken();
    let prHeaderData = {
      description: this.state.documentTitle,
      requisition_status: 0,
      document_status: 0,
      accounting_date: accountingDate,
      document_date: documentDate,
      delivery_date: deliveryDate,
      deliveryaddress_id: null,
      delivery_name: delivery,
      attention_information: attentionInformation,
      priceinclude_tax: null,
      requester_id: this.state.requester ?? this.props.userData?.employee_id,
      currency_id: parseInt(this.state.currency),
      email_address: this.state.email,
      entity_id: parseInt(this.state.entity),
      site_id: parseInt(this.state.site),
      warehouse_id: parseInt(this.state.warehouse),
      distributionsource_id: null,
      externalreference: "",
      attachmentsource_id: null,
      notesource_id: null,
      contactsource_id: null,
      convertPr_to: null,
      agreement_id: null,
      onbehalf_request: this.state.onBehalfRequest,
    };
    this.setState({ isLoading: true });
    try {
      //  create pr header
      const createHeaderResponse = await api
        .axios()
        .post(Settings.apiPurchaseRequisitionUrl + "/pr_header", prHeaderData);

      console.log(createHeaderResponse);
      if (createHeaderResponse) {
        let purchaseRequisitionId =
          createHeaderResponse.data?.requisition_header_id;
        console.log(purchaseRequisitionId);

        // create pr line for each line selected
        console.log(this.props.checkoutLineIds);
        this.props.checkoutLineIds?.forEach(async (line_id) => {
          // const cartLineInAction = this.state.cartItems?.filter(
          //   (item) => item.line_id === line_id
          // )?.[0];
          // console.log(cartLineInAction);
          console.log(line_id);

          const prCartLineResponse = await api
            .axios()
            .get(
              Settings.apiPurchaseRequisitionUrl + "/pr_cartline/" + line_id
            );

          if (prCartLineResponse) {
            let prLineData = {
              ...prCartLineResponse?.data?.data,
              purchaserequisition_id: purchaseRequisitionId,
              requisitionstatus: 0,
              document_status: 0,
            };
            console.log(prLineData);
            const addPRLineResponse = await api
              .axios()
              .post(
                Settings.apiPurchaseRequisitionUrl + "/pr_line",
                prLineData
              );
            if (addPRLineResponse) {
              if (addPRLineResponse.data?.status === "success") {
                Helper.alert(addPRLineResponse.data.message, "success");
                this.props.checkoutLineIds?.forEach(async (lineId, index) => {
                  const deletedRes = await api
                    .axios()
                    .delete(
                      Settings.apiPurchaseRequisitionUrl +
                        "/pr_cartline/" +
                        lineId
                    );
                  if (
                    deletedRes &&
                    index === this.props.checkoutLineIds?.length - 1
                  ) {
                    this.props.rs_router.navigate("/purchase-requisition/shop");
                  }
                });
              }
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
      Helper.alert(
        error?.response?.data?.message ?? "Something went wrong",
        "error"
      );
    }
  }

  // deleteallcartitems
  deleteAllCartItems() {
    let api = Api;
    let that = this;
    api.setUserToken();

    this.state.selectedCartItems.forEach((selectedLineId) => {
      api
        .axios()
        .delete(
          Settings.apiPurchaseRequisitionUrl + "/pr_cartline/" + selectedLineId
        )
        .then(function (res) {
          if (res.status === 202) {
            that.setState((prevState) => {
              const newCartItems = prevState.cartItems.filter(
                (cartItem) => cartItem.line_id !== selectedLineId
              );
              return { cartItems: newCartItems };
            });
            that.decrementCartCount();
            Helper.alert("Cart item removed", "success");
          }
        })
        .catch(function (err) {
          Helper.alert("Failed to remove cart item", "error");
        });
    });
  }

  render() {
    let navs = [
      {
        title: Helper.getLabel(
          this.props.language,
          "manage_approvals",
          "Manage Approvals"
        ),
        isActive: true,
      },
      {
        title: Helper.getLabel(
          this.props.language,
          "general_information",
          "General Information"
        ),
      },
      { title: Helper.getLabel(this.props.language, "segments", "Segments") },
    ];

    let generalNavs = [
      { title: "Basic", isActive: true },
      { title: "Additional Information" },
    ];

    return (
      <MasterComonent>
        <div className="pr-wrapper">
          <NavigationHeder
            title="Shop > Shopping Cart > Requisition details"
            backUrl="/purchase-requisition/cart"
          >
            {this.props.hideMoreBtn ? (
              <NavCartWishlist />
            ) : (
              <>
                <Button
                  isActive={false}
                  title="Back"
                  onClick={() =>
                    this.props.rs_router.navigate("/purchase-requisition/cart")
                  }
                  isDisable={this.state.isLoading}
                />
                <Button
                  isActive={true}
                  title="Save"
                  onClick={() => this.handlePRSubmit()}
                  isDisable={
                    this.state.isLoading ||
                    this.props.checkoutLineIds?.length === 0
                  }
                />
              </>
            )}
          </NavigationHeder>
          <div className="container-fluid">
            {this.state.isLoading ? (
              <SimpleLoading />
            ) : (
              <div id="summary_tabs_sections">
                <Tabs
                  navs={navs}
                  showAll={true}
                  scrollOffset="300"
                  addComponentRight={this.addComponentRight.bind(this)}
                >
                  <Collapse title="Manage Approvals" open={true}>
                    <ManageApprovals />
                  </Collapse>
                  <Collapse title="General Information" open={true}>
                    <div className="generalInformation">
                      <Tabs navs={generalNavs} showAll={false} scrollOffset="0">
                        <div className="generalInformation-basic">
                          <div className="generalInformation-basic-grid">
                            <Input
                              label="Document title"
                              value={this.state.documentTitle}
                              onChange={(e) =>
                                this.setState({ documentTitle: e.target.value })
                              }
                            />
                            <Input
                              label="Delivery"
                              value={this.state.delivery}
                              onChange={(e) =>
                                this.setState({ delivery: e.target.value })
                              }
                            />
                            <Input
                              label="Contract type"
                              value={this.state.contractType}
                              onChange={(e) =>
                                this.setState({ contractType: e.target.value })
                              }
                            />
                            <Input
                              label="Attention information"
                              value={this.state.attentionInformation}
                              onChange={(e) =>
                                this.setState({
                                  attentionInformation: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="generalInformation-title">Dates</div>
                          <div className="generalInformation-basic-grid">
                            <InputDatePicker
                              label="Document Date"
                              inputClassName="document_date"
                              name="document_date"
                              onChange={() => {
                                this.setState({
                                  documentDate: $(".document_date").val(),
                                });
                              }}
                              value={this.state.documentDate}
                            />
                            <InputDatePicker
                              label="Accounting Date"
                              inputClassName="accounting_date"
                              name="accounting_date"
                              onChange={() => {
                                this.setState({
                                  accountingDate: $(".accounting_date").val(),
                                });
                              }}
                              value={this.state.accountingDate}
                            />
                            <InputDatePicker
                              label="Delivery Date"
                              inputClassName="delivery_date"
                              name="delivery_date"
                              onChange={() => {
                                this.setState({
                                  deliveryDate: $(".delivery_date").val(),
                                });
                              }}
                              value={this.state.deliveryDate}
                            />
                          </div>
                        </div>
                        <div className="generalInformation-advanced">
                          <div className="generalInformation-basic-grid">
                            <Dropdown
                              label="Requester"
                              options={
                                this.state.onBehalfRequest
                                  ? this.state.employeeList.map((item) => {
                                      return {
                                        value: item.employee_id,
                                        label: item.displayname,
                                      };
                                    })
                                  : this.state.requesterList.map((item) => {
                                      return {
                                        value: item.employee_id,
                                        label: item.first_name,
                                      };
                                    })
                              }
                              placeHolder="Select requester"
                              id="requester_id"
                              onChange={(e) => {
                                this.setState({ requester: e.target.value });
                              }}
                            />
                            <span className="generalInformation-basic-grid-toggle">
                              On behalf request{" "}
                              <label className="toggle-switch">
                                <input
                                  type="checkbox"
                                  class="toggle-switch-checkbox"
                                  value={this.state.onBehalfRequest}
                                  onChange={() => {
                                    this.setState({
                                      onBehalfRequest:
                                        !this.state.onBehalfRequest,
                                      requester_id: null,
                                    });
                                  }}
                                />
                                <span className="toggle-switch-slider"></span>
                              </label>
                            </span>
                            <Dropdown
                              label="Submitter"
                              options={this.state.submitterList.map((item) => {
                                return {
                                  value: item.employee_id,
                                  label: item.first_name,
                                };
                              })}
                              placeHolder="Select submitter"
                              id="submitter_id"
                              onChange={(e) => {
                                this.setState({ submitter: e.target.value });
                              }}
                            />
                            <Input
                              label="Email"
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                })
                              }
                            />
                            <Input
                              label="Attention information"
                              value={this.state.attentionInformation}
                              onChange={(e) =>
                                this.setState({
                                  attentionInformation: e.target.value,
                                })
                              }
                            />
                            <Dropdown
                              label="Currency"
                              options={this.state.currencyList.map((item) => {
                                return {
                                  value: item.currency_id,
                                  label: item.currency_name,
                                };
                              })}
                              placeHolder="Select currency"
                              id="currency_id"
                              onChange={(e) => {
                                this.setState({ currency: e.target.value });
                              }}
                              value={this.state.currency}
                            />
                            <Dropdown
                              label="Entity"
                              options={this.state.entitylist.map((item) => {
                                return {
                                  value: item.entity_id,
                                  label: item.name,
                                };
                              })}
                              placeHolder="Select entity"
                              id="entity_id"
                              onChange={(e) => {
                                this.setState({ entity: e.target.value });
                              }}
                              value={this.state.entity}
                            />
                            <Dropdown
                              label="Site"
                              options={this.state.siteList.map((item) => {
                                return {
                                  value: item.site_id,
                                  label: item.name,
                                };
                              })}
                              placeHolder="Select site"
                              id="site_id"
                              onChange={(e) => {
                                this.setState({ site: e.target.value });
                              }}
                              value={this.state.site}
                            />
                            <Dropdown
                              label="Warehouse"
                              options={this.state.warehouseList.map((item) => {
                                return {
                                  value: item.warehouse_id,
                                  label: item.name,
                                };
                              })}
                              placeHolder="Select warehouse"
                              id="warehouse_id"
                              onChange={(e) => {
                                this.setState({ warehouse: e.target.value });
                              }}
                              value={this.state.warehouse}
                            />
                            <Input
                              label="Convert To"
                              value={this.state.convertTo}
                              onChange={(e) =>
                                this.setState({
                                  convertTo: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="generalInformation-basic-grid">
                            <Dropdown
                              label="Created by"
                              options={this.state.employeeList.map((item) => {
                                return {
                                  value: item.employee_id,
                                  label: item.displayname,
                                };
                              })}
                              placeHolder="Select"
                              id="created_by_id"
                              onChange={(e) => {
                                this.setState({ createdBy: e.target.value });
                              }}
                              value={this.state.createdBy}
                            />
                            <InputDatePicker
                              label="Created date & time"
                              inputClassName="created_date_time"
                              name="created_date_time"
                              timepicker={true}
                              onChange={() => {
                                this.setState({
                                  createdDateTime:
                                    $(".created_date_time").val(),
                                });
                              }}
                              value={this.state.createdDateTime}
                            />
                            <Dropdown
                              label="Modified by"
                              options={this.state.employeeList.map((item) => {
                                return {
                                  value: item.employee_id,
                                  label: item.displayname,
                                };
                              })}
                              placeHolder="Select"
                              id="modified_by_id"
                              onChange={(e) => {
                                this.setState({ modifiedBy: e.target.value });
                              }}
                              value={this.state.modifiedBy}
                            />
                            <InputDatePicker
                              label="Modified date & time"
                              inputClassName="modified_date_time"
                              name="modified_date_time"
                              timepicker={true}
                              onChange={() => {
                                this.setState({
                                  modifiedDateTime: $(
                                    ".modified_date_time"
                                  ).val(),
                                });
                              }}
                              value={this.state.modifiedDateTime}
                            />
                          </div>
                        </div>
                      </Tabs>
                    </div>
                  </Collapse>
                  <Collapse title="Segments" className="mb-5" open={true}>
                    <SegmentsDistribution
                      source_id={9}
                      totalAmount={null}
                      integrator={null}
                      distribution_source_id={null}
                    />
                  </Collapse>
                </Tabs>
              </div>
            )}
          </div>
        </div>
      </MasterComonent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    userData: state.auth.user,
    checkoutLineIds: state.prShop.checkoutLineIds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_CART_COUNT, payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_WISHLIST_COUNT, payload }),
    updateWishlistIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_WISHLIST_IDS, payload }),
    updateCheckoutIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_CHECKOUT_LINE_IDS, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsWithRouter(Checkout));
