class ThemeSettings{
    constructor(themeOptions){
        this.themeOptions = themeOptions;
        this.theme = this.themeOptions.themeName;
        if(this.theme==='theme_1'){
            this.settings = this.getDarkThemeSettings();
        }else{
            this.settings =  this.themeOptions.settings;
        }
    }
    getDarkThemeSettings(){
        return {
            ...this.theme.settings,
            primary_color: "#1D2327",
            secondary_color:'#FC5E24',
            header_color:'#fff',
            header_bg_color: "#1D2327",
            sidebar_color: "#fff",
            sidebar_bg_color: "#1D2327",
        }
    }
    getSidebarStyle(){
        return `.sidebar_section .main_logo,.app_master_area .sidebar_section{ background: ${this.settings.sidebar_bg_color}} .sidebar_menu > li > a{color:${this.settings.sidebar_color}}`
    }
    getMainHeaderStyle(){
        return `.main_header{ background: ${this.settings.header_bg_color}}  
        .main_header .user_widget_menu .u_name{color:${this.settings.header_color}}
        `
    }
    getUtilityStyle(){
        
        return `
            .rs_btn.btn_active{ background-color:${this.settings.secondary_color}; border-color:${this.settings.secondary_color} } .rs_master_sidebar .master_sidebar_controller,.menu_style_2 li.active{background-color:${this.settings.secondary_color}}
            .request-row-badge,
            .app_navigation .app_nav_title,
            .lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item.active p{color:${this.settings.secondary_color}}
            .lynkaz_tab .lynkaz_tab_nav .lynkaz_tab_nav_item.active{border-color:${this.settings.secondary_color}}
            `
    }
    getCss(){
        if(!this.theme || this.theme ==='default'){
            return "";
        }
        return`${this.getSidebarStyle()} ${ this.getMainHeaderStyle() } ${this.getUtilityStyle()}`
    }
}
export default ThemeSettings;