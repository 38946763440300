import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComonent from '../../Backend/MasterComonent'
import AgGrid from '../../grid/ag/ag-grid'
import $ from 'jquery'
import Alert from '../../inc/Alert'
import Button from '../../inc/Button'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'

const ModeDeliveryArchive = (props) => {
  
  const [modesDelivery, setModesDelivery] = useState([])  
  const [loading, setLoading] = useState(true)

  let modesdeliveryCalled = false
  
  useEffect(() => {
    get_modes_delivery()
  }, [])

  const get_modes_delivery = () => {
    if(modesdeliveryCalled) {
      return;
    }
      let api = Api
      modesdeliveryCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiPurchaseOrderUrl+'/mode_of_delivery',{}).then(function(res){
        if(res.data.status==='success'){   
          setModesDelivery(res.data.data.reverse())
          setLoading(false)
        } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        setLoading(false)
      })
  }
     
  const DeleteDelivery = (allRows) => {
    let api = Api
    api.setUserToken() 
    allRows.forEach(element => {
      api.axios().delete(Settings.apiPurchaseOrderUrl+`/mode_of_delivery/${element.mode_of_delivery_id}`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_modes_delivery()
        } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        get_modes_delivery()
      })
    })
  }
 
  const SaveHandler = (rowItem) => {
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.mode_of_delivery_id || item.mode_of_delivery_id <=0 ){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){ 
        addNewData(newData);
    }
    if(updatedData.length>=1){ 
        updateData(updatedData);
    }
  }

  const addNewData = (data) => { 
    let api = Api
    api.setUserToken()  
    data.forEach(element => { 
      api.axios().post(Settings.apiPurchaseOrderUrl+'/mode_of_delivery',{
        "name": element.name
      }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_modes_delivery()
        } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      get_modes_delivery()
    })
   }) 
  }

  const updateData = (data) => {
    let api = Api
    api.setUserToken()
    data.forEach(element => {  
      api.axios().put(Settings.apiPurchaseOrderUrl+`/mode_of_delivery/${element.mode_of_delivery_id}`,{
        "name": element.name
    }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          get_modes_delivery()
        } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        get_modes_delivery()
      })
    })
  } 

  const Columns_Headings = [
    {field:'name', headerName: Helper.getLabel(props.language,'name','Name')}, 
  ]

  const FormDataHandler = () => {
    $('.rs_delivery_mode .rs_grid_btn_save').trigger('click'); 
  }
  
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.mode_delivery.mode_delivery)){
    return <Fragment> 
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return ( 
      <div className='delivery_mode_archive'>
        <div className='container-fluid'>
            <NavigationHeder backUrl='/procure' title={Helper.getLabel(props.language,'delivery_mode','Modes Of Delivery')}>
              <Button onClick={FormDataHandler} isActive={true} icon='/images/icons/save-white.svg' isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.mode_delivery.mode_delivery_save_button)} title={Helper.getLabel(props.language,'save','Save')} />
            </NavigationHeder>
              {loading ? <SimpleLoading /> : null}
              <div className='rs_delivery_mode'>
                <AgGrid onSave={SaveHandler} id="rs_delivery_mode_list" hideDelete={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.mode_delivery.mode_delivery_delete_button)} onDelete={DeleteDelivery} header={Columns_Headings} data={modesDelivery} addBtnHide={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.mode_delivery.mode_delivery_create_button)}/>
              </div> 
          </div>
      </div> 
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders.control_forms.mode_delivery.mode_delivery
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ModeDeliveryArchive, SecurityOptions))))