import { Link } from "react-router-dom";
import Logo from '../../assets/lynkaz.svg';
const AuthHeader = () => {
    return(
        <div className="login_header">
            <div className="container">
                <Link to="/" className="login_logo"><img src={Logo} /></Link>
            </div>
        </div>
    )
}
export default AuthHeader;