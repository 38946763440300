import React from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import AgGrid from '../../grid/ag/ag-grid';

const GeneralDistributionTab = (props) => {

  //Grid Functions
  const getAdditionalInfoDataFromRowClick = (event) => { 
    props.getGridAPIObj(event.api)
    props.updateAdditionalInfo(event.data)
  }
    
  const gridQuantityValueSetter = (params) => { 
    if( isNaN(params.newValue) ){
      Helper.alert('Order Quantity Invalid', 'error')
      // params.data.ordered_qty = params.newValue 
      return false
    } 
    if( Number.parseFloat(params.newValue) > Number.parseFloat(props.LinesGridObject.ordered_qty) ){
      Helper.alert('Order Quantity cannot be greater from line order quantity!', 'error')
      // params.data.ordered_qty = params.newValue 
      return false
    }
    params.data.ordered_qty = params.newValue
      return true
  }

  //End
 
    const SaveHandler = (rowItem) => {
      let updatedData = []
      let newData=[];
      rowItem.forEach(item => {
        if( !item.line_id || item.line_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
      });
      if(newData.length>=1){ 
          addNewData(newData);
      }
      if(updatedData.length>=1){ 
          updateData(updatedData);
      }
    }

    const updateData = (data) =>{  
      let api = Api
      api.setUserToken() 
      data.forEach(element => {  
        api.axios().put(Settings.apiPurchaseOrderUrl+`/PO_linesite/${element.line_id}`,{
          "canceled_qty": element.canceled_qty ? element.canceled_qty : null,
          "delivery_date": Helper.getDateForDb(element.delivery_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.delivery_date) : null,
          "distributionsource_id": element.distributionsource_id ? element.distributionsource_id : null,
          "end_date": Helper.getDateForDb(element.end_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.end_date) : null,
          "entity_id": element.entity_id ? parseInt(element.entity_id) : null,
          "external_reference": element.external_reference ? element.external_reference : null,
          "line_id": element.line_id ? parseInt(element.line_id) : null,
          "line_status": element.line_status ? parseInt(element.line_status) : null,
          "ordered_qty": element.ordered_qty ? parseInt(element.ordered_qty) : null,
          "over_delivery_tolearance": element.over_delivery_tolearance ? element.over_delivery_tolearance : null,
          "overreceipt_action": element.overreceipt_action ? element.overreceipt_action : null,
          "received_qty": element.received_qty ? element.received_qty : null,
          "requester_id": element.requester_id ? parseInt(element.requester_id) : null,
          "requisition_id": element.requisition_id ? parseInt(element.requisition_id) : null,
          "returned_qty": element.returned_qty ? element.returned_qty : null,
          "revision": element.revision ? parseInt(element.revision) : null,
          "site_id": element.site_id ? parseInt(element.site_id) : null,
          "start_date": Helper.getDateForDb(element.start_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.start_date) : null,
          "under_delivery_tolerance": element.under_delivery_tolerance ? element.under_delivery_tolerance : null,
          "version": element.version ? parseInt(element.version) : null,
          "warehouse_id": element.warehouse_id ? parseInt(element.warehouse_id) : null, 
      }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            props.reloadAPI()
          } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          props.reloadAPI()
        })
      })
  }

    const addNewData = (data) => {    
      let api = Api
      api.setUserToken() 
      data.forEach(element => {
        api.axios().post(Settings.apiPurchaseOrderUrl+'/PO_linesite',{
          "canceled_qty": element.canceled_qty ? element.canceled_qty : null,
          "delivery_date": Helper.getDateForDb(element.delivery_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.delivery_date) : null,
          "distributionsource_id": element.distributionsource_id ? element.distributionsource_id : null,
          "end_date": Helper.getDateForDb(element.end_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.end_date) : null,
          "entity_id": element.entity_id ? parseInt(element.entity_id) : null,
          "external_reference": element.external_reference ? element.external_reference : null,
          "line_id": element.line_id ? parseInt(element.line_id) : null,
          "line_status": element.line_status ? parseInt(element.line_status) : null,
          "ordered_qty": element.ordered_qty ? parseInt(element.ordered_qty) : null,
          "over_delivery_tolearance": element.over_delivery_tolearance ? element.over_delivery_tolearance : null,
          "overreceipt_action": element.overreceipt_action ? element.overreceipt_action : null,
          "received_qty": element.received_qty ? element.received_qty : null,
          "requester_id": element.requester_id ? parseInt(element.requester_id) : null,
          "requisition_id": element.requisition_id ? parseInt(element.requisition_id) : null,
          "returned_qty": element.returned_qty ? element.returned_qty : null,
          "revision": element.revision ? parseInt(element.revision) : null,
          "site_id": element.site_id ? parseInt(element.site_id) : null,
          "start_date": Helper.getDateForDb(element.start_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.start_date) : null,
          "under_delivery_tolerance": element.under_delivery_tolerance ? element.under_delivery_tolerance : null,
          "version": element.version ? parseInt(element.version) : null,
          "warehouse_id": element.warehouse_id ? parseInt(element.warehouse_id) : null, 
      }).then(function(res){
          if(res.data.status==="success"){
            Helper.alert(res.data.message) 
            props.reloadAPI()
          } 
      }).catch((res) => { 
        Helper.alert(res.response.data.message, 'error')
        props.reloadAPI()
      })
     }) 
  }
 
    const DeleteHandler = (allRows) => { 
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiPurchaseOrderUrl+`/`,{}).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message) 
          props.reloadAPI()
        } 
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          props.reloadAPI()
        })
    })
    } 

    const Columns_Headings_Distribution = [
        {field:'entity_id', headerName:Helper.getLabel(props.language,'entity',"Entity"), options: props.entities, inputType: "dropdown"},
        {field:'site_id', headerName:Helper.getLabel(props.language,'site',"Site"), options: props.sites, inputType: "dropdown"},
        {field:'ordered_qty', valueSetter: gridQuantityValueSetter, headerName:Helper.getLabel(props.language,'order_quantity',"Order Quantity")},
        {field:'delivery_date', headerName:Helper.getLabel(props.language,'delivery_date',"Delivery Date"), name: "delivery_date_po_lines", inputType: "datepicker"},
        {field:'start_date', headerName:Helper.getLabel(props.language,'start_date',"Start Date"), inputType: "datepicker"},
        {field:'end_date', headerName:Helper.getLabel(props.language,'end_date',"End Date"), inputType: "datepicker"},
    ]
      
  return (
    <div className='container-fluid purchase_orders_lines_list_view_distribution_general_grid'>
      <AgGrid singleRowSelect={true} onSave={SaveHandler} onRowClick={getAdditionalInfoDataFromRowClick} data={props.state} id="rs_purchase_orders_lines_list_view_distribution_general_grid" header={Columns_Headings_Distribution} />
    </div>
    )
}

 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}



export default connect(mapStateToProps) (GeneralDistributionTab) 