class CheckboxRender {
    // gets called once before the renderer is used
    init(params) {
        // create the cell
        this.iconUrl = (params.value === true || params.value === "yes" ) ? '/images/icons/checkbox-green.svg' : '/images/icons/uncheck-black.svg';
        this.eGui = document.createElement('div');
        this.eGui.innerHTML = `<div class="rs_grid_simple_checkbox"><img src="${this.iconUrl}" alt="${params.value}"/></div>`;
        this.cellValue = this.getValueToDisplay(params);
    }
 
    getGui() {
        return this.eGui;
    }
 
    // gets called whenever the cell refreshes
    refresh(params) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
 
        // return true to tell the grid we refreshed successfully
        return true;
    }
 
    // gets called when the cell is removed from the grid
    destroy() {
        
    }
 
    getValueToDisplay(params) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }
 }
 export default CheckboxRender;