 
export const ValidateData = (Data) => {
    const found = Object.values(Data).every(value => {
        if (value === null || value === "" || value === undefined) {
            return true;
        }else {       
        return false;  
}})
    return found;
};

export const checkIfArrayIsEmpty = (arr) => {
    if (Array.isArray(arr)) {
      return arr.length === 0;
    }
    return false;
}

export const checkObjectProperties = (obj) => {
    for (const prop in obj) {
      if (obj[prop] !== null) {
        return true;
      }
    }
    return false;
}

export const checkObjectResponseCodes = (obj) => {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = obj[key];
      if (value !== null && value === 500) {
        return false;
      }
    }
    return true;
}

export const ValidateArray = (dataArray) => { 
    let found;
    dataArray.forEach(element => {
        Object.values(element).every(value => {
            if (value === null || value === "" || value === undefined) {
                found = true;
        }else {       
            found = false;  
    }}) 
}); 
return found
}

//checks for inputType, and onChange field data
export const VallidateFields = (fieldData, inputType) => {
   let validate = false;
   if(inputType === "string" || inputType === "text" ){
    if(fieldData !== '' || fieldData !== null){
        validate = true
    }
   }  
   if(inputType === "number" || inputType === "integer" ){
    if(fieldData !== '' || fieldData !== null){
        validate = true
    }
   }  
   return validate;

}