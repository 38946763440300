import React, {useState, useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import MasterComonent from '../../Backend/MasterComonent' 
import Input from '../../Forms/Input'
import $ from 'jquery'
import '../Parameters.css'; 
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import LinkBtn from '../../inc/LinkBtn'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Tabs from '../../Widgets/Tabs/Tabs'
import { useParams } from 'react-router-dom'
import Alert from '../../inc/Alert'
import Helper from '../../../inc/Helper'
import AgGrid from '../../grid/ag/ag-grid'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings' 

const EditTeamHeader = (props) => {
  const {id} = useParams()
  const [loading, setLoading] = useState(true)
  const [teamLinesLoading ,setTeamLinesLoading] = useState(false)
  const [editTeamHeader, setEditTeamHeader] = useState({}) 
  const [employeeList, setEmployeeList] = useState([])
  const [teamLinesGrid, setTeamLinesGrid] = useState([])
 
  const DefaultNavs = [
    {title:Helper.getLabel(props.language,'team_header','Team Header'), isActive: true, },
    {title:Helper.getLabel(props.language,'team_header_lines','Team Header Lines')},  
  ]

  useEffect(() => {
    getEmployees()
    getTeamHeaderName()
    getTeamHeaderLines()
  }, [])

  let employeeCalled = false  
  let teamHeaderLinesCalled = false  
  let teamHeaderCalled = false  

  //GET Calls

  const getEmployees = () => {
    if(employeeCalled) {
      return;
    }
    let api = Api
    employeeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/employee',{}).then(function(res){
      if(res.data.status==="success"){
        let employeeARR = []
        res.data.data.forEach(item => {  
        employeeARR.push({
          value: item.employee_id,
          label: item.displayname
        })
       }) 
       setEmployeeList(employeeARR)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
    })
  }

  const getTeamHeaderName = () => {
    if(teamHeaderCalled) {
      return;
    }
    let api = Api
    teamHeaderCalled = true
    setLoading(true)
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/teamheader/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
         setEditTeamHeader(res.data.data)
         setLoading(false)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setLoading(false)
    })
  }

  const getTeamHeaderLines = () => {
    if(teamHeaderLinesCalled) {
      return;
    }
    setTeamLinesLoading(true)
    let api = Api
    teamHeaderLinesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/teamlines_header/${id}`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setTeamLinesGrid(res.data.data)
        setTeamLinesLoading(false)
      } 
    }).catch((res) => { 
      Helper.alert(res.response.data.message, 'error')
      setTeamLinesLoading(false)
    })
  }

  //End

  const onInputChangeHandler = (e) => { 
    setEditTeamHeader((prevState) => {
        return {
            ...prevState,
            [e.target.name]:e.target.value
        }
    })
  }

  const Columns_Headings = [
    {field:'employee_id', headerName: Helper.getLabel(props.language,'employee','Employee'), inputType: "dropdown", options: employeeList}, 
    {field:'from_date', headerName: Helper.getLabel(props.language,'from_date','From Date'), inputType: "datepicker"},
    {field:'to_date', headerName: Helper.getLabel(props.language,'to_date','To Date'), inputType: "datepicker"}, 
    {field:'RFP_view_access', headerName: Helper.getLabel(props.language,'RFP_view_access','RFP View Access'), inputType: "checkbox"}, 
    {field:'PR_view_access', headerName: Helper.getLabel(props.language,'PR_view_access','PR View Access'), inputType: "checkbox"},
    {field:'description', headerName: Helper.getLabel(props.language,'description','Description')}, 
  ]

  const GridSaveHandler = (rowItem) => {
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.teamLine_id || item.teamLine_id <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){ 
        addNewDataTeamLines(newData);
    }
    if(updatedData.length>=1){ 
      UpdateDataTeamLines(updatedData);
    }
  }

  const addNewDataTeamLines = (data) => {
    data.forEach((element) => {
      let api = Api
      api.setUserToken()  
      api.axios().post(Settings.apiUrl+'/teamlines',{
        "employee_id": parseInt(element.employee_id),
        "teamheader_id": id,
        "from_date": Helper.getDateForDb(element.from_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.from_date) : null,
        "to_date":  Helper.getDateForDb(element.to_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.to_date) : null,
        "description": element.description,
        "PR_view_access": element.PR_view_access == "yes" ? true : false,
        "RFP_view_access": element.RFP_view_access == "yes" ? true : false
    }).then(function(res){
        if(res.data.status==="success"){ 
          Helper.alert(res.data.message)
          getTeamHeaderLines()
        }
      }).catch((res) => {  
        Helper.alert(res.response.data.message, 'error')
        getTeamHeaderLines()
      }) 
     }) 
  }

  const UpdateDataTeamLines = (data) => {
    data.forEach((element) => {
      let api = Api
      api.setUserToken()  
      api.axios().put(Settings.apiUrl+`/teamlines/${element.teamLine_id}`,{
        "employee_id": parseInt(element.employee_id),
        "teamheader_id": id,
        "from_date": Helper.getDateForDb(element.from_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.from_date) : null,
        "to_date":  Helper.getDateForDb(element.to_date) !== "NaN-NaN-NaN" ? Helper.getDateForDb(element.to_date) : null,
        "description": element.description,
        "PR_view_access": element.PR_view_access == "yes" ? true : false,
        "RFP_view_access": element.RFP_view_access == "yes" ? true : false
    }).then(function(res){
        if(res.data.status==="success"){ 
          Helper.alert(res.data.message)
          getTeamHeaderLines()
        }
      }).catch((res) => {  
        Helper.alert(res.response.data.message, 'error')
        getTeamHeaderLines()
      }) 
     }) 
  }

  const DeleteHandler = (data) => {
    let api = Api
    api.setUserToken()  
    data.forEach((element) => {
      api.axios().delete(Settings.apiUrl+`/teamlines/${element.teamLine_id}`,{}).then(function(res){
        if(res.data.status==="success"){ 
          Helper.alert(res.data.message)
          getTeamHeaderLines()
        }
      }).catch((res) => {  
        Helper.alert(res.response.data.message, 'error')
        getTeamHeaderLines()
      }) 
    })
  }

  const SaveHandler = () => {
    if(!editTeamHeader.name){
      Helper.alert('Name field missing!', 'error')
      return;
    }
    let api = Api
    api.setUserToken()  
    api.axios().put(Settings.apiUrl+`/teamheader/${id}`,{
      "name": editTeamHeader.name !== "" ? editTeamHeader.name : null
    }).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res.data.message)
        $('.team_header_lines .rs_grid_btn_save').trigger('click') 
        getTeamHeaderName()
      }
    }).catch((res) => {  
      Helper.alert(res.response.data.message, 'error')
      getTeamHeaderName()
    })
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header_edit_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }

  return (
    <>
        <div className='procurement_settings'>
            <div className='container-fluid'>
                <NavigationHeder backUrl='/team-header' title={Helper.getLabel(props.language,'team_header_create',"Edit Team Header")}>
                    <LinkBtn isActive= {false} to="/team-header" title={Helper.getLabel(props.language,'close',"Close")} /> 
                    <Button onClick={SaveHandler} isActive={true} icon='/images/icons/save-white.svg' title={Helper.getLabel(props.language,'save',"Save")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_edit_screen_save_button)} /> 
                </NavigationHeder>  
                {loading ? <SimpleLoading /> : ''}
                <Collapse className='mb-4 default_team_header_tabs' open={true} title={Helper.getLabel(props.language,'defaults',"Defaults")}>
                    <Tabs disableSticky={true} navs={DefaultNavs} showAll={false} scrollOffset= "500">
                        <div className='container-fluid team_header'>
                            <div className='row'> 
                                <div className='col-lg-3'>
                                  <Input required={true} onChange={onInputChangeHandler} value={editTeamHeader.name} label={Helper.getLabel(props.language,'name',"Name")} name='name' />
                                </div> 
                            </div>
                        </div>

                        <div className='container-fluid team_header_lines'>
                           {teamLinesLoading ? <SimpleLoading /> : ''}
                           <AgGrid data={teamLinesGrid} onSave={GridSaveHandler} onDelete={DeleteHandler} header={Columns_Headings} />  
                        </div>
                    </Tabs>
                </Collapse>
            </div>
        </div>
    </>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
const SecurityOptions = {
gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header_edit_screen
}
  
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditTeamHeader, SecurityOptions))))