import React, { useRef, useState, useCallback } from 'react'
import './ContactInfo.css'
import CollapsedAddress from '../Edit Address/Collapsed Address/CollapsedAddress';
import axios from 'axios';  
import Settings from '../../inc/Settings';
import Helper from '../../inc/Helper';
import { connect } from 'react-redux';
import AgGrid from '../grid/ag/ag-grid';
 
 
const ContactInfo = ({language}) => {

  const onSaveClick = (rowItem) => {
    axios.post(Settings.apiBaseUrl+'',[rowItem]).then(function(res){
      if(res.data.status==="success"){
        // Update the State
      }
  })  
} 
  let Columns_Headings = [
    {field:'Description', headerName: Helper.getLabel(language,1188,'Description') },
    {field:'Type', headerName: Helper.getLabel(language,1189,'Type') } ,
    {field:'Contact Number / Address', headerName: Helper.getLabel(language,11234,'Contact Number / Address'), width: 260 },  
    {field:'Extension', headerName: Helper.getLabel(language,11235,'Extension') },
    {field:'Primary', headerName: Helper.getLabel(language,11230,'Primary') },
    {field:'Active', headerName: Helper.getLabel(language,11231,'Active') } ,
    {field:'Mobile', headerName: Helper.getLabel(language,11232,'Mobile') } 
  ]
 //Active & Mobile Labels to be included into the API

 
  return (
    <CollapsedAddress title={Helper.getLabel(language,11231,'Contact Information')} defaultExpanded='false'>
      <div className='contact-info'>
      <div className='row gy-2'>
      <div className='col-12 p-3'> 
      <AgGrid data={[]} id="contact-grid" viewOnly={true} dissableCheckBox = {true} header={Columns_Headings} onSave={onSaveClick} />
      </div>
      </div>
      </div>
 
    </CollapsedAddress>
  )
}
 
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      language:state.language
  }
}
 
export default connect (mapStateToProps) (ContactInfo);