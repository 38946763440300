import React, { Component } from 'react';
import Helper from '../../inc/Helper';
import $ from 'jquery';
class MasterSidebar extends Component {
    constructor(props){
        super(props);
        this.state ={
            isHideSidebar:false
        }
    }
    componentDidMount(){
        if(!this.props.disableSticky){
            let mainHeaderHeight = $('.main_header').outerHeight();
            let app_navigation  = $('.app_navigation').outerHeight();
            let navigationBarHeight = $('.lynkaz_tab_nav_section').outerHeight();
            let headerNavBradcrumbHeight = mainHeaderHeight + navigationBarHeight + app_navigation;
            this.stickyTopMargin = this.props.stickyTopMargin ? this.props.stickyTopMargin : headerNavBradcrumbHeight;
            let sidebarStikcyOptions = {topSpace: -25,zIndex:10}
            if(this.props.sidebarStckyContainerId){
                sidebarStikcyOptions.containerId = this.props.sidebarStckyContainerId;
            }
            
            let stickyItem = Helper.makeSticky('master_sidebar_sticky',sidebarStikcyOptions)
        }
    }
    onIconClick(e){
        this.setState({isHideSidebar: !this.state.isHideSidebar}, function(){
            Helper.refreshAppScript()
        })
    }
    render() {
        let sidebarClassName =  'rs_master_sidebar ' + (this.props.className ? this.props.className : '' );
        if(this.state.isHideSidebar){
            sidebarClassName += ' hide_sidebar'
        }
        return ( 
            <div className={ sidebarClassName }>
                <div className='master_sidebar_sticky' id="master_sidebar_sticky">
                    <div className='rs_master_sidebar_inner'>
                        <div className= {'master_sidebar_controller ' } onClick = { e => this.onIconClick(e)}>
                            <img src="/images/icons/angle-right-white.svg" alt="Sidebar toggle"/>
                        </div>
                        <div className='m_sidebar_items'>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default MasterSidebar;