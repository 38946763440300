import React, {useCallback, useEffect} from 'react';
import Checkbox from '../../Forms/Checkbox';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import $ from 'jquery';
import { ValidateData } from '../../../inc/Validation';
 
const FieldsRenderer = ({ fieldsArray, updateState, currentState }) => {
    //Component For Mapping Field Input Boxes.

    // ** Props 
        {/* 
            mainClassName,
            className, 
            mainTitle,
            required,
            inputClassName,
            disable,
            type,
            inputType,
            label,
            isChecked,
            value,
            gridObjectFunction,
            id
        */}
    //End

    const onInputCheckboxChangeHandler = (name, value) => {  
        updateState((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        })
    }

    const onInputDatePickerChangeHandler = (name, input) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]:input
            }
        })
    } 
      
    const onInputChangeHandler = useCallback((e) => {
        if (e.target.value !== currentState[e.target.name]) {
            updateState((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: e.target.value
                }
            }) 
        }
    }, [currentState])
   
  return (
    <div className='row'>
        {fieldsArray.map((item,key) => { 
            return (
            <>
                <div className={`mt-2 col-12 ${item.mainClassName ? item.mainClassName : 'd-none'}`}>
                        <h3 className='mb-3'>{item.mainTitle ? item.mainTitle : null}</h3>
                    </div>
                    {item.inputBoxData ? item.inputBoxData.map((element, key) => {
                        return (
                            <div className={item.className ? item.className : 'col-lg-4'} key={key}>
                                {element.inputType == "text" && 
                                    <Input id={element.id ? element.id : null} required={element.required ? element.required : false} disable={element.disable ? element.disable : false} name={element.name} label={element.label} onChange={onInputChangeHandler} value={currentState[element.name]} />
                                }
                                {element.inputType == "dropdown" && 
                                    <DropDownInput id={element.id ? element.id : null} required={element.required ? element.required : false} isDisable={element.disable ? element.disable : false} label={element.label} options={element.options ? element.options : []} value={currentState[element.name]} name={element.name} onChange={onInputChangeHandler}  /> 
                                }
                                {element.inputType == "checkbox" &&
                                    <Checkbox id={element.id ? element.id : null} type={element.inputType} name={element.name} onChange={onInputCheckboxChangeHandler} isDisable={element.disable ? element.disable : false} label={element.label} isChecked={currentState[element.name]} /> 
                                }
                                {element.inputType == "datepicker" &&
                                    <InputDatePicker id={element.id ? element.id : null} value={currentState[element.name]} label={element.label} name={element.name} inputClassName={element.inputClassName} required={element.required ? element.required : false} onChange={onInputDatePickerChangeHandler}  /> 
                                }
                            </div>
                        )
                    }): null}
                    {!item.inputBoxData && 
                    <div className={item.className ? item.className : 'col-lg-4'} key={key}>
                        {item.inputType == "text" && 
                            <Input id={item.id ? item.id : null} required={item.required ? item.required : false} disable={item.disable ? item.disable : false} name={item.name} label={item.label} onChange={onInputChangeHandler} value={currentState[item.name]} /> 
                        }
                        {item.inputType == "dropdown" && 
                            <DropDownInput id={item.id ? item.id : null} required={item.required ? item.required : false} isDisable={item.disable ? item.disable : false} label={item.label} options={item.options ? item.options : []} value={currentState[item.name]} name={item.name} onChange={onInputChangeHandler}  /> 
                        }
                        {item.inputType == "checkbox" &&
                            <Checkbox id={item.id ? item.id : null} type={item.inputType} name={item.name} onChange={onInputCheckboxChangeHandler} isDisable={item.disable ? item.disable : false} label={item.label} isChecked={currentState[item.name]} /> 
                        }
                        {item.inputType == "datepicker" &&
                            <InputDatePicker id={item.id ? item.id : null} value={currentState[item.name]} label={item.label} name={item.name} inputClassName={item.inputClassName} required={item.required ? item.required : false} onChange={onInputDatePickerChangeHandler}  /> 
                        }
                    </div>
                    }
            </>
            )
        })}
    </div>
  )
}

export default FieldsRenderer;