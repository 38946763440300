import ActionTypes from "../inc/ActionsTypes";

const DefaultState = {
    allLanguageLoaded:false,
    allLabelsLoaded:false,
    allLabels:[],
    allLanguage:[],
    currentLanguage: {},
    isLoading:false
}
const LanguageReducer = (state = DefaultState, actions) =>{
    if(actions.type ===  ActionTypes.SET_LABELS ){
        state = {
            ...state,
            allLabelsLoaded:true,
            allLabels:actions.payload
        }
    }
    if(actions.type ===  ActionTypes.SET_LANGUAGE ){
        state = {
            ...state,
            allLanguageLoaded:true,
            allLanguage:actions.payload
        }
    }
    if(actions.type ===  ActionTypes.SET_CURRENT_LANGUAGE ){
        state = {
            ...state,
            currentLanguage:actions.payload
        }
    }
    
    return state;
}
export default LanguageReducer;