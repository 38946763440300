import React, {useState, useEffect} from 'react';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import $ from 'jquery'
import AgGrid from '../../grid/ag/ag-grid';
import Popup from '../../Popup/Popup';
import Button from '../../inc/Button';
import { checkIfArrayIsEmpty } from '../../../inc/Validation';

const Entities = ({setOpenEntites, setEntites, dataExportable, itemMaster_id, reloadGrid, entityGridObj, setEntityGridObj}) => {
    const [entity, setEntity] = useState([])
    const [gridObject, setGridObject] = useState([])

    useEffect(() => {
        get_entity_name_list()
    }, [])

    const get_entity_name_list = () => {
        let api = Api
        api.setUserToken()
        api.axios().post(Settings.apiUrl+`/search_entity`,{
          "code":"",
          "name":""
        }
        ).then(function(res){
          if(res.data.status==="success"){   
            setEntity(res.data.data)
          }
        })
       }

    const GridAPI = (gridObj) => {
        setGridObject(gridObj)
    }
      
    const EntitesAssign = () => { 
        if(checkIfArrayIsEmpty(gridObject.api.getSelectedRows())){
            Helper.alert('Please Select Entities To Continue!', 'error')
            return;
        }
        if(!dataExportable && itemMaster_id){
        let UPDATED_ENTITES = [] 
        gridObject.api.getSelectedRows().forEach(element => {
          UPDATED_ENTITES.push({
            "entity_id":element.entity_id,
            "itemmaster_id":itemMaster_id,
            "IsActive":true,
            "Reason_Id":null,
            "comments":null
        })
        })
        let api = Api
        api.setUserToken()
        UPDATED_ENTITES.forEach((element) => {
            api.axios().post(Settings.apiItemUrl+'/itementity',{
                "entity_id":element.entity_id,
                "itemmaster_id":element.itemmaster_id,
                "IsActive":true,
                "Reason_Id":null,
                "comments":null
            }).then(function(res){
            if(res.data.status==="success"){ 
                Helper.alert(res.data.message) 
                setOpenEntites(false)
                $('.item_entities .rs_popup_close').trigger('click');
                reloadGrid()
            } 
            }).catch((res) => { 
                Helper.alert(res.response.data.message, 'error') 
            }) 
        })
    }
    if(dataExportable && !itemMaster_id) {
        let UPDATED_ENTITES = [] 
        gridObject.api.getSelectedRows().forEach(element => {
          UPDATED_ENTITES.push({
            "name":element.entity_id,
            "code": element.code,
            "active":true,
            "reason":null,
            "comments":null,
            "uniqueid": Date.now()
        })
        })
        // let newData = Helper.getDataFromGrid(entityGridObj) 
        // console.log(entityGridObj)
        // 
        // let newUpdatedData = []
    // newData.forEach((element) => {
    //  UPDATED_ENTITES.forEach((item) => {
    //     if(element.uniqueid == item.uniqueid){
    //         newUpdatedData.push(item)
    //         console.log(item, 'update')
    //     } else {
    //         newUpdatedData.push(element, 'new')
    //     }
    //  })
    // })
        setEntites(UPDATED_ENTITES)
        $('.item_entities .rs_popup_close').trigger('click');
        setOpenEntites(false)
    }
}

    const CloseHandler = () => {
        setOpenEntites(false)
        $('.item_entities .rs_popup_close').trigger('click');
    }

    const Callbuttons = () => {
        return <Button title='Select Entites' onClick={EntitesAssign} />;
    }
    
    const Columns_Headings_ENTITIES = [
        {field:'name', headerName:'Entity', editable:false},
    ]

  return (
    <div className='item_entities'>
    <Popup autoOpen={true} width={"70%"} onClose={CloseHandler}>
        <div className='rs_item_entities_grid'>
            <AgGrid id="rs_popup_open_entites_roles" afterDeleteButtons={Callbuttons} allBtnHide={true} viewOnly={true} onGridReady={GridAPI} header={Columns_Headings_ENTITIES} data={entity} />
        </div>
    </Popup> 
    </div>
  )
}

export default Entities;