import React, { Component } from "react";
import { Alert } from "react-bootstrap";

import Helper from "../../../inc/Helper";
import DeleteGrey from "../../../assets/delete-grey.svg";
import WishlistIconActive from "../../../assets/wishlist-icon-active.svg";
import ShoppingItemCard from "../common/ShoppingItemCard";
import CheckDeleteActionBar from "../common/CheckDeleteActionBar";
import Settings from "../../../inc/Settings";
import Api from "../../../inc/Api";
import SimpleLoading from "../../Loading/SimpleLoading";
import { connect } from "react-redux";
import ShopSearchbar from "../common/ShopSearchbar";

class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      isWishlistLoading: false,
      wishlistItems: [],
      selectedItems: [],
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.deleteWishlistItems = this.deleteWishlistItems.bind(this);
    this.incrementCartCount = this.incrementCartCount.bind(this);
    this.decrementWishlistCount = this.decrementWishlistCount.bind(this);
  }

  componentDidMount() {
    this.getWishlistItems();
  }

  getWishlistItems() {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isWishlistLoading: true });
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/wishlist")
      .then(function (res) {
        if (res.data.status === "success") {
          const wishlistIds = [...res.data.data];
          if (wishlistIds.length === 0)
            this.setState({ isWishlistLoading: false });
          wishlistIds.map((id, index) => {
            api
              .axios()
              .get(Settings.apiItemUrl + "/item/" + id)
              .then(function (res) {
                if (res.data.status === "success") {
                  that.setState((prevState) => {
                    const existingItem = prevState.wishlistItems.find(
                      (item) => item.item_id === res.data.data.item_id
                    );
                    if (!existingItem) {
                      return {
                        wishlistItems: [
                          ...prevState.wishlistItems,
                          res.data.data,
                        ],
                      };
                    }
                    return {};
                  });
                }
                if (wishlistIds.length - 1 === index) {
                  that.setState({
                    isWishlistLoading: false,
                  });
                }
              })
              .catch((error) => {
                that.setState({ isWishlistLoading: false });
                Helper.alert(error?.response?.data?.message, "error");
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isWishlistLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  deleteWishlistItems() {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isWishlistLoading: true });
    this.state.selectedItems.map((id, index) => {
      api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl + "/wishlist/" + id)
        .then(function (res) {
          if (res.data.status === "success") {
            that.setState((prevState) => {
              const selectedItems = [...prevState.selectedItems];
              const index = selectedItems.indexOf(id);
              selectedItems.splice(index, 1);
              return { selectedItems };
            });
          }
          if (that.state.selectedItems.length - 1 === index) {
            that.setState({
              isWishlistLoading: false,
            });
          }
          this.decrementWishlistCount();
        })
        .catch((error) => {
          that.setState({ isWishlistLoading: false });
          Helper.alert(error?.response?.data?.message, "error");
        });
    });
  }

  handleSelect(id) {
    this.setState((prevState) => {
      const selectedItems = [...prevState.selectedItems];
      const existingIndex = selectedItems.indexOf(id);
      if (existingIndex === -1) {
        selectedItems.push(id);
      } else {
        selectedItems.splice(existingIndex, 1);
      }
      return { selectedItems };
    });
  }

  toggleSelectAll() {
    this.setState((prevState) => {
      const selectedItems = [...prevState.selectedItems];
      const wishlistItems = [...prevState.wishlistItems];

      const selectAll = !prevState.selectAll;

      if (selectAll) {
        wishlistItems.forEach((item) => {
          if (!selectedItems.includes(item.item_id)) {
            selectedItems.push(item.item_id);
          }
        });
      } else {
        selectedItems.length = 0;
      }

      this.setState({ selectedItems, selectAll });
    });
  }

  incrementCartCount() {
    this.props.changeCartCount(this.props.cartCount + 1);
  }

  decrementWishlistCount() {
    this.props.changeWishlistCount(
      this.props.wishlistCount >= 1
        ? this.props.wishlistCount - 1
        : this.props.wishlistCount
    );
  }

  render() {
    return (
      <>
        <ShopSearchbar />
        <div className="pr-shop">
          {this.state.isWishlistLoading ? (
            <div style={{ margin: "20px auto" }}>
              <SimpleLoading />
            </div>
          ) : (
            <div className="pr-shop-main">
              <CheckDeleteActionBar
                icon={WishlistIconActive}
                title="Wishlist"
                itemCount={this.state.wishlistItems.length}
                selectAll={this.state.selectAll}
                toggleSelectAll={this.toggleSelectAll}
                deleteAll={this.deleteWishlistItems}
              />
              <div className="pr-shop-main-itemgrid">
                {this.state.wishlistItems.map((item) => (
                  <ShoppingItemCard
                    showCheckbox={true}
                    item={item}
                    handleSelect={this.handleSelect}
                    incrementCartCount={this.incrementCartCount}
                    selectedItems={this.state.selectedItems}
                    decrementWishlistCount={this.decrementWishlistCount}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
