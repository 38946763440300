import React from 'react'
import './ExtHeaderLine.css'

const ExtHeaderLine = ({title}) => {
  return (
    <div className='header_line'>
        <h2>{title}</h2>
    </div>
  )
}

export default ExtHeaderLine;