import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'; 
import { useParams } from 'react-router-dom';
import Api from '../../../../inc/Api';
import FormValidator from '../../../../inc/FormValidator';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import MasterComonent from '../../../Backend/MasterComonent';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import Input from '../../../Forms/Input';
import Alert from '../../../inc/Alert';
import Button from '../../../inc/Button';
import Collapse from '../../../inc/Collapse';
import LinkBtn from '../../../inc/LinkBtn';
import SimpleLoading from '../../../Loading/SimpleLoading';
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';

const GroupEdit = (props) => {
  const [editGroup, setEditGroup] = useState({})
  const [loading, setLoading] = useState(false)
  const {id} = useParams()

  //Reduction Calls
    let vendorGroupCalled = false
  //end

  const get_vendors_group = () => { 
    if(vendorGroupCalled){
      return;
    }
    setLoading(true)
    let api = Api
    vendorGroupCalled = false
    api.setUserToken()
    api.axios().get(Settings.apiVendorUrl+`/vendorgroup/${id}`,{}).then(function(res){
      if(res.data.status==="success"){  
        setEditGroup(res.data.data)
        setLoading(false)
      }
    }).catch((res) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    get_vendors_group()
  }, [])

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'name', max:50, displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']},  
      {name:'external_code',max:50,  displayName:Helper.getLabel(props.language,'code','Code'),types:['Required'] },  
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }
  validationConfigure()
   

  const CodeChangeHandler = (event) => {
    let extcode = event.target.value
    setEditGroup((prevState) => {
      return {
        ...prevState,
        external_code: extcode
      }
    })
  }

  const NameChangeHandler = (event) => {
    let name = event.target.value
    setEditGroup((prevState) => {
      return {
        ...prevState,
        name: name
      }
    }) 
  }

  const VendorGroupModule = () => {
    return (
    <Collapse title={Helper.getLabel(props.language,'vendor_group','Vendor Group')} className="mb-4 general_tabs" open={true}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3'> 
            <Input label={Helper.getLabel(props.language,'name',"Name")} type='text' required={true} hasError={validator.hasError('name')} value={editGroup.name} onChange={NameChangeHandler} />
          </div>
          <div className='col-lg-3'> 
            <Input label={Helper.getLabel(props.language,'external_code',"Code")} type='text' required={true} hasError={validator.hasError('external_code')} value={editGroup.external_code} onChange={CodeChangeHandler} />
          </div>
        </div>
      </div>
    </Collapse>
    )
  }

  const SaveHandler = () => {
    let newGroup = { 
      "name": editGroup.name, 
      "external_code": editGroup.external_code
    }
    let validData = {
      ...newGroup
    } 
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }   
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiVendorUrl+`/vendorgroup/${id}`,{
      "name": editGroup.name !== "" ? editGroup.name : null,
      "external_code": editGroup.external_code !== "" ? editGroup.external_code : null,
      "isprepaymentgroup":true
  }).then(function(res){
      if(res.data.status==="success"){
        Helper.alert(res.data.message)  
        get_vendors_group()
      }
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
    get_vendors_group()
  })
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_group.vendors_group_edit_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }


  return ( 
    <div className='container-fluid'>
      <NavigationHeder title={ `<span style="color:#000;">${Helper.getLabel(props.language,'edit_vendor_group',"Edit Vendor Group")} #</span> ${editGroup.name} ` }>
        <LinkBtn isActive= {false} to="/vendors-group" title={Helper.getLabel(props.language,'cancel',"Cancel")} />
        <Button isDisable={!security.canCreate(frontendIds.masterData.vendor_group.vendors_group_edit_screen_save_button)}  isActive= {true} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
      </NavigationHeder> 
      {loading ? <SimpleLoading /> : (VendorGroupModule())}
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_group.vendors_group_edit_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(GroupEdit, SecurityOptions))))