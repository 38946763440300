import React, { Component } from "react";
import { Link } from "react-router-dom";

import Helper from "../../../inc/Helper";
import WishlistIcon from "../../../assets/wishlistIcon.svg";
import CartIcon from "../../../assets/cartIcon.svg";
import { connect } from "react-redux";
import ShoppingCartModal from "../modals/ShoppingCartModal";

class NavCartWishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShoppingCart: false,
    };

    this.handleShowShoppingCart = this.handleShowShoppingCart.bind(this);
    this.handleCloseShoppingCart = this.handleCloseShoppingCart.bind(this);
    this.decrementCartCount = this.decrementCartCount.bind(this);
  }

  handleCloseShoppingCart() {
    console.log("close");
    this.setState({ showShoppingCart: false });
  }

  handleShowShoppingCart() {
    console.log("open");
    this.setState({ showShoppingCart: true });
  }

  decrementCartCount() {
    this.props.changeCartCount(
      this.props.cartCount >= 1
        ? this.props.cartCount - 1
        : this.props.cartCount
    );
  }

  render() {
    return (
      <div className="pr-nav">
        <Link className="pr-nav-wishlist" to="/purchase-requisition/wishlist">
          <img src={WishlistIcon} alt="wishlist" height="20px" width="20px" />
          {Helper.getLabel(this.props.language, "wishlist", "Wishlist")}
          {this.props.wishlistCount > 0 ? (
            <span
              className={`${
                Helper.isRtl()
                  ? "pr-nav-wishlist-count-rtl"
                  : "pr-nav-wishlist-count"
              }`}
            >
              {this.props.wishlistCount}
            </span>
          ) : null}
        </Link>
        <div className="pr-nav-cart" onClick={this.handleShowShoppingCart}>
          <img src={CartIcon} alt="cart" height="20px" width="20px" />
          {Helper.getLabel(this.props.language, "cart", "Cart")}
          {this.props.cartCount > 0 ? (
            <span
              className={`${
                Helper.isRtl() ? "pr-nav-cart-count-rtl" : "pr-nav-cart-count"
              }`}
            >
              {this.props.cartCount}
            </span>
          ) : null}
        </div>
        <ShoppingCartModal
          visibility={this.state.showShoppingCart}
          hide={this.handleCloseShoppingCart}
          decrementCartCount={this.decrementCartCount}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    cartCount: state.prShop.cartCount,
    wishlistCount: state.prShop.wishlistCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavCartWishlist);
