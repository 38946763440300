
import React, { useState } from 'react';
import LinkBtn from '../../../../../components/inc/LinkBtn';
import './activeUserSection.css';

export default function ActiveUserSection() {

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <h5 className='mainHeading'>Action History</h5>
        </div>
        <div>
          <LinkBtn to='/workflow-element/ganttchart' title='Gantt' className='rightTopButton' icon={'/images/icons/Rectangle 34624579.svg'} />
        </div>
      </div>

      <hr className="hrSection" />

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv'>
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aHVtYW4lMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=700&q=60" alt="New York" />
        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">Ranajeet Singha</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023 <span className='ms-3'>View</span></p>
            </div>
          </div>
          <h6 className="card-subtitle ">
            <span className='statusColor'>Pending: </span>
            Lorem ipsum dolor sit amet</h6>
        </div>
      </div>

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv'>
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aHVtYW4lMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=700&q=60" />
        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">Zack Jones</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023<span className='ms-3'>View</span></p>
            </div>
          </div>
          <div>
            <h6 className="card-subtitle text-muted">
              <span className='text-primary'>Sendback by: </span>
              Lorem ipsum dolor sit amet
            </h6>
            <div className='mt-2 detail-contain'>
              <p className='p-2'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, necessitatibus. Sit optio deleniti eveniet saepe?</p>
            </div>
          </div>
        </div>
      </div>

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv'>
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1589234217365-08d3e0e5cf42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aHVtYW4lMjBmYWNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=700&q=60" />

        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">Bill Gates</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023 <span className='ms-3'>View</span></p>
            </div>
          </div>
          <h6 className="card-subtitle text-muted">
            <span className='text-primary'>Recall by: </span>
            Lorem ipsum dolor sit amet</h6>
        </div>
      </div>

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv' >
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1612230337141-903f3d330055?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGh1bWFuJTIwZmFjZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=700&q=60" />

        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">David Dhawan</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023 <span className='ms-3'>View</span></p>
            </div>
          </div>
          <h6 className="card-subtitle text-muted">
            <span className='text-primary'>Approve by: </span>
            Lorem ipsum dolor sit amet</h6>
        </div>
      </div>

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv'>
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1586822339087-80cc375ac083?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGh1bWFuJTIwZmFjZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=700&q=60" />
        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">Numano Tato</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023 <span className='ms-3'>View</span></p>
            </div>
          </div>
          <h6 className="card-subtitle text-muted">
            <span className='text-primary'>Request more information by: </span>
            dolor sit amet</h6>
        </div>
      </div>

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv'>
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1590895340509-793cb98788c9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fGh1bWFuJTIwZmFjZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=700&q=60" />
        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">Jamesh jone</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023 <span className='ms-3'>View</span></p>
            </div>
          </div>
          <h6 className="card-subtitle text-muted">
            <span className='text-primary'>Submitted by: </span>
            Lorem ipsum dolor sit amet</h6>
        </div>
      </div>

      <div className="card flex-row flex-wrap align-items-center border-0 mb-5">
        <div className='col-auto containedImgDiv'>
          <img className="img-fluid rounded-circle  float-start containedImg" src="https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDJ8fGh1bWFuJTIwZmFjZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=700&q=60" />
        </div>
        <div className='col ms-2'>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 className="card-title text-primary">Dravir Smith</h5>
            </div>
            <div>
              <p className='text-muted'>04/02/2023 <span className='ms-3'>View</span></p>
            </div>
          </div>
          <h6 className="card-subtitle text-muted">
            <span className='text-primary '>Rejected by: </span>
            Lorem ipsum dolor sit amet</h6>
        </div>
      </div>
    </>
  )
}
