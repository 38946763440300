import React, { Component } from 'react';
import InputPlaceholder from './InputPlaceholder';
import $ from 'jquery';
import Helper from '../../inc/Helper';
import { connect } from 'react-redux';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
class DropDownInput extends Component {
    constructor(props){
        super(props);
        this.trigerTime = 0;
        this.id =  this.props.id ? this.props.id : new Date().getTime();
        this.state ={
            isRequired: this.props.required ? true : false,
            isError:false
        }
    }
    componentDidMount(){
        if(this.props.disable) {
            return;
        }
        let that = this;
        setTimeout(function(){ 
            that.initChoosen();
        },100)
    }
    componentWillUnmount(){
        let inputSelector = $('#'+this.id);
        //inputSelector.chosen('destroy');
    }
    initChoosen(){
        let language = this.props.language;
        let that = this;
        let inputSelector = $('#'+this.id);
       
        let select2Settings = this.props.select2Settings ? this.props.select2Settings : {};
        if(this.props.select2Settings){
            if(select2Settings.ajax){
                select2Settings.ajax.delay = 500;
                select2Settings.ajax.transport =  (params, success, failure) =>{
                    var $request = $.ajax({
                        ...params,
                        headers:{
                            ...params.headers,
                            Authorization: 'Bearer '+Api.getUserToken()
                        }
                    });
                
                    $request.then(success);
                    $request.fail(failure);
                
                    return $request;
                  }
                /*
                select2Settings.ajax.headers = {
                    Authorization: 'Bearer '+Api.getUserToken()
                }
                */
                if(!select2Settings.ajax.data){
                    select2Settings.ajax.data =  (dataParams)  =>{
                        var query = {
                          search: dataParams.term,
                          page_number: dataParams.page ? ( dataParams.page + 1) : 1,
                          page_size:Settings.dropdownPageSize
                        }
                        return query;
                    }
                }
            }
        }
        let s2Options = {
            placeholder: this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select'),
            allowClear: true,
            width: '100%',
            openOnEnter: true,
            ...select2Settings
        }

        //inputSelector.select2(s2Options)
        if(this.props.disable || this.props.isDisable){
            inputSelector.select2({...s2Options,disabled:true});
        }else{
            inputSelector.select2({...s2Options,disabled:false});
        }
        
        inputSelector.on('select2:select', function (event) {
            if(event.timeStamp !==that.trigerTime){
                if(that.props.onChange && typeof that.props.onChange === 'function'){
                    that.trigerTime =event.timeStamp;
                    that.props.onChange(event)
                }
            }
        });
        inputSelector.on('select2:unselect', function (event) {
            if(event.timeStamp !==that.trigerTime){
                if(that.props.onChange && typeof that.props.onChange === 'function'){
                    that.trigerTime =event.timeStamp;
                    that.props.onChange(event)
                }
            }
        });
        
        /*
        if(inputSelector){
            inputSelector.chosen({
                disable_search_threshold:0,
                placeholder_text_single:this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select'),
                placeholder_text_multiple :this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select'),
                rtl:Helper.isRtl()
            }).change(function(event){
                if(event.timeStamp !==that.trigerTime){
                    if(that.props.onChange && typeof that.props.onChange === 'function'){
                        that.trigerTime =event.timeStamp;
                        that.props.onChange(event)
                    }
                }
            });
        } 
        */
    
    }
    componentDidUpdate(prevProps) { 
        if(this.props.disable) {
            return;
        }
        let that = this;
        setTimeout(function(){ 
            that.initChoosen();
        },100)
        /*
        let inputSelector = $('#'+this.id );
        if(inputSelector){
            inputSelector.trigger('chosen:updated');
        }
        */
    }
    getInputBox = () => {
        let language = this.props.language;
        let props = this.props;
        let options = this.props.options ? this.props.options : [];
        if(props.disable){
            return <InputPlaceholder label= {options ? options.find(id => id.value == props.value)?.label : props.value}/>
        }
        let that = {
            props:props
        }
        
        return (
            <select data-placeholder={this.props.placeHolder ? this.props.placeHolder : Helper.getLabel(language,'please_select','Please Select')}  id ={this.id} name={props.name} className="form-control rs_chosen_dropdown" onChange={ props.onChange ? e => props.onChange(e) : e => { } }>
                <option value=""></option>
                {
                    options.map( (item,key) => {
                        if(that.props.value == item.value){
                            return(<option key={key} value={item.value} selected={true}>{item.label}</option>)
                        }
                        return(<option key={key} value={item.value} >{item.label}</option>)
                    })
                }
            </select>
        )
    }
    hasError(){
        if(this.state.isError){
            return true;
        }
        if(this.props.hasError){
            return true;
        }
        return false;
    }
    render() {
        let props = this.props;
        let cssClass = props.className ? 'form-group '+ props.className : 'form-group';
        if(props.labelAlign && props.labelAlign ==='left'){
            cssClass = cssClass+' lebel_align_left';
        }
        
        return(
            <div className={  cssClass + ( this.hasError() ? ' invalid' : '' ) }>
                {props.label ? <label>{props.label} {this.state.isRequired ? <span>*</span> : ''}</label> : '' }
                { this.getInputBox() }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        language:state.language
    }
}

export default  connect (mapStateToProps)  ( DropDownInput );