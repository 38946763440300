import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import Rating from "../../../assets/rating.svg";
import ProductDetailMain from "../../../assets/product-detail-main.png";
import ProductDetailThumb from "../../../assets/product-detail-thumbnail.png";
import EnlargeImage from "../../../assets/enlarge-image.svg";
import ShoppingCartModal from "../modals/ShoppingCartModal";
import ImagePopupCarousel from "../modals/ImagePopupCarousel";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import RsWithRouter from "../../inc/RsWithRouter";
import ShopSearchbar from "../common/ShopSearchbar";

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isAddingToCart: false,
      isFetchingImages: false,
      itemData: null,
      itemImages: [],
      activeImageIndex: 0,
      showImagePopupCarousel: false,
    };

    this.handleCloseImagePopupCarousel =
      this.handleCloseImagePopupCarousel.bind(this);
    this.handleShowImagePopupCarousel =
      this.handleShowImagePopupCarousel.bind(this);
  }

  componentDidMount() {
    let pathname = this.props.rs_router.location.pathname;
    let idInAction = pathname.substr(pathname.lastIndexOf("/") + 1);
    this.getItemDetails(idInAction);
    this.getItemImages(idInAction);
  }

  getItemDetails(id) {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isLoading: true });
    api
      .axios()
      .get(Settings.apiItemUrl + "/item/" + id)
      .then(function (res) {
        console.log(res);
        if (res.data.status === "success") {
          that.setState({ isLoading: false, itemData: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getItemImages(id) {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isFetchingImages: true });
    api
      .axios()
      .get(Settings.apiItemUrl + "/itemimage/" + id)
      .then(function (res) {
        console.log(res);
        if (res.data.status === "success") {
          let imagesArray = [];
          if (res.data?.data?.length > 4) {
            imagesArray = res.data?.data?.slice(0, 4);
          } else {
            imagesArray = res.data?.data;
          }
          that.setState({ isFetchingImages: false, itemImages: imagesArray });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isFetchingImages: false });
      });
  }

  addToCart(item) {
    let itemData = {
      type: 0,
      ordered_qty: 1,
      unit_price: parseInt(item.purchase_price) ?? null,
    };
    this.setState({ isAddingToCart: true });
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .post(Settings.apiPurchaseRequisitionUrl + "/pr_cartline", itemData)
      .then(function (res) {
        console.log(res);
        if (res.data?.status === "success") {
          that.incrementCartCount();
          that.setState({ isAddingToCart: false });
          Helper.alert("Item added to cart", "success");
        }
      })
      .catch((error) => {
        that.setState({ isAddingToCart: false });
        Helper.alert(error?.response?.data?.message ?? error?.message, "error");
      });
  }

  handleCloseImagePopupCarousel() {
    this.setState({ showImagePopupCarousel: false });
  }

  handleShowImagePopupCarousel() {
    console.log("show", this.props);
    this.setState({ showImagePopupCarousel: true });
  }

  incrementCartCount() {
    this.props.changeCartCount(this.props.cartCount + 1);
  }

  render() {
    return (
      <>
        <ShopSearchbar />
        <div className="pr-wrapper">
          <div className="pr-mainbody-wrapper">
            {this.state.isLoading || this.state.isFetchingImages ? (
              <div style={{ margin: "20px auto" }}>
                <SimpleLoading />
              </div>
            ) : (
              <div className="pr-productdetail">
                <div className="pr-productdetail-gallery">
                  <div className="pr-productdetail-gallery-main">
                    <img
                      className="pr-productdetail-gallery-main-mainimage"
                      src={
                        this.state.itemImages?.length > 0
                          ? this.state.itemImages[this.state.activeImageIndex]
                          : ""
                      }
                      alt="gallery-main"
                    />
                    <img
                      className="pr-productdetail-gallery-main-enlarge"
                      src={EnlargeImage}
                      alt="enlarge"
                      onClick={this.handleShowImagePopupCarousel}
                    />
                  </div>
                  <div className="pr-productdetail-gallery-thumbnails">
                    {this.state.itemImages.map((itemImage, index) => (
                      <img
                        key={itemImage.image_id}
                        src={itemImage.image}
                        alt={itemImage.image_name}
                        onClick={() => {
                          this.setState({ activeImageIndex: index });
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="pr-productdetail-content">
                  <h4>{this.state.itemData?.display_name}</h4>
                  <div className="pr-productdetail-content-ratingprice">
                    <span className="pr-productdetail-content-ratingprice-rating">
                      <img src={Rating} alt="rating" />{" "}
                      {this.state.itemData?.rating ?? "-"}
                    </span>
                    <span className="pr-productdetail-content-ratingprice-price">
                      AED{" "}
                      {this.state.itemData?.unit_price
                        ? parseInt(this.state.itemData?.unit_price)
                        : "-"}
                    </span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "sku", "SKU")}
                    </label>
                    <span>{this.state.itemData?.sku ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "circa", "Circa")}
                    </label>
                    <span>{this.state.itemData?.rating ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "dimensions",
                        "Dimensions"
                      )}
                    </label>
                    <span>{this.state.itemData?.dimension_id ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "medium", "Medium")}
                    </label>
                    <span>{this.state.itemData?.medium ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(
                        this.props.language,
                        "location",
                        "Location"
                      )}
                    </label>
                    <span>{this.state.itemData?.location ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec">
                    <label>
                      {Helper.getLabel(this.props.language, "origin", "Origin")}
                    </label>
                    <span>{this.state.itemData?.origin ?? "-"}</span>
                  </div>
                  <div className="pr-productdetail-content-spec pr-productdetail-content-desc">
                    <label>Description</label>
                    <p>{this.state.itemData?.description ?? "-"}</p>
                  </div>
                  {this.state.isAddingToCart ? (
                    <SimpleLoading />
                  ) : (
                    <button
                      className="pr-productdetail-content-btn-outlined"
                      onClick={() => this.addToCart(this.state.itemData)}
                    >
                      {Helper.getLabel(
                        this.props.language,
                        "add_to_cart",
                        "Add to Cart"
                      )}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <ShoppingCartModal
            visibility={this.state.showShoppingCart}
            hide={this.handleCloseShoppingCart}
          />
          <ImagePopupCarousel
            show={this.state.showImagePopupCarousel}
            hide={this.handleCloseImagePopupCarousel}
            activeIndex={this.state.activeImageIndex}
          >
            {this.state.itemImages.map((itemImage, index) => (
              <img
                key={itemImage.image_id}
                src={itemImage.image}
                alt={itemImage.image_name}
              />
            ))}
          </ImagePopupCarousel>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsWithRouter(ProductDetail));
