import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import { useEffect, useState } from "react";
import { ViewSwitcher } from "./ViewSwitcher";
import ActionSpecificUserModal from '../Gantt Chart/Action Specific User Modal/ActionSpecificUserModal';
import './GanttChart.css';
import { useNavigate } from 'react-router-dom';
import "gantt-task-react/dist/index.css";
import { tasks } from './InitialTaskValue';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const GanttChart = () => {

  const [view, setView] = useState(ViewMode.QuarterDay);
  const [task, setTasks] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ganttData, setganttData] = useState(false);
  // console.log(ganttData);

  // useEffect(() => {
  //   localStorage.setItem('task', JSON.stringify(tasks));
  //   setLoading(true);
  //   setTimeout(() => {
  //     setganttData(JSON.parse(localStorage.getItem('task')));
  //   }, 5000);
  // }, []);

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskDelete = (task) => {
    console.log(1);
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  const [userActionPopup, setUserActionPopup] = useState(false);
  const showActionUserModal = (eve) => {
    console.log(1, eve);
    // return <div><ActionSpecificUserModal /></div>
  }
  const [taskDetail, setTaskDetail] = useState();
  const checkOnClick = (eve) => {
    setUserActionPopup(true);
    setTaskDetail(eve);
  }

  const navigate = useNavigate();
  const redirectToWorkflowElement = () => {
    navigate("/workflow-element/workflow-element");
  }

  const showTaskButton = () => {
    setLoading(false);
  }

  return (
    <>
      <div className='card mt-3 active-main-container'>
        <button className='btn togglrArrow' onClick={redirectToWorkflowElement} >
          <img src='/images/icons/Vector (Stroke).svg' />
        </button>
        <div className='card-header'>
          <div className='card-title text-primary'>Action history</div>
        </div>
        <div className='card-body'>
          <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
          />
          {userActionPopup ? <div className='user_action_popup'><ActionSpecificUserModal hideUserActionPopup={setUserActionPopup} taskDetail={taskDetail} /></div> : ""}
          <button onClick={showTaskButton}>Show Task</button>
          {/* {loading ?
            <Skeleton height={50} duration={1} count={3} /> : */}
            <Gantt
              tasks={tasks}
              viewMode={view}
              // onDateChange={handleTaskChange}
              // onDelete={handleTaskDelete}
              onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              // onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={isChecked ? "155px" : ""}
              columnWidth={columnWidth}
              onClick={checkOnClick}
            // TooltipContent={showActionUserModal}
            >
            </Gantt>
          {/* } */}
        </div>
      </div>
    </>
  );
}
export default GanttChart;