import Constant_GUI from "./GuiConstants";

const Gui_id_list = { 
  setupForms: {
    employee: {
        employee: `18${Constant_GUI.listPage}`,
        employee_main_create_button: `18${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
        employee_main_delete_button: `18${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
        employee_edit_screen: `18${Constant_GUI.editMode}`,
        employee_edit_screen_save_button: `18${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
        employee_create_screen_save_button: `18${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
        employee_create_screen: `18${Constant_GUI.createMode}`
    },
    banks: {
      bank: `8${Constant_GUI.listPage}`,  
      bank_main_create_button: `8${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      bank_main_delete_button: `8${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      bank_edit_screen: `8${Constant_GUI.editMode}`,
      bank_edit_screen_save_button: `8${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      bank_create_screen_save_button: `8${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      bank_create_screen: `8${Constant_GUI.createMode}`
    },
    bank_accounts: {
      bank_accounts: `30${Constant_GUI.listPage}`, 
      bank_accounts_main_create_button: `30${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      bank_accounts_main_delete_button:  `30${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      bank_accounts_edit_screen: `30${Constant_GUI.editMode}`,
      bank_accounts_edit_screen_save_button:`30${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      bank_accounts_create_screen_save_button: `30${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      bank_accounts_create_screen: `30${Constant_GUI.createMode}`
    },
    employee_position: {
      employee_position: `18${Constant_GUI.listPage}01`, 
      employee_position_main_create_button: `18${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}01`,
      employee_position_main_delete_button: `18${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}01`,
      employee_position_edit_screen: `18${Constant_GUI.editMode}01`,
      employee_position_edit_screen_save_button: `18${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}01`,
      employee_position_create_screen_save_button: `18${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}01`,
      employee_position_create_screen: `18${Constant_GUI.createMode}01`
    },
    payment_terms: {
      payment_terms: `31${Constant_GUI.listPage}`, 
      payment_terms_main_create_button: `31${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      payment_terms_main_delete_button:  `31${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      payment_terms_edit_screen: `31${Constant_GUI.editMode}`,
      payment_terms_edit_screen_save_button:`31${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      payment_terms_create_screen_save_button: `31${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      payment_terms_create_screen: `31${Constant_GUI.createMode}`
    },
    unit_organization: {
      unit_organization: `33${Constant_GUI.listPage}`, 
      unit_organization_main_create_button: `33${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      unit_organization_main_delete_button: `33${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      unit_organization_edit_screen: `33${Constant_GUI.editMode}`,
      unit_organization_edit_screen_save_button: `33${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`	,
      unit_organization_create_screen_save_button: `33${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      unit_organization_create_screen: `33${Constant_GUI.createMode}`
    },
    number_sequence: {
      number_sequence: `26${Constant_GUI.listPage}`, 
      number_sequence_main_create_button: `26${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      number_sequence_main_delete_button:  `26${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      number_sequence_edit_screen: `26${Constant_GUI.editMode}`,
      number_sequence_edit_screen_save_button: `26${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      number_sequence_create_screen_save_button: `26${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      number_sequence_create_screen: `26${Constant_GUI.createMode}`
    },
    fiscal_year: {
      fiscal_year: `29${Constant_GUI.simpleForm}`, 
      fiscal_year_create_button: `29${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}0`,
      fiscal_calendar_create_button: `29${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}1`,
      fiscal_year_update_button: `29${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.editButton}`,
      fiscal_delete_button: `29${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
    },
    email_template: {
      email_template: `49${Constant_GUI.listPage}`, 
      email_template_main_create_button: `49${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      email_template_main_delete_button:  `49${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      email_template_edit_screen: `49${Constant_GUI.editMode}`,
      email_template_edit_screen_save_button: `49${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      email_template_create_screen_save_button: `49${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      email_template_create_screen: `49${Constant_GUI.createMode}`
    } 
  },
  administrationForms: { 
    system_parameters: {
      system_parameters: `34${Constant_GUI.screen}`, 
      system_parameters_main_button: `34${Constant_GUI.screen}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`, 
    },
    users: {
      users: `25${Constant_GUI.listPage}`,
      users_main_create_button:`25${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      users_main_delete_button: `25${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      users_edit_screen:`25${Constant_GUI.editMode}`,
      users_edit_screen_save_button:`25${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      users_create_screen_save_button: `25${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      users_create_screen: `25${Constant_GUI.createMode}`
    },
  },
  external_screens: {
    ext_command_center: {
      ext_command_center_screen: `32${Constant_GUI.screen}`
    } 
  },
  procure:{
    purchase_orders: {
      purchase_orders: `12${Constant_GUI.listPage}`,
      purchase_orders_main_create_button:`12${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      purchase_orders_main_delete_button: `12${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      purchase_orders_edit_screen:`12${Constant_GUI.editMode}`,
      purchase_orders_edit_screen_save_button:`12${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      purchase_orders_create_screen_save_button: `12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      purchase_orders_create_screen: `12${Constant_GUI.createMode}`,
      control_forms: {
        mode_delivery: {  
          mode_delivery: `12${Constant_GUI.simpleForm}001`,
          mode_delivery_save_button: `12${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}001`,
          mode_delivery_create_button: `12${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}001`,
          mode_delivery_delete_button: `12${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}001`
        }, 
        delivery_term: { 
          delivery_term: `12${Constant_GUI.simpleForm}002`,
          delivery_term_save_button: `12${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}002`,
          delivery_term_create_button: `12${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}002`,
          delivery_term_delete_button: `12${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}002`
        }, 
        team_header: { 
          team_header: `12${Constant_GUI.listPage}003`,
          team_header_main_create_button:`12${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}003`,
          team_header_main_delete_button: `12${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}003`,
          team_header_edit_screen:`12${Constant_GUI.editMode}003`,
          team_header_edit_screen_save_button:`12${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}003`,
          team_header_create_screen_save_button: `12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}003`,
          team_header_create_screen: `12${Constant_GUI.createMode}003`
        }
      }
    },
    purchase_orders_listing: {
      purchase_orders_listing: `12${Constant_GUI.listPage}01`,
      purchase_orders_listing_main_create_button:`12${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}01`,
      purchase_orders_detail: `12${Constant_GUI.createMode}01`,
      purchase_orders_detail_create_button:`12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}01`,
      purchase_orders_detail_view_invoices_button:`12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.viewButton}01`,
      purchase_orders_detail_view_credit_note_button:`12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.viewButton}0101`,
      purchase_orders_detail_receiving_button:`12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.uniqueButton}01`,
      purchase_orders_detail_open_button:`12${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.openButton}01`,
    },
    procurement_settings: {
      procurement_settings: `50${Constant_GUI.listPage}`,
      procurement_settings_main_create_button:`50${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      procurement_settings_main_delete_button: `50${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      procurement_settings_edit_screen:`50${Constant_GUI.editMode}`,
      procurement_settings_edit_screen_save_button:`50${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      procurement_settings_create_screen_save_button: `50${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      procurement_settings_create_screen: `50${Constant_GUI.createMode}`
    }
  },
  formModules: {
    security_roles_forms: {
      security_roles_main_form: `39${Constant_GUI.simpleForm}`,
      security_roles_main_form_save_button: `39${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      security_roles_main_form_create_button: `39${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      security_roles_main_form_delete_button: `39${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    security_roles: {
      security_roles_main: `40${Constant_GUI.simpleForm}`,
      security_roles_main_save_button: `40${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      security_roles_main_create_button: `40${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      security_roles_main_delete_button: `40${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    priveleges: {
      previleges_main: `41${Constant_GUI.simpleForm}`,
      previleges_main_save_button: `41${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      previleges_main_create_button: `41${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      previleges_main_delete_button: `41${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    resources: {
      resources_main: `42${Constant_GUI.simpleForm}`,
      resources_main_save_button: `42${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      resources_main_create_button: `42${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      resources_main_delete_button: `42${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    form_resources: {
      form_resources_main: `43${Constant_GUI.simpleForm}`,
      form_resources_main_save_button: `43${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      form_resources_main_create_button: `43${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      form_resources_main_delete_button: `43${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    module: {
      module_main: `44${Constant_GUI.simpleForm}`,
      module_main_save_button: `44${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      module_main_create_button: `44${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      module_main_delete_button: `44${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    resources_access: {
      resources_access_main: `45${Constant_GUI.simpleForm}`,
      resources_access_main_save_button: `45${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      resources_access_main_create_button: `45${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      resources_access_main_delete_button: `45${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    previleges_assignment: {
      previleges_assignment_main: `46${Constant_GUI.simpleForm}`,
      previleges_assignment_main_save_button: `46${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      previleges_assignment_main_create_button: `46${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      previleges_assignment_main_delete_button: `46${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    language: {
      language_main: `37${Constant_GUI.simpleForm}`,
      language_main_save_button: `37${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      language_main_create_button: `37${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      language_main_delete_button: `37${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    labels_form: {
      labels_main: `38${Constant_GUI.simpleForm}`,
      labels_main_save_button: `38${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      labels_main_create_button: `38${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      labels_main_delete_button: `38${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    unit: {
      unit_main: `27${Constant_GUI.simpleForm}`,
      unit_main_save_button: `27${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      unit_main_create_button: `27${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      unit_main_delete_button: `27${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    unit_conversion: {
      unit_conversion_main: `28${Constant_GUI.simpleForm}`,
      unit_conversion_main_save_button: `28${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      unit_conversion_main_create_button:`28${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      unit_conversion_main_delete_button:`28${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    unit_organization_type: {
      unit_organization_type_main:`35${Constant_GUI.simpleForm}`,
      unit_organization_type_main_save_button: `35${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      unit_organization_type_main_create_button: `35${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      unit_organization_type_main_delete_button:`35${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    currency: {
      currency_main: `36${Constant_GUI.simpleForm}`,
      currency_main_save_button: `36${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      currency_main_create_button:`36${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      currency_main_delete_button: `36${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    },
    currency_conversion: {
      currency_conversion_main: `47${Constant_GUI.simpleForm}`,
      currency_conversion_main_save_button: `47${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      currency_conversion_main_create_button: `47${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      currency_conversion_main_delete_button: `47${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      currency_conversion_rates_main_create_button: `48${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      currency_conversion_rates_main_delete_button: `48${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`
    } 
  },
  masterData: {
    vendors: {
      vendors: `6${Constant_GUI.listPage}`,
      vendors_main_create_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
      vendors_main_delete_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
      vendors_edit_screen: `6${Constant_GUI.editMode}`,
      vendors_edit_screen_save_button: `6${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      vendors_create_screen_save_button: `6${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
      vendors_create_screen: `6${Constant_GUI.createMode}`
    },
    vendor_group: {
      vendors_group: `6${Constant_GUI.listPage}01`,
      vendors_group_main_create_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}01`,
      vendors_group_main_delete_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}01`,
      vendors_group_edit_screen: `6${Constant_GUI.editMode}01`,
      vendors_group_edit_screen_save_button: `6${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}01`,
      vendors_group_create_screen_save_button: `6${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}01`,
      vendors_group_create_screen: `6${Constant_GUI.createMode}01`
    },
    vendor_reason: {
      vendors_reason: `6${Constant_GUI.listPage}02`,
      vendors_reason_main_create_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}02`,
      vendors_reason_main_delete_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}02`,
      vendors_reason_edit_screen: `6${Constant_GUI.editMode}02`,
      vendors_reason_edit_screen_save_button: `6${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}02`,
      vendors_reason_create_screen_save_button: `6${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}02`,
      vendors_reason_create_screen: `6${Constant_GUI.createMode}02`
    },
    vendor_business_classification: {
      vendors_business_classification: `6${Constant_GUI.listPage}03`,
      vendors_business_classification_main_create_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}03`,
      vendors_business_classification_main_delete_button: `6${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}03`,
      vendors_business_classification_edit_screen:  `6${Constant_GUI.editMode}03`,
      vendors_business_classification_edit_screen_save_button: `6${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}03`,
      vendors_business_classification_create_screen_save_button: `6${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}03`,
      vendors_business_classification_create_screen: `6${Constant_GUI.createMode}03`
    },
    item_master_modules: {
      item: {
        item: `24${Constant_GUI.listPage}`,
        item_main_create_button:`24${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}`,
        item_main_delete_button: `24${Constant_GUI.listPage}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}`,
        item_edit_screen: `24${Constant_GUI.editMode}`,
        item_edit_screen_save_button:`24${Constant_GUI.editMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
        item_create_screen_save_button:`24${Constant_GUI.createMode}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}`,
        item_create_screen: `24${Constant_GUI.createMode}`
      },
      dimension: {
        dimensions_main: `24${Constant_GUI.simpleForm}01`,
        dimensions_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}01`,
        dimensions_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}01`,
        dimensions_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}01`
      },
      color: {
        color_main: `24${Constant_GUI.simpleForm}02`,
        color_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}02`,
        color_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}02`,
        color_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}02`
      },
      size: {
        size_main: `24${Constant_GUI.simpleForm}03`,
        size_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}03`,
        size_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}03`,
        size_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}03`
      },
      style: {
        style_main: `24${Constant_GUI.simpleForm}04`,
        style_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}04`,
        style_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}04`,
        style_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}04`
      },
      item_group: {
        item_group_main: `24${Constant_GUI.simpleForm}05`,
        item_group_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}05`,
        item_group_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}05`,
        item_group_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}05`
      },
      item_brand: {
        item_brand_main: `24${Constant_GUI.simpleForm}06`,
        item_brand_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}06`,
        item_brand_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}06`,
        item_brand_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}06`
      },
      configuration: {
        configuration_main: `24${Constant_GUI.simpleForm}07`,
        configuration_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}07`,
        configuration_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}07`,
        configuration_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}07`
      },
      item_buyer_group: {
        item_buyer_group_main: `24${Constant_GUI.simpleForm}08`,
        item_buyer_group_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}08`,
        item_buyer_group_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}08`,
        item_buyer_group_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}08`
      },
      item_group_account: {
        item_group_account_main: `24${Constant_GUI.simpleForm}09`,
        item_group_account_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}09`,
        item_group_account_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}09`,
        item_group_account_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}09`
      },
      item_group_account_type: {
        item_group_account_type_main: `24${Constant_GUI.simpleForm}10`,
        item_group_account_type_main_save_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.saveButton}10`,
        item_group_account_type_main_create_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.createButton}10`,
        item_group_account_type_main_delete_button: `24${Constant_GUI.simpleForm}${Constant_GUI.buttonResourceId}${Constant_GUI.deleteButton}10`
      } 
    }
  }
}

export default Gui_id_list;

