import React, {useState, useEffect, Fragment} from 'react'
import '../Numbers.css' 
import { connect } from 'react-redux'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import MasterComonent from '../../Backend/MasterComonent'
import CollapsedAddress from '../../Edit Address/Collapsed Address/CollapsedAddress'
import LinkBtn from '../../inc/LinkBtn'
import Checkbox from '../../Forms/Checkbox'
import Helper from '../../../inc/Helper'
import $ from 'jquery'
import NavigationHeder from '../../Navigations/NavigationHeder'
import AgGrid from '../../grid/ag/ag-grid'
import Button from '../../inc/Button'
import Api from '../../../inc/Api' 
import Settings from '../../../inc/Settings' 
import DropDownInput from '../../Forms/Dropdown'
import Collapse from '../../inc/Collapse'
import { useNavigate } from 'react-router-dom'
import SimpleLoading from '../../Loading/SimpleLoading'
import FormValidator from '../../../inc/FormValidator'
import Input from '../../Forms/Input'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../inc/Gui_id_list'
import Alert from '../../inc/Alert'

const NewNumber = (props) => { 
  const [segmentList, setSegmentList] = useState([])
  const [scopeList, setScopeList] = useState([])
  const [entityList, setEntityList] = useState([])
  const [disableManual, setDisableManual] = useState(false)
  const [disableContinuous, setDisableContinuous] = useState(false)
  const [displayFormat, setDisplayFormat] = useState('')
  const navigate = useNavigate() 
  const [loading, setLoading] = useState(false)
  const [entitesLoading, setEntityLoading] = useState(true)
  const [defaultCols, setDefaultCols] = useState(false)
  const [gridObject, setGridObject] = useState(null)
  const [segments, setSegments] = useState({
    number_sequence_code: '',
    name: null,
    scope: null,
    entity_id: null,
    in_use: null,
    continuous: null,
    manual: null,
    sequence_format: null,
    min_value: null,
    max_value: null,
    next_value: null,
    segments: []
  })
  const [gridLabels, setGridLabels] = useState({
    Segment: null,
    Value: null
  }) 
//API Reduction Calls




let seqTypeCalled = false
let scopeListCalled = false
let entityListCalled = false

//End


  useEffect(() => { 
    get_sequence_type()
    get_scope_list_data ()
    get_entites_list_data()
    StateInitializer()
  }, []) 

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
        {name:'name', displayName:Helper.getLabel(props.language,'name','Name'),types:['Required'] }, 
        {name:'scope', displayName:Helper.getLabel(props.language,'scope','Scope'),types:['Required']}, 
        {name:'number_sequence_code', displayName:Helper.getLabel(props.language,'code','code'),types:['Required'], max:10},
        {name:'min_value', displayName:Helper.getLabel(props.language,'small','Smallest'),types:['Required', 'Number']},
        {name:'max_value', displayName:Helper.getLabel(props.language,'max','Max'),types:['Required', 'Number']},
        {name:'next_value', displayName:Helper.getLabel(props.language,'code','Next'),types:['Required', 'Number']}
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }
  validationConfigure()

  let gridLoaded = false

  const StateInitializer = () => {
    if(gridLoaded){
      return
    }
    gridLoaded = true
    setSegments({
      ...segments,
      segments: [{
        "segment_type": 'Company',
        "segment_value": 'Tenant',
        "segment_length": 6
      },
      {
        "segment_type": "Alphanumeric",
        "segment_value": "####",
        "segment_length": 4
      },
      {
        "segment_type": "Number",
        "segment_value": "021",
        "segment_length": 3
      }]
    })
    // setSegments((prevState) => {
      // console.log(prevState)
      // let AllData = prevState
      // AllData.segments = 
    //   return prevState
    // })
  }

  const get_scope_list_data = () => {
    if(scopeListCalled) {
      return;
    }
    let api = Api
    scopeListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/numbersequenceScopeType',{}).then(function(res){
      if(res.data.status==="success"){
        let Scope_List_API = []
       res.data.data.forEach(item => {  
        Scope_List_API.push({
          value: item.id,
          label: item.name
        })
       })
       setScopeList(Scope_List_API)
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }
  
  const get_entites_list_data = () => {
    if(entityListCalled) {
      return
    }
    let api = Api
    entityListCalled = true
    api.setUserToken()
    api.axios().post(Settings.apiUrl+'/search_entity',{ 
        "code":"",
        "name":"" 
    }).then(function(res){
      if(res.data.status==="success"){
        let Entity_List_API = []
       res.data.data.forEach(item => {  
        Entity_List_API.push({
          value: item.entity_id,
          label: item.name
        })
       })
       setEntityLoading(false)
       setEntityList(Entity_List_API)
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  
  const onSaveClick = (rowItem) => { 
    let updatedData = []
    let newData=[];
    rowItem.forEach(item => {
        if( !item.segment_order || item.segment_order <=0){
          newData.push(item);
        }else{
          updatedData.push(item);
        }
    });
    if(newData.length>=1){ 
        addNewData(newData);
    }
    if(updatedData.length>=1){ 
        updateData(updatedData);
    }
  } 
  
  const updateData = (data) =>{  
   console.log(data)
  }
   
  const addNewData = (data) => { 
    data.forEach(element => {
      segments.segments.push({
        "segment_length": element.segment_length,
        "segment_type": element.segment_type,
        "segment_value": element.segment_value
      })
    })
  }
  
  const get_sequence_type = () => {
    if(seqTypeCalled){
      return;
    }
    let api = Api
    seqTypeCalled =true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/numbersequenceSegmentType',{}).then(function(res){
      if(res.data.status==='success'){   
        let Sequence_Types_List_API = []
        res.data.data.forEach(item => {  
          Sequence_Types_List_API.push({
           value: item.id,
           label: item.name
         })
        }) 
        setSegmentList(Sequence_Types_List_API)
      } 
  }).catch((res) => { 
    Helper.alert(res.response.data.message, 'error')
  })
  }

  const CodeChangeHandler = (event) => {
    let Code = event.target.value
      setSegments((prevState) => {
        return {...prevState, number_sequence_code: Code}
    })
  }

  const NameChangeHandler = (event) => {
    let Name = event.target.value
      setSegments((prevState) => {
        return {...prevState, name: Name}
    })
  }

  const ScopeChangeHandler = (event) => {
    let Scope = event.target.value
    let ScopeLabel = event.label
      setSegments((prevState) => {
        return {...prevState, scope: Scope}
    }) 
    setGridLabels((prevState) => {
      return {...prevState, Segment: ScopeLabel}
    })
    setDefaultCols(true)
  }

  const InUseChangeHandler = (name, value) => {
    if(value == "on") {
      setSegments((prevState) => {
        return {...prevState, in_use: true}
      })
    }else {
      setSegments((prevState) => {
        return {...prevState, in_use: false}
      })
    }
  }

  const ContinousChangeHandler = (name, value) => { 
    if(value == "on"){
      setSegments((prevState) => {
        return {...prevState, continuous: true}
      })
      setDisableManual(true)
    }else {
      setSegments((prevState) => {
        return {...prevState, continuous: false}
      })
      setDisableManual(false)
    }
  }

  const ManualChangeHandler = (name, value) => {
    if(value == "on"){
      setSegments((prevState) => {
        return {...prevState, manual: true}
      })
      setDisableContinuous(true)
    }else {
      setSegments((prevState) => {
        return {...prevState, manual: false}
      })
      setDisableContinuous(false)
    }

  }

  const SmallestChangeHandler = (event) => {
    let Smallest = event.target.value 
      setSegments((prevState) => {
        return {...prevState, min_value: Smallest}
      }) 
  }

  const LargestChangeHandler = (event) => {
    let Largest = event.target.value
    setSegments((prevState) => {
      return {...prevState, max_value: Largest}
    })
  }

  const NextChangeHandler = (event) => {
    let Next = event.target.value
    setSegments((prevState) => {
      return {...prevState, next_value: Next}
    })
  } 

  const EntityChangeHandler = (event) => {
    let Entity = event.target.value
    let EntityLabel = event.label
      setSegments((prevState) => {
        return {...prevState, entity_id: Entity }
    }) 
    setGridLabels((prevState) => {
      return {...prevState, Value: EntityLabel}
    })
  }


  const onGridReady = (grid) => {
    setGridObject(grid)
  }

const getConData = () => {
    if(!gridObject){
        return '';
    }
    let result = '';
    gridObject.api.forEachNode( gridItem => {
        result += ( gridItem.data.segment_value ? gridItem.data.segment_value + '-' : '' )  
    }) 
    return result;
}

  const onCellValue = (event) => {
     event.node.setDataValue("segment_length",event.data.segment_value.length) 
   setDisplayFormat(getConData())
  }

  const SaveHandler = () => { 
    let validData = {
      ...segments
    }
    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
  }
    $('.rs_new_number_sequence .rs_grid_btn_save').trigger('click'); 
    setLoading(true)
    let api = Api
    api.setUserToken()  
      api.axios().post(Settings.apiUrl+'/numbersequence',{
        "number_sequence_code": segments.number_sequence_code !== "" ? segments.number_sequence_code: null,
        "name": segments.name !== "" ? segments.name : null,
        "scope": document.getElementById('scope_id') ? document.getElementById('scope_id').value : null,
        "entity_id":document.getElementById('scope_entity_id') ? document.getElementById('scope_entity_id').value : null,
        "segments":segments.segments,
        "in_use": segments.in_use ? segments.in_use : false,
        "continuous": segments.continuous ? segments.continuous : false,
        "manual": segments.manual ? segments.manual : false,
        "sequence_format": displayFormat !== '' ? displayFormat : null,
        "min_value": parseInt(segments.min_value),
        "max_value": parseInt(segments.max_value),
        "next_value": parseInt(segments.next_value)
    }).then(function(res){
        if(res.data.status==="success"){
          Helper.alert(res.data.message)
          setSegments({
            number_sequence_code: '',
            name: '',
            scope: null,
            entity_id: null,
            in_use: null,
            continuous: null,
            manual: null,
            sequence_format: null,
            min_value: '',
            max_value: '',
            next_value: ''
          })
          setLoading(false)
          navigate('/number-sequence')
        }
    }).catch((res) => { 
      setLoading(false)
      Helper.alert(res.response.data.message, 'error')
    })  
  }
  
  const Columns_Headings = [
    {field: 'segment_type', headerName:'Segment', inputType: "dropdown", options:segmentList},
    {field: 'segment_value', headerName:'Value'},
    {field: 'segment_length', headerName:'Length', editable: false}
  ]
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.number_sequence.number_sequence_create_screen)){
    return <Fragment>
    <Alert message='NO ACCESS' type='danger' />
    </Fragment>
  }
  


  return (
    <>
    <div className='new_numbers_sequence'>
      <div className='container-fluid'>
        <NavigationHeder title='New Number Sequence'>
        <LinkBtn isActive= {false} to="/number-sequence" title={Helper.getLabel(props.language,'cancel','Cancel')} />
        <Button isDisable={!security.canCreate(frontendIds.setupForms.number_sequence.number_sequence_create_screen_save_button)} isActive= {true} title={Helper.getLabel(props.language,'save','Save')} onClick={SaveHandler} icon={'/images/icons/save-white.svg'} />
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : ''}
        <Collapse open={true} title={Helper.getLabel(props.language,'number_seq-001','Identification')}>
        <div className='container-fluid'>
        <div className='row'>
     
         <div className='col-12'>
         <div className='row gy-0 mt-2 mb-2'>
     
         <div className='col-lg-2 col-md-2'> 
         <Input label={Helper.getLabel(props.language,'code_NS_ADMIN','Code')} type='text' onChange={CodeChangeHandler} value={segments.number_sequence_code} required={true} placeholder='Enter Code'  />
         </div>
 
         <div className='col-lg-3 col-md-3'> 
         <Input type='text' label={Helper.getLabel(props.language,'name_NS_ADMIN','Name')} onChange={NameChangeHandler} value={segments.name} required={true}  placeholder='Enter Name'  />
         </div>

         </div>
         </div>
         </div>
        </div> 

        </Collapse>

        <Collapse open={true} title={Helper.getLabel(props.language,'number_seq-002','Scope Parameters')}>
          <div className='container-fluid'>
        <div className='row'>
     
         <div className='col-12'>
         <div className='row gy-0 mt-2 mb-2'>
     
         <div className='col-lg-3 col-md-3'> 
         <DropDownInput label={Helper.getLabel(props.language,'scope_NS_ADMIN','Scope')} onChange={ScopeChangeHandler} required={true}  id='scope_id' options={scopeList} value={segments.scope} /> 
         </div>
     
         <div className='col-lg-3 col-md-3'>
         { document.getElementById('scope_id') && <React.Fragment>
          {document.getElementById('scope_id').value == 1 ? <React.Fragment> 
            { entitesLoading ?
              <SimpleLoading />
           :  <React.Fragment>
           <DropDownInput label={Helper.getLabel(props.language,'entity_NS_ADMIN','Entity')} onChange={EntityChangeHandler} id='scope_entity_id' options={entityList} value={segments.entity_id} />
           </React.Fragment>
          }
          </React.Fragment>
          : null
        }   
       </React.Fragment>} 

          </div>

         </div>
         </div>
         </div>
        </div> 
        </Collapse>

        <Collapse open={true} title={Helper.getLabel(props.language,'number_seq-003','Segments')}>
        <div className='rs_new_number_sequence'>
          <AgGrid id="rs_new_number_sequence" onCellValueChanged = {onCellValue} onGridReady={ onGridReady} onSave={onSaveClick} hideSave={false} hideDelete={false} header={Columns_Headings} data={segments.segments} />
        </div>
        </Collapse>

        <Collapse open={true} title={Helper.getLabel(props.language,11217,'General')}>
          <div className='container-fluid'>

            <div className='row mb-4 ps-3'>
              <div className='col-lg-3 col-md-3'> 
                <Input label={Helper.getLabel(props.language,'format_NS_ADMIN','Format')} type='text' className='format' disable={true} value={displayFormat} /> 
              </div>  
            </div> 
 
            <div className='row p-0 mb-4'>
                <div className='col-lg-2 col-md-2'>
                  <Checkbox type='checkbox' onChange={InUseChangeHandler} isChecked={segments.in_use} label={Helper.getLabel(props.language,'in-use_NS_ADMIN','In Use')} />
                </div>
                <div className='col-lg-2 col-md-2'>
                  <Checkbox type='checkbox' onChange={ContinousChangeHandler} isChecked={segments.continuous} disable={disableContinuous} label={Helper.getLabel(props.language,'continues_NS_ADMIN','Continues')} /> 
                </div>
                <div className='col-lg-2 col-md-2'>
                  <Checkbox type='checkbox' onChange={ManualChangeHandler} isChecked={segments.manual} disable={disableManual} label={Helper.getLabel(props.language,'manual_NS_ADMIN','Manual')} required={true} />
                </div>
            </div> 

            <div className='row mb-4 ps-3'>
            <div className='col-lg-3 col-md-3'> 
              <Input required={true} label={Helper.getLabel(props.language,'smallest_NS_ADMIN','Smallest')} type='number' onChange={SmallestChangeHandler} value={segments.min_value} placeholder='-99' />
            </div>  

            
            <div className='col-lg-3 col-md-3'> 
              <Input required={true} label={Helper.getLabel(props.language,'largest_NS_ADMIN','Largest')} type='number' onChange={LargestChangeHandler} value={segments.max_value} placeholder='11000' />
            </div>  

            
            <div className='col-lg-3 col-md-3'> 
              <Input type='number' label={Helper.getLabel(props.language,'next_NS_ADMIN','Next')} onChange={NextChangeHandler} value={segments.next_value} placeholder='1'/>
            </div>  
          </div> 

          </div>
        </Collapse>
      </div>
    </div>
    </>
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.number_sequence.number_sequence_create_screen
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(NewNumber, SecurityOptions))))