import React, { Component } from "react";
import { Alert, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import ModernPagination from "../common/ModernPagination";
import ShoppingItemCard from "../common/ShoppingItemCard";
import AddCategoryFormModal from "../modals/AddCategoryFormModal.js";
import SearchFilters from "./SearchFilters";
import ShopSearchbar from "../common/ShopSearchbar";
import SortSelect from "./SortSelect";

// temp data
const sortOptions = [
  {
    key: "featured",
    option: "Featured",
  },
  {
    key: "price_low_to_high",
    option: "Price: Low to High",
  },
  {
    key: "price_high_to_low",
    option: "Price: High to Low",
  },
  {
    key: "average_customer_reviews",
    option: "Average Customer Reviews",
  },
];
const departmentFilters = {
  label: "Department",
  key: "department",
  options: [
    {
      label: "Smart Phones & Mobiles",
      key: "smart_phones_&_mobiles",
      options: [{ label: "Smart Phones" }, { label: "Basic Mobiles" }],
    },
  ],
};
const brandFilters = {
  label: "Brand",
  key: "brand",
  options: [
    {
      label: "Samsung",
      key: "samsung",
    },
    {
      label: "Realme",
      key: "realme",
    },
    {
      label: "Vivo",
      key: "vivo",
    },
    {
      label: "Oppo",
      key: "oppo",
    },
    {
      label: "Apple",
      key: "apple",
    },
    {
      label: "Xiaomi",
      key: "xiaomi",
    },
    {
      label: "Redmi",
      key: "redmi",
    },
    {
      label: "Sony",
      key: "sony",
    },
  ],
};

class ShopResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      shopItems: [],
      resultCount: 0,
      pageSize: 16,
      pageNumber: 1,
      showAddCategoryForm: false,
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.incrementCartCount = this.incrementCartCount.bind(this);
    this.decrementCartCount = this.decrementCartCount.bind(this);
    this.incrementWishlistCount = this.incrementWishlistCount.bind(this);
    this.decrementWishlistCount = this.decrementWishlistCount.bind(this);
    this.handleShowAddCategoryForm = this.handleShowAddCategoryForm.bind(this);
    this.handleCloseAddCategoryForm =
      this.handleCloseAddCategoryForm.bind(this);
  }

  componentDidMount() {
    this.initialSearch();
    console.log(this.props);
  }

  initialSearch() {
    let data = {
      filter: {
        price: {
          start_price: 0,
          end_price: null,
        },
      },
    };
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isLoading: true });
    api
      .axios()
      .post(
        Settings.apiItemUrl +
          `/get_items_ecommerce/${this.state.pageNumber}/${this.state.pageSize}`,
        data
      )
      .then(function (res) {
        console.log(res);
        if (res.data.status === "success") {
          that.setState({
            isLoading: false,
            shopItems: [...res.data.data],
            resultCount: [...res.data.data].length,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        that.setState({ isLoading: true });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  handlePageChange(pageNumber) {
    this.setState({ pageNumber });
  }

  handleCloseAddCategoryForm() {
    console.log("close");
    this.setState({ showAddCategoryForm: false });
  }

  handleShowAddCategoryForm() {
    console.log("open");
    this.setState({ showAddCategoryForm: true });
  }

  incrementCartCount() {
    this.props.changeCartCount(this.props.cartCount + 1);
  }

  decrementCartCount() {
    this.props.changeCartCount(
      this.props.cartCount >= 1
        ? this.props.cartCount - 1
        : this.props.cartCount
    );
  }

  incrementWishlistCount() {
    this.props.changeWishlistCount(this.props.wishlistCount + 1);
  }

  decrementWishlistCount() {
    this.props.changeWishlistCount(
      this.props.wishlistCount >= 1
        ? this.props.wishlistCount - 1
        : this.props.wishlistCount
    );
  }

  render() {
    return (
      <>
        <ShopSearchbar />
        <div className="pr-search-additional">
          <div className="pr-search-additional-count"></div>
          <SortSelect sortOptions={sortOptions} />
        </div>
        {this.state.isLoading ? (
          <SimpleLoading />
        ) : (
          <div className="pr-shop">
            <div className="pr-filters">
              <SearchFilters returnPriceFilters={true} />
            </div>
            <div className="pr-shop-main">
              <Alert className="pr-add-category-alert">
                {Helper.getLabel(
                  this.props.language,
                  "want_to_add_category_as_pr",
                  "Want to add category as part of purchase requisition?"
                )}
                <Button
                  variant="outline-primary"
                  onClick={this.handleShowAddCategoryForm}
                >
                  Add
                </Button>
              </Alert>
              <div className="pr-shop-main-itemgrid">
                {this.state.shopItems?.map((item) => (
                  <ShoppingItemCard
                    key={item.item_id}
                    item={item}
                    showWishlistIcon={true}
                    wishlistIds={this.props.wishlistIds}
                    incrementCartCount={this.incrementCartCount}
                    incrementWishlistCount={this.incrementWishlistCount}
                    decrementWishlistCount={this.decrementWishlistCount}
                  />
                ))}
              </div>
              <div className="modern-pagination-wrapper">
                {this.state.resultCount > 16 ? (
                  <ModernPagination
                    currentPage={this.state.pageNumber}
                    totalPages={Math.ceil(this.state.resultCount)}
                    onPageChange={this.handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}
        <AddCategoryFormModal
          incrementCartCount={this.incrementCartCount}
          visibility={this.state.showAddCategoryForm}
          hide={this.handleCloseAddCategoryForm}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopResults);
