import React, { Component } from 'react';
import RsDataLoader from '../../inc/RsDataLoader';

class NoteExportable extends Component {
    constructor(props){
        super(props);
        this.state ={
            noteText:''
        }
    }
    onChangeNote(event){
        this.props.dataLoaderPropsMethods.setNotes(event.target.value)
    }
    render() {
        let notes = this.props.dataLoaderProps.notes;
        return (
            <div className='notes_widgets'>
                <div className='note_form'>
                    <textarea value={notes.notes ? notes.notes : '' } style={{paddingTop:"5px"}}  onChange={e=>this.onChangeNote(e) } placeholder='Write a new note'/>
                </div>
            </div>
        );
    }
}

export default  RsDataLoader(NoteExportable,{});