import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Settings from '../../inc/Settings';
import AuthCheck from './AuthCheck';
import LoginForm from './Login/LoginForm';
import RegisterForm from './Register/RegisterForm';
class LoginRegisterTab extends Component {
    render() {
        return (
            <div className='login_regiser_box'>
                <AuthCheck noAccessForLogin={"/"+Settings.redirectUrlAfterLogin}/>
                <div className='lr_box_inner'>
                    <div className='log_reg_th'>
                        <Link to="/login" className={ this.props.tab === "login" ? 'active' : '' } >Login </Link>
                        <Link to="/register" className={ this.props.tab === "register" ? 'active' : '' }> Register</Link>
                    </div>
                    { this.props.tab === "login" ?  <LoginForm/> : <RegisterForm/> }
                </div>
            </div>
        );
    }
}

export default LoginRegisterTab;