import React, {Fragment, useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComonent from '../../Backend/MasterComonent'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'
import LinkBtn from '../../inc/LinkBtn'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import {useDispatch} from 'react-redux'
import { gridIdActions } from '../../../store/grid-id'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'

const VendorsArchive = (props) => {
    const [vendors, setVendors] = useState([])
    const [vendorType, setVendorType] = useState([])
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    let vendorCalled = false
    let vendorTypesCalled = false

    const navigate = useNavigate()
    
    const resetCentralState = () => { 
      dispatch(gridIdActions.VendorID({payload: undefined}))  
    }
 
    useEffect(() => {
      resetCentralState()
      get_vendors_data()
      get_vendors_types_data()
    }, [])

    const CellHandler = (event) => {   
        if(event.colDef.field === 'code') { 
          navigate(`/edit-vendor/${event.data.vendor_id}`)
        }
    }

    const get_vendors_data = () => {
      if(vendorCalled){
        return;
      }
      let api = Api
      vendorCalled = true;
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+'/vendor',{}).then(function(res){
        if(res.data.status==="success"){  
          setVendors(res.data.data.reverse())
          setLoading(false)
        }
      }).catch((res) => {
        Helper.alert(res.response.data.message, 'error') 
        setLoading(false)
      })
    }

    const get_vendors_types_data = () => {
        if(vendorTypesCalled){
          return;
        }
        let api = Api
        vendorTypesCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/enum/vendortype',{}).then(function(res){
          if(res.data.status==="success"){  
            let Vendor_List_API = []
            res.data.data.forEach(item => {  
            Vendor_List_API.push({
               value: item.id,
               label: item.name
             })
            })
            setVendorType(Vendor_List_API) 
          }
        })
      }

    const DeleteHandler = (allRows) => {
      let api = Api
      api.setUserToken() 
      allRows.forEach(element => {
        api.axios().delete(Settings.apiVendorUrl+`/vendor/${element.vendor_id}`,{}).then(function(res){
            if(res.data.status==="success"){
              get_vendors_data()
              Helper.alert(res.data.message)
            }
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
          get_vendors_data()
        })
    })
    }
  
    const gridLinkValueFormat = (params) => {
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
    }

    const Columns_Headings = [
        {field:'code', headerName:Helper.getLabel(props.language,'code',"Code"), editable: false, cellRenderer: CustomHtmlCell, valueFormatter: gridLinkValueFormat.bind(this)},
        {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), editable: false},
        {field:'vendor_type', headerName:Helper.getLabel(props.language,'vendor_type',"Vendor Type"), options: vendorType, inputType: "dropdown", editable: false}, 
        {field:'category', headerName:Helper.getLabel(props.language,'category',"Category"), editable: false}
    ]

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.masterData.vendors.vendors)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }
  return ( 
    <div className='vendors_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/master-data' title={Helper.getLabel(props.language,'vendors',"Vendors")}>
        <ButtonNew to="/create-vendor" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.masterData.vendors.vendors_main_create_button)} /> 
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : null}
        <AgGrid id="rs_vendors_list" hideDelete={!security.canDelete(!security.canCreate(frontendIds.masterData.vendors.vendors_main_delete_button))} onCellClicked={CellHandler} onDelete={DeleteHandler} header={Columns_Headings} data={vendors} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div> 
  )
}
  
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.masterData.vendors.vendors
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(VendorsArchive, SecurityOptions))))