import React from 'react'

const RateWidget = () => {
  return (
    <section className='rate_user'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 p-0 pt-3'>
            <h3>Rate Card</h3>
          </div> 
        </div>
      </div>
    </section>
  )
}

export default RateWidget