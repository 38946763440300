import React from 'react'
import './MasterHeader.css'
import {connect} from 'react-redux'; 
import Helper from '../../inc/Helper';
import PlusIcon from '../../assets/plus.svg'
import SearchIcon from '../../assets/search.svg'
/**
 * simpleSave : false 
 * dissble2StepSave: false
 * onSaveClick : function
 * onAddClick: function
 * onSearchClick : function
 * on
 */
class MasterHeader extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      mode:'find',
      isAddClick:false
    }
  }
  onSaveClick(e){
    if(this.props.onSaveClick && (typeof this.props.onSaveClick === 'function') ){
      this.props.onSaveClick()
    }
  }
  onAddClick(e){
    this.setState({
      mode:"add",
      isAddClick:true
    })
    if(this.props.onAddClick && (typeof this.props.onAddClick === 'function') ){
      this.props.onAddClick()
    }
  }
  onSearchClick(e){
    this.setState({
      mode:"find",
      isAddClick:false
    })
    if(this.props.onSearchClick && (typeof this.props.onSearchClick === 'function') ){
      this.props.onSearchClick()
    }
  }
  
  isSaveBtnNeed(){
    return (this.state.mode === "add" ) && this.state.isAddClick ? true : false;
  }
  simpleSave(){
    if(!this.props.simpleSave){
      return <></>
    }
    const language = this.props.language;
    return <button type="button" className='tenant-btns-search justify-content-center  ms-2 me-2' onClick={ e=>this.onSaveClick(e)} >{Helper.getLabel(language,'save','Save')}</button> 
  }
  secondStepSaveBtn(){
    if(this.props.dissble2StepSave){
      return <></>
    }
    const language = this.props.language;
    return ( 
      this.isSaveBtnNeed() ? 
      <button type="button" className='tenant-btns-search justify-content-center  ms-2 me-2' onClick={ e=>this.onSaveClick(e)} >{Helper.getLabel(language,'save','Save')}</button> 
      :
      <button className='tenant-btns-create ms-2 me-2'  onClick={ e=>this.onAddClick(e)}><img src={PlusIcon} alt='Plus' className='img-fluid' /> {Helper.getLabel(language,11221,'Add')}</button>
    ) 
  }
  render() {
    const Title = this.props.Title;
    const language = this.props.language;
    return (
      <section className='tenant-header-section mb-0 border-bottom'>
        <div className='container'>
          <div className='row d-flex'>
            <div className='col-12'>
              <div className='tenant-header d-flex align-items-center justify-content-between'>
                <div className='tenant-title mt-1'>
                  <h1>{Title}</h1>
                </div>
                <div className='d-flex justify-content-center'>
                  { this.simpleSave() }
                  {
                    this.secondStepSaveBtn()
                  }
                  <button type="button" className='tenant-btns-search'  onClick={ e=>this.onSearchClick(e)}><img src={SearchIcon} alt='Search' className='img-fluid ms-1 me-1' /> {Helper.getLabel(language,11237,'Search')}</button>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
 
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      language:state.language
  }
}
 
export default connect (mapStateToProps) (MasterHeader);
