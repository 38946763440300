import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './ExtPO.css'
import View from './view.svg'
import DropDownInput from '../../Forms/Dropdown'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComonent from '../../Backend/MasterComonent'
import AgGrid from '../../grid/ag/ag-grid'
import CustomHtmlCell from '../../grid/ag/cellRender/CustomHtmlCell'
import Alert from '../../inc/Alert'
import LinkBtn from '../../inc/LinkBtn'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import ExtHeaderLine from '../../Header/ExtHeaderLine'
import ButtonNew from '../../inc/ButtonNew'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'

const PurchaseOrderListing = (props) => {

    const [purchaseOrdersListing, setPurchaseOrdersListing] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
      get_purchase_orders_data()
    }, [])

    const CellHandler = (event) => {   
        if(event.colDef.field === 'purchase_order') { 
          navigate(`/edit-purchase-order/${event.data.id}`)
        }
    }

    const get_purchase_orders_data = () => {
      let api = Api
      api.setUserToken()
      api.axios().get(Settings.apiOrgUrl+'/',{}).then(function(res){
        if(res.data.status==="success"){  
          setPurchaseOrdersListing(res.data.data.reverse())
          setLoading(false)
        }
      }).catch((res) => {
        Helper.alert(res.response.data.message, 'error')
        setLoading(false)
      })
    }

    // const DeleteHandler = (allRows) => {
    //   let api = Api
    //   api.setUserToken() 
    //   allRows.forEach(element => {
    //     api.axios().delete(Settings.apiOrgUrl+`/`,{}).then(function(res){
    //         if(res.data.status==="success"){
    //           get_purchase_orders_data()
    //           Helper.alert(res.data.message)
    //         }
    //     }).catch((res) => { 
    //       Helper.alert(res.response.data.message, 'error')
    //       get_purchase_orders_data()
    //     })
    // })
    // }
 
    const FilterDropdown = () => {
        return (
            <div className='grid_btns_ext_po row'>
                <DropDownInput className='' options={[]} label={Helper.getLabel(props.language,'purchase_order',"Purchase Order")} id='purchase_order_type_id' />
            </div>
            )
    }

    const displayViewLogo = () => { 
        return `<div class="logo_inside_grid"><img src="${View}" style="height:40px;" /></div>`;
    }
 
    const statusValueFormat = (params) => {
        if(params.value === true){
            return `<span class="rs_active_label_invoice">Invoiced</span>`;
        }
        return `<span class="rs_inactive_label_invoice">Not Invoiced</span>`;
    }


    const Columns_Headings = [
      {field:'purchase_order', headerName:Helper.getLabel(props.language,'purchase_order',"Purchase Order"), editable: false},
      {field:'document', headerName:Helper.getLabel(props.language,'document_title',"Document Title"), editable: false},
      {field:'category', headerName:Helper.getLabel(props.language,'category',"Category"), editable: false}, 
      {field:'start_date', headerName:Helper.getLabel(props.language,'start_date', "Start Date"), inputType:"datepicker"},
      {field:'end_date', headerName:Helper.getLabel(props.language,'end_date', "End Date"), inputType:"datepicker"},
      {field:'status', headerName:Helper.getLabel(props.language,'status',"Status"), inputType: "select", options: [true,false], valueFormatter: statusValueFormat,  cellRenderer: CustomHtmlCell, editable: false}, 
      {field:'view', headerName:Helper.getLabel(props.language,'view',"View"), editable: false, valueFormatter: displayViewLogo,  cellRenderer: CustomHtmlCell}, 
    ]

    let security = props.security;
    let frontendIds= Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders_listing.purchase_orders_listing)){
      return <Fragment>
      <Alert message='NO ACCESS' type='danger' />
      </Fragment>
    }

  return (
    <div className='purchase_orders_listing_archive'>
      <div className='container-fluid'>
        <NavigationHeder backUrl='/procure' title={ `<span style="color:#757575;">${Helper.getLabel(props.language,'home',"Home")} > </span> Purchase Orders ` }>
        <ButtonNew to="/purchase-orders-details" title={Helper.getLabel(props.language,'new',"New")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders_listing.purchase_orders_listing_main_create_button)} /> 
        </NavigationHeder> 
        {loading ? <SimpleLoading /> : null}
        <ExtHeaderLine title={Helper.getLabel(props.language,'purchase_orders',"Purchase Orders")} />
        <AgGrid id="rs_purchase_orders_listing" afterDeleteButtons={FilterDropdown} hideDelete={true} onCellClicked={CellHandler} header={Columns_Headings} data={purchaseOrdersListing} addBtnHide={true} hideSave={true} viewOnly={true}/>
        </div>
    </div>
  )
}
 

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
  const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders_listing.purchase_orders_listing
}


export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(PurchaseOrderListing, SecurityOptions))))