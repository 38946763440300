import React, { Component } from 'react';
import MasterComonent from "./MasterComonent";
const MasterComponentWraper = (InnerComponent) =>{
    class MasterComponentWraperClass extends Component {
        render() {
            return  ( <MasterComonent  {...this.props}><InnerComponent {...this.props} /></MasterComonent>);
        }
    }
    return MasterComponentWraperClass;
}
export default MasterComponentWraper;
