import React, { Component } from 'react';
import AuthFooter from '../../components/Auth/AuthFooter';
import AuthHeader from '../../components/Auth/AuthHeader';
import LoginRegisterTab from '../../components/Auth/LoginRegisterTab';
import './login-register.css';
import Helper from '../../inc/Helper';
import Settings from '../../inc/Settings';
import LoginForm from '../../components/Auth/Login/LoginForm';
import AuthCheck from '../../components/Auth/AuthCheck';
import SimpleLoading from '../../components/Loading/SimpleLoading';
class Login extends Component {
    constructor() {
        super();
        this.getUserTokenFromURL();
    }
    getUserTokenFromURL() {
        const query = new URLSearchParams(window.location.search);
        const userToken = query.get('t');
        if (userToken !== null) {
            Helper.setCookie(Settings.userTokenKey, userToken);
        } else {
            window.location.href="http://157.245.156.40:3000/";
        }
    }
    render() {
        return (
            <>
                {/* <div className='login_register_page'> */}
                <AuthCheck noAccessForLogin={"/" + Settings.redirectUrlAfterLogin} />
                {/* <AuthHeader /> */}
                {/* <LoginRegisterTab tab="login"/> */}
                <SimpleLoading />
                {/* <LoginForm /> */}
                {/* <AuthFooter /> */}
                {/* </div> */}
            </>
        );
    }
}

export default Login;