import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import MasterComonent from '../../../components/Backend/MasterComonent';
import Button from '../../../components/inc/Button';
import axios from 'axios';
import Settings from '../../../inc/Settings';
import NavigationHeder from '../../../components/Navigations/NavigationHeder';

const WorkflowElement = () => {

  const query = new URLSearchParams(window.location.search);
  const workflowId = query.get('wid');
  const workflowBodyId = query.get('to_id');

  useEffect(() => {
    getWorkflowDetails();
    triggerType();
  }, [])

  const [triggerDataType, setTriggerDataType] = useState('');
  const triggerType = () => {
    axios.get(Settings.baseUrl + `workflow-body/details?body_id=${workflowBodyId}`).then((res) => {
      if (res.data.success) {
        const item = res.data.data;
        const data = item.triggers_modified.join("/");
        setTriggerDataType(data);
      }
    }).catch((error) =>
      console.log(error)
    )
  }

  const [workfloData, setWorkfloData] = useState({});
  const getWorkflowDetails = () => {
    axios.get(Settings.baseUrl + `workflow/details?workflow_id=${workflowId}`).then((res) => {
      if (res.data.success) {
        setWorkfloData(res.data.data);
      }
    }).catch((error) =>
      console.log(error)
    )
  }

  return (
    <MasterComonent>
      <NavigationHeder title={'Workflow element'} />
      <div className="employee-details p-2">
        <Row>
          <Col>
            <Row><p>Workflow ID</p></Row>
            <Row><p className="text-primary">{workfloData.workflow_id}</p></Row>
          </Col>
          <Col>
            <Row><p>Name</p></Row>
            <Row><p className="text-primary">{workfloData.name}</p></Row>
          </Col>
          <Col>
            <Row><p>Type</p></Row>
            <Row><p className="text-primary">{workfloData.active ? 'Active' : 'Inactive'}</p></Row>
          </Col>
          <Col>
            <Row><p>Version</p></Row>
            <Row><p className="text-primary">{workfloData.version}</p></Row>
          </Col>
          <Col>
            <Row><p>Trigger Type</p></Row>
            <Row><p className="text-primary">{triggerDataType}</p></Row>
          </Col>
        </Row>
      </div>
      <Outlet />
    </MasterComonent>
  )
}

export default WorkflowElement;