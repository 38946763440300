import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {Dropdown, DropdownButton, Form} from 'react-bootstrap'; 
import SelectDrop from '../../../src/assets/select.svg'
import './Select.css'


const Select = ({header, options}) => {
    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(options);
    const [selectedItem, setSelectedItem] = useState(null);
    
    const toggleDropdown = () => setOpen(!isOpen);
    
    const handleItemClick = (id) => {
      selectedItem === id ? setSelectedItem(id) : setSelectedItem(id);
      setOpen(false)
    }
  return ( 
 
    <Dropdown onClick={toggleDropdown} className='dropdown-toggle'>
    <Dropdown.Toggle variant='custom' size='sm'> 
      <div className='row'>
         <div className='col-3'>
         {selectedItem ? items.find(item => item.id === selectedItem).label : header}   
         </div>
         <div className='col-5'>
         <div className='icons-dropdown'>
         {isOpen &&
          <FontAwesomeIcon icon={faChevronRight} className='icon open' />
        } 
          {!isOpen && 
          <FontAwesomeIcon icon={faChevronRight} className='icon' />} 
          </div>
         </div>
      </div>
    </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-body'>
          {options.map(item => (  
            <Dropdown.Item className='dropdown-item' onClick={e => handleItemClick(e.target.id)} id={item.id} key={item.id}>
             <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}>• </span>
             {item.label}
            </Dropdown.Item>  
          ))}
      </Dropdown.Menu>
    </Dropdown> 

  )
}

export default Select;