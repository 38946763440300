import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import GeneralDistributionTab from "../../Purchase Orders Internal/Edit PO/GeneralDistributionTab";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import Tabs from "../../Widgets/Tabs/Tabs";
import LineDistribution from "./LineDistribution";

class DistributionTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalInfo: [],
      gridAPIObj: [],
    };
  }

  componentDidUpdate() {
    console.log(this.props);
  }

  render() {
    let distributionNavs = [
      {
        title: Helper.getLabel(
          this.props.language,
          "distribution",
          "Distribution"
        ),
        isActive: true,
      },
      { title: Helper.getLabel(this.props.language, "segments", "Segments") },
    ];

    return (
      <Tabs
        id="list_view_parent_tab"
        tabContantsId="list_view_parent_tab_contents"
        navs={distributionNavs}
        className="purchase-orders-list-view-tabs"
        showAll={false}
        scrollOffset="300"
      >
        <div className="container-fluid">
          <LineDistribution
            cartItem={this.props.activeCartItem}
            source_id={9}
            viewOnly={this.props.viewOnly}
          />
        </div>
        <div className="container-fluid">
          <SegmentsDistribution
            id="purchase_requisition_lines_site_segments"
            distribution_source_id={null}
            totalAmount={null}
            integrator={null}
            source_id={9}
          />
        </div>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeCartItem: state.prShop.activeCartItem,
  };
};

export default connect(mapStateToProps)(DistributionTabs);
