import React, {useState, useEffect} from 'react';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import Collapse from '../../inc/Collapse';
import DropDownInput from '../../Forms/Dropdown';
import Tabs from '../../Widgets/Tabs/Tabs';
import $ from 'jquery'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';

const PriceDiscount = (props) => {
    const [priceType, setPriceType] = useState([])
    const [discountType, setDiscountType] = useState([])

    let PriceDiscountNavs = [
        {title:Helper.getLabel(props.language,'price','Price'), isActive: true, },
        {title:Helper.getLabel(props.language,'discount','Discount')},  
    ]

    useEffect(() => { 
        get_price_type_list()
        get_discount_type_list()
    }, [])

    let priceTypeListCalled = false
    let discountTypeListCalled = false

    const get_price_type_list = () => {
      if(priceTypeListCalled){
        return;
      }
      let api = Api
      priceTypeListCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+`/enum/itempricingtype`,{}).then(function(res){
        if(res.data.status==="success"){ 
          let UPDATED_STATE = []
          res.data.data.forEach((element) => {
            UPDATED_STATE.push({
            value: element.id,
            label: element.name
          })
        })
        setPriceType(UPDATED_STATE)
        }
      })
    }
    
       const get_discount_type_list = () => {
        if(discountTypeListCalled){
          return
        }
        let api = Api
        discountTypeListCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/item_discounttype`,{}).then(function(res){
          if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
             UPDATED_STATE.push({
              value: element.itemdiscounttype_id,
              label: element.name
            })
          })
          setDiscountType(UPDATED_STATE)
          }
        })
       }
    

    const PriceTypeHandler = (event) => {
        let price_type = event.target.value
       props.setEditItemPricing((prevState) => {
        return{
          ...prevState,
          pricing_type: price_type 
        }
       })
      }
      
      const PriceUnitHandler = (event) => {
        let priceunit = event.target.value
        if (/\D/.test(priceunit)) { // Check for non-numeric characters
          Helper.alert('Please provide Numeric values!', 'error')
          return;
        }
       props.setEditItemPricing((prevState) => {
        return{
          ...prevState,
          pricing_unit: parseInt(priceunit)
        }
       })
      }
      
      const PriceHandler = (event) => {
        let pricediscount = event.target.value
        if (/\D/.test(pricediscount)) { // Check for non-numeric characters
          Helper.alert('Please provide Numeric values!', 'error')
          return;
        }
        props.setEditItemPricing((prevState) => {
            return{
            ...prevState,
            price: parseInt(pricediscount)
            }
        })
      }
      
      const DiscountNameHandler = (event) => {
        let discountname = event.target.value
        props.setEditItemDiscount((prevState) => {
          return {
            ...prevState,
            name: discountname
          }
        }) 
      }
      
      const DiscountTypeHandler = (event) => {
        let discounttype = event.target.value 
        props.setEditItemDiscount((prevState) => {
          return {
            ...prevState,
            itemdiscounttype_id: parseInt(discounttype) 
          }
        }) 
      }
      
      const DiscountUnitHandler = (event) => {
        let discountunit = event.target.value
        if (/\D/.test(discountunit)) { // Check for non-numeric characters
          Helper.alert('Please provide Numeric values!', 'error')
          return;
        }
        props.setEditItemDiscount((prevState) => {
          return {
            ...prevState,
            discount_unit: parseInt(discountunit)
          }
        }) 
      }
      
      const DiscountHandler = (event) => {
        let discountname = event.target.value
        if (/\D/.test(discountname)) { // Check for non-numeric characters
          Helper.alert('Please provide Numeric values!', 'error')
          return;
        }
        props.setEditItemDiscount((prevState) => {
          return {
            ...prevState,
            discount: parseInt(discountname)
          }
        }) 
      }
      
      const EffectiveDateHandler = (event) => { 
        props.setEditItemDiscount((prevState) => {
          return {
            ...prevState,
            effective_date: $('.effective_date_id').val()
          }
        }) 
      }
      
      const ExpiryDateHandler = (event) => { 
        props.setEditItemDiscount((prevState) => {
          return {
            ...prevState,
            expire_date: $('.expiry_date_id').val()
          }
        }) 
      }
       

  return (
    <Collapse className='mb-4 price_discount' open={true} title={Helper.getLabel(props.language,'price_&_discount',"Price & Discount")}>
        <Tabs navs={PriceDiscountNavs} showAll={false} scrollOffset= "500">
        <div className='container-fluid price'>
        <div className='row'>
          <div className='col-lg-4'>
            <DropDownInput required={true} onChange={PriceTypeHandler} value={props.editItemPricing.pricing_type} options={priceType} label={Helper.getLabel(props.language,'type',"Type")}  id='price_type_id' />
          </div>
          <div className='col-lg-4'> 
            <Input onChange={PriceUnitHandler} value={props.editItemPricing.pricing_unit} label={Helper.getLabel(props.language,'price_unit_quantity',"Price Unit Quantity")} type='number'  />
          </div>
          <div className='col-lg-4'> 
            <Input onChange={PriceHandler} value={props.editItemPricing.price} label={Helper.getLabel(props.language,'price',"Price")} type='number'  />
          </div>
        </div>
        </div>

        <div className='container-fluid discount'>
        <div className='row'>
          <div className='col-lg-4'> 
            <Input onChange={DiscountNameHandler} value={props.editItemDiscount.name} label={Helper.getLabel(props.language,'name',"Name")} type='text'  />
          </div>
          <div className='col-lg-4'> 
            <DropDownInput required={true} onChange={DiscountTypeHandler} value={props.editItemDiscount.item_discounttype_id} options={discountType} label={Helper.getLabel(props.language,'type',"Type")} id='item_discounttype_id' />
          </div>
          <div className='col-lg-4'> 
            <Input onChange={DiscountUnitHandler} value={props.editItemDiscount.discount_unit} label={Helper.getLabel(props.language,'discount_unit_quantity',"Discount Unit Quantity")} type='number'  />
          </div>
          <div className='col-lg-4'> 
            <Input onChange={DiscountHandler} value={props.editItemDiscount.discount} label={Helper.getLabel(props.language,'discount',"Discount %")} type='number'   />
          </div>
          <div className='col-lg-4'> 
            <InputDatePicker inputClassName='effective_date_id' onChange={EffectiveDateHandler} value={props.editItemDiscount.effective_date} label={Helper.getLabel(props.language,'effective_date',"Effective Date")} name='effective_date' id='effective_date_id'  />
          </div>
          <div className='col-lg-4'> 
            <InputDatePicker inputClassName='expiry_date_id' onChange={ExpiryDateHandler} value={props.editItemDiscount.expire_date } label={Helper.getLabel(props.language,'expiry_date',"Expiry Date")} name='expiry_date' id='expiry_date_id'  />
          </div>
          </div>
        </div>

        </Tabs>
      </Collapse>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }

export default connect(mapStateToProps) (PriceDiscount);