import Settings from "../inc/Settings";
import ActionTypes from "../inc/ActionsTypes";

const defaultState = {
    formate:Settings.defaultDateFormate
}
const DateFormateReducer = (state = defaultState, actions) =>{
    if(actions.type === ActionTypes.SET_DATE_FORMATE && actions.payload){
        state = {
            ...state,
            formate:actions.payload
        }
    }
    return state;
}
export default DateFormateReducer;